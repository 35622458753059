import DiscountsV2ComponentController from './discountsV2.component.controller';

export default class DiscountsV2Component implements ng.IComponentOptions {
    template: string = require('./discountsV2.html');
    bindings: { [binding: string]: string } = {
        year: '<',
        shareDiscounts: '<'
    };
    controller: Function = DiscountsV2ComponentController;

    static factory() {
        const component: Function = () => {
            return new DiscountsV2Component();
        };

        return component();
    }
}