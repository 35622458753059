interface IFocusDirectiveScope extends ng.IScope {
    /**
        DOM selector for input container element
    */
    container: string;

    /**
        DOM selector for input element to focus on
    */
    input: string;
}

interface IHTMLElement extends HTMLElement {
    closest(selectors: string): IHTMLElement;
}

export default class CommentsFocusInputDirective implements ng.IDirective {
    restrict: string = 'A';
    scope: Object = {
        container: '@',
        input: '@'
    };

    constructor() { }

    link = (scope: IFocusDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void => {

        element.on('click touchend', ($event: JQueryEventObject) => {
            const instanceElement: IHTMLElement = element[0] as IHTMLElement;
            const container: IHTMLElement = instanceElement.closest(scope.container);
            const input: ng.IAugmentedJQuery = angular.element(container.querySelector(scope.input));

            if (input) {
                input.focus();
            }
        });

        scope.$on('$destroy', () => {
            element.remove();
            element.off('click touchend');
        });
    };

    static factory() {
        const directive = () => {
            return new CommentsFocusInputDirective();
        };

        return directive;
    };
}