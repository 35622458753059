import {ICommissionAdvanceRequestService} from './commissionAdvanceRequest.service.interface';
import {CommissionAdvanceRequest} from './commissionAdvanceRequest';
import {CommissionAdvanceRequestRow} from './commissionAdvanceRequestRow';
import {ICommissionAdvanceRequestDetails} from './commissionAdvanceRequestDetails.interface';
import {ICommissionRequestActivity} from '../commission/commissionRequestActivity.interface';

export default class CommissionAdvanceRequestService implements ICommissionAdvanceRequestService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getCommissionAdvanceRequests(): ng.IPromise<Array<CommissionAdvanceRequest>> {
        return this.$http.get<Array<CommissionAdvanceRequest>>('{API-ApprovalTool}/commission-advance-requests')
            .then((response) => {
                return response.data;
            });
    }

    getCommissionAdvanceDetails(salesYear: number, salesAgencyId: number): ng.IPromise<ICommissionAdvanceRequestDetails> {
        return this.$http
            .get<ICommissionAdvanceRequestDetails>(`{API-ApprovalTool}/commission-advance-detail/salesagencies/${salesAgencyId}/salesyears/${salesYear}/v2`)
            .then((response) => {
                return response.data;
            });
    }

    approve(request: CommissionAdvanceRequestRow): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/commission-advance-request/approve/v2`, request);
    }

    saveCommissionAdvanceOverride(agencyId: number, percentage: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/agency/${agencyId}/advance-override`, percentage);
    }

    deleteCommissionAdvanceOverride(agencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.delete(`{API-ApprovalTool}/agency/${agencyId}/advance-override`);
    }

    getCommissionAdvanceOverride(agencyId: number): ng.IPromise<string> {
        return this.$http.get<string>(`{API-ApprovalTool}/agency/${agencyId}/advance-override`)
            .then((response) => {
                return response.data;
            });
    }

    deny(request: CommissionAdvanceRequestRow): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/commission-advance-request/deny/v2`, request);
    }

    calculateCommissionAdvanceData(overridePercent: number, salesYear: number, salesAgencyId: number): ng.IPromise<ICommissionAdvanceRequestDetails> {
        return this.$http
            .get<ICommissionAdvanceRequestDetails>(`{API-ApprovalTool}/commission-advance-detail/override-percent/${overridePercent}/salesagencies/${salesAgencyId}/salesyears/${salesYear}/v2`)
            .then((response) => {
                return response.data;
            });
    }

    getCommissionAdvanceSavedDetails(requestId: number): ng.IPromise<ICommissionAdvanceRequestDetails> {
        return this.$http.get<ICommissionAdvanceRequestDetails>(`{API-ApprovalTool}/commission-advance-detail/requests/${requestId}/v2`)
            .then((response) => {
                return response.data;
            });
    }

    getRequestDetails(request: CommissionAdvanceRequest): ng.IPromise<ICommissionAdvanceRequestDetails> {
        if (request.statusId === 3) {
            return this.getCommissionAdvanceDetails(request.salesYear, request.salesAgencyId);
        } else {
            return this.getCommissionAdvanceSavedDetails(request.requestId);
        }
    }

    getSavedCommissionAdvanceRequest(requestId: number): ng.IPromise<CommissionAdvanceRequestRow> {
        return this.$http.get(`{API-ApprovalTool}/commission-advance-requests/${requestId}/saved`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                const row: CommissionAdvanceRequestRow = new CommissionAdvanceRequestRow(response.data.request);
                row.addDetails(response.data.details);

                return row;
            });
    }

    getActivity(requestId: number): ng.IPromise<Array<ICommissionRequestActivity>> {
        return this.$http.get<Array<ICommissionRequestActivity>>(`{API-ApprovalTool}/commission-advance-detail/requests/${requestId}/activities`)
            .then((response) => {
                return response.data;
            });
    }
}
