import ApproverSearchBarSideNavService from '../../components/approverSearchBar/approverSearchBarSideNav.service';
import { EncircaCommissionAdvanceRequestRow } from '../encircaCommissionAdvanceRequestRow';
import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';
import { IApproverSearchBarOptionsModel } from '../../components/approverSearchBar/models/approverSearchBarOptionsModel.model.interface';
import ServicesCommissionAdvanceApproverSearchService from './servicesCommissionAdvanceApproverSearch.service';

let async: any = require('async');

export default class ServicesCommissionAdvanceApproverSearchController {

    maximumRowsToDisplay: number = 200;
    loadingOptions: boolean = false;
    searching: boolean = false;
    requests: Array<any>;
    totalRequests: number = 0;
    shownRequests: number = 0;
    sideNavComponentId: string = 'search';
    private concurrentDetailsCalls: number = 3;

    options: IApproverSearchBarOptionsModel;
    constructor(private $mdMedia: ng.material.IMedia,
                private $document: ng.IDocumentService,
                private servicesCommissionAdvanceApproverSearchService: ServicesCommissionAdvanceApproverSearchService,
                private approverSearchBarSideNavService: ApproverSearchBarSideNavService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.loadingOptions = true;

        if (this.$mdMedia('max-width: 1280px')) {
            this.openSearchNavAsync();
        }

        this.servicesCommissionAdvanceApproverSearchService.getSearchOptions()
            .then((response: ng.IHttpPromiseCallbackArg<IApproverSearchBarOptionsModel>) => {
                this.options = response.data;
            });
    }

    onSearch(criteria: any): void {
        this.searching = true;

        (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

        this.servicesCommissionAdvanceApproverSearchService.getSearchResults(criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.totalRequests = response.data.totalRequests;
                this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
                this.requests = response.data.requestList.map(req => new EncircaCommissionAdvanceRequestRow(req));
                this.loadDetailsForRequests();

                this.closeSearchNav();
            })
            .catch(() => {
                this.requests = [];
            })
            .finally(() => {
                this.searching = false;
            });
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    private loadDetailsForRequests() {
        const queue = async.queue((row: EncircaCommissionAdvanceRequestRow, callback: Function) => {
            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private getRequestDetails(row: EncircaCommissionAdvanceRequestRow): ng.IPromise<any> {
        if (row.request.statusId === 3) {
            return this.servicesCommissionAdvanceApproverSearchService
                .getCommissionAdvanceDetail(row.request.requestId)
                .then(details => row.addDetails(details));
        } else {
            return this.servicesCommissionAdvanceApproverSearchService
                .getCommissionAdvanceSavedDetails(row.request.requestId)
                .then(details => row.addDetails(details));
        }
    }

    private openSearchNavAsync(): ng.IPromise<any> {
        return this.approverSearchBarSideNavService.openSearchNavAsync();
    }

    openSearchNav(): ng.IPromise<any> {
        return this.approverSearchBarSideNavService.openSearchNav();
    }

    closeSearchNav(): ng.IPromise<any> {
        return this.approverSearchBarSideNavService.closeSearchNav();
    }
}