/* eslint-disable angular/component-limit, angular/factory-name */
(() => {
    angular
        .module('approval-tool')
        .factory('appInsightsClientVersionInterceptorFactory', appInsightsClientVersionInterceptorFactory);

    appInsightsClientVersionInterceptorFactory.$inject = [
        'appInsightsClientVersionService',
        '$q'
    ];

    function appInsightsClientVersionInterceptorFactory(
        appInsightsClientVersionService: any,
        $q: ng.IQService
    ) {
        return {
            'request': function (config: any) {
                appInsightsClientVersionService.configure(config.headers);
                return config || $q.when(config);
            }
        };
    }
})();