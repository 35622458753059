export function encodeUriInterceptor($q: ng.IQService) {

    const interceptor: ng.IHttpInterceptor = {

        // URIEncodes the request data sent to the xml gateway
        request(config: ng.IRequestConfig): any {
            if (config.method.toLowerCase() === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=UTF-8') {
                config.transformRequest = transformFormData;
                return config || $q.when(config);
            }

            return config;
        }
    };

    return interceptor;

    function transformFormData(obj: ng.IHttpRequestTransformer) {
        const str: string[] = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
        return str.join('&');
    }

}