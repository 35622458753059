export default class BalanceWriteOffSearchController {

    constructor(
        private $mdSidenav: ng.material.ISidenavService
    ) {
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }
}
