import InfinityPackageOverrideDetailService from './infinityPackageOverrideDetail.service'; 
Application.registerRepository('infinityPackageOverrideDetailService', InfinityPackageOverrideDetailService);

import InfinityPackageOverrideDetailController from './infinityPackageOverrideDetail.controller';
Application.registerController('InfinityPackageOverrideDetailController', InfinityPackageOverrideDetailController);

import InfinityPackageOverrideDetailBarComponent from '../detail/detailBar/infinityPackageOverrideBar.component';
Application.registerComponent('infinityPackageOverrideDetailBar', InfinityPackageOverrideDetailBarComponent.factory());

import InfinityPackageOverrideCommentsComponent from '../detail/comments/infinityPackageOverrideComments.component';
Application.registerComponent('infinityPackageOverrideComments', InfinityPackageOverrideCommentsComponent.factory());

import InfinityPackageOverrideCommentsService from '../detail/comments/infinityPackageOverrideComments.service';
Application.registerRepository('infinityPackageOverrideCommentsService', InfinityPackageOverrideCommentsService);