import {AgencyBalanceSummary} from './agencyBalanceSummary.model';

export default class AgencyBalanceSummaryServiceV2 {
    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getBalanceSummary(salesPeriodId: number, salesYear: number, agencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<AgencyBalanceSummary>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/agencies/${agencyId}/sales-years/${salesYear}/salesperiods/${salesPeriodId}`);
    }

    getBalanceSummaryByRequest(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<AgencyBalanceSummary>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/${requestId}/agency-balance-summary`);
    }
}