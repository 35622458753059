import {ISupportSearchSampleService} from './supportSearchSample.service.interface';
import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';
import { SampleRequest } from '../../sample/sampleRequest.model';
import SampleDetailDisplayService from '../../sample/detail/sampleDetailDisplay.service';

export default class SupportSearchSampleController {

    maximumRowsToDisplay: number = 1000;

    searching: boolean = false;
    criteria: string = '';
    requests: SampleRequest[];
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;

    constructor(
        private $document: ng.IDocumentService,
        private supportSearchSampleService: ISupportSearchSampleService,
        private sampleDetailDisplayService: SampleDetailDisplayService
    ) {
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.supportSearchSampleService.getSampleSearchResults(this.getFormattedCriteria())
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                    this.requestGroups = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    private getFormattedCriteria(): string {
        return this.criteria.replace('\\', '').replace('"', '');
    }

    private processResponse(data: any): void {

        this.requests = data.results;
        this.totalRequests = data.count;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;

        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests;

        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesYear + ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;
    }

    showDialog($event: MouseEvent, request: SampleRequest): void {
        this.sampleDetailDisplayService.showSampleDetails($event, request, 'SampleDetailV2Controller');
    }

}