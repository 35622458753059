import {IInfinityPackageOverrideApproverSearchService} from './infinityPackageOverrideApproverSearch.service.interface';
import {IInfinityPackageOverrideApproverSearchModel} from './models/infinityPackageOverrideApproverSearch.model.interface';

export default class InfinityPackageOverrideApproverSearchService implements IInfinityPackageOverrideApproverSearchService {

    constructor(private $http: ng.IHttpService, private $q) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/packageoverride/requests/approversearch`, {timeout: 600000});
    }

    getSearchResults(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/packageoverride/requests/approversearch`, criteria, { timeout: 600000 });     
    }

    getPackageNames(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/packageoverride/requests/approversearch/packagename`, criteria, { timeout: 180000 });
    }

    getSalesAgencies(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: IInfinityPackageOverrideApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }
}
