import {BalanceExceptionHistory} from './balanceExceptionHistory.model';

export default class BalanceExceptionHistoryServiceV2 {
    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getHistory(salesAgencyId: number, salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<BalanceExceptionHistory>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/history/agencies/${salesAgencyId}/salesperiods/${salesPeriodId}`);
    }
}