import {ISupportSearchEncircaCommissionAdvanceService} from './supportSearchEncircaCommissionAdvance.service.interface';
import {IApproverSearchModel} from '../approver/models/approverSearch.model.interface';
import {ISupportSearchEncircaCommissionAdvanceSearchResult} from './supportSearchEncircaCommissionAdvanceSearchResult.interface';

export default class SupportSearchEncircaCommissionAdvanceService implements ISupportSearchEncircaCommissionAdvanceService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<ISupportSearchEncircaCommissionAdvanceSearchResult>> {
        return this.$http.get(`{API-ApprovalTool}/services-commission-advance-requests-all/` + criteria);
    }
}
