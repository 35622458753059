angular
    .module('approval-tool')
    .factory(
        'appInsightsServerPerformanceQueue',
        [
            function () {
                'use strict';

                class AppInsightsQueue {
                    key: string;

                    constructor(key: string) {
                        this.key = key;
                    }

                    enqueue(telemetry: any): void {
                        'use strict';

                        try {
                            if (!telemetry) {
                                throw new Error('telemetry must not be null or undefined.');
                            }

                            let queue: any[] = JSON.parse(localStorage.getItem(this.key) || '[]');

                            queue.push(telemetry);

                            localStorage.setItem(this.key, JSON.stringify(queue));
                        } catch (ex) {
                            angular.noop(ex);
                        }
                    }

                    dequeue(): any {
                        'use strict';

                        let result: any = null;

                        try {
                            let queue: any[] = JSON.parse(localStorage.getItem(this.key) || '[]');

                            if (queue && queue.length > 0) {
                                result = queue.shift();
                                localStorage.setItem(this.key, JSON.stringify(queue));
                            }
                        } catch (ex) {
                            angular.noop(ex);
                        }

                        return result;
                    }
                }

                return new AppInsightsQueue('telemetry-sensors-performance-server');
            }
        ]
    );