import './balanceWriteOffNavigation.scss';

import BalanceWriteOffNavigationController from './balanceWriteOffNavigation.controller';

export default class BalanceWriteOffNavigationComponent implements ng.IComponentOptions {
    template: string = require('./balanceWriteOffNavigation.html');
    controller: Function = BalanceWriteOffNavigationController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new BalanceWriteOffNavigationComponent();
        };

        return component();
    }
}