import { AgencySummaryModel } from './agencySummary.model';

export default class AgencySummaryService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getAgencySummary(salesAgencyId: number): ng.IHttpPromise<AgencySummaryModel> {
        return this.$http.get(`{API-ApprovalTool}/bpp/agencies/${salesAgencyId}`);
    }
}
