import { forEach } from "lodash";
import { IUser } from "../../../core/models/user.model.interface";
import { IUserService } from "../../../core/services/user.service.interface";
import { IAgencyDiscountMasterService } from "./AgencyDiscountMaster.service.interface";
import { SalesPeriod } from "./SalesPeriod.model";

import HistoryDialogController from "./dialog/history.dialog.controller";
import { ILocationService } from "angular";
import { IActiveElementHTMLElement } from "../../../core/interfaces/activeElementHTMLElement.interface";

export default class agencyDiscountMasterController {
    user: IUser;
    permissions: any;
    salesPeriods: any[];
    masterList: Array<any>;
    masterListHistory: Array<object>;
    productLineList: Array<any>;
    masterListUnfiltered: Array<any>;
    productLineListUnfiltered: Array<any>;
    operations: Array<any>;
    salesAgencies: any;
    selectedSalesPeriodId: number = 0;
    selectedSalesAgencyId: number;
    canEdit: boolean;
    isApproving: boolean;
    isDenying: boolean;
    isSupportUser: boolean;
    searchCriteria: string;
    searching: boolean;
    selectedOperationIds: number[];
    isLoading: boolean = true;

    isCurrentYear: boolean = false;
    flexChartColors = ['green', 'rgb(30, 145, 235)', 'gray', 'rgb(247, 70, 74)'];
    flexChartData: any[] = [0, 0, 0, 0];
    flexChartLabels = ['Used', 'Pending', 'Remaining', 'Over'];
    totalChartColors = ['green', 'rgb(30, 145, 235)', 'gray', 'rgb(247, 70, 74)'];
    totalChartData: any[] = [0, 0, 0, 0];
    totalChartLabels = ['Used', 'Pending', 'Remaining', 'Over'];
    flexLegend = '';
    totalLegend = '';
    agencyFlexDiscountUsedPending = 0;
    agencyFlexDiscountUsedImplemented = 0;
    agencyTotalDiscountUsedPending = 0;
    agencyTotalDiscountUsedImplemented = 0;
    agencyFlexDiscountThreshold = 0;
    agencyTotalDiscountThreshold = 0;
    agencyFlexDiscountRemaining = 0;
    agencyTotalDiscountRemaining = 0;
    pendingFlexFundBudget = 0;
    pendingTotalDiscountBudget = 0;
    implementedFlexFundBudget = 0;
    implementedTotalDiscountBudget = 0;
    sumTotalInvoiceValue = 0;
    inSeasonEnabled = false;
    isProductLineEstimateFixEnabled = false;
    budgetUsedLoaded = false;

    buttonText = 'Deselect All';

    constructor(
        private $mdSidenav: ng.material.ISidenavService,
        private $mdDialog: ng.material.IDialogService,
        private agencyDiscountMasterService: IAgencyDiscountMasterService,
        private userService: IUserService,
        private $location: ILocationService,
        private $document: ng.IDocumentService,
        private $q: ng.IQService) {
        this.$onInit();
    }

    $onInit(): void {

        $fsLDFeatureFlagService.isEnabledAsync('OZ.701897.InSeason')
            .then(enabled => {
                this.inSeasonEnabled = enabled;
            });
        $fsLDFeatureFlagService.isEnabledAsync('Sierra.AgencyMasterProductLineEstimate')
            .then(enabled => {
                this.isProductLineEstimateFixEnabled = enabled;
            });
        $fsLDFeatureFlagService.isEnabledAsync('OZ.609557.AgencyDiscountMasterSubmission')
            .then(enabled => {
                if (enabled) {
                    this.user = this.userService.get();
                    this.canEdit = true;
                    this.isSupportUser = this.$location.url() === '/support/requests/';
                    this.loadData();
                    this.flexChartColors = ['green', 'rgb(30, 145, 235)', 'gray', 'rgb(247, 70, 74)'];
                    this.flexChartData = [0, 0, 0, 0];
                    this.flexChartLabels = ['Used', 'Pending', 'Remaining', 'Over'];
                    this.totalChartColors = ['green', 'rgb(30, 145, 235)', 'gray', 'rgb(247, 70, 74)'];
                    this.totalChartData = [0, 0, 0, 0];
                    this.totalChartLabels = ['Used', 'Pending', 'Remaining', 'Over'];
                }
            });
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    loadData() {
        this.isLoading = true;
        this.agencyDiscountMasterService.getPermissions()
            .then((perms) => {
                this.permissions = perms as any;
                this.salesPeriods = this.permissions.data as any[];
                this.selectedSalesPeriodId = this.salesPeriods[0].salesPeriodId;
                this.isCurrentYear = this.IsCurrentYear(this.selectedSalesPeriodId);
                this.getSalesAgencies(this.salesPeriods[0].salesPeriodId - 2);
            }
            );
    }

    getSalesAgencies(salesPeriodId: number) {
        this.agencyDiscountMasterService.getSalesAgencies(salesPeriodId)
            .then(
                (data) => {
                    this.salesAgencies = data.data;
                }
            ).then(() => {
                if (this.salesAgencies.length > 0) {
                    this.selectedSalesAgencyId = this.salesAgencies[0].agencyId;
                    this.getDemandPlanData();
                }
            });
    }

    getMasterList(salesAgencyId: number, salesPeriod: number) {
        this.isLoading = true;
        var vm = this;
        if (this.inSeasonEnabled) {
            return this.agencyDiscountMasterService.getListV2(salesAgencyId, salesPeriod)
                .then((response) => {
                    if (vm.selectedSalesAgencyId !== salesAgencyId || vm.selectedSalesPeriodId - 2 !== salesPeriod) {
                        return;
                    }
                    this.budgetUsedLoaded = false;
                    this.setListData(response.data);
                    this.applyOperationFilter();
                    this.getAgencyBudgetUsed();
                });
        }
        return this.agencyDiscountMasterService.getList(salesAgencyId, salesPeriod)
            .then((data) => {
                if (vm.selectedSalesAgencyId !== salesAgencyId || vm.selectedSalesPeriodId - 2 !== salesPeriod) {
                    return;
                }
                this.budgetUsedLoaded = false;
                this.setListData(data.data);
                this.applyOperationFilter();
                this.getAgencyBudgetUsed();
            });
    }

    getDataBySalesPeriodId() {
        this.isCurrentYear = this.IsCurrentYear(this.selectedSalesPeriodId);
        this.getSalesAgencies(this.selectedSalesPeriodId - 2);
    }

    approve() {
        if (!this.canApprove()) {
            return false;
        }

        this.isApproving = true;

        let adjustedMasterList = this.masterList.concat(this.productLineList);
        let operationIds = adjustedMasterList.reduce((acc, list) => [...acc, ...list.operationIds], []);      
        this.agencyDiscountMasterService.approve(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2, adjustedMasterList, operationIds)
                .then(() => {
                    this.isApproving = false;
                    this.getMasterList(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2);
                });
    }

    approveV2() {
        if (!this.canApproveV2()) {
            return false;
        }
        this.isApproving = true;
        let adjustedMasterList = this.masterList.concat(this.productLineList);
        let operationIds = adjustedMasterList.reduce((acc, list) => [...acc, ...list.operationIds], []);

        if (this.isProductLineEstimateFixEnabled) {
            this.agencyDiscountMasterService.approveV3(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2, adjustedMasterList, operationIds)
                .then(() => {
                    this.isApproving = false;
                    this.getMasterList(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2);
                });  
        } else {
            this.agencyDiscountMasterService.approveV2(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2, adjustedMasterList, operationIds)
                .then(() => {
                    this.isApproving = false;
                    this.getMasterList(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2);
                });  
        }
    }

    deny() {
        this.isDenying = true;
        this.agencyDiscountMasterService.deny(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2, this.selectedOperationIds)
            .then(() => {
                this.isDenying = false;
                this.getMasterList(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2);
            })
    }

    applyFilter() {
        this.getDemandPlanData();
    }

    private masterRowIsValid(row: any) {
         if (this.isCurrentYear) {
            return this.isBudgetValid(row.totalDiscountBudget, row.totalDiscountImplementedPercent)
                && this.isBudgetValid(row.flexFundBudget, row.flexFundImplementedPercent)
                && !this.isInputOver(row.flexFundBudget)
                && !this.isInputUnder(row.flexFundBudget)
                && !this.isInputOver(row.totalDiscountBudget)
                && !this.isInputUnder(row.totalDiscountBudget)
        } else {
            return !this.isInputOver(row.flexFundBudget)
                && !this.isInputUnder(row.flexFundBudget)
                && !this.isInputOver(row.totalDiscountBudget)
                && !this.isInputUnder(row.totalDiscountBudget)
        } 
    }

    // obsolete FeatureFlag OZ.701897.InSeason
    canApprove() {
        let everyBudgetOverImplemented = !this.isCurrentYear || (this.masterList.every(x => x.flexFundInSeason >= x.flexFundImplemented && x.totalDiscountInSeason >= x.totalDiscountImplemented) && this.productLineList.every(x => x.flexFundInSeason >= x.flexFundImplemented && x.totalDiscountInSeason >= x.totalDiscountImplemented));

        // if totalDiscount or flexDiscount is zero allow they can approve as long as they have all Zeros for the field
        let allTotalDiscountsZero = false;
        let allFlexDiscountsZero = false;

        if (!!this.productLineList && this.productLineList.length > 0) {
            allTotalDiscountsZero = this.productLineList.every(x => x.totalDiscountBudget == 0);
            allFlexDiscountsZero = this.productLineList.every(x => x.flexFundBudget == 0);
        }
        else {
            allTotalDiscountsZero = this.masterList.every(x => x.totalDiscountBudget == 0);
            allFlexDiscountsZero = this.masterList.every(x => x.flexFundBudget == 0);
        }

        return !this.isApproving &&
            !this.isDenying &&
            (!!this.masterList && this.masterList.length > 0 || !!this.productLineList && this.productLineList.length > 0) &&
            (this.agencyFlexDiscountRemaining > 0 || allFlexDiscountsZero) &&
            (this.agencyTotalDiscountRemaining > 0 || allTotalDiscountsZero) &&
            everyBudgetOverImplemented;
    }

    canApproveV2() {
        if (this.isApproving
            || this.isDenying
            || (!this.masterList || this.masterList.length === 0) && (!this.productLineList || this.productLineList.length === 0)) {
            return false;
        }

        const allRowBudgetsValid = this.masterList.every(x => this.masterRowIsValid(x)) && this.productLineList.every(x => this.masterRowIsValid(x));
        if (!allRowBudgetsValid) {
            return false;
        }

        if (!this.isCurrentYear) {
            let allTotalDiscountsZero = false;
            let allFlexDiscountsZero = false;

            if (!!this.productLineList && this.productLineList.length > 0) {
                allTotalDiscountsZero = this.agencyTotalDiscountRemaining > 0 || this.productLineList.every(x => x.totalDiscountBudget == 0);
                allFlexDiscountsZero = this.agencyFlexDiscountRemaining > 0 || this.productLineList.every(x => x.flexFundBudget == 0);
            }
            else {
                allTotalDiscountsZero = this.agencyTotalDiscountRemaining > 0 || this.masterList.every(x => x.totalDiscountBudget == 0);
                allFlexDiscountsZero = this.agencyFlexDiscountRemaining > 0 || this.masterList.every(x => x.flexFundBudget == 0);
            }

            return allFlexDiscountsZero && allTotalDiscountsZero;
        }
       
        return true;
       
    }

    canDeny() {
        return !this.isApproving && !this.isDenying && (!!this.masterList && this.masterList.length > 0 || this.productLineList && this.productLineList.length > 0);
    }

    openHistory(masterListItem) {
        let history: object[] = [];
        this.agencyDiscountMasterService.getRequestHistory(masterListItem.requestId)
            .then((data) => {
                history = data.data;
            }).then(() => {
                this.$mdDialog.show({
                    template: require('./dialog/history.dialog.html'),
                    parent: angular.element(document.body),
                    locals: { "masterList": history },
                    controller: HistoryDialogController,
                    controllerAs: '$ctrl',
                    fullscreen: true

                });
            });

    }

    reflectEstimatedFlexDiscountAmount($masterIndex) {
        if (this.inSeasonEnabled) {
            this.recalculateFlexFundEstimatedValue($masterIndex);
        } else {
            if (this.isCurrentYear) {
                this.masterList[$masterIndex].estimatedFlexDiscountAmount = this.masterList[$masterIndex].flexFundInSeason *
                    this.masterList[$masterIndex].totalInvoiceValueInSeason;
            }
            else {
                this.masterList[$masterIndex].estimatedFlexDiscountAmount = this.masterList[$masterIndex].flexFundBudget *
                    this.masterList[$masterIndex].totalInvoiceValue;
                this.masterList[$masterIndex].flexFundInSeason = this.masterList[$masterIndex].flexFundBudget;
            }

            this.setFlexBudgetPercent();
        }
    }

    reflectEstimatedFlexDiscountAmountProductLine($masterIndex) {
        if (this.inSeasonEnabled) {
            if (this.isProductLineEstimateFixEnabled) {
                this.recalculateFlexFundBudgetProductLineEstimatedValueV2($masterIndex);

            } else {
                this.recalculateFlexFundBudgetProductLineEstimatedValue($masterIndex);
            }
        }
        else {
            if (this.isCurrentYear) {
                this.productLineList[$masterIndex].estimatedFlexDiscountAmount = this.productLineList[$masterIndex].flexFundInSeason *
                    this.productLineList[$masterIndex].totalInvoiceValueInSeason;
            }
            else {
                this.productLineList[$masterIndex].estimatedFlexDiscountAmount = this.productLineList[$masterIndex].flexFundBudget *
                    this.productLineList[$masterIndex].totalInvoiceValue;
                this.productLineList[$masterIndex].flexFundInSeason = this.productLineList[$masterIndex].flexFundBudget;
            }

            this.setFlexBudgetPercent();
        }
    }

    recalculateFlexFundEstimatedValue($masterIndex) {
        var requestBody = JSON.stringify({
            flexFundBudget: this.masterList[$masterIndex].flexFundBudget,
            planYearTotalInvoiceValue: this.masterList[$masterIndex].planYearTotalInvoiceValue,
            totalInvoiceValue: this.masterList[$masterIndex].totalInvoiceValue
        });

        this.agencyDiscountMasterService.getFlexFundBudgetEstimatedValue(requestBody).then((response) => {
            this.masterList[$masterIndex].estimatedFlexDiscountAmount = response.data.estimatedFlexDiscountAmount;
            this.setFlexBudgetPercent();
        });
    }

    recalculateFlexFundBudgetProductLineEstimatedValue($masterIndex) {
        var requestBody = JSON.stringify({
            flexFundBudget: this.productLineList[$masterIndex].flexFundBudget,
            totalInvoiceValue: this.productLineList.reduce((sum, product) => sum + product.totalInvoiceValue, 0),
            planYearTotalInvoiceValue: this.productLineList.reduce((sum, product) => sum + product.planYearTotalInvoiceValue, 0)
        });

            this.agencyDiscountMasterService.getFlexFundBudgetEstimatedValue(requestBody).then((response) => {
            this.productLineList[$masterIndex].estimatedFlexDiscountAmount = response.data.estimatedFlexDiscountAmount;
            this.setFlexBudgetPercent();
        });
    }

    recalculateFlexFundBudgetProductLineEstimatedValueV2($masterIndex) {
        var requestBody = JSON.stringify({
            flexFundBudget: this.productLineList[$masterIndex].flexFundBudget,
            totalInvoiceValue: this.productLineList.reduce((sum, product) => sum + product.totalInvoiceValue, 0),
            planYearTotalInvoiceValue: this.productLineList.reduce((sum, product) => sum + product.planYearTotalInvoiceValue, 0),
            productLinePlanYearTotalInvoiceValue: this.productLineList[$masterIndex].planYearTotalInvoiceValue,
            productLineTotalInvoiceValue: this.productLineList[$masterIndex].totalInvoiceValue
        });

        this.agencyDiscountMasterService.getProductLineFlexFundBudgetEstimatedValue(requestBody).then((response) => {
            this.productLineList[$masterIndex].estimatedFlexDiscountAmount = response.data.estimatedFlexDiscountAmount;
            this.setFlexBudgetPercent();
        });
    }

    reflectTotalBudgetChanges($index) {
        if (this.isCurrentYear) {
            this.masterList[$index].totalDiscountInSeason = this.masterList[$index].totalDiscountBudget;
        }
        this.setTotalDiscountPercent();
    }

    reflectTotalBudgetChangesProductLine($index) {
        var record = this.productLineList[$index];
        this.productLineListUnfiltered.forEach(x => {
            if (x.customerId === record.customerId) {
                x.totalDiscountBudget = record.totalDiscountBudget;
                if (this.isCurrentYear) {
                    x.totalDiscountInSeason = x.totalDiscountBudget;
                }
            }
        })
        this.setTotalDiscountPercent();
    }

    setFlexBudgetPercent() {
        let sumFlexFundsPending = this.pendingFlexFundBudget;
        this.masterListUnfiltered.forEach(x => { sumFlexFundsPending += (x.flexFundBudget) * x.totalInvoiceValue; });
        this.productLineListUnfiltered.forEach(x => { sumFlexFundsPending += (x.flexFundBudget) * x.totalInvoiceValue; })
        const flexFundUsedPending = this.sumTotalInvoiceValue > 0 ? sumFlexFundsPending / this.sumTotalInvoiceValue : 0;
        this.agencyFlexDiscountUsedPending = this.agencyFlexDiscountThreshold > 0 ? flexFundUsedPending * 100 : 0;

        const flexFundUsedImplemented = this.sumTotalInvoiceValue > 0 ? this.implementedFlexFundBudget / this.sumTotalInvoiceValue : 0;
        this.agencyFlexDiscountUsedImplemented = this.agencyFlexDiscountThreshold > 0 ? flexFundUsedImplemented * 100 : 0;
        this.flexChartData[0] = this.agencyFlexDiscountUsedImplemented.toFixed(1);

        if (this.agencyFlexDiscountUsedPending != null) {
            let flexThreshold = this.agencyFlexDiscountThreshold * 100;
            let flexPendingThreshold = flexThreshold - this.agencyFlexDiscountUsedImplemented;
            let flexUsed = this.agencyFlexDiscountUsedPending;
            let flexRemaining = this.agencyFlexDiscountRemaining = flexPendingThreshold - flexUsed;
            this.flexLegend = `Flex Threshold: ${flexThreshold.toFixed(1)}%`;
            this.flexChartData[1] = flexUsed > flexPendingThreshold ? flexPendingThreshold.toFixed(1) : flexUsed.toFixed(1);
            this.flexChartData[2] = flexRemaining < 0 ? 0 : flexRemaining.toFixed(1);
            this.flexChartData[3] = flexRemaining < 0 ? Math.abs(flexPendingThreshold - flexUsed).toFixed(1) : 0;
        }
    }

    setTotalDiscountPercent() {
        let sumTotalDiscountsPending = this.pendingTotalDiscountBudget;
        if (this.isCurrentYear) {
            this.masterListUnfiltered.forEach(x => { sumTotalDiscountsPending += (x.totalDiscountInSeason) * x.totalInvoiceValue; });
            this.productLineListUnfiltered.forEach(x => { sumTotalDiscountsPending += (x.totalDiscountInSeason) * x.totalInvoiceValue; })
        }
        else {
            this.masterListUnfiltered.forEach(x => { sumTotalDiscountsPending += (x.totalDiscountBudget) * x.totalInvoiceValue; });
            this.productLineListUnfiltered.forEach(x => { sumTotalDiscountsPending += (x.totalDiscountBudget) * x.totalInvoiceValue; })
        }
        const totalDiscountUsedPending = this.sumTotalInvoiceValue > 0 ? sumTotalDiscountsPending / this.sumTotalInvoiceValue : 0;
        this.agencyTotalDiscountUsedPending = this.agencyTotalDiscountThreshold > 0 ? totalDiscountUsedPending * 100 : 0;

        const totalDiscountUsedImplemented = this.sumTotalInvoiceValue > 0 ? this.implementedTotalDiscountBudget / this.sumTotalInvoiceValue : 0;
        this.agencyTotalDiscountUsedImplemented = this.agencyTotalDiscountThreshold > 0 ? totalDiscountUsedImplemented * 100 : 0;
        this.totalChartData[0] = this.agencyTotalDiscountUsedImplemented.toFixed(1);

        if (this.agencyTotalDiscountUsedPending != null) {
            let totalThreshold = this.agencyTotalDiscountThreshold * 100;
            let totalPendingThreshold = totalThreshold - this.agencyTotalDiscountUsedImplemented;
            let totalUsed = this.agencyTotalDiscountUsedPending;
            let totalRemaining = this.agencyTotalDiscountRemaining = totalPendingThreshold - totalUsed;
            this.totalLegend = `Total Threshold: ${totalThreshold.toFixed(1)}%`;
            this.totalChartData[1] = totalUsed > totalPendingThreshold ? totalPendingThreshold.toFixed(1) : totalUsed.toFixed(1);
            this.totalChartData[2] = totalRemaining < 0 ? 0 : totalRemaining.toFixed(1);
            this.totalChartData[3] = totalRemaining < 0 ? Math.abs(totalPendingThreshold - totalUsed).toFixed(1) : 0;
        }
    }

    onSearch() {
        if (!this.searching && !!this.searchCriteria && this.searchCriteria.replace(/ /g, '') !== '') {
            this.searching = true;
            this.masterList = [];
            this.masterListUnfiltered = [];
            this.productLineList = [];
            this.productLineListUnfiltered = [];

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.agencyDiscountMasterService.getSupportSearchResults(this.getFriendlyCriteria(this.searchCriteria))
                .then((response) => {
                    this.setListData(response.data);
                    this.masterList = this.masterListUnfiltered;
                    this.productLineList = this.productLineListUnfiltered;
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    loyalStatus(loyal) {
        return loyal === true ? 'Yes' : 'No';
    }

    private getFriendlyCriteria(criteria: string): string {
        return criteria.replace('\\', '').replace('"', '');
    }

    private setListData(list) {
        this.masterListUnfiltered = list.filter(x => x.productId === '').map(agencyDiscountMasterDetail => {
            if (this.isCurrentYear && !this.inSeasonEnabled) {
                agencyDiscountMasterDetail.estimatedFlexDiscountAmount = agencyDiscountMasterDetail.flexFundInSeason *
                    agencyDiscountMasterDetail.totalInvoiceValueInSeason;
            }
            return agencyDiscountMasterDetail;
        });
        this.productLineListUnfiltered = list.filter(x => x.productId != '').map(agencyDiscountMasterDetail => {
            if (this.isCurrentYear && !this.inSeasonEnabled) {
                agencyDiscountMasterDetail.estimatedFlexDiscountAmount = agencyDiscountMasterDetail.flexFundInSeason *
                    agencyDiscountMasterDetail.totalInvoiceValueInSeason;
            }
            return agencyDiscountMasterDetail;
        });

        this.isLoading = false;
    }

    private getDemandPlanData() {
        this.selectedOperationIds = [];
        this.$q.all([
            this.agencyDiscountMasterService.getAgencyBudgetThreshold(this.selectedSalesAgencyId, this.selectedSalesPeriodId),
            this.agencyDiscountMasterService.getOperationsBySalesAgencyAndSalesPeriod(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2)
        ])
            .then(([threshold, operations]) => {

                this.agencyFlexDiscountThreshold = threshold.data.budgetThreshold.flexFundsRate;
                this.agencyTotalDiscountThreshold = threshold.data.budgetThreshold.totalDiscountRate;

                this.operations = operations.data;
                if (this.operations.length > 0) {
                    for (let j = 0; j < this.operations.length; j++) {
                        this.selectedOperationIds.push(this.operations[j].id);
                    }
                }
                this.getMasterList(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2);
            })

    }
    private getAgencyBudgetUsed() {
        var allRequestIds = this.masterListUnfiltered.concat(this.productLineListUnfiltered).map(x => x.requestId);
        this.agencyDiscountMasterService.getAgencyBudgetUsed(this.selectedSalesAgencyId, this.selectedSalesPeriodId - 2, allRequestIds)
            .then(response => {
                this.pendingFlexFundBudget = response.data.pendingFlexFundBudget;
                this.implementedFlexFundBudget = response.data.implementedFlexFundBudget;
                this.pendingTotalDiscountBudget = response.data.pendingTotalDiscountBudget;
                this.implementedTotalDiscountBudget = response.data.implementedTotalDiscountBudget;
                this.sumTotalInvoiceValue = response.data.sumTotalInvoiceValue;
                this.setFlexBudgetPercent();
                this.setTotalDiscountPercent();
                this.budgetUsedLoaded = true;
            });
    }

    public selectAll() {
        if (this.selectedOperationIds.length == this.operations.length) {
            this.selectedOperationIds = [];
            this.buttonText = 'Select All';
        } else {
            if (this.operations.length > 0) {
                this.selectedOperationIds = [];
                for (let j = 0; j < this.operations.length; j++) {
                    this.selectedOperationIds.push(this.operations[j].id);
                }
            }
            this.buttonText = 'Deselect All';
        }

        this.applyOperationFilter();
    }

    public applyOperationFilter() {
        if (this.operations && (this.selectedOperationIds.length == this.operations.length)) {
            this.buttonText = 'Deselect All';
        } else {
            this.buttonText = 'Select All';
        }

        if (!!this.masterListUnfiltered) {
            this.masterList = this.masterListUnfiltered.filter(x => {
                return this.selectedOperationIds.filter(value => x.operationIds.includes(value)).length > 0;
            });

        };

        if (!!this.productLineListUnfiltered) {
            this.productLineList = this.productLineListUnfiltered.filter(x => {
                return this.selectedOperationIds.filter(value => x.operationIds.includes(value)).length > 0;
            });
        }

    }
    public IsCurrentYear(selectedSalesPeriodId: number): boolean {
        let ret = false;
        if (this.inSeasonEnabled) {
            if (this.salesPeriods == null || this.salesPeriods.length == 0) {
                ret = false;
            }
            else {
                this.salesPeriods.forEach((x) => {
                    if (x.salesPeriodId == selectedSalesPeriodId)
                        ret = x.isCurrent;
                });
            }
        }
        return ret;
    }

    public isBudgetValid(budget: number, implemented: number): boolean {
        return budget >= implemented;
    }

    isInputOver(budget) {
        if (budget > 1) {
            return true;
        }
        return false;
    }

    isInputUnder(budget) {
        if (budget < 0) {
            return true;
        }
        return false;
    }
}