import { IUserService } from '../../../../core/services/user.service.interface';


export default class InfinityPackageOverrideNavigationComponentController {
    // bindings
    page: string;
    canRequestOverride: boolean;

    constructor( private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService) {
    }

    navigateToRequestPackageOverride(): void {
        this.redirectNow('/infinityPackageOverride/overrideRequest');
    }

    navigateToPendingRequest(): void {
        this.redirectNow('/infinityPackageOverride/pendingRequests');
    }

    navigateToSearch(): void {
        this.redirectNow('/infinityPackageOverride/search');
    }

    private redirectNow(url: string) {
        this.$timeout(() => {
            this.$location.url(url);
        }, 0);
    }
}