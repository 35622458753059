import './infinityPackageOverridePendingList.scss';
import routing from './infinityPackageOverridePendingList.routes';

Application.registerConfig(routing);

//// add service
import InfinityPackageOverrideNavigationService from './navigation/infinityPackageOverrideNavigation.service';
Application.registerRepository('infinityPackageOverrideNavigationService', InfinityPackageOverrideNavigationService);

import InfinityPackageOverridePendingListController from './infinityPackageOverridePendingList.controller';
Application.registerController('InfinityPackageOverridePendingListController', InfinityPackageOverridePendingListController);

import InfinityPackageOverridePendingNavigationComponent from './navigation/infinityPackageOverrideNavigation.component';
Application.registerComponent('infinityPackageOverrideNavigation', InfinityPackageOverridePendingNavigationComponent.factory());

import InfinityPackageOverridePendingListService from './infinityPackageOverridePendingList.service';
Application.registerRepository('infinityPackageOverridePendingListService', InfinityPackageOverridePendingListService);