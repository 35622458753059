import { SampleDetail } from './sampleDetail.model';

export default class SampleDetailService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    getDetail(requestShareId: number, invoiceId: number, year: number, shareId: number): ng.IPromise<SampleDetail> {
        return this.$http.get(`{API-ApprovalTool}/sample/requestShares/${requestShareId}/invoices/${invoiceId}/year/${year}/shares/${shareId}/detail`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return new SampleDetail(
                    null,
                    this.processRequiredApprovalsResponse(response.data.requiredApprovals),
                    response.data.comments,
                    null,
                    response.data.operationSales,
                    null);
            });
    }

    getDetailV2(requestShareId: number, invoiceId: number, year: number, shareId: number): ng.IPromise<SampleDetail> {
        return this.$http.get(`{API-ApprovalTool}/sample/request-shares/${requestShareId}/invoices/${invoiceId}/years/${year}/shares/${shareId}/detail/v2`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return new SampleDetail(
                    null,
                    this.processRequiredApprovalsResponse(response.data.requiredApprovals),
                    response.data.comments,
                    null,
                    response.data.operationSales,
                    null);
            });
    }

    private processRequiredApprovalsResponse(data: any): any {
        return {
            approvalList: data.approvalList,
            finalApprovalDate: data.finalApprovalDate
        };
    }
}