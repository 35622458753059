// local resources
import './login.scss';

import routing from './login.routes';
Application.registerConfig(routing);

import LoginRepository from './login.repository';
Application.registerRepository('loginRepository', LoginRepository);

import LoginController from './login.controller';
Application.registerController('LoginController', LoginController);
