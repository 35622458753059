import './balanceExceptionRequestsV2.scss';
import './createBalanceExceptionRequestsV2.scss';
import './summary/agencyBalanceSummaryV2.scss';
import './history/balanceExceptionHistoryV2.scss';

import balanceExceptionRouting from './balanceExceptionRequests.routes';
Application.registerConfig(balanceExceptionRouting);

import BalanceExceptionRequestsServiceV2 from './balanceExceptionRequests.service';
Application.registerRepository('balanceExceptionRequestsServiceV2', BalanceExceptionRequestsServiceV2);

import BalanceExceptionPendingRequestsControllerV2 from './balanceExceptionPendingRequests.controller';
Application.registerController('BalanceExceptionPendingRequestsControllerV2', BalanceExceptionPendingRequestsControllerV2);

import BalanceExceptionAllPendingRequestsControllerV2 from './balanceExceptionAllPendingRequests.controller';
Application.registerController('BalanceExceptionAllPendingRequestsControllerV2', BalanceExceptionAllPendingRequestsControllerV2);

import CreateBalanceExceptionRequestControllerV2 from './createBalanceExceptionRequest.controller';
Application.registerController('CreateBalanceExceptionRequestControllerV2', CreateBalanceExceptionRequestControllerV2);

import BalanceExceptionRequestDetailsControllerV2 from './balanceExceptionRequestDetails.controller';
Application.registerController('BalanceExceptionRequestDetailsControllerV2', BalanceExceptionRequestDetailsControllerV2);

import SupportCreateBalanceExceptionRequestControllerV2 from './supportCreateBalanceExceptionRequest.controller';
Application.registerController('SupportCreateBalanceExceptionRequestControllerV2', SupportCreateBalanceExceptionRequestControllerV2);

import BalanceExceptionNavigationComponentV2 from './navigation/balanceExceptionNavigation.component';
Application.registerComponent('balanceExceptionNavigationV2', BalanceExceptionNavigationComponentV2.factory());

import AgencyBalanceSummaryComponentV2 from './summary/agencyBalanceSummary.component';
Application.registerComponent('agencyBalanceSummaryV2', AgencyBalanceSummaryComponentV2.factory());

import AgencyBalanceSummaryServiceV2 from './summary/agencyBalanceSummary.service';
Application.registerRepository('agencyBalanceSummaryServiceV2', AgencyBalanceSummaryServiceV2);

import BalanceExceptionHistoryComponentV2 from './history/balanceExceptionHistory.component';
Application.registerComponent('balanceExceptionHistoryV2', BalanceExceptionHistoryComponentV2.factory());

import BalanceExceptionHistoryServiceV2 from './history/balanceExceptionHistory.service';
Application.registerRepository('balanceExceptionHistoryServiceV2', BalanceExceptionHistoryServiceV2);

import BalanceExceptionTypeComponentV2 from './createComponents/exceptionType/exceptionType.component';
Application.registerComponent('balanceExceptionTypeV2', BalanceExceptionTypeComponentV2.factory());

import BalanceExceptionInformationComponentV2 from './createComponents/exceptionInformation/exceptionInformation.component';
Application.registerComponent('balanceExceptionInformationV2', BalanceExceptionInformationComponentV2.factory());

import BalanceExceptionUnitSettlementChargesComponentV2 from './createComponents/unitSettlementCharges/unitSettlementCharges.component';
Application.registerComponent('balanceExceptionUnitSettlementChargesV2', BalanceExceptionUnitSettlementChargesComponentV2.factory());

import UnitSettlementChargesServiceV2 from './createComponents/unitSettlementCharges/unitSettlementCharges.service';
Application.registerRepository('unitSettlementChargesServiceV2', UnitSettlementChargesServiceV2);