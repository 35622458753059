import {RedirectService} from './redirect.service';
import {StorageService} from './storage.service';
import {HeaderService} from './header.service';
import {UserService} from './user.service';
import {MetatagService} from './metatag.service';

Application.registerService('redirectService', RedirectService);
Application.registerService('storageService', StorageService);
Application.registerService('headerService', HeaderService);
Application.registerService('userService', UserService);
Application.registerService('metatagService', MetatagService);
