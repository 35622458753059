import PlanningDetailsService from './planningDetails.service';
import { CustomerPlanningDetailsModel } from '../customerPlanningDetails.model';
export default class PlanningDetailsController {

    planningYear: number = -1;
    planningDetails: CustomerPlanningDetailsModel;
    canEdit: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    agencyCustomerId: number = -1;
    salesAgencyId: number = -1;

    constructor(private planningDetailsService: PlanningDetailsService) {
    }

    public preventPropagation($event: MouseEvent) {
        $event.stopPropagation();
    }

    saveIsLoyal(validator: ng.INgModelController) {
        if (this.canEdit && validator.$dirty) {
            this.saving = true;
            this.planningDetailsService.saveCustomerIsLoyal(this.salesAgencyId,
                this.agencyCustomerId,
                this.planningDetails.plannedIsLoyal)
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    saveInfinityLevel(validator: ng.INgModelController) {
        if (this.canEdit && validator.$dirty) {
            this.saving = true;
            this.planningDetailsService.saveCustomerInfinityDiscountLvl(this.salesAgencyId,
                this.agencyCustomerId,
                this.planningDetails.plannedInfinityLevel)
                .finally(() => {
                    this.saving = false;
                });
        }
    }
}