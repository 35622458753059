import {MaterialCharge} from './materialCharge.model';

export class CreateUnitSettlementChargesRequestData {
    constructor(
        public salesAgencyId: number,
        public salesAgencyName: string,
        public salesPeriodId: number,
        public territoryId: string,
        public agencyBalanceExceptionTypeId: number,
        public agencyBalanceExceptionTypeName: string,
        public requestType: number,
        public comments: string,
        public productLineId: string,
        public materials: Array<MaterialCharge>) {
        // noop
    }
}