import { ILeftNavService } from './leftNav.service.interface';
import { RequestCount } from './requestCount.model';

export default class LeftNavService implements ILeftNavService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    pendingRequestCount(): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestCount>> {
        return this.$http.get(`{API-ApprovalTool}/request-count/pending-requests`);
    }

    pendingRequestCountV2(): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestCount>> {
        return this.$http.get(`{API-ApprovalTool}/v2/request-count/pending-requests`);
    }

    agencyDiscountRequestCount(): ng.IPromise<ng.IHttpPromiseCallbackArg<number>> {
        return this.$http.get(`{API-ApprovalTool}/agency-discount/pending-request-count`);
    }

    isCommissionAdvanceRequestApprover(): ng.IPromise<ng.IHttpPromiseCallbackArg<boolean>> {
        return this.$http.get(`{API-ApprovalTool}/commission-advance-request/isapprover`);
    }
}
