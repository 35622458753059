export class DiscountDisplayModel {

    implementedCpa: Array<any> = [];
    discountLines: Array<any> = [];
    pendingCpa: Array<any> = [];
    replant: Array<any> = [];
    doubleCrop: Array<any> = [];
    doubleCropPending: Array<any> = [];
    cactus: Array<any> = [];
    cactusPending: Array<any> = [];

    cpaTotal: any = {};
    total: any = {};
    totalPending: any = {};
    allPendingTotals: any = {};
    customerAllPendingTotals: any = {};
    totalReplant: any = {};
    replantPendingTotal: any = {};
    replantPending: Array<any> = [];
    replantPendingApproved: any = {};

    doubleCropTotal: any = {};
    doubleCropPendingTotal: any = {};
    doubleCropPendingApproved: any = {};

    cactusTotal: any = {};
    cactusPendingTotal: any = {};
    cactusPendingApproved: any = {};

    loadedPreviousCustomer: boolean = false;
    loadedCurrentCustomer: boolean = false;
    loadedCurrentShare: boolean = false;

    loaded(): boolean {
        return this.loadedCurrentCustomer && this.loadedCurrentShare && this.loadedPreviousCustomer;
    }
}