import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';
import { MassPricingRequest } from '../request/models/massPricingRequest.model';
import { MassPricingDetailResponse, MassPricingDetailRequest } from './massPricingDetail.model';
import { IMassPricingDetailResponse } from './massPricingDetail.model.interface';
import MassPricingDetailService from './massPricingDetail.service';


export default class MassTreatmentPricingDetailController {

    isLoading: boolean;
    user: IUser;
    detail: MassPricingDetailResponse;
    comments: IMassPricingDetailResponse[];

    commentForm: ng.IFormController;
    commentText: string = '';

    isView: boolean = false;
    isDeny: boolean = false;

    constructor(private $mdDialog: ng.material.IDialogService,
        private massPricingDetailService: MassPricingDetailService,
        public request: MassPricingRequest,
        private userService: IUserService,
        public detailType: string) {

    }

    $onInit(): void {
        this.user = this.userService.get();
        this.loadDetails();
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    deny(commentText: string): void {
        var isAccepted = false;
        var massPricingApprovalTypeId = 2

        var detailData = new MassPricingDetailRequest(this.request.fileName, this.removeSpecialCharacters(this.request.fileVersion), isAccepted, massPricingApprovalTypeId, commentText);

        this.massPricingDetailService.sendDenyRequest(detailData)
            .then(() =>
                this.cancel()
            );
    }

    disableDeny(): boolean {
        return this.commentForm && (this.commentForm.$invalid);
    }

    showValidationMessages(): boolean {
        return this.commentForm && (this.commentForm.$dirty && this.commentForm.$invalid);
    }

    private loadDetails(): void {
        this.isLoading = true;

        if (this.detailType == 'view') {
            this.getComments();
            this.isView = true;
        }

        if (this.detailType == 'deny') {
            this.isDeny = true;
        }

        this.isLoading = false;
    }

    private getComments() {
        this.comments = [];

        this.massPricingDetailService.getComments(this.request.fileName, this.removeSpecialCharacters(this.request.fileVersion))
            .then((response) => this.comments = response.data);
    }

    private removeSpecialCharacters(input: string): string {
        return input.replace(/[:.-]/g, '');
    }
}