import { PlanningTerritoryRequestModel } from './planningTerritoryRequest.model';

export default class PendingTerritoryRequestService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<PlanningTerritoryRequestModel>>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/pending-territory-requests`);
    }

    approveRequest(requestId: number) : ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/requests/${requestId}/approve`, {});
    }

    denyRequest(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/requests/${requestId}/deny`, {});
    }
}
