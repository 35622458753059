import SampleDetailBarComponentController from './sampleDetailBar.component.controller';

export default class SampleDetailBarComponent implements ng.IComponentOptions {
    template: string = require('./sampleDetailBar.html');
    bindings: { [binding: string]: string } = {
        request: '<',
        finalApprovalDate: '<'
    };
    controller: Function = SampleDetailBarComponentController;

    static factory() {
        const component: any = () => {
            return new SampleDetailBarComponent();
        };

        return component();
    }
}