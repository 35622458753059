import {SampleApproverSearchModel} from './models/sampleApproverSearch.model';

export default class SampleApproverSearchService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/sample/approversearch`, {timeout: 600000});
    }

    getSearchResults(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch`, criteria, { timeout: 600000 });
    }

    getSalesAgencies(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }

    export(criteria: SampleApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/sample/approversearch/export`, { criteria: criteria }, { timeout: 600000 });
    }
}
