import './sampleApproverSearch.scss';

import routing from './sampleApproverSearch.routes';
Application.registerConfig(routing);

import SampleApproverSearchService from './sampleApproverSearch.service';
Application.registerRepository('sampleApproverSearchService', SampleApproverSearchService);

import ApproverSearchController from './sampleApproverSearch.controller';
Application.registerController('SampleApproverSearchController', ApproverSearchController);
