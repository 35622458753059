export default class TerritoryPlanningService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveDiscountFundsHeld(territoryId: string, discountFundsHeld: number): ng.IPromise<ng.IHttpPromiseCallbackArg<void>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/territory-planning/territories/${territoryId}/discount-funds-held/save`, discountFundsHeld);
    }

    saveFlexFundsHeld(territoryId: string, flexFundsHeld: number): ng.IPromise<ng.IHttpPromiseCallbackArg<void>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/territory-planning/territories/${territoryId}/flex-funds-held/save`, flexFundsHeld);
    }
}