import { IRedirectService } from '../../../../core/services/redirect.service.interface';

import BwoRequestController from './bwoRequest.controller';
import { BwoRequestService } from './bwoRequest.service';

export default class SupportCreateBwoRequestController extends BwoRequestController {
    hideToolbar: boolean = true;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $document: ng.IDocumentService,
        protected $q: ng.IQService,
        protected bwoRequestService: BwoRequestService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected redirectService: IRedirectService,
        protected $mdToast: ng.material.IToastService) {
        super($timeout, $document, $q, bwoRequestService, $mdSidenav, redirectService, $mdToast);
    }

    submit(): void {
        this.loading.submit = true;

        this.bwoRequestService.supportSubmit(this.criteria)
            .then(() => {
                this.loading.submit = false;
                this.$mdToast.show(
                    this.$mdToast.simple().textContent('Balance write-off request saved')
                    .position('bottom center'));
                this.resetForm();
            });
    }
}