import PlanningPendingRequestDetailService from './planningPendingRequestDetail.service';
import { PlanningPendingRequestDetailModel } from './planningPendingRequestDetail.model';
import { IAngularEvent } from 'angular';

export default class PlanningPendingRequestDetailController {

    navigationText: string = this.territoryId;
    loading: boolean = true;
    requests: Array<any> = [];
    
    constructor(public territoryId: string,
        public requestType: string,
        public requestId: number,
        private planningPendingRequestDetailService: PlanningPendingRequestDetailService,
        private $mdDialog: ng.material.IDialogService,
        private $q: ng.IQService) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.getList();
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    private getList(): void {
        this.loading = true;

        // Need seperate urls because in-season/pre-season can overlap (small window)
        if (this.requestType === 'Pre-Season') {
            this.planningPendingRequestDetailService.getPreseasonList(this.requestId).then(t => {
                this.groupRequestByAgencies(t.data);
            }).finally(() => this.done());
        }
        else {
            this.planningPendingRequestDetailService.getInseasonList(this.territoryId).then(t => {
                this.groupRequestByAgencies(t.data);
            }).finally(() => this.done());
        }
    }

    groupRequestByAgencies(data: Array<PlanningPendingRequestDetailModel>) {
        data.forEach((r) => r.dateRequested = new Date(r.requestedDate));

        this.requests = _.chain(data).groupBy(function (data) {
            return data.year + ' ' + data.salesAgencyName + ' (' + data.salesAgencyId + ') ';
        }).map((value, key) => ({ agency: key, requests: value })).value();
    }

    canApprove(request: PlanningPendingRequestDetailModel): boolean {
        return request.canApprove && this.requestType === 'In-Season';
    }

    canDeny(request: PlanningPendingRequestDetailModel): boolean {
        return request.canDeny && this.requestType === 'In-Season';
    }

    approve(event: IAngularEvent, request: PlanningPendingRequestDetailModel) {
        event.stopPropagation();

        if (this.loading) {
            return this.$q.resolve();
        }

        this.loading = true;
        return this.planningPendingRequestDetailService.approveRequest(request.requestId)
            .then(() => this.getList());
    }

    deny(event: IAngularEvent, request: PlanningPendingRequestDetailModel) {
        event.stopPropagation();

        if (this.loading) {
            return this.$q.resolve();
        }

        this.loading = true;
        return this.planningPendingRequestDetailService.denyRequest(request.requestId).then(() => this.getList());
    }

    private done(): void {
        this.loading = false;
    }
}