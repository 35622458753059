import PendingTerritoryRequestService from './pendingTerritoryRequest.service';
import { PlanningTerritoryRequestModel } from './planningTerritoryRequest.model';
import  PlanningPendingRequestDetail  from './detail/planningPendingRequestDetail.controller';
import { IAngularEvent } from 'angular';

export default class BusinessPartnerPlanningPendingRequestsController {

    navigationText: string = 'My Pending Planning Requests';
    sortOrder: string = 'dateRequested';
    loading: boolean = true;
    requests: Array<PlanningTerritoryRequestModel> = [];

    constructor(private pendingTerritoryRequestService: PendingTerritoryRequestService,
        private $mdDialog: ng.material.IDialogService,
        private $q: ng.IQService,
        private $scope: ng.IScope) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.getList();
    }

    private getList(): void {
        this.loading = true;
        this.pendingTerritoryRequestService.getList().then(t => {
            this.requests = t.data;
            this.requests.forEach((r) => r.dateRequested = new Date(r.requestedDate));
        }
        ).finally(() => this.done());
    }

    canApprove(request: PlanningTerritoryRequestModel): boolean {
        return request.canApprove && request.requestType === 'Pre-Season';
    }

    canDeny(request: PlanningTerritoryRequestModel): boolean {
        return request.canDeny && request.requestType === 'Pre-Season';
    }

    approve(event: IAngularEvent, request: PlanningTerritoryRequestModel) {
        event.stopPropagation();

        if (this.loading) {
            return this.$q.resolve();
        }

        this.loading = true;
        return this.pendingTerritoryRequestService.approveRequest(request.requestId)
            .then(() => this.getList());
    }

    deny(event: IAngularEvent, request: PlanningTerritoryRequestModel) {
        event.stopPropagation();

        if (this.loading) {
            return this.$q.resolve();
        }

        this.loading = true;
        return this.pendingTerritoryRequestService.denyRequest(request.requestId).then(() => this.getList());
    }

    openRequestDetail($event: MouseEvent, territoryId: string, requestType: string, requestId: number): void {
        this.$mdDialog.show({
            template: require('./detail/planningPendingRequestDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: PlanningPendingRequestDetail,
            controllerAs: 'vm',
            locals: {
                territoryId: territoryId,
                requestType: requestType,
                requestId: requestId
            },
            fullscreen: true,
            hasBackdrop: false,
            escapeToClose: false,
            onRemoving: () => {
                this.getList();
            }
        });
    }

    private done(): void {
        this.loading = false;
    }
}