export default class PlanningDetailsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveCustomerIsLoyal(salesAgencyId: number, agencyCustomerId: number, isLoyal: boolean) {
        return this.$http.post(`{API-ApprovalTool}/bpp/sales-agencies/${salesAgencyId}/customer-summary/agency-customer/${agencyCustomerId}/loyal`, isLoyal);
    }

    saveCustomerInfinityDiscountLvl(salesAgencyId: number, agencyCustomerId: number, discountLevel: string) {
        return this.$http.post(`{API-ApprovalTool}/bpp/sales-agencies/${salesAgencyId}/customer-summary/agency-customer/${agencyCustomerId}/discount-level`, { discountLevel: discountLevel });
    }
}