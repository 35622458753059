import PricingNavigationComponentController from './pricingNavigation.controller';

export default class PricingNavigationComponent implements ng.IComponentOptions {
    template: string = require('./pricingNavigation.html');
    controller: Function = PricingNavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@',
        canRequest: '<',
        canMassApprove: '<'
    };

    static factory() {
        const component: () => PricingNavigationComponent = () => {
            return new PricingNavigationComponent();
        };

        return component();
    }
}