import {ISupportSearchMonthlyDrawService} from './supportSearchMonthlyDraw.service.interface';
import {ISupportSearchMonthlyDrawSearchResult} from './supportSearchMonthlyDrawSearchResult.interface';

export default class SupportSearchMonthlyDrawService implements ISupportSearchMonthlyDrawService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<ISupportSearchMonthlyDrawSearchResult>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/monthly-draw`, data);
    }
}
