module.exports = function localShortDate($filter) {

    const angularDateFilter = $filter('date');

    const filter = function(theDate) {
        'option strict';

        return angularDateFilter(theDate, 'MM/dd/yyyy');
    };

    return filter;

};