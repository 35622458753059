import {IDetailBarComponentController} from './detailBar.component.controller.interface';

import {ICpaRequest} from '../../cpa/cpaRequest.model.interface';

import {ICpaRequestService} from '../../cpa/cpaRequest.service.interface';

export default class DetailBarComponentController implements IDetailBarComponentController {
    request: ICpaRequest;
    finalApprovalDate: string;
    regularInvoiceValue: number;
    doubleCropReplantInvoiceValue: number;
    
    constructor(
        private cpaRequestService: ICpaRequestService
    ) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        if (this.request.reason.toLowerCase().indexOf('truchoice') > -1) {
              this.getTruChoiceCropProtection();
        }
    }

    private $onChanges(changesObj: any) {
        this.request.finalApprovalDate = this.finalApprovalDate;
    }

    private getTruChoiceCropProtection(): void {
        this.cpaRequestService.getTruChoiceCropProtection(this.request)
            .then((response: ng.IHttpPromiseCallbackArg<number>) =>
                this.request.truChoiceCropProtectionAmount = response.data);
    }

    isTruChoice(): boolean {
        return this.request.reason.toLowerCase().indexOf('truchoice') > -1;
    }

    showInvoiceSpinner(): boolean {
        return this.regularInvoiceValue === null || !angular.isDefined(this.regularInvoiceValue);
    }
}