export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/support/requests/', {
            template: require('./supportRequests.html'),
            controller: 'SupportRequestsController',
            controllerAs: 'vm'
        });
}

let supportRequestsCPAState: ng.ui.IState = {
    name: 'supportRequestsCPA',
    url: '/support/requests/',
    template: require('../support/supportSearch.html'),
    controller: 'SupportSearchController',
    controllerAs: 'vm'
};

let supportRequestsCommissionAdvanceState: ng.ui.IState = {
    name: 'supportRequestsCommissionAdvance',
    url: '/support/requests/',
    template: require('../supportCommissionAdvance/supportSearchCommissionAdvance.html'),
    controller: 'SupportSearchCommissionAdvanceController',
    controllerAs: 'vm'
};

let supportRequestsServicesCommissionAdvanceState: ng.ui.IState = {
    name: 'supportRequestsEncircaCommissionAdvance',
    url: '/support/requests/',
    template: require('../supportEncircaCommissionAdvance/supportSearchEncircaCommissionAdvance.html'),
    controller: 'SupportSearchEncircaCommissionAdvanceController',
    controllerAs: 'vm'
};

let supportRequestsMonthlyDrawState: ng.ui.IState = {
    name: 'supportRequestsMonthlyDrawState',
    url: '/support/requests/',
    template: require('../supportMonthlyDraw/supportSearchMonthlyDraw.html'),
    controller: 'SupportSearchMonthlyDrawController',
    controllerAs: 'vm'
};

let supportRequestsReplantState: ng.ui.IState = {
    name: 'supportRequestsReplantState',
    url: '/support/requests/',
    template: require('../supportReplant/supportSearchReplant.html'),
    controller: 'SupportSearchReplantController',
    controllerAs: 'vm'
};

let supportRequestsAgencyBalanceExceptionV2State: ng.ui.IState = {
    name: 'supportRequestsAgencyBalanceExceptionV2State',
    url: '/support/requests/',
    template: require('../supportAgencyBalanceExceptionV2/supportSearchAgencyBalanceException.html'),
    controller: 'SupportSearchAgencyBalanceExceptionControllerV2',
    controllerAs: 'vm'
};

let supportRequestsPendingAgencyBalanceExceptionV2State: ng.ui.IState = {
    name: 'supportRequestsPendingAgencyBalanceExceptionV2State',
    url: '/support/requests/',
    template: require('../supportAgencyBalanceExceptionV2/supportSearchAgencyBalanceException.html'),
    controller: 'SupportSearchPendingAgencyBalanceExceptionControllerV2',
    controllerAs: 'vm'
};

let supportCreateAgencyBalanceExceptionV2State: ng.ui.IState = {
    name: 'supportCreateAgencyBalanceExceptionV2State',
    url: '/support/requests/',
    template: require('../../agencyBalanceExceptionV2/createBalanceExceptionRequest.html'),
    controller: 'SupportCreateBalanceExceptionRequestControllerV2',
    controllerAs: 'vm'
};

let supportMyPendingBalanceWriteOffState: ng.ui.IState = {
    name: 'supportMyPendingBalanceWriteOffState',
    url: '/support/requests/',
    template: require('../../balanceWriteOff/balanceWriteOffRequests.html'),
    controller: 'SupportBalanceWriteOffRequestsController',
    controllerAs: 'vm'
};

let supportRequestsBalanceWriteOffState: ng.ui.IState = {
    name: 'supportRequestsBalanceWriteOffState',
    url: '/support/requests/',
    template: require('../supportBalanceWriteOff/supportSearchBalanceWriteOff.html'),
    controller: 'SupportSearchBalanceWriteOffController',
    controllerAs: 'vm'
};

let supportMyPendingImplementBalanceWriteOffState: ng.ui.IState = {
    name: 'supportMyPendingImplementBalanceWriteOffState',
    url: '/support/requests/',
    template: require('../../balanceWriteOff/balanceWriteOffRequests.html'),
    controller: 'SupportBalanceWriteOffManualImplementRequestsController',
    controllerAs: 'vm'
};

let supportCreateBalanceWriteOffState: ng.ui.IState = {
    name: 'supportCreateBalanceWriteOffState',
    url: '/support/requests/',
    template: require('../../balanceWriteOff/request/bwoRequest.html'),
    controller: 'SupportCreateBwoRequestController',
    controllerAs: 'vm'
};

let createPricingStateV2: ng.ui.IState = {
    name: 'createPricingStateV2',
    url: '/support/requests/',
    template: require('../../pricing/request/pricingRequestV2.html'),
    controller: 'PricingRequestV2Controller',
    controllerAs: 'vm'
};

let supportMyPendingPricingRequestState: ng.ui.IState = {
    name: 'supportMyPendingPricingRequestState',
    url: '/support/requests/',
    template: require('../../pricing/pricingRequests.html'),
    controller: 'SupportPricingPendingRequestsController',
    controllerAs: 'vm'
};

let createTreatmentPricingState: ng.ui.IState = {
    name: 'createTreatmentPricingState',
    url: '/support/requests/',
    template: require('../../pricing/request/pricingTreatmentRequest.html'),
    controller: 'PricingTreatmentRequestController',
    controllerAs: 'vm'
};

let createTreatmentPricingStateV2: ng.ui.IState = {
    name: 'createTreatmentPricingStateV2',
    url: '/support/requests/',
    template: require('../../pricing/request/pricingTreatmentRequestV2.html'),
    controller: 'PricingTreatmentRequestV2Controller',
    controllerAs: 'vm'
};

let allPricingRequestsState: ng.ui.IState = {
    name: 'allPricingRequestsState',
    url: '/support/requests/',
    template: require('../../pricing/allPricingRequests.html'),
    controller: 'AllPricingRequestsController',
    controllerAs: 'vm'
};

let supportRequestsLoanTypeChangeState: ng.ui.IState = {
    name: 'supportRequestsLoanTypeChangeState',
    url: '/support/requests/',
    template: require('../supportLoanTypeChange/supportSearchLoanTypeChange.html'),
    controller: 'SupportSearchLoanTypeChangeController',
    controllerAs: 'vm'
};

let supportPlanningRequestState: ng.ui.IState = {
    name: 'supportPlanningRequestState',
    url: '/support/requests/',
    template: require('../supportPlanning/supportPlanningRequests.html'),
    controller: 'SupportSearchPlanningController',
    controllerAs: 'vm'
};

let supportPlanningRequestsState: ng.ui.IState = {
    name: 'supportPlanningRequestsState',
    url: '/support/requests/',
    template: require('../supportPlanningRequests/supportPlanningRequests.html'),
    controller: 'SupportSearchPlanningRequestsController',
    controllerAs: 'vm'
};

let supportRequestsSampleState: ng.ui.IState = {
    name: 'supportRequestsSampleState',
    url: '/support/requests/',
    template: require('../supportSample/supportSearchSample.html'),
    controller: 'SupportSearchSampleController',
    controllerAs: 'vm'
};

let supportPackageOverrideSearchRequestsState: ng.ui.IState = {
    name: 'supportPackageOverrideSearchRequestsState',
    url: '/support/requests/',
    template: require('../supportInfinityPackageOverride/supportInfinityPackageOverrideSearch.html'),
    controller: 'SupportInfinityPackageOverrideSearchController',
    controllerAs: 'vm'
};

let supportPackageOverrideRequestsState: ng.ui.IState = {
    name: 'supportPackageOverrideRequestsState',
    url: '/support/requests/',
    template: require('../../infinityPackageOverride/overrideRequest/infinityPackageOverrideRequest.html'),
    controller: 'InfinityPackageOverrideRequestController',
    controllerAs: 'vm'
};

let supportAgencyDiscountMasterState: ng.ui.IState = {
    name: 'supportAgencyDiscountMasterState',
    url: '/support/requests/',
    template: require('../../AgencyDiscountMaster/AgencyDiscountMaster.html'),
    controller: 'agencyDiscountMasterController',
    controllerAs: 'vm'
};

let supportDoaOverrideState: ng.ui.IState = {
    name: 'supportDoaOverrideState',
    url: '/support/requests/',
    template: require('../../doaOverride/doaOverride.html'),
    controller: 'DoaOverrideController',
    controllerAs: 'vm'
};

angular.module('approval-tool').config(($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider
        .state(supportRequestsCPAState)
        .state(supportRequestsCommissionAdvanceState)
        .state(supportRequestsServicesCommissionAdvanceState)
        .state(supportRequestsMonthlyDrawState)
        .state(supportRequestsReplantState)
        .state(supportRequestsAgencyBalanceExceptionV2State)
        .state(supportRequestsPendingAgencyBalanceExceptionV2State)
        .state(supportCreateAgencyBalanceExceptionV2State)
        .state(supportMyPendingBalanceWriteOffState)
        .state(supportRequestsBalanceWriteOffState)
        .state(supportMyPendingImplementBalanceWriteOffState)
        .state(supportCreateBalanceWriteOffState)
        .state(createPricingStateV2)
        .state(supportMyPendingPricingRequestState)
        .state(createTreatmentPricingState)
        .state(createTreatmentPricingStateV2)
        .state(allPricingRequestsState)
        .state(supportRequestsLoanTypeChangeState)
        .state(supportPlanningRequestState)
        .state(supportPlanningRequestsState)
        .state(supportRequestsSampleState)
        .state(supportPackageOverrideSearchRequestsState)
        .state(supportPackageOverrideRequestsState)
        .state(supportAgencyDiscountMasterState)
        .state(supportDoaOverrideState);
});