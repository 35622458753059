import {RequiredApproval} from './required.approval.model';

export default class RequiredApprovalsController {

    approvals: RequiredApproval[];

    isPending: (status: string) => boolean = this.isPendingImpl;

    private isPendingImpl(status: string): boolean {
        return status === 'Pending';
    }
}