import BalanceExceptionRequestsServiceV2 from './balanceExceptionRequests.service';

import {RequestLine} from './core/requestLine.model';

import {IUser} from '../../../core/models/user.model.interface';
import {IUserService} from '../../../core/services/user.service.interface';

import BalanceExceptionRequestDetailsControllerV2 from './balanceExceptionRequestDetails.controller';

export default class BalanceExceptionPendingRequestsControllerV2  {

    navigationText: string = 'My pending requests';
    requests: Array<RequestLine>;
    loading: boolean = true;
    nothingToApprove: () => boolean = this.nothingToApproveImpl;
    canRequest: boolean = false;
    total: number = 0;

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService,
        private $mdDialog: ng.material.IDialogService,
        private balanceExceptionRequestsServiceV2: BalanceExceptionRequestsServiceV2,
        private userService: IUserService,
        private $timeout: ng.ITimeoutService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    canShowTable(): boolean {
        return !this.loading;
    }

    viewDetails($event: MouseEvent, request: RequestLine): void {
        this.$mdDialog.show({
                template: require('./balanceExceptionRequestDetails.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                controller: BalanceExceptionRequestDetailsControllerV2,
                controllerAs: 'vm',
                locals: {
                    request: request
                },
                fullscreen: true
            })
            .finally(() => this.getPending());
    }

    approve($event: MouseEvent, request: RequestLine): void {
        $event.stopPropagation();
        request.processing = true;

        this.balanceExceptionRequestsServiceV2.approve(request.requestId)
            .then(() => {
                _.remove(this.requests,
                    (line: RequestLine) => {
                        return line.requestId === request.requestId;
                    });
            })
            .finally(() => {
                request.processing = false;
            });
    }

    deny($event: MouseEvent, request: RequestLine): void {
        $event.stopPropagation();
        request.processing = true;

        this.balanceExceptionRequestsServiceV2.deny(request.requestId)
            .then(() => {
                _.remove(this.requests,
                    (line: RequestLine) => {
                        return line.requestId === request.requestId;
                    });
            })
            .finally(() => {
                request.processing = false;
            });
    }

    private activate() {
        this.user = this.userService.get();
        this.canRequest = this.user.canCreateAgencyBalanceExceptions;
        this.getPending();
    }

    private getPending(): void {
        this.loading = true;
        this.balanceExceptionRequestsServiceV2.getPendingList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    private processResponse(response: any): void {
        if (response && response.data) {
            this.requests = response.data;
            this.total = response.data.length;
        }
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }
}