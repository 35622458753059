import DocumentPreviewDialogController from "./dialog/documentPreview.dialog.controller";
import { ViewDocument } from "./viewDocument.model.";

export default class ViewDocumentComponentController {

    shareId: number;
    invoiceId: number;
    proposalId: number;
    buttonText: string;
    businessPartnerId: number;
    businessPartnerName: string;

    constructor(private $http: ng.IHttpService,
        private $mdDialog: ng.material.IDialogService) {
        // noop
    }

    $onInit(): void {}

    public viewDocument($event: MouseEvent) {
        this.showDialog($event);
    }

    public showDialog($event: MouseEvent): void {
        var viewDocumentModel  = new ViewDocument(this.invoiceId, this.shareId, this.proposalId, this.businessPartnerId, this.businessPartnerName);
            var modalConfig = {
                template: require('././dialog/documentPreview.dialog.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                controller: DocumentPreviewDialogController,
                controllerAs: '$ctrl',
                locals: {
                    viewDocumentModel: viewDocumentModel
                },
                fullscreen: true,
                multiple: true
            };

            this.$mdDialog.show(modalConfig);
    }
}
