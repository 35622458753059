import ViewDocumentComponentController from './viewDocument.component.controller';

export default class ViewDocumentComponent implements ng.IComponentOptions {
    template: string = require('./viewDocument.html');
    bindings: { [binding: string]: string } = {
        shareId: '<?',
        invoiceId: '<?',
        proposalId: '<?',
        buttonText: '<?',
        businessPartnerId: '<?',
        businessPartnerName: '<?'
    };
    controller: Function = ViewDocumentComponentController;

    static factory() {
        const component: Function = () => {
            return new ViewDocumentComponent();
        };

        return component();
    }
}