export class CreateRequestMaterialData {
    constructor(
        public requestId: number,
        public statusId: number,
        public salesAgencyId: number,
        public salesAgencyName: string,
        public salesPeriodId: number,
        public territoryId: string,
        public agencyBalanceExceptionTypeId: number,
        public agencyBalanceExceptionTypeName: string,
        public requestType: number,
        public amount: number,
        public requestedDate: Date,
        public comments: string,
        public productId: string,
        public subproductId: string) {
        // no-op
    }
}