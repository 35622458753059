import {ISampleDetailBarComponentController} from './sampleDetailBar.component.controller.interface';

import {SampleRequest} from '../../sampleRequest.model';

export default class SampleDetailBarComponentController implements ISampleDetailBarComponentController{
    request: SampleRequest;
    finalApprovalDate: string;

    constructor() {
    }

    private $onChanges(changesObj: any) {
        this.request.finalApprovalDate = this.finalApprovalDate;
    }

}