launchDarklyInit.$inject = [
    '$rootScope',
    '$location'
];

export default function launchDarklyInit($rootScope: ng.IRootScopeService, $location: ng.ILocationService) {

    const initializeLD = () => {
        const port: Number = Number(document.location.port);
        if (port >= 9876) { // running karma tests - do not initialize Launch Darkly
            return;
        }
        const path: string = $location.path();
        const metaTag: Element = document.head.querySelector('meta[name=launchdarkly-clientkey]'); // eslint-disable-line angular/document-service
        const ldClientKey: string = metaTag ? metaTag.getAttribute('content') : '';
        if (ldClientKey.length === 0) {
            throw Error('A LaunchDarkly client key was expected, but not found!');
        }

        // Add the $fsLDFeatureFlagService to window
        /* eslint-disable */
        // @ts-ignore
        window.$fsLDFeatureFlagService = new LDFeatureFlagService(ldClientKey);
        /* eslint-enable */
        const currentUser: string = sessionStorage.getItem('upn') || null;
        console.log(`state changed to [${path}] for user [${currentUser || 'anonymous user'}]`);
        (window as any).$fsLDFeatureFlagService.initialize(currentUser || ''); // initialize with empty UPN
    };

    const destructor = $rootScope.$on('$locationChangeStart', initializeLD);

    $rootScope.$on('$destroy', () => { destructor(); });
}