import { PackageOverrideRequest } from './packageOverrideRequest.model';
import { ISupportInfinityPackageOverrideService } from './supportInfinityPackageOverride.service.interface';

export default class SupportInfinityPackageOverrideService implements ISupportInfinityPackageOverrideService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSupportSearchResult(criteria: string): ng.IPromise<any> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/packageoverride`, data)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });;
    }
}
