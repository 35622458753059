import { IUserService } from '../../../core/services/user.service.interface';

import PricingPendingRequestsController from './pricingPendingRequests.controller';
import PricingRequestsService from './pricingRequests.service';

export default class SupportPricingPendingRequestsController extends PricingPendingRequestsController  {
    hideToolbar: boolean = true;

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected $mdDialog: ng.material.IDialogService,
        protected pricingRequestsService: PricingRequestsService,
        protected userService: IUserService,
        protected $timeout: ng.ITimeoutService
    ) {
        super($location, $mdSidenav, $mdDialog, pricingRequestsService, userService, $timeout);
    }
}