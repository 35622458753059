import {IOperationSaleGrowth} from './operationSalesGrowth.model.interface';
import {IOperationSaleGrowthTotal} from './operationSalesGrowthTotal.model.interface';

import {IOperationSalesController} from './operation.sales.controller.interface';

export default class OperationSalesController implements IOperationSalesController {
    year: number;
    previousYear: number;
    name: string;

    operation: {
        operationSaleGrowths: IOperationSaleGrowth[],
        operationSaleGrowthsTotal: IOperationSaleGrowthTotal
    };

    constructor() { }

    $onInit(): void {
        this.previousYear = this.year - 1;
    }
}