import {IProposalCpaApproverSearchService} from './proposalCpaApproverSearch.service.interface';
import {IProposalCpaApproverSearchModel} from './models/proposalCpaApproverSearch.model.interface';

export default class ProposalCpaApproverSearchService implements IProposalCpaApproverSearchService {

    constructor(private $http: ng.IHttpService, private $q) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/proposalcpa/requests/approversearch`, {timeout: 600000});
    }

    getSearchResults(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/proposalcpa/requests/approversearch`, criteria, { timeout: 600000 });     
    }

    getTypes(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/proposalcpa/requests/approversearch/cpatypes`, criteria, { timeout: 180000 });
    }

    getSalesAgencies(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: IProposalCpaApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }
}
