import {BalanceExceptionHistory} from './balanceExceptionHistory.model';
import BalanceExceptionHistoryServiceV2 from './balanceExceptionHistory.service';

export default class BalanceExceptionHistoryComponentController {
    salesAgencyId: number;
    salesPeriodId: number;

    exceptions: Array<BalanceExceptionHistory>;
    exceptionTotal: number;
    requestId: number;

    loading: boolean = false;

    constructor(private balanceExceptionHistoryServiceV2: BalanceExceptionHistoryServiceV2) { }

    $onInit(): void {
        this.loadData();
    }

    private $onChanges() {
        this.loadData();
    }

    private loadData() {
        if (this.salesAgencyId && this.salesPeriodId) {
            this.loading = true;
            this.exceptionTotal = 0;

            this.balanceExceptionHistoryServiceV2.getHistory(this.salesAgencyId, this.salesPeriodId)
                .then((response) => {
                    this.exceptions = response.data;
                    if (this.requestId) {
                        _.remove(this.exceptions, (exception) => {
                            return exception.requestId === this.requestId;
                        });
                    }
                    if (this.exceptions.length) {
                        this.exceptionTotal = _.reduce(_.pluck(this.exceptions, 'amount'), (total: number, amount: number) => {
                            return total + amount;
                        });
                    }
                })
                .finally(() => this.loading = false);
        } else {
            this.exceptions = null;
        }
    }


}