export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-LoanTypeChange/',
            {
                template: require('../ltc/loanTypeChangePendingRequests.html'),
                controller: 'LoanTypeChangePendingRequestsController',
                controllerAs: 'vm'
            })
        .when('/requestType-LoanTypeChange/create',
            {
                template: require('../ltc/request/loanTypeChangeRequest.html'),
                controller: 'LoanTypeChangeRequestController',
                controllerAs: 'vm'
            })
        .when('/requestType-LoanTypeChange/all', {
            template: require('../ltc/allLoanTypeChangeRequests.html'),
            controller: 'AllLoanTypeChangeRequestsController',
            controllerAs: 'vm'
        });
}