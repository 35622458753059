import { IEncircaCommissionAdvanceRequestService } from './encircaCommissionAdvanceRequest.service.interface';
import { EncircaCommissionAdvanceRequestRow } from './encircaCommissionAdvanceRequestRow';
import { IEncircaCommissionAdvanceRequestController } from './encircaCommissionAdvanceRequest.controller.interface';

let async: any = require('async');

export default class EncircaCommissionAdvanceRequestController implements IEncircaCommissionAdvanceRequestController {

    loading: boolean = true;
    requests: Array<EncircaCommissionAdvanceRequestRow>;
    approvedList: number[];
    deniedList: number[];

    private concurrentDetailsCalls: number = 3;

    constructor(private encircaCommissionAdvanceRequestService: IEncircaCommissionAdvanceRequestService,
        private $mdSidenav: ng.material.ISidenavService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
        this.approvedList = [];
        this.deniedList = [];
    }

    public approve($event: MouseEvent, row: EncircaCommissionAdvanceRequestRow, index: number): void {
        $event.stopPropagation();
        this.encircaCommissionAdvanceRequestService.approve({ requestId: row.request.requestId, agencyBalanceAmount: row.details.agencyBalanceData.agencyAccountBalance, advanceAmount: row.details.advanceAmount, netCommissionableRevenue: row.details.netCommissionableRevenue, totalAdvanceCommission: row.details.totalAdvanceCommission });
        this.approvedList.push(index);
    }

    public deny($event: MouseEvent, row: EncircaCommissionAdvanceRequestRow, index: number): void {
        $event.stopPropagation();
        this.encircaCommissionAdvanceRequestService.deny({ requestId: row.request.requestId, agencyBalanceAmount: row.details.agencyBalanceData.agencyAccountBalance, advanceAmount: row.details.advanceAmount, netCommissionableRevenue: row.details.netCommissionableRevenue, totalAdvanceCommission: row.details.totalAdvanceCommission });
        this.deniedList.push(index);
    }

    public showApprovalButton(row: EncircaCommissionAdvanceRequestRow, index: number): boolean {
        return this.approvedList && this.approvedList.indexOf(index) < 0 && this.deniedList && this.deniedList.indexOf(index) < 0 && row.request.status === 'Pending';
    }

    public showApprovedStatus(row: EncircaCommissionAdvanceRequestRow, index: number): boolean {
        return (this.approvedList && this.approvedList.indexOf(index) > -1) || row.request.status === 'Approved';
    }

    public showDeniedStatus(row: EncircaCommissionAdvanceRequestRow, index: number): boolean {
        return (this.deniedList && this.deniedList.indexOf(index) > -1) || row.request.status === 'Denied';
    }

    private activate() {
        this.encircaCommissionAdvanceRequestService.getCommissionAdvanceRequests()
            .then((requests) => {
                this.requests = requests.map(req => new EncircaCommissionAdvanceRequestRow(req));
                this.loadDetailsForRequests();
            })
            .finally(() => this.done());
    }

    private loadDetailsForRequests() {
        const queue = async.queue((row: EncircaCommissionAdvanceRequestRow, callback: Function) => {
            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private done(): void {
        this.loading = false;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private getRequestDetails(row: EncircaCommissionAdvanceRequestRow): ng.IPromise<any> {
        if (row.request.status === 'Pending') {
            return this.encircaCommissionAdvanceRequestService
                .getCommissionAdvanceDetail(row.request.requestId)
                .then(details => row.addDetails(details));
        }

        return this.encircaCommissionAdvanceRequestService
                .getCommissionAdvanceSavedDetails(row.request.requestId)
                .then(details => row.addDetails(details));
    }
}