import { PricingCreateRequestData } from './models/pricingCreateRequestData.model';
import { PricingCreateRequestDataV2 } from './models/pricingCreateRequestDataV2.model';

export default class CreatePricingRequestService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getPricingSalesperiods(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/salesperiods`);
    }

    getPricingDistricts(salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/salesperiods/${salesPeriodId}/districts`);
    }

    getPricingProductLine(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/productLines`);
    }

    getPricingProduct(productLineId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/productLines/${productLineId}/products`);
    }

    getPricingTreatment(productLineId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/productLines/${productLineId}/treatments`);
    }

    getPriceType(productLineId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/productLines/${productLineId}/types`);
    }

    getPrice(salesPeriodId: number, district: string, productLineId: string, productId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pricing/salesperiods/${salesPeriodId}/districts/${district}/productlines/${productLineId}/products/${productId}/prices`);
    }

    getTreatmentPrice(salesPeriodId: number, district: string, productLineId: string, treatmentId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data = {
            salesPeriodId: salesPeriodId,
            district: district,
            productLineId: productLineId,
            treatmentId: treatmentId
        };
        return this.$http.post(`{API-ApprovalTool}/requests/pricing/treatment-price`, data);
    }

    createPricingRequest(data: PricingCreateRequestData): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/pricing/create/`, data);
    }

    createPricingRequestV2(data: PricingCreateRequestDataV2): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/pricing/create/v2`, data);
    }

    createPricingTreatmentRequest(data: PricingCreateRequestData): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/pricing/treatments/create/`, data);
    }

    createPricingTreatmentRequestV2(data: PricingCreateRequestDataV2): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/pricing/treatments/create/v2`, data);
    }
}
