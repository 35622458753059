import { ISupportSearchPlanningRequestsService } from './supportSearchPlanningRequests.service.interface';
import { ISupportSearchPlanningRequestsResult } from './supportSearchPlanningRequestsResult.interface';
import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';
import PlanningPendingRequestDetailSalesSupport from '../../../../views/requests/businessPartnerPlanning/pending/detail/planningPendingRequestDetailSalesSupport.controller';

export default class SupportSearchPlanningRequestsController {

    searching: boolean = false;
    criteria: string = '';
    requests: Array<ISupportSearchPlanningRequestsResult>;

     constructor(
        private $document: ng.IDocumentService,
         private supportSearchPlanningRequestsService: ISupportSearchPlanningRequestsService,
         private $mdDialog: ng.material.IDialogService
    ) {}

    onSearch(): void {
        this.requests = [];

        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.supportSearchPlanningRequestsService.getSearchResults(this.criteria)
                .then((response) => {
                    this.requests = response.data;
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    isTerritoryRequest(requestType) {
        return requestType === 'Pre-Season';
    }

    approve(request) {
        request.isApproved = true;
        return this.supportSearchPlanningRequestsService.approve(request.year, request.territoryId);
    }

    deny(request) {
        request.isApproved = true;
        return this.supportSearchPlanningRequestsService.deny(request.year, request.territoryId);
    }

    canApproveRequest(isApproved): boolean {
        return !isApproved;
    }

    openRequestDetail($event: MouseEvent, territoryId: string, requestType: string, salesYear: number): void {
        this.$mdDialog.show({
            template: require('../../../../views/requests/businessPartnerPlanning/pending/detail/planningPendingRequestDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: PlanningPendingRequestDetailSalesSupport,
            controllerAs: 'vm',
            locals: {
                territoryId: territoryId,
                requestType: requestType,
                salesYear: salesYear
            },
            fullscreen: true,
            hasBackdrop: false,
            escapeToClose: false
        });
    }
}