import {IPackages} from './packages.model.interface';

export class Packages implements IPackages {
    infinityDiscountPackageId: number;
    name: string;

    constructor(name: string) {
        this.infinityDiscountPackageId = -1;
        this.name = name;
    }
}