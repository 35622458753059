import './pricingRequests.scss';
import './allPricingRequests.scss';
import './massProductPricingRequests.scss';
import './massTreatmentPricingRequests.scss';
import './core/pricingCommentDialog.scss';
import './request/_bootstrap';

import routes from './pricingRequest.routes';
Application.registerConfig(routes);

import PricingNavigationComponent from './navigation/pricingNavigation.component';
Application.registerComponent('pricingNavigation', PricingNavigationComponent.factory());

import PricingRequestsService from './pricingRequests.service';
Application.registerService('pricingRequestsService', PricingRequestsService);

import PricingPendingRequestsController from './pricingPendingRequests.controller';
Application.registerController('PricingPendingRequestsController', PricingPendingRequestsController);

import SupportPricingPendingRequestsController from './supportPricingPendingRequests.controller';
Application.registerController('SupportPricingPendingRequestsController', SupportPricingPendingRequestsController);

import AllPricingRequestsController from './allPricingRequests.controller';
Application.registerController('AllPricingRequestsController', AllPricingRequestsController);

import MassProductPricingRequestsController from './massProductPricingRequests.controller';
Application.registerController('MassProductPricingRequestsController', MassProductPricingRequestsController);

import MassTreatmentPricingRequestsController from './massTreatmentPricingRequests.controller';
Application.registerController('MassTreatmentPricingRequestsController', MassTreatmentPricingRequestsController); 

import MassPricingDetailService from './detail/massPricingDetail.service';
Application.registerService('massPricingDetailService', MassPricingDetailService);

import MassPricingRequestsService from './massPricingRequests.service';
Application.registerService('massPricingRequestsService', MassPricingRequestsService);