import routing from './AgencyDiscountMaster.routes'
import './AgencyDiscountMaster.scss'
Application.registerConfig(routing);

import agencyDiscountMasterController from "./AgencyDiscountMaster.controller";
import AgencyDiscountMasterService from './AgencyDiscountMaster.service';
Application.registerController('agencyDiscountMasterController', agencyDiscountMasterController);

Application.registerService('agencyDiscountMasterService', AgencyDiscountMasterService);

import DonutChartDirective from './donut-chart/donutChart.directive';
Application.registerDirective('donutChart', DonutChartDirective.factory());