import {ProductCharges} from './productCharges.model';
import {MaterialCharge} from './materialCharge.model';
import {Reason} from './reason.model';
import UnitSettlementChargesServiceV2 from './unitSettlementCharges.service';
import {RequestOptions} from '../../core/requestOptions.model';
import BalanceExceptionRequestCriteria from '../../core/balanceExceptionRequest.criteria.model';
import {CreateBalanceExceptionRequestFormController} from '../../core/createBalanceExceptionRequestForm.interface';

export default class UnitSettlementChargesComponentController {

    form: CreateBalanceExceptionRequestFormController;

    productCharges: Array<ProductCharges>;
    reasons: Array<Reason>;

    totalUnits: number;
    totalAmount: number;

    options: RequestOptions;
    criteria: BalanceExceptionRequestCriteria;

    loadingSettlementCharges: boolean = false;
    loadingReasons: boolean = false;

    constructor(
        private unitSettlementChargesServiceV2: UnitSettlementChargesServiceV2,
        private $scope: ng.IScope) { }

    $onInit(): void {
        this.$scope.$watch('$ctrl.criteria.salesAgency', () => {
            this.loadSettlementCharges();
        });

        this.$scope.$watch('$ctrl.criteria.salesSeason', () => {
            this.loadSettlementCharges();
            this.loadSettlementReasons();
        });

        this.$scope.$watch('$ctrl.criteria.territory', () => {
            this.loadSettlementReasons();
        });

        this.loadSettlementCharges();
        this.loadSettlementReasons();
    }

    calculateTotals(): void {
        if (this.criteria.unitSettlementChargesProduct) {
            let units: number = 0;
            let amount: number = 0;

            for (let material of this.criteria.unitSettlementChargesProduct.materials) {
                this.sanitizeUnits(material);

                units += material.units;
                amount += this.roundCurrency(material.units * material.unitPrice);
            }

            this.totalUnits = units;
            this.totalAmount = amount;
        }
    }

    roundCurrency(value: number): number {
        return Math.round(Math.round(value * 1000) / 10) / 100; // Round to a tenth of a cent, then to nearest cent, then convert to decimal for more accurate rounding
    }

    canSelectProductLine(): boolean {
        return this.productCharges && this.productCharges.length > 0 && !this.loadingSettlementCharges;
    }

    canSelectReason(): boolean {
        return this.criteria.unitSettlementChargesProduct && !this.loadingReasons;
    }

    resetReason(): void {
        this.criteria.unitSettlementChargesReason = undefined;
        this.resetElement(this.form.unitSettlementChargeReason);
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    private sanitizeUnits(material: MaterialCharge): void {
        if (!material.units) {
            material.units = 0;
        } else if (material.units > material.unitMax) {
            material.units = material.unitMax;
        } else {
            material.units = Math.floor(material.units * 10) / 10; // Ensure any input at or after the hundreths place is ignored
        }
    }

    private loadSettlementCharges(): void {
        if (this.criteria.salesAgency && this.criteria.salesSeason) {
            this.loadingSettlementCharges = true;
            this.criteria.unitSettlementChargesProduct = undefined;
            this.resetElement(this.form.unitSettlementChargeProductLine);
            this.unitSettlementChargesServiceV2.getUnitSettlementCharges(this.criteria.salesAgency.id, this.criteria.salesSeason.salesPeriodId)
                .then(response => {
                    this.productCharges = response.data;
                    this.setMaterialMaximums();
                    if (this.productCharges.length === 1) {
                        this.criteria.unitSettlementChargesProduct = this.productCharges[0];
                        this.calculateTotals();
                    }
                })
                .finally(() => this.loadingSettlementCharges = false);
        }
    }

    private loadSettlementReasons(): void {
        if (this.criteria.salesSeason && this.criteria.territory) {
            this.loadingReasons = true;
            this.criteria.unitSettlementChargesReason = undefined;
            this.resetElement(this.form.unitSettlementChargeReason);

            this.unitSettlementChargesServiceV2
                .getUnitSettlementReasonsBySalesAgency(this.criteria.salesSeason.salesPeriodId,
                    this.criteria.salesAgency.id)
                .then(response => {
                    this.reasons = response.data;
                })
                .finally(() => this.loadingReasons = false);

        }
    }

    private setMaterialMaximums(): void {
        for (let product of this.productCharges) {
            for (let material of product.materials) {
                material.unitMax = material.units;
            }
        }
    }
}