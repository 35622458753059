import './detail/cpaDetail.scss';

import CpaRequestService from './cpaRequest.service';
Application.registerService('cpaRequestService', CpaRequestService);

// DETAIL
import CpaDetailRepository from './detail/cpaDetail.repository';
Application.registerRepository('cpaDetailRepository', CpaDetailRepository);

import CpaDetailService from './detail/cpaDetail.service';
Application.registerService('cpaDetailService', CpaDetailService);

import CpaDetailDisplayService from './detail/cpaDetailDisplay.service';
Application.registerService('cpaDetailDisplayService', CpaDetailDisplayService);

import CpaApprovalDetailV2Controller from './detail/cpaApprovalDetailV2.controller';
Application.registerController('CpaApprovalDetailV2Controller', CpaApprovalDetailV2Controller);