import { IUserService } from '../../../../../../core/services/user.service.interface';
import DiscountsService from '../../../../components/discounts/discounts.service';
import { ICpaRequest } from '../../../../cpa/cpaRequest.model.interface';
import { ICpaRequestService } from '../../../../cpa/cpaRequest.service.interface';
import CpaApprovalDetailV2Controller from '../../../../cpa/detail/cpaApprovalDetailV2.controller';
import CpaDetailRepository from '../../../../cpa/detail/cpaDetail.repository';
import CpaDetailService from '../../../../cpa/detail/cpaDetail.service';
import { IUser } from '../../../../../../core/models/user.model.interface';


export default class SupportSearchCpaDetailV2Controller extends CpaApprovalDetailV2Controller {

    constructor(
        protected $q: ng.IQService,
        protected $mdDialog: ng.material.IDialogService,
        protected requestLocal: ICpaRequest,
        protected cpaRequestService: ICpaRequestService,
        protected cpaDetailRepository: CpaDetailRepository,
        protected $timeout: ng.ITimeoutService,
        protected userService: IUserService,
        protected cpaDetailService: CpaDetailService,
        protected discountsService: DiscountsService
    ) {

        super($q,
            $mdDialog,
            requestLocal,
            cpaRequestService,
            cpaDetailRepository,
            $timeout,
            userService,
            cpaDetailService,
            discountsService);

    }

    $onInit() {
        super.$onInit();
        this.user = this.userService.get();
        this.allowComment = this.user.canApprove;
    }

    canDeny(): boolean {
        return false;
    }
}