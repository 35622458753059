import {ICommentsController} from './comments.controller.interface';
import {IComment} from './comment.model.interface';
import {Comment} from './comment.model';
import {ICommentsService} from './comments.service.interface';

import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

export default class CommentsController implements ICommentsController {
    // component bindings
    comments: IComment[];
    requestId: number;
    requestType: string;
    allowComment: boolean;

    commentForm: ng.IFormController;
    commentText: string = '';
    showCommentForm: boolean = false;

    processing: boolean = false;

    user: IUser;

    constructor(
        private commentsService: ICommentsService,
        private $window: ng.IWindowService,
        private userService: IUserService) {}

    $onInit(): void {
        this.user = this.userService.get();
    }

    canComment(): boolean {
        return angular.isDefined(this.allowComment) && this.allowComment;
    }

    addComment(): void {
        this.showCommentForm = true;
        this.resetForm();
    }

    cancel(): void {
        this.showCommentForm = false;
        this.resetFocus();
        this.resetForm();
    }

    saveComment(commentText: string): void {
        const comment = new Comment(this.requestId, commentText);

        this.processing = true;

        this.commentsService.saveComment(comment, this.requestType)
            .then(() => this.processCommentResponse(comment))
            .finally(() => {
                this.processing = false;
                this.showCommentForm = false;

                this.resetFocus();
                this.resetForm();
            });
    }

    disableSubmit(): boolean {
        return this.commentForm && (this.commentForm.$invalid || this.processing);
    }

    showValidationMessages(): boolean {
        return this.commentForm && (this.commentForm.$dirty && this.commentForm.$invalid);
    }

    private processCommentResponse(comment: IComment): void {
        comment.displayName = this.getDisplayName();
        comment.commentDate = new Date().getTime().toString();

        this.comments.push(comment);
    }

    private getDisplayName(): string {
        return this.user.name;
    }

    private resetForm(): void {
        this.commentText = '';

        if (this.commentForm) {
            this.commentForm.$setPristine();
            this.commentForm.$setUntouched();
        }
    }

    private resetFocus(): void {
        this.$window.focus();
    }
}