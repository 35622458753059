import ProposalCpaDetailService from './proposalcpaDetail.service'; 
Application.registerRepository('proposalCpaDetailService', ProposalCpaDetailService);

import ProposalCpaDetailController from './proposalcpaDetail.controller';
Application.registerController('ProposalCpaDetailController', ProposalCpaDetailController);

import ProposalCpaCommentsComponent from '../detail/comments/proposalcpaComments.component';
Application.registerComponent('proposalCpaComments', ProposalCpaCommentsComponent.factory());

import ProposalCpaDetailBarComponent from '../detail/detailBar/proposalcpaDetailBar.component';
Application.registerComponent('proposalCpaDetailBar', ProposalCpaDetailBarComponent.factory());

import ProposalCpaCommentsService from '../detail/comments/proposalcpaComments.service'; 
Application.registerRepository('proposalcpaCommentsService', ProposalCpaCommentsService);