import AgencyPlanningService from './agencyPlanning.service';
import { Agency } from './agency';
import AgencySummaryController from '../agencySummary/agencySummary.controller';
import { AgencyPlanningModel } from './agencyPlanning.model';
import { TerritoryPlanning } from './territory/territoryPlanning';

export default class AgencyPlanningController {

    loading: boolean = false;
    saving: boolean = false;
    territoryId: string;
    canEdit: boolean = false;
    sortOrder: string = 'name';
    submitEnabled: boolean = false;
    requestStatus: number = 0;
    isInseason: boolean = false;
    territoryFlexFundVariance: number = -1;

    navigationText: string = 'Agency Planning';

    agencies: Agency[] = [];
    territoryPlanning: TerritoryPlanning;

    constructor(private agencyPlanningService: AgencyPlanningService,
        private $mdDialog: ng.material.IDialogService,
        private $routeParams: any) {
        this.$onInit();
    }

    $onInit(): void {
        this.territoryId = this.$routeParams.territoryId;
        this.activate();
    }

    private activate() {

        this.updateSubmitEnabled();

        this.getList();
    }

    private getList(): void {
        this.loading = true;
        this.agencyPlanningService.getList(this.territoryId).then(t => {
            this.setVariables(t.data);
        }).finally(() => this.done());
    }

    private setVariables(data: AgencyPlanningModel) {
        this.agencies = data.agencyPlanning.agencies;
        this.canEdit = data.canEdit;
        this.territoryPlanning = data.agencyPlanning.territoryPlanning;
        this.requestStatus = data.requestStatus;
        this.isInseason = this.requestStatus === 7;
        this.territoryFlexFundVariance = data.agencyPlanning.territoryPlanning.flexFundVariance;
        this.updateSubmitEnabled();
    }

    private updateSubmitEnabled(): void {
        if (this.territoryPlanning) {

            this.submitEnabled = (this.requestStatus === 0 || this.requestStatus === 7) && this.territoryFlexFundVariance === 0;
        }
    }

    saveTotalDiscountTarget(agency: Agency, validator: ng.INgModelController) {
        if (this.canEdit && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.agencyPlanningService.saveTotalDiscountTarget(agency.salesAgencyId, agency.totalDiscountTarget).finally(() => this.saving = false);
        }
    }

    saveFlexFundTarget(agency: Agency, validator: ng.INgModelController) {
        if (this.canEdit && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.agencyPlanningService.saveFlexFundTarget(agency.salesAgencyId, agency.flexFundTarget)
                .finally(() => this.saving = false);
        }
    }

    getVariance(target: number, plan: number) {
        return target - plan;
    }

    private done(): void {
        this.loading = false;
    }

    openAgencySummary($event: MouseEvent, agencyId: number, agencyName: string): void {
        this.$mdDialog.show({
            template: require('./../agencySummary/agencySummary.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: AgencySummaryController,
            controllerAs: 'vm',
            locals: {
                salesAgencyId: agencyId,
                salesAgencyName: agencyName,
                territoryId: this.territoryId,
                isInseason: this.isInseason
            },
            fullscreen: true,
            hasBackdrop: false,
            escapeToClose: false,
            onRemoving: () => {
                this.getList();
            }
        });
    }

    submitForApproval(): void {
        if (this.saving) {
            return;
        }

        this.saving = true;
        this.agencyPlanningService.submitForApproval(this.territoryId)
            .then(() => {
                this.submitEnabled = false;
                if (this.requestStatus == 0) {
                    this.requestStatus = 3;
                }
                this.updateSubmitEnabled();
            })
            .finally(() => this.saving = false);
    }

    territoryFlexFundChanged(variance: number) {
        this.territoryFlexFundVariance = variance;
        this.updateSubmitEnabled();
    }
}