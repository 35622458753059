import PlanningDetailsController from './planningDetails.controller';

export default class PlanningDetailsComponent implements ng.IComponentOptions {
    template: string = require('./planningDetails.html');
    controller: Function = PlanningDetailsController;

    bindings: { [binding: string]: string } = {
        planningYear: '<',
        planningDetails: '<',
        canEdit: '<',
        loading: '<',
        saving: '<',
        agencyCustomerId: '<',
        salesAgencyId: '<'
    };

    static factory() {
        const component = () => {
            return new PlanningDetailsComponent();
        };

        return component();
    }
}