export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/businessPartnerPlanning', {
            template: require('./businessPartnerPlanning.html'),
            controller: 'BusinessPartnerPlanningController',
            controllerAs: 'vm'
        })
        .when('/businessPartnerPlanning/agencyPlanning', {
            template: require('./businessPartnerPlanning.html'),
            controller: 'BusinessPartnerPlanningController',
            controllerAs: 'vm'
        }).when('/businessPartnerPlanning/agencyPlanning/:territoryId', {
            template: require('./agencyPlanning/agencyPlanning.html'),
            controller: 'AgencyPlanningController',
            controllerAs: 'vm'
        }).when('/businessPartnerPlanning/pendingRequests', {
            template: require('./pending/businessPartnerPlanningPendingRequests.html'),
            controller: 'BusinessPartnerPlanningPendingRequestsController',
            controllerAs: 'vm'
        }); 
};