import { IInfinityPackageOverrideDetailBarComponentController } from './infinityPackageOverrideDetailBar.component.controller.interface';

import { PendingPackageOverrideRequest } from '../../pendingPackageOverrideRequest.model';
import { InfinityPackageOverrideDetail } from '../infinityPackageOverrideDetail.model';

export default class InfinityPackageOverrideDetailBarComponentController implements IInfinityPackageOverrideDetailBarComponentController{
    request: PendingPackageOverrideRequest;
    detail: InfinityPackageOverrideDetail;

    constructor() {
    }

}