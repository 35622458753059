import SupportSearchAgencyBalanceExceptionResponse from './supportSearchAgencyBalanceExceptionResponse.model';
import {RequestLine} from './../../agencyBalanceExceptionV2/core/requestLine.model';
import SupportSearchAgencyBalanceExceptionServiceV2 from './supportSearchAgencyBalanceException.service';
import { RequestStatus } from './../../agencyBalanceExceptionV2/core/requestStatus.model';

import BalanceExceptionRequestDetailsControllerV2 from '../../agencyBalanceExceptionV2/balanceExceptionRequestDetails.controller';

import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';

export default class SupportSearchAgencyBalanceExceptionControllerV2  {

    maximumRowsToDisplay: number = 200;

    searching: boolean = false;
    criteria: string = '';
    requests: Array<RequestLine>;
    totalRequests: number = 0;
    shownRequests: number = 0;

    private concurrentDetailsCalls: number = 3;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchAgencyBalanceExceptionServiceV2: SupportSearchAgencyBalanceExceptionServiceV2
    ) {
        // no-op
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

            this.supportSearchAgencyBalanceExceptionServiceV2.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    viewDetails($event: MouseEvent, request: RequestLine): void {
        this.$mdDialog.show({
            template: require('./../../agencyBalanceExceptionV2/balanceExceptionRequestDetails.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                controller: BalanceExceptionRequestDetailsControllerV2,
                controllerAs: 'vm',
                locals: {
                    request: request
                },
                fullscreen: true
        }).finally(() => {
            this.refreshRequest(request);
        });
    }

    private refreshRequest(request: RequestLine): void {
        this.supportSearchAgencyBalanceExceptionServiceV2
            .getRequestStatus(request.requestId)
            .then(response => this.updateRowStatus(request, response.data));
    }

    private updateRowStatus(request: RequestLine, requestStatus: RequestStatus): void {
        request.statusId = requestStatus.statusId;
        request.status = requestStatus.status;
        request.canApprove = requestStatus.canApprove;
        request.canDeny = requestStatus.canDeny;
        request.canReview = requestStatus.canReview;
        request.canManuallyImplement = requestStatus.canManuallyImplement;
        request.canWithdraw = requestStatus.canWithdraw;
    }

    private processResponse(data: SupportSearchAgencyBalanceExceptionResponse): void {
        this.requests = data.requestList;

        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }
}