import CreatePricingRequestService from './createPricingRequest.service';
import { PricingCreateRequestDataV2 } from './models/pricingCreateRequestDataV2.model';
import { PricingProductLine } from './models/pricingProductLine.model';
import { PricingProduct } from './models/pricingProduct.model';
import { PricingProductPrice } from './models/pricingProductPrice.model';
import { PricingPriceType } from './models/pricingPriceType.model';
import { PricingRequestCriteriaV2 } from './models/pricingRequestCriteriaV2.model';
import { PricingRequestLoading } from './models/pricingRequestLoading.model';
import { PricingSalesPeriod } from './models/pricingSalesPeriod.model';
import { IUserService } from '../../../../core/services/user.service.interface';
import { IUser } from '../../../../core/models/user.model.interface';
import { IPricingRequestFormController } from './models/pricingRequestForm.interface';

export default class PricingRequestV2Controller {
    isMultipleDistrictsFlagEnabled: boolean = false;

    criteria: PricingRequestCriteriaV2 = {
        salesPeriod: undefined,
        districts: undefined,
        productLine: undefined,
        product: undefined,
        price: undefined,
        priceType: undefined,
        requestPrice: undefined,
        comment: undefined
    };

    temp: PricingRequestCriteriaV2 = {
        salesPeriod: undefined,
        districts: undefined,
        productLine: undefined,
        product: undefined,
        price: undefined,
        priceType: undefined,
        requestPrice: undefined,
        comment: undefined
    };

    loading: PricingRequestLoading = {
        salesPeriods: undefined,
        districts: undefined,
        productLines: undefined,
        products: undefined,
        prices: undefined,
        priceTypes: undefined,
        submit: undefined,
        treatments: undefined
    };

    searchText: string = null;
    salesPeriods: Array<PricingSalesPeriod>;
    districts: Array<string>;
    productLines: Array<PricingProductLine>;
    products: Array<PricingProduct>;
    prices: Array<PricingProductPrice>;
    priceTypes: Array<PricingPriceType>;
    requestForm: IPricingRequestFormController;
    pendingRequestTypes: Array<string> = [];

    user: IUser;
    isPricingAdmin: boolean = false;
    isInvalidProduct: boolean = false;
    forageProductLine: string = '080';
    maxPriceAmount: number = 99999;
    minPriceAmount: number = 0;
    maxUnits: number = 9999999;
    minUnits: number = 1;

    constructor(
        protected createPricingRequestService: CreatePricingRequestService,
        private userService: IUserService,
        protected $mdToast: ng.material.IToastService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.user = this.userService.get();
        this.isPricingAdmin = this.user.isPricingAdminUser;
        this.activate();
    }

    private activate(): void {
        this.getSalesPeriod();
        this.getProductLine();
    }

    submit(): void {

        if (!this.canSubmit()) {
            return;
        }

        this.loading.submit = true;

        // Send one price request with all districts selected. API will then make a request per district.
        this.createPricingRequestService.createPricingRequestV2(this.createDataFromCriteria(this.criteria))
            .then(() => {
                this.loading.submit = false;
                this.$mdToast.show(
                    this.$mdToast.simple().textContent('Pricing request(s) saved')
                        .position('bottom center'));
                this.resetForm();
            });
    }

    resetForm(): void {
        this.clearSalesPeriod();
        this.clearDistricts();
        this.clearPrice();
        this.clearPriceType();
        this.clearProductLine();
        this.clearProduct();
        this.clearRequestPrice();
        this.clearComment();
        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
        this.resetSalesPeriod();
    }

    private getSalesPeriod() {
        this.loading.salesPeriods = true;

        return this.createPricingRequestService.getPricingSalesperiods()
            .then((response) => {
                this.processSalesPeriodResponse(response.data);
                if (response.data.length === 1) {
                    this.setInitialDropdownValues();
                    this.getDistrict();
                }
            })
            .finally(() => this.loading.salesPeriods = false);
    }

    private resetSalesPeriod() {
        if (this.salesPeriods.length === 1) {
            this.criteria.salesPeriod = this.salesPeriods[0];
            this.getDistrict();
        }
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit;
    }

    private processSalesPeriodResponse(options: Array<PricingSalesPeriod>): void {
        this.salesPeriods = options;
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (this.requestForm && formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    private setInitialDropdownValues() {
        this.setCurrentSalesPeriod();
    }

    private setCurrentSalesPeriod() {
        if (this.salesPeriods.filter(y => y.isCurrent).length === 0) {
            if (this.salesPeriods.length === 1) {
                this.criteria.salesPeriod = this.salesPeriods[0];
            }
        } else {
            this.criteria.salesPeriod = this.salesPeriods.filter(y => y.isCurrent).shift();
        }
    }

    private getProductLine() {
        this.loading.productLines = true;

        return this.createPricingRequestService.getPricingProductLine()
            .then((response) => {
                this.processProductLineResponse(response.data);
            })
            .finally(() => this.loading.productLines = false);
    }

    private processProductLineResponse(options: Array<PricingProductLine>): void {
        if (this.isPricingAdmin) {
            this.productLines = options;
        } else {
            this.productLines = options.filter(productLine => productLine.connexProductLineId.toLowerCase() !== '700');
        }
    }

    private getDistrict() {
        this.loading.districts = true;

        return this.createPricingRequestService.getPricingDistricts(this.criteria.salesPeriod.salesPeriodId)
            .then((response) => {
                this.processDistrictResponse(response.data);
            })
            .finally(() => this.loading.districts = false);
    }

    private processDistrictResponse(options: Array<string>) {
        this.districts = options;
        if (this.districts.length === 1) {
            this.criteria.districts = this.districts;
        }
    }

    private getProduct() {
        this.loading.products = true;

        return this.createPricingRequestService.getPricingProduct(this.criteria.productLine.connexProductLineId)
            .then((response) => {
                this.processProductResponse(response.data);
            })
            .finally(() => this.loading.products = false);
    }

    private processProductResponse(options: Array<PricingProduct>) {
        this.products = options;
    }

    private getPriceType() {
        this.loading.priceTypes = true;

        return this.createPricingRequestService.getPriceType(this.criteria.productLine.connexProductLineId)
            .then((response) => {
                this.processPriceTypeResponse(response.data);
            })
            .finally(() => this.loading.priceTypes = false);
    }

    private processPriceTypeResponse(options: Array<PricingPriceType>) {
        if (this.criteria.productLine.connexProductLineId === this.forageProductLine) {
            this.priceTypes = options;
        } else {
            this.priceTypes = options.concat(this.getStandardPriceType());
        }
    }

    private getStandardPriceType() {
        const standard: PricingPriceType[] = [{ specialConditionId: 'Standard', name: 'Standard', sortOrder: '1' }];
        return standard;
    }

    private hasStandardPrice() {
        return this.prices.filter(p => p.name === this.getStandardPriceType()[0].name)[0];
    }

    private getPrice() {
        if (this.criteria.salesPeriod && this.criteria.districts && this.criteria.product) {
            this.loadPrice(this.criteria.salesPeriod.salesPeriodId,
                this.criteria.districts,
                this.criteria.productLine.connexProductLineId,
                this.criteria.product.ProductId);
        }
    }

    private loadPrice(salesPeriodId: number, districts: string[], productLineId: string, productId: string) {
        this.loading.prices = true;
        this.prices = [];
        this.pendingRequestTypes = []
        this.priceTypes = [];

        districts.map((district) => {
            this.createPricingRequestService.getPrice(salesPeriodId, district, productLineId, productId)
                .then((response) => {
                    this.processPriceResponse(response.data.prices);
                    this.processPendingRequestResponse(response.data.pendingRequestTypes);
                });
        });

        this.getPriceType();
        this.loading.prices = false;
    }

    private processPriceResponse(options: Array<PricingProductPrice>) {
        this.prices = _.union(this.prices, options);
    }

    private processPendingRequestResponse(pendingRequestTypes: Array<string>) {
        this.pendingRequestTypes = _.union(this.pendingRequestTypes, pendingRequestTypes);
    }

    private isPendingRequestExist() {
        return (this.pendingRequestTypes.indexOf(this.criteria.priceType.specialConditionId) > -1);
    }

    private createDataFromCriteria(criteria: PricingRequestCriteriaV2): PricingCreateRequestDataV2 {
        const startTypeAndPrice: PricingProductPrice = this.getStartPriceAndType();

        const data: PricingCreateRequestDataV2 = new PricingCreateRequestDataV2(-1,
            criteria.salesPeriod.salesPeriodId,
            criteria.districts,
            criteria.productLine.connexProductLineId,
            (criteria.product === null) ? this.searchText : criteria.product.ProductId,
            '',
            criteria.priceType.specialConditionId,
            criteria.priceType.name,
            criteria.requestPrice.pricePerUnit,
            criteria.requestPrice.numberOfUnits,
            criteria.requestPrice.estimatedValue,
            startTypeAndPrice.specialConditionId,
            startTypeAndPrice.name,
            startTypeAndPrice.price,
            criteria.comment,
            false);

        return data;
    }

    private productQuerySearch(query: string) {
        return query && this.products ? this.products.filter(product => product.ProductId.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.products;
    }

    onSalesYearSelect(): void {
        if (this.hasChanged(this.temp.salesPeriod, this.criteria.salesPeriod)) {
            this.clearDistricts();
            this.clearPriceType();
            this.getDistrict();
        }
    }

    onDistrictSelect(): void {
        if (this.hasChanged(this.temp.districts, this.criteria.districts)) {
            this.clearPriceType();
            this.getPrice();
        }
    }

    onProductLineSelect(): void {
        if (this.hasChanged(this.temp.productLine, this.criteria.productLine)) {
            this.clearProduct();
            this.clearPrice();
            this.clearPriceType();
            if (this.criteria.productLine.connexProductLineId === this.forageProductLine) {
                this.prices = [];
                this.getPriceType();
            }
            this.getProduct();
        }
    }

    private onProductSelect(item: any, form: any) {
        if (item === undefined) {
            return;
        }

        this.productSearchTextChange(this.searchText, form);
        this.clearPriceType();
        this.getPrice();
    }

    private onPriceTypeSelect(): void {
        if (this.hasChanged(this.temp.priceType, this.criteria.priceType)) {
            this.clearRequestPrice();
            this.validatePendingRequest();
        }
    }

    validatePendingRequest(): void {
        if (this.isPendingRequestExist()) {
            this.requestForm.priceType.$setValidity('nopendingrequest', false);
            this.requestForm.priceType.$touched = true;
        } else {
            this.requestForm.priceType.$setValidity('nopendingrequest', true);
            this.requestForm.priceType.$touched = false;
        }
    }

    calculateEstimatedPrice(): void {
        if (!this.criteria.priceType) {
            return;
        }

        this.setPriceAndUnitValidity();

        let startPrice: PricingProductPrice = this.getStartPriceAndType();

        this.criteria.requestPrice.estimatedValue = (this.criteria.requestPrice.pricePerUnit - startPrice.price) *
            this.criteria.requestPrice.numberOfUnits;
    }

    setPriceAndUnitValidity(): void {
        this.setPriceValidity();
        this.setUnitValidity();
    }

    setPriceValidity(): void {
        if (this.criteria.requestPrice.pricePerUnit > this.maxPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('maxprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('maxprice', true);
        }

        if (this.criteria.requestPrice.pricePerUnit < this.minPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('minprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('minprice', true);
        }
    }

    setUnitValidity(): void {
        if (this.criteria.requestPrice.numberOfUnits > this.maxUnits) {
            this.requestForm.numberOfUnits.$setValidity('maxunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('maxunits', true);
        }

        if (this.criteria.requestPrice.numberOfUnits < this.minUnits) {
            this.requestForm.numberOfUnits.$setValidity('minunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('minunits', true);
        }
    }

    private getStartPriceAndType(): PricingProductPrice {
        return this.getExistingMaxPrice(this.criteria.priceType.specialConditionId);
    }

    private getExistingPriceByType(priceType: string): PricingProductPrice {
        return this.prices.filter(p => p.specialConditionId === priceType)[0];
    }

    private getExistingMaxPrice(priceType: string): PricingProductPrice {
        if (priceType === 'Standard' && !this.hasStandardPrice()) {
            return new PricingProductPrice('', '', 0);
        }
        let pricingProductPrice = this.getExistingPriceByType(priceType);

        if (pricingProductPrice && pricingProductPrice.price !== 0) {
            return this.getExistingPriceByType(priceType);
        }
        if (this.prices.length === 0 || (this.criteria && this.criteria.productLine && this.criteria.productLine.name === 'Forage Additives')) {
            return new PricingProductPrice('', '', 0);
        }

        const maxPrice = this.prices.reduce((prev, current) => prev.price > current.price ? prev : current);
        return new PricingProductPrice(maxPrice.specialConditionId, maxPrice.name, maxPrice.price);
    }

    private clearSalesPeriod(): void {
        this.resetElement(this.requestForm.salesYear);
        this.criteria.salesPeriod = undefined;
    }

    private clearRequestPrice(): void {
        this.resetElement(this.requestForm.pricePerUnit);
        this.resetElement(this.requestForm.numberOfUnits);
        if (this.criteria.requestPrice) {
            this.criteria.requestPrice = undefined;
        }
    }

    private clearDistricts(): void {
        this.resetElement(this.requestForm.district);
        this.districts = [];
        this.criteria.districts = undefined;
        this.temp.districts = undefined;
    }

    private clearProduct(): void {
        this.resetElement(this.requestForm.productAutocomplete);
        this.products = [];
        this.criteria.product = undefined;
        this.temp.product = undefined;
        this.isInvalidProduct = false;
    }

    private clearProductLine(): void {
        this.resetElement(this.requestForm.productLine);
        this.criteria.product = undefined;
        this.temp.product = undefined;
    }

    private clearPrice(): void {
        this.prices = undefined;
    }

    private clearComment(): void {
        this.criteria.comment = '';
        this.resetElement(this.requestForm.comment);
        this.criteria.comment = undefined;
    }

    private clearPriceType(): void {
        this.temp.priceType = undefined;
        this.priceTypes = undefined;
        this.criteria.priceType = undefined;
        this.resetElement(this.requestForm.priceType);

        this.clearRequestPrice();
    }

    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    productSearchTextChange(searchText: string, form: any) {
        this.clearPriceType();
        this.validateProduct(searchText, form);
        this.validatePriceType(searchText);
        this.isInvalidProduct = (searchText === '') ? false : this.invalidProduct(searchText);
    }

    refreshPriceSearchText(searchText: string, form: any) {
        if (this.isPricingAdmin &&
            !this.criteria.product &&
            searchText &&
            this.criteria.salesPeriod &&
            this.criteria.districts) {
            this.loadPrice(this.criteria.salesPeriod.salesPeriodId, this.criteria.districts, this.criteria.productLine.connexProductLineId, this.searchText);
        }
    }

    validateProduct(query: string, form: any) {
        const isInvalid: boolean = (query !== '' && this.isPricingAdmin) ? false : this.invalidProduct(query);
        form.productAutocomplete.$error.unknownProductError = isInvalid;
        form.productAutocomplete.$setValidity('unknownProductError', !isInvalid);
    }

    invalidProduct(query: string) {
        const x: PricingProduct[] = query ? this.products.filter(product => product.ProductId.toLowerCase() === query.toLowerCase()) : this.products;
        return x.length <= 0;
    }

    validatePriceType(query: string) {
        const x: PricingProduct[] = query ? this.products.filter(product => product.ProductId.toLowerCase() === query.toLowerCase()) : this.products;
        if (this.isPricingAdmin && x.length <= 0 && this.criteria.productLine.connexProductLineId !== this.forageProductLine) {
            this.prices = [];
        }
    }

    // Field enable disable
    canSelectDistricts(): boolean {
        return !_.isEmpty(this.districts) && !_.isUndefined(this.criteria.salesPeriod);
    }

    canSelectProducts(): boolean {
        return ((!_.isEmpty(this.products) || (_.isEmpty(this.products) && this.isPricingAdmin)) && !_.isUndefined(this.criteria.productLine));
    }

    canShowNotPriced(): boolean {
        return _.isEmpty(this.prices) && !_.isUndefined(this.prices);
    }

    canShowPriceType(): boolean {
        return !_.isUndefined(this.prices);
    }
}