import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IPackages } from './models/packages.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';
import { Invoice } from './models/invoice.model';
import { Share } from './models/share.model';
import InfinityPackageOverrideRequestService from './infinityPackageOverrideRequest.service';
import { InfinityPackageOverrideRequestCriteria } from './models/infinityPackageOverrideRequest.criteria.model';
import { InfinityPackageOverrideRequestLoading } from './models/infinityPackageOverrideRequest.loading.model';
import { InfinityPackageOverrideRequestOptions } from './models/infinityPackageOverrideRequest.options.model';
import { InfinityPackageOverrideRequestFormController } from './models/infinityPackageOverrideRequestForm.interface';
import { Packages } from './models/packages.model';
import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';

export default class InfinityPackageOverrideRequestController {
    user: IUser;
    navigationText: string = "Request Infinity Package Benefits Override";
    isSystemUser: boolean = false;

    loading: InfinityPackageOverrideRequestLoading = {
        options: false,
        salesSeasons: false,
        agencies: false,
        territories: false,
        operations: false,
        accountDescriptions: false,
        businessPartners: false,
        overridePackages: false,
        accountDescriptionData: false,
        submit: false
    };

    options: InfinityPackageOverrideRequestOptions = {
        salesSeasons: [],
        territories: [],
        agencies: [],
        operations: [],
        accountDescriptions: [],
        businessPartners: [], 
        allBusinessPartners: [],        
        overridePackages: []
    };

    criteria: InfinityPackageOverrideRequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        overridePackage: undefined,
        comment: undefined,
    };

    temp: InfinityPackageOverrideRequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        overridePackage: undefined,
        comment: undefined
    };

    // Search terms
    territoriesSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    operationsSearchTerm: string = '';    

    infinityInvoiceInformation: any;
    businessPartnerList: any;

    requestForm: InfinityPackageOverrideRequestFormController;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $document: ng.IDocumentService,
        private $mdSidenav: ng.material.ISidenavService,
        private infinityPackageOverrideRequestService: InfinityPackageOverrideRequestService,
        private userService: IUserService) {
        this.$onInit();
    }

    $onInit(): void {
        this.user = this.userService.get();
        this.isSystemUser = this.user.isSystemUser;
        this.activate();  
    }

    private activate(): void {
        this.setHandlers();

        this.loading.options = true;
        this.infinityPackageOverrideRequestService.getOptions()
            .then((response) => {
                this.processOptionsResponse(response.data);
                this.setInitialDropdownValues();
            })
            .finally(() => this.loading.options = false);
    } 

    private setHandlers(): void {
        const selectors: string[] = [
            'territories',
            'agencies',
            'operations'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    /**
    * Sets respective temp criteria object property for comparison on menu close
    * or to undefined if criteria property not found/defined
    * @param property
    */
    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    // Select callbacks
    onSalesSeasonsSelect(): void {
        if (this.hasChanged(this.temp.salesSeason, this.criteria.salesSeason)) {
            this.clearTerritories();
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.getTerritories();
        }
    }

    showBudgetInfo(): boolean {
        if (this.criteria.salesSeason) {
            return this.criteria.salesSeason.year > 2019;
        }

        return false;
    }

    onTerritoriesSelect(): void {
        this.territoriesSearchTerm = '';

        if (this.hasChanged(this.temp.territory, this.criteria.territory)) {
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();

            this.getSalesAgencies();
            this.getPackages();
        }
    }

    onSalesAgenciesSelect(): void {
        this.salesAgenciesSearchTerm = '';

        if (this.hasChanged(this.temp.salesAgency, this.criteria.salesAgency)) {
            this.clearOperations();
            this.clearBusinessPartners();
            this.clearAccountDescriptions();

            this.getOperations();
        }
    }

    onOperationsSelect(): void {
        this.operationsSearchTerm = '';

        if (this.hasChanged(this.temp.operation, this.criteria.operation)) {
            this.clearBusinessPartners();
            this.clearAccountDescriptions();

            this.getInvoicesShares();
        }
    }

    onBusinessPartnersSelect(): void {
        if (this.hasChanged(this.temp.businessPartner, this.criteria.businessPartner)) {
            this.clearAccountDescriptions();

            this.getFilteredAccountDescriptions();
        }
    }

    onAccountDescriptionSelect(): void {      
        if (this.hasChanged(this.temp.accountDescription, this.criteria.accountDescription)) {
            if (!_.isUndefined(this.criteria.accountDescription)) {
                this.loading.accountDescriptionData = true;
                let invoiceIds = this.criteria.accountDescription.map(a => a.invoiceId);
                let accountDescriptionData = this.options.allBusinessPartners.filter((item) => {
                    return invoiceIds.indexOf(item.invoiceId) > -1 && item.businessPartnerId === this.criteria.businessPartner.businessPartnerId;
                });
                let invoiceShareData = accountDescriptionData.map(({ invoiceId, shareId }) => ({ invoiceId, shareId }));

                this.infinityPackageOverrideRequestService.getInfinityInvoiceInformation(invoiceShareData)
                    .then((infinityInvoiceInformation: any) => {
                        this.infinityInvoiceInformation = infinityInvoiceInformation.data;
                        this.getAccountDescriptions();
                    })
                    .finally(() => this.loading.accountDescriptionData = false);
            }     
        }
    }

    // Set dropdown values
    private setInitialDropdownValues() {
        this.setCurrentSalesPeriod();

        this.$timeout(() => {
            this.setTerritory();
            this.setSalesAgency();
        });
    }

    private setCurrentSalesPeriod() {
        this.criteria.salesSeason = this.options.salesSeasons.filter(salesSeason => salesSeason.isCurrent).shift();
    }

    private setTerritory(): void {
        if (this.isSingular(this.options.territories)) {
            this.criteria.territory = this.options.territories[0];
            this.getSalesAgencies();
            this.getPackages();
        }
    }

    private setSalesAgency(): void {
        if (this.isSingular(this.options.agencies)) {
            this.criteria.salesAgency = this.options.agencies[0];
            this.getOperations();
        }
    }

    private setOperation(): void {
        if (this.isSingular(this.options.operations)) {
            this.criteria.operation = this.options.operations[0];
            this.getInvoicesShares();
        }
    }

    private setBusinessPartners(filteredBusinessPartners: Share[]): void {
        if (this.isSingular(filteredBusinessPartners)) {
            this.criteria.businessPartner = filteredBusinessPartners[0];
        }
    }  

    // Getters

    private getTerritories(): ng.IPromise<any> {
        this.loading.territories = true;

        return this.infinityPackageOverrideRequestService.getTerritories(this.criteria.salesSeason.salesPeriodId)
            .then((response) => {
                this.processTerritoriesResponse(response.data);
                this.setSalesAgency();
            })
            .finally(() => this.loading.territories = false);
    }

    private getSalesAgencies(): ng.IPromise<any> {
        this.loading.agencies = true;

        return this.infinityPackageOverrideRequestService.getSalesAgencies(this.criteria)
            .then((response) => {
                this.processSalesAgenciesResponse(response.data);
                this.setSalesAgency();
            })
            .finally(() => this.loading.agencies = false);
    }

    private getOperations(): void {
        this.loading.operations = true;

        this.infinityPackageOverrideRequestService.getOperations(this.criteria)
            .then((response) => {
                this.processOperationsResponse(response.data);
                this.setOperation();
            })
            .finally(() => this.loading.operations = false);
    }

    private getInvoicesShares(): ng.IPromise<any> {
        this.loading.businessPartners = true;
        return this.infinityPackageOverrideRequestService
            .getInvoicesShares(this.criteria.salesSeason.salesPeriodId, this.criteria.operation.id, this.criteria.salesAgency.id)
            .then((response) => {
                this.processInvoicesSharesResponse(response.data);
                this.setBusinessPartners(this.getFilteredBusinessPartners());
            })
            .finally(() => {                
                this.loading.businessPartners = false;                
            });
    }

    private getPackages(): ng.IPromise<any> {
        this.loading.overridePackages = true;
        return this.infinityPackageOverrideRequestService
            .getPackages(this.criteria.salesSeason.salesPeriodId, this.criteria.territory.orgId)
            .then((response) => {
                this.processPackageResponse(response.data);
            })
            .finally(() => {
                this.loading.overridePackages = false;
            });
    }

    private getAccountDescriptionCriteria(): void {

        this.businessPartnerList = this.options.allBusinessPartners;

        let reformattedArray = this.criteria.accountDescription.map(p => {
            let bplObj = this.businessPartnerList.find(q => q.invoiceId === p.invoiceId && q.businessPartnerId === p.businessPartnerId)
            return new Invoice(p.invoiceId, p.name, p.businessPartnerId, bplObj.shareId);
        })

        this.criteria.accountDescription = reformattedArray;
    }

    // Field enable disable
    canSelectTerritories(): boolean {
        return !_.isEmpty(this.options.territories) && !_.isUndefined(this.criteria.salesSeason);
    }

    canSelectSalesAgencies(): boolean {
        return !_.isEmpty(this.options.agencies) && !_.isUndefined(this.criteria.territory);
    }

    canSelectOperations(): boolean {
        return !_.isEmpty(this.options.operations) && !_.isUndefined(this.criteria.salesAgency);
    }

    canSelectAccountDescriptions(): boolean {
        return !_.isEmpty(this.options.accountDescriptions) && !_.isUndefined(this.criteria.businessPartner);
    }

    canSelectBusinessPartners(): boolean {
        return !_.isEmpty(this.options.businessPartners) && !_.isUndefined(this.criteria.operation);
    }

    canSelectPackages(): boolean {
        return !_.isEmpty(this.options.overridePackages) && !_.isUndefined(this.criteria.overridePackage);
    }

    canComment(): boolean {
        return !_.isUndefined(this.criteria.overridePackage);
    }

    // Form
    showHint(formElement: ng.INgModelController): boolean {
        return formElement.$pristine || formElement.$valid;
    }

    private resetForm(): void {
        this.criteria.territory = undefined;

        this.clearSalesAgencies();

        this.clearOperations();
        this.clearAccountDescriptions();
        this.clearBusinessPartners();
        this.clearOverridePackage();
        this.clearInvoiceInformation();
        this.clearComment();



        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (this.requestForm && formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit && this.criteria.overridePackage != null;
    }

    submit(): void {
        this.loading.submit = true;

        this.getAccountDescriptionCriteria();

        this.infinityPackageOverrideRequestService.submit(this.criteria)
            .then(() => {
                this.loading.submit = false;
                this.resetForm();
            });

        this.loading.submit = false;
    }

    isProcessing(): boolean {
        return this.loading.options || this.loading.submit;
    }

    // API data processing
    private processOptionsResponse(options: InfinityPackageOverrideRequestOptions): void {
        this.options = options;
    }

    private processTerritoriesResponse(territories: IOrgIdAndName[]): void {
        this.options.territories = territories;
    }

    private processSalesAgenciesResponse(agencies: IIdAndName[]): void {
        this.options.agencies = agencies;
    }

    private processOperationsResponse(operations: IIdAndName[]): void {
        this.options.operations = operations;
    }

    private processInvoicesSharesResponse(invoicesShares: { invoices: Invoice[]; shares: Share[] }) {
        this.options.accountDescriptions = invoicesShares.invoices;
        this.options.businessPartners = invoicesShares.shares;
    }

    private processPackageResponse(packages: IPackages[]) {
        packages.push(new Packages('Remove Override'));
        this.options.overridePackages = packages;
    }

    // Select options and criteria reset
    private clearTerritories(): void {
        this.resetElement(this.requestForm.territory);

        this.criteria.territory = undefined;
        this.temp.territory = undefined;
    }

    private clearSalesAgencies(): void {
        this.resetElement(this.requestForm.salesAgency);

        this.options.agencies = [];
        this.criteria.salesAgency = undefined;
        this.temp.salesAgency = undefined;
    }

    private clearOperations(): void {
        this.resetElement(this.requestForm.operation);

        this.options.operations = [];
        this.criteria.operation = undefined;
        this.temp.operation = undefined;
    }

    private clearAccountDescriptions(): void {
        this.resetElement(this.requestForm.accountDescription);

        this.criteria.accountDescription = undefined;
        this.temp.accountDescription = undefined;
    }

    private clearBusinessPartners(): void {
        this.resetElement(this.requestForm.businessPartner);

        this.options.businessPartners = [];
        this.criteria.businessPartner = undefined;
        this.temp.businessPartner = undefined;
    }

    private clearOverridePackage(): void {
        this.resetElement(this.requestForm.package);

        this.criteria.overridePackage = undefined;
        this.temp.overridePackage = undefined;

    }

    private clearInvoiceInformation(): void {
        this.infinityInvoiceInformation = null; 
    }

    private clearComment(): void {
        this.resetElement(this.requestForm.comment);
        this.criteria.comment = undefined;
    }

    private getFilteredBusinessPartners(): Share[] {
        if (!_.isUndefined(this.criteria.accountDescription)) {
            return this.options.businessPartners.filter((businessPartner) => {
                return businessPartner.invoiceId === this.criteria.accountDescription[0].invoiceId;
            });
        }

        this.options.allBusinessPartners = this.options.businessPartners;
        this.options.businessPartners = _.uniq(this.options.businessPartners, obj => obj.businessPartnerId);
        return this.options.businessPartners;
    }

    private getFilteredAccountDescriptions(): Invoice[] {
        if (!_.isUndefined(this.criteria.businessPartner)) {
            return this.options.accountDescriptions.filter((accountDescription) => {
                return accountDescription.businessPartnerId === this.criteria.businessPartner.businessPartnerId;
            });
        }

        return this.options.accountDescriptions;
    }

    private getAccountDescriptions(): void {
        this.infinityInvoiceInformation = this.criteria.accountDescription.map(p => ({ ...p, ...this.infinityInvoiceInformation.find(q => q.invoiceId === p.invoiceId) }))
    }

    // Helper methods
    /**
     * Checks if collection has only one (1) item
     * @param values - collection of options objects
     * @returns {Boolean}
     */
    private isSingular(values: any[]): boolean {
        return _.isArray(values) && _.size(values) === 1;
    }

    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    noFilteredResults(length: number, canSelect: boolean): boolean {
        return canSelect && length === 0;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    formatPackage(packageName): string {
        if (!_.isUndefined(packageName) && packageName !== '') {
            return packageName;
        }
        return 'No Package'
    }

    formatOverriddenPackage(packageName): string {
        if (!_.isUndefined(packageName) && packageName !== '') {
            return packageName;
        }
        return 'No Override'
    }
}