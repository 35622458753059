import { IInfinityPackageOverrideComment } from './comments/infinityPackageOverrideComment.model.interface';
import { IDiscount } from '../../components/discounts/models/discount.model.interface';
import { IDiscountTotal } from '../../components/discounts/models/totals/discount.total.model.interface';
import { IOperationSaleGrowth } from '../../components/operation/operationSalesGrowth.model.interface';
import { IOperationSaleGrowthTotal } from '../../components/operation/operationSalesGrowthTotal.model.interface';

import { OperationProductLineSalesGrowthModel } from '../../components/operation/operationProductLineSalesGrowth.model';
import { RequiredApproval } from '../../components/approvals/required.approval.model';

export class InfinityPackageOverrideDetail {

    constructor(    
        public requiredApprovals: {
            approvalList: RequiredApproval[],
            finalApprovalDate: string;
        },
        public comments: IInfinityPackageOverrideComment[],
        public operationSalesGrowth: OperationProductLineSalesGrowthModel = null,
        public currentInfinityPackage: any = null) {
        // no-op
    }

    static empty(): InfinityPackageOverrideDetail {
        return new InfinityPackageOverrideDetail(          
            {
                approvalList: [],
                finalApprovalDate: undefined
            },
            [],
            new OperationProductLineSalesGrowthModel([], undefined, undefined, undefined),
            undefined);
    }
}