export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType/', {
            template: require('./requestTypes.html'),
            controller: 'RequestTypesController',
            controllerAs: 'vm'
        })
        .when('/requestType/all/', {
            template: require('./requestTypes.html'),
            controller: 'RequestTypesController',
            controllerAs: 'vm'
        });
}

let requestTypesState: ng.ui.IState = {
    name: 'requestType',
    url: '/requestType/',
    views: {
        '': {
            template: require('./requestTypes.html'),
            controller: 'RequestTypesController',
            controllerAs: 'vm'
        },
        'cpaPending-tab@requestType': {
            template: require('../pending/pendingRequests.html'),
            controller: 'PendingRequestsController',
            controllerAs: 'vm'
        }
    }
};

angular.module('approval-tool').config(($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state(requestTypesState);
});
