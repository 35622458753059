import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

export default class LoanTypeChangeNavigationController  {
    // bindings
    page: string;
    user: IUser;

    constructor(
        private $location: ng.ILocationService,
        private userService: IUserService) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    navToPending(): void {
        this.$location.url('/requestType-LoanTypeChange/');
    }

    navToLoanTypeChangeCreateRequest(): void {
        this.$location.url('/requestType-LoanTypeChange/create');
    }

    navToAllLoanTypeChangeRequests(): void {
        this.$location.url('/requestType-LoanTypeChange/all');
    }

    canRequestLoanTypeChange(): boolean {
        return this.user.canCreate;
    }
}