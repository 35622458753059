import { ShareDiscountV2 } from './models/shareDiscountV2.model';
import { CustomerDiscounts } from './models/customerDiscounts.model';

export default class DiscountsService {

    // Feature Flags
    constructor(
        private $http: ng.IHttpService) {
        // no-op
    }

    getShareCurrentDiscounts(requestId: number, invoiceId: number, year: number, shareId: number): ng.IPromise<ShareDiscountV2> {
        return this.$http.get(`{API-ApprovalTool}/cpa/requests/${requestId}/invoices/${invoiceId}/year/${year}/shares/${shareId}/discounts`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }

    getShareCachedDiscounts(requestId: number, invoiceId: number, year: number, shareId: number): ng.IPromise<ShareDiscountV2> {
        return this.$http.get(`{API-ApprovalTool}/cpa/requests/${requestId}/invoices/${invoiceId}/year/${year}/shares/${shareId}/cached-discounts`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }

    getCpaShareCurrentDiscounts(requestId: number, invoiceId: number, shareId: number): ng.IPromise<any> {
        return this.$http.get(`{API-ApprovalTool}/cpa/requests/${requestId}/invoices/${invoiceId}/shares/${shareId}/current-discounts/v2`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }

    getCustomerPreviousDiscounts(requestId: number, invoiceId: number, year: number, shareId: number): ng.IPromise<CustomerDiscounts> {
        return this.$http.get(`{API-ApprovalTool}/cpa/requests/${requestId}/invoices/${invoiceId}/year/${year}/shares/${shareId}/customer-prior-discounts`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }

    getCustomerPreviousDiscounts2020V2(shareId: number): ng.IPromise<any> {
        return this.$http.get(`{API-ApprovalTool}/cpa/shares/${shareId}/customer-prior-discounts-2020/v2`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }

    getCustomerCurrentDiscountsV2(shareId: number): ng.IPromise<any> {
        return this.$http.get(`{API-ApprovalTool}/cpa/shares/${shareId}/customer-current-discounts/v2`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return response.data;
            });
    }
}