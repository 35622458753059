import PricingCommentDialogController from './pricingCommentDialog.controller';

export default class PricingCommentDialog implements ng.material.IDialogOptions {
    template: string;
    targetEvent: MouseEvent;
    controller: string | Function;
    locals: { [index: string]: any };
    resolve: { [index: string]: () => angular.IPromise<any> };
    controllerAs: string;
    parent: string | Element | JQuery; // default: root node
    fullscreen: boolean; // default: false
    skipHide: boolean;

    static factory(): PricingCommentDialog {
        let dialog: PricingCommentDialog = new PricingCommentDialog();
        dialog.template = require('./pricingCommentDialog.html');
        dialog.controller = PricingCommentDialogController;
        dialog.controllerAs = 'vm';
        dialog.fullscreen = true;
        dialog.skipHide = true;
        dialog.locals = {
            title: 'Comments',
            confirmText: 'Ok',
            cancelText: 'Cancel',
            maxLength: 255,
            requireComment: false
        };

        return dialog;
    }

    title(titleText: string): PricingCommentDialog {
        this.locals['title'] = titleText;
        return this;
    }

    okay(okText: string): PricingCommentDialog {
        this.locals['confirmText'] = okText;
        return this;
    }

    cancel(cancelText: string): PricingCommentDialog {
        this.locals['cancelText'] = cancelText;
        return this;
    }

    maxLength(maxLength: number): PricingCommentDialog {
        this.locals['maxLength'] = maxLength;
        return this;
    }

    required(requireComment: boolean): PricingCommentDialog {
        this.locals['requireComment'] = requireComment;
        return this;
    }

    dialogParent(parent: string | Element | JQuery): PricingCommentDialog {
        this.parent = parent;
        return this;
    }
}