import TerritoryService from './territory.service';
import { BusinessPartnerPlanningModel } from './businessPartnerPlanning.model';

export default class BusinessPartnerPlanningController {

    loading: boolean = true;
    navigationText: string = 'Agency Planning';
    details: BusinessPartnerPlanningModel;

    constructor(private $location: ng.ILocationService,
                private $timeout: ng.ITimeoutService,
                private territoryService: TerritoryService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.getList();
    }

    private getList(): void {
        this.loading = true;
        this.territoryService.getList().then(t => {
            if (t.data.territories.length === 1) {
                this.$timeout(() => {
                        const url: string = `/businessPartnerPlanning/agencyPlanning/${t.data.territories[0]}`;
                        this.$location.url(url);
                    },
                    0);
            } else {
                this.details = t.data;
            }
        }).finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }
}