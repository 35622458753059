import {INavigationComponentController} from './navigation.controller.interface';

import {IRedirectService} from '../../services/redirect.service.interface';

import {IUser} from '../../models/user.model.interface';
import {IUserService} from '../../services/user.service.interface';

export default class NavigationComponentController implements INavigationComponentController {
    // bindings
    page: string;

    user: IUser;

    constructor(
        private redirectService: IRedirectService,
        private userService: IUserService) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    navToPending(): void {
        this.redirectService.redirectToPendingRequestTypes();
    }

    navToAllPending(): void {
        this.redirectService.redirectToAllPendingRequestTypes();
    }

    navToSearch(): void {
        this.redirectService.redirectToSearch();
    }

    navToRequestCPA(): void {
        this.redirectService.redirectToRequestCPA();
    }

    canRequestCPA(): boolean {
        return this.user.canCreate;
    }
}