import { IWindowService } from 'angular';

angular
    .module('approval-tool')
    .factory(
        'appInsightsClientVersionRepository',
        [
            '$window',
            function ($window: IWindowService) {
                'use strict';

                const getClientVersionMetaTag = function (): HTMLMetaElement | null {
                    const cssSelector = 'meta[name=telemetry-client-version]';
                    return $window.document.querySelector(cssSelector) as HTMLMetaElement;
                };

                const getTagContent = function (tag: HTMLMetaElement | null): string {
                    let tagContent = '';
                    if (tag && tag.getAttribute) {
                        tagContent = tag.getAttribute('content') || '';
                    }
                    return tagContent;
                };

                return {
                    getClientVersion: function (): string {
                        const versionTag = getClientVersionMetaTag();
                        return getTagContent(versionTag);
                    }
                };
            }
        ]
    );