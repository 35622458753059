import './pricingRequest.scss';

import CreatePricingRequestService from './createPricingRequest.service';
Application.registerService('createPricingRequestService', CreatePricingRequestService);

import PricingRequestController from './pricingRequest.controller';
Application.registerController('PricingRequestController', PricingRequestController);

import PricingRequestV2Controller from './pricingRequestV2.controller';
Application.registerController('PricingRequestV2Controller', PricingRequestV2Controller);

import PricingTreatmentRequestController from './pricingTreatmentRequest.controller';
Application.registerController('PricingTreatmentRequestController', PricingTreatmentRequestController);

import PricingTreatmentRequestV2Controller from './pricingTreatmentRequestV2.controller';
Application.registerController('PricingTreatmentRequestV2Controller', PricingTreatmentRequestV2Controller);

// DETAIL
import PricingRequestDetailController from '../detail/pricingRequestDetail.controller';
Application.registerController('PricingRequestDetailController', PricingRequestDetailController);

import PricingRequestDetailService from '../detail/pricingRequestDetail.service';
Application.registerService('pricingRequestDetailService', PricingRequestDetailService);

import PricingCommentDialogController from '../core/pricingCommentDialog.controller';
Application.registerController('PricingCommentDialogController', PricingCommentDialogController);
