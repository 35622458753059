import { ILoginController } from './login.controller.interface';
import { ILoginRepository } from './login.repository.interface';

// core
import { IUserAuthService } from '../../core/authentication/userAuth.service.interface';
import { AuthenticatedUserService } from '../../core/authentication/authenticatedUser.service';
import { IRedirectService } from '../../core/services/redirect.service.interface';
import { IStorageService } from '../../core/services/storage.service.interface';
import { StorageKeyEnum } from '../../core/services/storageKey.enum';
import { IMetatagService } from '../../core/services/metatag.service.interface';
import { IHeaderService } from '../../core/services/header.service.interface';
import { HeaderKeyEnum } from '../../core/services/headerKey.enum';

export default class LoginController implements ILoginController {

    loading: boolean = true;
    waiting: boolean = false;
    errorMessage: string = undefined;
    useMsalLogin: boolean = this.canUseMsalLogin();
    displayMyAccessLink: boolean = false;
    username: string = '';
    password: string = '';
    // Example of using $fsLDFeatureFlagService
    // ffEnabled: boolean = (window as any).$fsLDFeatureFlagService.isEnabled('team:key');

    constructor(
        private $location: ng.ILocationService,
        private userAuthService: IUserAuthService,
        private authenticatedUserService: AuthenticatedUserService,
        private redirectService: IRedirectService,
        private storageService: IStorageService,
        private loginRepository: ILoginRepository,
        private globalExceptionService: pioneer.IGlobalExceptionService,
        private metatagService: IMetatagService,
        private headerService: IHeaderService,
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate(this.$location.url());
    }


    canLogin(): boolean {
        return this.hasUserName() && this.hasPassword() && this.waiting === false;
    }

    login(): void {
        this.globalExceptionService.overrideGlobalException(true);
        this.clearError();

        if (this.canLogin()) {
            this.waiting = true;
            this.authenticatedUserService.invalidateCurrentUser();
            this.authenticatedUserService.setCurrentUser(this.username, this.password);
            this.userAuthService.processLogin(this.username, this.password)
                .then((response: Authentication.Results.IAuthenticationResult): void => {
                    this.processAuthenticationResponse(response);
                }), () => {
                    this.errorMessage = 'Sorry, something unrelated to authentication happened.';
                    this.waiting = false;
                };
        }
    }

    clearError(): void {
        this.errorMessage = undefined;
    }

    // privates

    private canUseMsalLogin(): boolean {
        return this.metatagService.getFlagStatus('use-msal-auth');
    }

    private activate(url: string) {
        if (this.hasTimedOut(url)) {
            this.errorMessage = 'Sorry, your session has expired. Please login.';
        }

        this.storageService.remove(StorageKeyEnum.Impersonate);
        this.storageService.remove(StorageKeyEnum.ImpAuthInfo);
        this.storageService.remove(StorageKeyEnum.AuthInfo);
        this.headerService.delete(HeaderKeyEnum.Impersonate);

        if (this.useMsalLogin) {
            this.errorMessage = null;
            this.waiting = true;

            if (this.storageService.exists(StorageKeyEnum.UPN)) {
                this.processMsalLogin();
            } else {
                window.location.href = window.location.origin + '/msal.html';
            }
        }
        this.loading = false;

    }

    private hasTimedOut(url: string) {
        return url.indexOf('timeout') !== -1;
    }

    private hasUserName() {
        return this.username.length > 0;
    }

    private hasPassword() {
        return this.password.length > 0;
    }

    private processAuthenticationResponse(response: Authentication.Results.IAuthenticationResult): void {
        if (response.appliesTo(200)) {
            this.processAuthenticationSuccess();
        } else {
            if ($fsLDFeatureFlagService.isEnabled('Foxtrot.401363.ATLoginError')) {
                this.globalExceptionService.overrideGlobalException(true);
            }
            this.errorMessage = response.message();
            this.waiting = false;
            this.displayMyAccessLink = response.appliesTo(401);
        }
    }

    private processAuthenticationSuccess(): void {
        this.loginRepository.authenticate()
            .then((response: any) => {

                this.storageService.set(StorageKeyEnum.AuthInfo, response.data.authInfo);
                if (response.data.authInfo.upn !== '') {
                    sessionStorage.setItem('upn', response.data.authInfo.upn);
                }

                if (response.data.authInfo.useSupportPage) {
                    this.redirectService.redirectToSupportRequests();
                } else {
                    this.redirectService.redirectToPendingRequestTypes();
                }
            }).finally(() => {
                this.waiting = false;
            });
    }


    private processMsalLogin(): void {

        this.userAuthService.processLogin(null, null)
            .then((response: Authentication.Results.IAuthenticationResult): void => {
                this.processAuthenticationResponse(response);
            }), () => {
                this.errorMessage = 'Sorry, something unrelated to authentication happened.';
                this.waiting = false;
            }
        // .catch(error => {
        //     return new Authentication.Results.AuthenticationError;
        // }
    }

}

