import './supportSearch.scss';

import SupportSearchService from './supportSearch.service';
Application.registerRepository('supportSearchService', SupportSearchService);

import SupportSearchController from './supportSearch.controller';
Application.registerController('SupportSearchController', SupportSearchController);

// DETAIL
import SupportSearchCpaDetailV2Controller from './detail/cpa/supportCpaDetailV2.controller';
Application.registerController('SupportSearchCpaDetailV2Controller', SupportSearchCpaDetailV2Controller);
