export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/pendingcpa/', {
            template: require('./pendingRequests.html'),
            controller: 'PendingRequestsController',
            controllerAs: 'vm'
        })
        .when('/pendingcpa/all', {
            template: require('./pendingRequests.html'),
            controller: 'PendingRequestsController',
            controllerAs: 'vm'
        });
}