import DoaUserSearchRepository from '../doaUserSearch.repository';
import { DoaUser } from '../doaUser.model';
import { DoaUserOverride } from '../doaUserOverride.model';
import { IDoaOverrideService } from '../doaOverride.service.interface';

export default class AddDoaOverrideController {
    selectedItem: any = null;
    replaceUserSearchText: string = null;
    replacementUserSearchText: string = null;
    replaceUser: DoaUser = null;
    replacement: DoaUser = null;
    saving = false;

    querySearch: (query: string) => ng.IPromise<any> = this.querySearchImpl;

    constructor(
        private $q: ng.IQService,
        private $mdDialog: ng.material.IDialogService,
        private userSearchRepository: DoaUserSearchRepository,
        private doaOverrideService: IDoaOverrideService    ) {

    }

    $onInit(): void {
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    clear(): void {
        this.replaceUserSearchText = null;
        this.replacementUserSearchText = null;
        this.$mdDialog.hide();
    }

    querySearchImpl(query: string): ng.IPromise<any> {

        const deferred: ng.IDeferred<boolean> = this.$q.defer();

        this.userSearchRepository.search(query)
            .then((response: any) => deferred.resolve(response.data));

        return deferred.promise;
    }

    save(): void {
        if (this.saving) {
            return;
        }

        this.saving = true;

        const doaOverride = new DoaUserOverride(this.replacement.id, this.replacement.email, this.replacement.name, this.replaceUser.id);
        this.doaOverrideService.upsert(doaOverride)
            .then(() => this.$mdDialog.hide())
            .finally(() => this.saving = false);
    }

    disableSave(): boolean {
        return !this.replacement || !this.replaceUser;
    }
}