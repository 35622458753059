/* eslint-disable angular/component-limit, angular/factory-name */
(() => {
    angular
        .module('approval-tool')
        .factory('appInsightsRequestInterceptorFactory', appInsightsRequestInterceptorFactory);

    appInsightsRequestInterceptorFactory.$inject = [
        'appInsightsRequestService',
        '$q'
    ];

    function appInsightsRequestInterceptorFactory(
        appInsightsRequestService: any,
        $q: ng.IQService
    ) {
        return {
            'request': function (config: any) {
                appInsightsRequestService.configure(config.headers);
                return config || $q.when(config);
            }
        };
    }
})();