import './requests.scss';
import './requestDetail.scss';

import './components/_bootstrap';
import './cpa/_bootstrap';
import './cpa/request/_bootstrap';
import './pending/_bootstrap';
import './search/_bootstrap';
import './requestTypes/_bootstrap';
import './commissionAdvance/_bootstrap';
import './encircaCommissionAdvance/_bootstrap';
import './monthlyDraw/_bootstrap';
import './replant/_bootstrap';
import './replant/detail/_bootstrap';
import './sample/_bootstrap';
import './sample/detail/_bootstrap';
import './sample/approver/_bootstrap';
import './balanceWriteOff/_bootstrap';
import './balanceWriteOff/request/_bootstrap';
import './agencyBalanceExceptionV2/_bootstrap';
import './pricing/_bootstrap';
import './ltc/_bootstrap';
import './ltc/request/_bootstrap';
import './businessPartnerPlanning/_bootstrap';
import './proposal/_bootstrap';
import './proposal/detail/_bootstrap';
import './proposal/approver/_bootstrap';
import './infinityPackageOverride/_bootstrap';
import './infinityPackageOverride/overrideRequest/_bootstrap';
import './infinityPackageOverride/detail/_bootstrap';
import './infinityPackageOverride/approver/_bootstrap';
import './AgencyDiscountMaster/_bootstrap'
import './doaOverride/_bootstrap'

import {CommissionPermissionsService} from './commission/commissionPermissions.service';
Application.registerService('commissionPermissionsService', CommissionPermissionsService);

