export default class BalanceExceptionTypeComponentController {
    searchTerm: string;
    formType: string;
    options: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $document: ng.IDocumentService,
        private $scope: ng.IScope) {

        
    }

    $onInit() {
        this.setKeyHandler(`#exceptionTypes-${this.formType} input`);
    }

    noFilteredResults(length: number, canSelect: boolean): boolean {
        return canSelect && length === 0;
    }

    onClose(): void {
        this.searchTerm = '';
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }
}