import { IMetatagService } from '../../services/metatag.service.interface';

export function apiInterceptor(
    $location: ng.ILocationService,
    metatagService: IMetatagService
) {
    const interceptor: ng.IHttpInterceptor = {
        request(config: ng.IRequestConfig): ng.IRequestConfig {

            const host: string = $location.host();
            const protocol: string = $location.protocol() + '://';

            const expression: RegExp = new RegExp('\{API\-[A-Za-z]+\}');
            const match: RegExpMatchArray = config.url.match(expression);

            if (match) {
                const key: string = match[0].replace('{', '').replace('}', '');
                config.url = config.url.replace(expression, metatagService.get(key));
                return config;

            } else if (config.url.indexOf('.html') > -1
                || config.url.indexOf('logout') > -1
                || config.url.indexOf('login') > -1
                || config.url.indexOf('.svg') > -1) {

                return config;
            }

            let uiPath: string = '';

            if (host === 'localhost') {
                uiPath = '/approvaltool';
            }

            config.url = protocol + host + uiPath + config.url;
            return config;
        }

    };
    return interceptor;
}
