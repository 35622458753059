export default class ApproverSearchBarSideNavService {

    sideNavComponentId: string = 'approverSearchBar';

    constructor( private $mdSidenav: ng.material.ISidenavService) {
        // noop
    }

    openSearchNavAsync(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId, true)
            .then((sideNavInstance: ng.material.ISidenavObject) => {
                return sideNavInstance.open();
            });
    }

    openSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).open();
    }

    closeSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).close();
    }
}
