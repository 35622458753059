import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';

import PricingCommentDialog from './../core/pricingCommentDialog';
import { PricingDetail } from './pricingDetail.model';
import { PricingRequest } from '../core/pricingRequest.model';
import PricingRequestDetailService from './pricingRequestDetail.service';
import PricingRequestsService from './../pricingRequests.service';
import { RequiredApprovals } from '../../pricing/detail/requiredApprovals.model';
import PricingRequestDetailUpdate from './pricingRequestDetailUpdate';
import {IPricingRequestDetailUpdateFormController} from './pricingRequestDetailUpdateFormController.interface';

export default class PricingRequestDetailController {

    price: number;
    estimatedValue: number;
    units: number;

    requiredApproval: RequiredApprovals;
    requestForm: IPricingRequestDetailUpdateFormController;

    allowComment: boolean = false;
    isLoading: boolean;
    detail: PricingDetail;
    approvalType: string;
    user: IUser;
    maxPriceAmount: number = 99999;
    minPriceAmount: number = 0;
    maxUnits: number = 9999999;
    minUnits: number = 1;

    constructor(private $mdDialog: ng.material.IDialogService,
        public request: PricingRequest,
        private pricingRequestDetailService: PricingRequestDetailService,
        private pricingRequestsService: PricingRequestsService,
        private userService: IUserService) {
        
    }

    $onInit(): void {
        this.price = this.request.price;
        this.units = this.request.units;
        this.estimatedValue = this.request.estimatedValue;
        this.allowComment = true;
        this.user = this.userService.get();
        this.loadDetails();
        this.approvalType = 'pricing';
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    approve($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        if (this.hasChanged(this.estimatedValue, Number(this.request.estimatedValue)) ||
            this.hasChanged(this.units, Number(this.request.units)) ||
            this.hasChanged(this.price, Number(this.request.price))) {

            let dialog: PricingCommentDialog = PricingCommentDialog
                .factory()
                .title('Reason for updating request')
                .okay('Approve')
                .maxLength(243)
                .required(true)
                .dialogParent(angular.element(document.body));

            this.$mdDialog.show(dialog)
                .then((result) => {
                    const detail: PricingRequestDetailUpdate = new PricingRequestDetailUpdate(this.request.price,
                        this.request.units,
                        this.request.estimatedValue,
                        result,
                        this.request.isTreatment);
                    this.approveRequestUpdated(this.request, detail);
                })
                .catch(() => {
                    this.request.processing = false;
                });
        } else {
            this.approveRequest(this.request);
        }
    }

    approveRequest(request: PricingRequest) {
        this.pricingRequestsService.approve(request.requestId, request.isTreatment)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                request.processing = false;
            });
    }

    approveRequestUpdated(request: PricingRequest, updatedRequestDetail: PricingRequestDetailUpdate) {
        if (updatedRequestDetail.comment === '') {
            request.processing = false;
            return;
        }

        this.pricingRequestsService.approveRequestUpdated(request.requestId, updatedRequestDetail)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                request.processing = false;
            });
    }


    deny($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        let dialog: PricingCommentDialog = PricingCommentDialog
            .factory()
            .title('Reason for denying request')
            .okay('Deny')
            .maxLength(245)
            .required(true)
            .dialogParent(angular.element(document.body));

        this.$mdDialog.show(dialog)
            .then((result) => {
                this.denyRequest(this.request, result);
            })
            .catch(() => {
                this.request.processing = false;
            });
    }

    denyRequest(request: PricingRequest, comment: string) {
        if (comment === '') {
            request.processing = false;
            return;
        }

        this.pricingRequestsService.deny(request.requestId, comment)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                request.processing = false;
            });
    }

    canApprove(): boolean {
        return this.user.canApprovePricingRequests && this.request.canApprove && !this.request.processing;
    }

    canDeny(): boolean {
        return this.user.canApprovePricingRequests && this.request.canDeny && !this.request.processing;
    }

    canEditRequest(): boolean {
        return this.user.canEditPricingRequests && !this.request.processing;
    }

    canShowNotPriced(): boolean {
        return this.request.startPrice === 0;
    }

    canShowStandard(): boolean {
        return _.isEmpty(this.request.priceType) && !_.isUndefined(this.request.priceType);
    }

    isTreatment(): boolean {
        return this.request.isTreatment;
    }

    isSupplyChainPlanner(requiredApproval: RequiredApprovals): boolean {
        return _.isEmpty(requiredApproval.approvalLevel) && !_.isUndefined(requiredApproval.approvalLevel);
    }

    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    private loadDetails(): void {
        this.isLoading = true;
        this.getDetail().finally(() => {
            this.isLoading = false;
        });
    }

    calculateEstimatedPrice(): void {
        this.setPriceAndUnitValidity();
        this.request.estimatedValue = (this.request.price - this.request.startPrice) * this.request.units;
    }

    setPriceAndUnitValidity(): void {
        this.setPriceValidity();
        this.setUnitValidity();
    }

    setPriceValidity(): void {
        if (this.request.price > this.maxPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('maxprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('maxprice', true);
        }

        if (this.request.price < this.minPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('minprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('minprice', true);
        }
    }

    setUnitValidity(): void {
        if (this.request.units > this.maxUnits) {
            this.requestForm.numberOfUnits.$setValidity('maxunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('maxunits', true);
        }

        if (this.request.units < this.minUnits) {
            this.requestForm.numberOfUnits.$setValidity('minunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('minunits', true);
        }
    }

    private getDetail() {

        return this.pricingRequestDetailService.getDetail(this.request.requestId)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.detail = response.data;
            });
    }
}