import DiscountsV3ComponentController from './discountsV3.component.controller';

export default class DiscountsV3Component implements ng.IComponentOptions {
    template: string = require('./discountsV3.html');
    bindings: { [binding: string]: string } = {
        year: '<',
        shareDiscounts: '<',
        salesAgencyName: '<'
    };
    controller: Function = DiscountsV3ComponentController;

    static factory() {
        const component: Function = () => {
            return new DiscountsV3Component();
        };

        return component();
    }
}