import {IUser} from './user.model.interface';

export class User implements IUser {

    name: string;
    email: string;

    canApprove: boolean = false;
    canCreate: boolean = false;
    canDeny: boolean = false;
    canImpersonate: boolean = false;
    canReview: boolean = false;
    canWithdraw: boolean = false;
    isSystemUser: boolean = false;
    canViewAgencyDiscountMaster: boolean = false;
    canViewAgencyBalanceExceptions: boolean = false;
    canCreateAgencyBalanceExceptions: boolean = false;
    canReviewAgencyBalanceExceptions: boolean = false;
    useSupportPage: boolean = false;
    canViewCommissionRequests: boolean = false;
    canApproveDenyCommissionRequests: boolean = false;
    canViewBalanceWriteOff: boolean = false;
    isFinanceApprover: boolean = false;
    canApproveDenyBalanceWriteOff: boolean = false;
    canManuallyImplementBalanceWriteOff: boolean = false;
    canCommentBalanceWriteOff: boolean = false;
    canCreateBalanceWriteOff: boolean = false;
    canCreatePricingRequests: boolean = false;
    canViewPricingRequests: boolean = false;
    isPricingAdminUser: boolean = false;
    canApprovePricingRequests: boolean = false;
    canCreatePricingTreatmentRequests: boolean = false;
    canEditPricingRequests: boolean = false;
    canViewLoanTypeChange: boolean = false;
    canApproveDenyLoanTypeChange: boolean = false;
    canCreateCpaRequests: boolean = false;
    canApproveCpaRequests: boolean = false;
    canDenyCpaRequests: boolean = false;
    canWithdrawCpaRequests: boolean = false;
    canViewBusinessPartnerPlanning: boolean = false;
    canViewBusinessPartnerPlanningPendingRequests: boolean = false;
    canViewReplant: boolean = false;
    canViewMonthlyDraw: boolean = false;
    canViewCommissionAdvances: boolean = false;
    approvalToolMassProductAndTreatmentPriceApproval: boolean = false;
    canEditDoaOverride: boolean = false;

    constructor(
        private authInfo: any,
        private impAuthInfo: any,
        private host: string
    ) {
        if (!authInfo) {
            authInfo = this.emptyUser();
        }
        if (!impAuthInfo) {
            impAuthInfo = this.emptyUser();
        }

        if (impAuthInfo.authenticatedName) {
            this.name = impAuthInfo.authenticatedName || '';
            this.email = impAuthInfo.authenticatedEmail || '';

            this.canApprove = impAuthInfo.canApprove || false;
            this.canCreate = impAuthInfo.canCreate || false;
            this.canDeny = impAuthInfo.canDeny || false;
            this.canImpersonate = true; // when impersonating someone, that someone can also impersonate.
            this.canReview = impAuthInfo.canReview || false;
            this.canWithdraw = impAuthInfo.canWithdraw || false;
            this.isSystemUser = impAuthInfo.isSystemUser || false;
            this.canViewAgencyBalanceExceptions = impAuthInfo.canViewAgencyBalanceExceptions || false;
            this.canCreateAgencyBalanceExceptions = impAuthInfo.canCreateAgencyBalanceExceptions || false;
            this.canReviewAgencyBalanceExceptions = impAuthInfo.canReviewAgencyBalanceExceptions || false;
            this.useSupportPage = impAuthInfo.useSupportPage || false;
            this.canViewCommissionRequests = impAuthInfo.canViewCommissionRequests || false;
            this.canApproveDenyCommissionRequests = impAuthInfo.canApproveDenyCommissionRequests || false;
            this.canViewBalanceWriteOff = impAuthInfo.canViewBalanceWriteOff || false;
            this.isFinanceApprover = impAuthInfo.isFinanceApprover || false;
            this.canApproveDenyBalanceWriteOff = impAuthInfo.canApproveDenyBalanceWriteOff || false;
            this.canManuallyImplementBalanceWriteOff = impAuthInfo.canManuallyImplementBalanceWriteOff || false;
            this.canCommentBalanceWriteOff = impAuthInfo.canCommentBalanceWriteOff || false;
            this.canCreateBalanceWriteOff = impAuthInfo.canCreateBalanceWriteOff || false;
            this.canCreatePricingRequests = impAuthInfo.canCreatePricingRequests || false;
            this.canViewPricingRequests = impAuthInfo.canViewPricingRequests || false;
            this.isPricingAdminUser = impAuthInfo.isPricingAdminUser || false;
            this.canApprovePricingRequests = impAuthInfo.canApprovePricingRequests || false;
            this.canCreatePricingTreatmentRequests = impAuthInfo.canCreatePricingTreatmentRequests || false;
            this.canEditPricingRequests = impAuthInfo.canEditPricingRequests || false;
            this.canViewLoanTypeChange = impAuthInfo.canViewLoanTypeChange || false;
            this.canApproveDenyLoanTypeChange = impAuthInfo.canApproveDenyLoanTypeChange || false;
            this.canCreateCpaRequests = impAuthInfo.canCreateCpaRequests || false;
            this.canApproveCpaRequests = impAuthInfo.canApproveCpaRequests || false;
            this.canDenyCpaRequests = impAuthInfo.canDenyCpaRequests || false;
            this.canWithdrawCpaRequests = impAuthInfo.canWithdrawCpaRequests || false;
            this.canViewBusinessPartnerPlanning = impAuthInfo.canViewBusinessPartnerPlanning || false;
            this.canViewBusinessPartnerPlanningPendingRequests = impAuthInfo.canViewBusinessPartnerPlanningPendingRequests || false;
            this.canViewReplant = impAuthInfo.canViewReplant || false;
            this.canViewMonthlyDraw = impAuthInfo.canViewMonthlyDraw || false;
            this.canViewCommissionAdvances = impAuthInfo.canViewCommissionAdvances || false;
            this.canViewAgencyDiscountMaster = impAuthInfo.canViewAgencyDiscountMaster || false;
            this.approvalToolMassProductAndTreatmentPriceApproval = impAuthInfo.approvalToolMassProductAndTreatmentPriceApproval || false;
            this.canEditDoaOverride = impAuthInfo.canEditDoaOverride || false;

        } else if (authInfo.authenticatedName || authInfo.name) {
            this.name = authInfo.authenticatedName || authInfo.name ||'';
            this.email = authInfo.authenticatedEmail || authInfo.preferredName || '';

            this.canApprove = authInfo.canApprove || false;
            this.canCreate = authInfo.canCreate || false;
            this.canDeny = authInfo.canDeny || false;
            this.canImpersonate = authInfo.canImpersonate || false;
            this.canReview = authInfo.canReview || false;
            this.canWithdraw = authInfo.canWithdraw || false;
            this.isSystemUser = authInfo.isSystemUser || false;
            this.canViewAgencyBalanceExceptions = authInfo.canViewAgencyBalanceExceptions || false;
            this.canCreateAgencyBalanceExceptions = authInfo.canCreateAgencyBalanceExceptions || false;
            this.canReviewAgencyBalanceExceptions = authInfo.canReviewAgencyBalanceExceptions || false;
            this.useSupportPage = authInfo.useSupportPage || false;
            this.canViewCommissionRequests = authInfo.canViewCommissionRequests || false;
            this.canApproveDenyCommissionRequests = authInfo.canApproveDenyCommissionRequests || false;
            this.canViewBalanceWriteOff = authInfo.canViewBalanceWriteOff || false;
            this.isFinanceApprover = authInfo.isFinanceApprover || false;
            this.canApproveDenyBalanceWriteOff = authInfo.canApproveDenyBalanceWriteOff || false;
            this.canManuallyImplementBalanceWriteOff = authInfo.canManuallyImplementBalanceWriteOff || false;
            this.canCommentBalanceWriteOff = authInfo.canCommentBalanceWriteOff || false;
            this.canCreateBalanceWriteOff = authInfo.canCreateBalanceWriteOff || false;
            this.canCreatePricingRequests = authInfo.canCreatePricingRequests || false;
            this.canViewPricingRequests = authInfo.canViewPricingRequests || false;
            this.isPricingAdminUser = authInfo.isPricingAdminUser || false;
            this.canApprovePricingRequests = authInfo.canApprovePricingRequests || false;
            this.canCreatePricingTreatmentRequests = authInfo.canCreatePricingTreatmentRequests || false;
            this.canEditPricingRequests = authInfo.canEditPricingRequests || false;
            this.canViewLoanTypeChange = authInfo.canViewLoanTypeChange || false;
            this.canApproveDenyLoanTypeChange = authInfo.canApproveDenyLoanTypeChange || false;
            this.canCreateCpaRequests = authInfo.canCreateCpaRequests || false;
            this.canApproveCpaRequests = authInfo.canApproveCpaRequests || false;
            this.canDenyCpaRequests = authInfo.canDenyCpaRequests || false;
            this.canWithdrawCpaRequests = authInfo.canWithdrawCpaRequests || false;
            this.canViewBusinessPartnerPlanning = authInfo.canViewBusinessPartnerPlanning || false;
            this.canViewBusinessPartnerPlanningPendingRequests = authInfo.canViewBusinessPartnerPlanningPendingRequests || false;
            this.canViewReplant = authInfo.canViewReplant || false;
            this.canViewMonthlyDraw = authInfo.canViewMonthlyDraw || false;
            this.canViewCommissionAdvances = authInfo.canViewCommissionAdvances || false;
            this.canViewAgencyDiscountMaster = authInfo.canViewAgencyDiscountMaster || false;
            this.approvalToolMassProductAndTreatmentPriceApproval = authInfo.approvalToolMassProductAndTreatmentPriceApproval || false;
            this.canEditDoaOverride = authInfo.canEditDoaOverride || false;
        }
    }

    private emptyUser(): any {
        return {
            name: '',
            email: '',
            canApprove: false,
            canCreate: false,
            canDeny: false,
            canImpersonate: false,
            canReview: false,
            canWithdraw: false,
            isSystemUser: false,
            canViewAgencyBalanceExceptions: false,
            canApproveCpaRequests: false,
            canCreateCpaRequests: false,
            canDenyCpaRequests: false,
            canWithdrawCpaRequests: false,
            approvalToolMassProductAndTreatmentPriceApproval: false
        };
    }
}
