import './commissionAdvanceNavigation.scss';

import CommissionAdvanceNavigationController from './commissionAdvanceNavigation.controller';

export default class CommissionAdvanceNavigationComponent implements ng.IComponentOptions {
    template: string = require('./commissionAdvanceNavigation.html');
    controller: Function = CommissionAdvanceNavigationController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component: () => CommissionAdvanceNavigationComponent = () => {
            return new CommissionAdvanceNavigationComponent();
        };

        return component();
    }
}