export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/login/', {
            template: require('./login.html'),
            controller: 'LoginController',
            controllerAs: 'vm'
        }).when('/login/timeout', {
            template: require('./login.html'),
            controller: 'LoginController',
            controllerAs: 'vm'
        });
}