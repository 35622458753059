import './impersonate.scss';

import ImperonsateComponentController from './impersonate.controller';

import UserSearchRepository from './userSearch.repository';
import ImpersonatedUserRepository from './dialog/impersonatedUser/impersonatedUser.repository';

Application.registerRepository('userSearchRepository', UserSearchRepository);
Application.registerRepository('impersonatedUserRepository', ImpersonatedUserRepository);

export default class ImpersonateComponent implements ng.IComponentOptions {

    template: string = require('./impersonate.html');
    controller: Function = ImperonsateComponentController;

    static factory() {
        const component = () => {
            return new ImpersonateComponent();
        };

        return component();
    }

}