import './pending/detail/businessPartnerPlanningDetail.scss';
import './agencyPlanning/agencyPlanning.scss';
import './agencySummary/agencySummary.scss';
import './agencyPlanning/territory/territoryPlanning.scss';
import './customerDetails/customerDetails.scss';
import './customerDetails/search/customerSearch.scss';
import './customerDetails/discounts/customerDiscount.scss';
import './businessPartnerPlanning.scss';

// ROUTES

import businessPartnerPlanning from './businessPartnerPlanning.routes';
Application.registerConfig(businessPartnerPlanning);

// COMPONENTS

import BusinessPartnerPlanningNavigationComponent from './navigation/businessPartnerPlanningNavigation.component';
Application.registerComponent('businessPartnerPlanningNavigation', BusinessPartnerPlanningNavigationComponent.factory());

import PlanningDetailsComponent from './customerDetails/planningDetails/planningDetails.component';
Application.registerComponent('planningDetails', PlanningDetailsComponent.factory());

import TerritoryPlanningComponent from './agencyPlanning/territory/territoryPlanning.component';
Application.registerComponent('territoryPlanning', TerritoryPlanningComponent.factory());

import CustomerSummaryComponent from './agencySummary/customerSummary/customerSummary.component';
Application.registerComponent('customerSummary', CustomerSummaryComponent.factory());

import CustomerDiscountComponent from './customerDetails/discounts/customerDiscount.component';
Application.registerComponent('customerDiscount', CustomerDiscountComponent.factory());


// SERVICES

import AgencyPlanningService from './agencyPlanning/agencyPlanning.service';
Application.registerRepository('agencyPlanningService', AgencyPlanningService);

import PlanningDetailsService from './customerDetails/planningDetails/planningDetails.service';
Application.registerRepository('planningDetailsService', PlanningDetailsService);

import TerritoryPlanningService from './agencyPlanning/territory/territoryPlanning.service';
Application.registerRepository('territoryPlanningService', TerritoryPlanningService);

import AgencySummaryService from './agencySummary/agencySummary.service';
Application.registerRepository('agencySummaryService', AgencySummaryService);

import PlanningCustomerDetailsService from './customerDetails/planningCustomerDetails.service';
Application.registerRepository('planningCustomerDetailsService', PlanningCustomerDetailsService);

import PlanningCustomerDiscountService from './core/planningCustomerDiscount.service';
Application.registerRepository('planningCustomerDiscountService', PlanningCustomerDiscountService);

import TerritoryService from './territory.service';
Application.registerRepository('territoryService', TerritoryService);

import PendingTerritoryRequestService from './pending/pendingTerritoryRequest.service';
Application.registerRepository('pendingTerritoryRequestService', PendingTerritoryRequestService);

import PlanningPendingRequestDetailService from './pending/detail/planningPendingRequestDetail.service';
Application.registerRepository('planningPendingRequestDetailService', PlanningPendingRequestDetailService);

// CONTROLLERS
import AgencyPlanningController from './agencyPlanning/agencyPlanning.controller';
Application.registerController('AgencyPlanningController', AgencyPlanningController);

import TerritoryPlanningController from './agencyPlanning/territory/territoryPlanning.controller';
Application.registerController('TerritoryPlanningController', TerritoryPlanningController);

import PlanningDetailsController from './customerDetails/planningDetails/planningDetails.controller';
Application.registerController('PlanningDetailsController', PlanningDetailsController);

import AgencySummaryController from './agencySummary/agencySummary.controller';
Application.registerController('AgencySummaryController', AgencySummaryController);

import PlanningCustomerDetailsController from './customerDetails/planningCustomerDetails.controller';
Application.registerController('PlanningCustomerDetailsController', PlanningCustomerDetailsController);

import PlanningCustomerDetailsSearchController from './customerDetails/search/planningCustomerDetailsSearch.controller';
Application.registerController('PlanningCustomerDetailsSearchController', PlanningCustomerDetailsSearchController);

import BusinessPartnerPlanningController from './businessPartnerPlanning.controller';
Application.registerController('BusinessPartnerPlanningController', BusinessPartnerPlanningController);

import BusinessPartnerPlanningPendingRequestsController from './pending/businessPartnerPlanningPendingRequests.controller';
Application.registerController('BusinessPartnerPlanningPendingRequestsController', BusinessPartnerPlanningPendingRequestsController);

import PlanningPendingRequestDetailController from './pending/detail/planningPendingRequestDetail.controller';
Application.registerController('PlanningPendingRequestDetailController', PlanningPendingRequestDetailController);

import PlanningPendingRequestDetailSalesSupportController from './pending/detail/planningPendingRequestDetailSalesSupport.controller';
Application.registerController('PlanningPendingRequestDetailSalesSupportController', PlanningPendingRequestDetailSalesSupportController);

import CustomerSummaryController from './agencySummary/customerSummary/customerSummary.controller';
Application.registerController('CustomerSummaryController', CustomerSummaryController);
