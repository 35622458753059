export default class SupportSearchLoanTypeChangeService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/loan-type-change`, data);
    }
}
