import './balanceExceptionNavigationV2.scss';

import BalanceExceptionNavigationComponentController from './balanceExceptionNavigation.controller';

export default class BalanceExceptionNavigationComponentV2 implements ng.IComponentOptions {
    template: string = require('./balanceExceptionNavigation.html');
    controller: Function = BalanceExceptionNavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@',
        canRequest: '<'
    };

    static factory() {
        const component = () => {
            return new BalanceExceptionNavigationComponentV2();
        };

        return component();
    }
}