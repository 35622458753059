import {IEncircaCommissionAdvanceRequestService} from './encircaCommissionAdvanceRequest.service.interface';
import {EncircaCommissionAdvanceRequest} from './encircaCommissionAdvanceRequest';
import {IEncircaCommissionAdvanceRequestDetails} from './encircaCommissionAdvanceRequestDetails.interface';
import {EncircaAdvanceCommissionData} from './EncircaAdvanceCommissionData.model';

export default class EncircaCommissionAdvanceRequestService implements IEncircaCommissionAdvanceRequestService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getCommissionAdvanceRequests(): ng.IPromise<Array<EncircaCommissionAdvanceRequest>> {
        return this.$http.get<Array<EncircaCommissionAdvanceRequest>>('{API-ApprovalTool}/services-commission-advance-requests')
            .then((response) => {
                return response.data;
            });
    }

    getCommissionAdvanceDetail(requestId: number): ng.IPromise<IEncircaCommissionAdvanceRequestDetails> {
        return this.$http
            .get<IEncircaCommissionAdvanceRequestDetails>(`{API-ApprovalTool}/encirca-commission-advance-detail/request/${requestId}`)
            .then((response) => {
                return response.data;
            });
    }

    approve(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/services-commission-advance-request-approve`, request);
    }

    deny(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/services-commission-advance-request-deny`, request);
    }

    getCommissionAdvanceSavedDetails(requestId: number): ng.IPromise<IEncircaCommissionAdvanceRequestDetails> {
        return this.$http.get<IEncircaCommissionAdvanceRequestDetails>(`{API-ApprovalTool}/encirca-commission-advance-detail/requests/${requestId}`)
            .then((response) => {
                return response.data;
            });
    }
}
