import { OperationProductLineSalesGrowthModel } from './operationProductLineSalesGrowth.model';

export default class OperationProductLineSalesController {
    year: number;
    previousYear: number;
    name: string;
    operationGrowth: OperationProductLineSalesGrowthModel;

    constructor() { }

    $onInit(): void {
        this.previousYear = this.year - 1;
    }
}