import {ICommissionAdvanceRequestService} from './commissionAdvanceRequest.service.interface';
import {CommissionAdvanceDetailController} from './commissionAdvanceDetail.controller';
import {CommissionAdvanceRequestRow} from './commissionAdvanceRequestRow';

let async: any = require('async');

export default class CommissionAdvanceRequestController {

    loading: boolean;
    requests: Array<CommissionAdvanceRequestRow>;

    private concurrentDetailsCalls: number = 3;

    constructor(private commissionAdvanceRequestService: ICommissionAdvanceRequestService,
        private $mdDialog: ng.material.IDialogService) {

        this.$onInit();
    }

    $onInit(): void {
        this.loading = true;
        this.activate();
    }

    public viewDetails($event: MouseEvent, row: CommissionAdvanceRequestRow) {
        if (row.isLoadingDetails) {
            return;
        }
        this.$mdDialog.show({
            template: require('./commissionAdvanceDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: CommissionAdvanceDetailController,
            controllerAs: '$ctrl',
            locals: {
                request: row.request,
                details: row.details
            },
            fullscreen: true
        }).finally(() => {
            this.reloadData(row);
        });
    }

    public approve($event: MouseEvent, row: CommissionAdvanceRequestRow): void {
        $event.stopPropagation();
        if (row.details.advanceAmount <= 0) {
            return;
        }

        row.request.processing = true;

        this.commissionAdvanceRequestService.approve(row)
            .then(() => {
                this.refreshSavedRow(row);
            });
    }

    public deny($event: MouseEvent, row: CommissionAdvanceRequestRow): void {
        $event.stopPropagation();
        row.request.processing = true;
        this.commissionAdvanceRequestService.deny(row)
            .then(() => {
                this.refreshSavedRow(row);
            });
    }

    private reloadData(commissionAdvanceRequestRow: CommissionAdvanceRequestRow): any {
        if (!commissionAdvanceRequestRow.request.isPending) {
            this.refreshSavedRow(commissionAdvanceRequestRow);
        }
    }

    private refreshSavedRow(row: CommissionAdvanceRequestRow): void {
        this.commissionAdvanceRequestService
            .getSavedCommissionAdvanceRequest(row.request.requestId)
            .then(newRow => {
                row.request = newRow.request;
                row.details = newRow.details;
            })
            .finally(() => {
                row.request.processing = false;
            });
    }

    private activate() {
        this.commissionAdvanceRequestService.getCommissionAdvanceRequests()
            .then((requests) => {
                this.requests = requests.map(req => new CommissionAdvanceRequestRow(req));
                this.loadDetailsForRequests();
            })
            .finally(() => this.done());
    }

    private loadDetailsForRequests() {
        const queue = async.queue((row: CommissionAdvanceRequestRow, callback: Function) => {
            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private done(): void {
        this.loading = false;
    }

    private getRequestDetails(row: CommissionAdvanceRequestRow): ng.IPromise<any> {
        if (row.request.statusId === 3) {
            return this.commissionAdvanceRequestService
                .getCommissionAdvanceDetails(row.request.salesYear, row.request.salesAgencyId)
                .then(details => row.addDetails(details));
        } else {
            return this.commissionAdvanceRequestService
                .getCommissionAdvanceSavedDetails(row.request.requestId)
                .then(details => row.addDetails(details));
        }
    }
}