import './approverSearch.scss';

import routing from './approverSearch.routes';
Application.registerConfig(routing);

import ApproverSearchService from './approverSearch.service';
Application.registerRepository('approverSearchService', ApproverSearchService);

import ApproverSearchController from './approverSearch.controller';
Application.registerController('ApproverSearchController', ApproverSearchController);

// DETAIL
import ApproverSearchCpaDetailV2Controller from './detail/cpa/approverCpaDetailV2.controller';
Application.registerController('ApproverSearchCpaDetailV2Controller', ApproverSearchCpaDetailV2Controller);