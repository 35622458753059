import { ISampleRequestsService } from './sampleRequests.service.interface';

export default class SampleRequestsService implements ISampleRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/sample/requests/pending`);
    }

    approve(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/sample/lineitems/${request.lineItemId}/requests/${request.requestId}/requestshares/${request.requestShareId}/approve`;
        const data: {} = request.requestShareId;
        return this.$http.post(url, data); 
    };

    deny(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
         const url: string = `{API-ApprovalTool}/sample/lineitems/${request.lineItemId}/requests/${request.requestId}/requestshares/${request.requestShareId}/deny`;
        const data: {} = request.requestShareId;
        return this.$http.post(url, data); 
    };

    isPermissionStopDatePassed(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/requests/competitivepriceadjustment/startstop/permissions/${request.territoryId}`;

        return this.$http.get(url);
    };
}