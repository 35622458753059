import {IStorageService} from './storage.service.interface';
import {StorageKeyEnum} from './storageKey.enum';

export class StorageService implements IStorageService {

    constructor(private $location: ng.ILocationService, private $q: ng.IQService) {
        // noop
    }

    get(key: StorageKeyEnum): any {
        const val: any = sessionStorage.getItem(key.toString());
        try {
            return JSON.parse(val);
        } catch (ex) {
            return val;
        }
    }

    set(key: StorageKeyEnum, value: any): ng.IPromise<any> {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        return this.$q.when(sessionStorage.setItem(key.toString(), value));
    }

    remove(key: StorageKeyEnum): ng.IPromise<any> {
        return this.$q.when(sessionStorage.removeItem(key.toString()));
    }

    clear(): ng.IPromise<any> {
        return this.$q.when(sessionStorage.clear());
    }

    exists(key: StorageKeyEnum): boolean {
        return sessionStorage.getItem(key.toString()) !== null;
    }
}
