import { IViewDocument } from "../viewDocument.model.interface";

export default class DocumentPreviewDialogController {    
    htmlDocumentData: any;   
    documentHtml: any;
    documentUrl: string;
    isHtmlVisible: boolean;
    loading: boolean;
    header: string;
    documentModel: IViewDocument;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private viewDocumentModel: IViewDocument,
        private $http: ng.IHttpService,
        private $sce: ng.ISCEService)
    {
        //noop
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {      
        this.loading = true;
        

        this.GetPdfDocument()
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                if (response.data != null) {
                    var data = response.data;
                    if (data.html && data.html.length > 0) {
                        this.documentUrl = data.secureLink;
                        this.htmlDocumentData = this.$sce.trustAsHtml(data.html.replace('width: 720px', 'width: 100%'));
                        this.isHtmlVisible = true;
                    }
                }
            }).finally(() => {
                this.loading = false;
            });
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    public GetPdfDocument() {
        const canEmbed = typeof navigator.mimeTypes['application/pdf'] !== 'undefined';
        this.documentModel = this.viewDocumentModel;

        const embedOption = canEmbed === true ? 'embed' : 'link';
        var paymentOptions = { paymentTypeId: 9, loanTypeId: 0, levelNumber: 1 };

        if (this.documentModel.ProposalId > 0) {
            this.header = this.documentModel.BusinessPartnerName + '(' + this.documentModel.BusinessPartnerId + ') - Proposal Document';

            return this.$http.post(`{API-ApprovalTool}/create-infinity-proposal/${this.documentModel.ProposalId}/invoices/${this.documentModel.InvoiceId}/shares/${this.documentModel.ShareId}/${embedOption}`, paymentOptions);
        } else {
            this.header = this.documentModel.BusinessPartnerName + '(' + this.documentModel.BusinessPartnerId + ') - Invoice Document';
            
            const documentTypeName = 'Invoice Document';
            return this.$http.get(`{API-ApprovalTool}/create-infinity/${this.documentModel.InvoiceId}/shares/${this.documentModel.ShareId}/documenttypename/${documentTypeName}/${embedOption}`);
        }
    }
}