import './sampleRequests.scss';

import routing from './sampleRequests.routes';
Application.registerConfig(routing);

import SampleRequestsService from './sampleRequests.service';
Application.registerRepository('sampleRequestsService', SampleRequestsService);

import SampleRequestsController from './sampleRequests.controller';
Application.registerController('SampleRequestsController', SampleRequestsController);

import SampleNavigationComponent from './navigation/sampleNavigation.component';
Application.registerComponent('sampleNavigation', SampleNavigationComponent.factory());