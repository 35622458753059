import './commissionAdvanceDetail.scss';

import CommissionAdvanceRequestService from './commissionAdvanceRequest.service';
Application.registerRepository('commissionAdvanceRequestService', CommissionAdvanceRequestService);

import CommissionAdvanceRequestController from './commissionAdvanceRequest.controller';
Application.registerController('CommissionAdvanceRequestController', CommissionAdvanceRequestController);

import CommissionAdvanceDetailcontroller = require('./commissionAdvanceDetail.controller');
Application.registerController('CommissionAdvanceDetailController', CommissionAdvanceDetailcontroller.CommissionAdvanceDetailController);

import CommissionAdvanceNavigationComponent from './Navigation/commissionAdvanceNavigation.component';
Application.registerComponent('commissionAdvanceNavigation', CommissionAdvanceNavigationComponent.factory());

import CommissionAdvanceApproverSearchController from './approver/commissionAdvanceApproverSearch.controller';
Application.registerController('CommissionAdvanceApproverSearchController', CommissionAdvanceApproverSearchController);

import CommissionAdvanceApproverSearchService from './approver/commissionAdvanceApproverSearch.service';
Application.registerRepository('commissionAdvanceApproverSearchService', CommissionAdvanceApproverSearchService);