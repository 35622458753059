import LoanTypeChangeBalanceDetailmodel = require('./loanTypeChangeBalanceDetail.model');
import LoanTypeChangeBalanceDetail = LoanTypeChangeBalanceDetailmodel.LoanTypeChangeBalanceDetail;

export class LoanTypeChangeRequestData {

    constructor(
        public salesPeriodId: number,
        public salesYear: number,
        public territoryId: string,
        public salesAgencyId: number,
        public salesAgencyName: string,
        public operationId: number,
        public operationName: string,
        public businessPartnerId: number,
        public businessPartnerName: string,
        public currentLoanType: string,
        public currentLoanTypeDescription: string,
        public currentLoanTypeRate: number,
        public epsImpact: boolean,
        public availableStartDate: string,
        public availableEndDate: string,
        public newLoanType: string,
        public newLoanTypeDescription: string,
        public newLoanTypeRate: number,
        public newEpsImpact: boolean,
        public interestAmount: number,
        public comment: string,
        public balances: LoanTypeChangeBalanceDetail
    ) { }
}