import {IRedirectService} from '../../../../core/services/redirect.service.interface';

import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

import {SupportNavItem} from './supportNavItem.model';
import { SupportDropdownItem } from './supportDropdownItem.model';
import SupportRequestsService from './supportRequests.service';

export default class SupportRequestsController {

    title: string = 'Search for requests';

    pages: Array<SupportNavItem> = [
        new SupportNavItem('Flex Fund Requests', 'Search for Flex Fund Requests', 'supportRequestsCPA')
    ];

    subPages: Array<SupportDropdownItem>;
    loadingRequestCount: boolean = true;

    private user: IUser;

    constructor(
        private $mdSidenav: ng.material.ISidenavService,
        private redirectService: IRedirectService,
        private userService: IUserService,
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private supportRequestsService: SupportRequestsService) {
        this.$onInit();
    }

    $onInit(): void {
        this.user = this.userService.get();
        if (!this.user.useSupportPage) {
            this.redirectService.redirectToLogin();
        }

        this.setupPackageOverridePage();
        this.sortMenuItems();

        this.activate();
    }

    openLeftNav(): void {
        this.refreshCount();
        this.$mdSidenav('left').open();
    }

    selectState(state: SupportNavItem): void {
        this.$mdSidenav('left').close();
        this.title = state.title;
        this.subPages = state.subPages;
    }

    selectSubpage(subPage: SupportDropdownItem): void {
        this.title = subPage.name;
    }

    private sortMenuItems() {
        this.pages.sort((n1: SupportNavItem, n2: SupportNavItem) => {
            if (n1.name.toLowerCase() > n2.name.toLowerCase()) {
                return 1;
            }

            if (n1.name.toLowerCase() < n2.name.toLowerCase()) {
                return -1;
            }

            return 0;
        });
    }

    private activate() {
        this.setupSamplePage();

        if (this.pages.length > 0) {
            this.title = this.pages[0].title;
        }

        this.setSupportPermissions();

        this.sortMenuItems();

        this.setupDefaultPage();

        this.refreshCount();

        this.$timeout(() => {
            if (this.$state && this.$state.current && this.$state.current.name && 'supportPlanningRequestState') {
                //do nothing
            }
            else {
                this.openLeftNav();
            }

        }, 1);
    }

    private setupSamplePage() {
        this.pages.push(new SupportNavItem('Sample Requests', 'Search for Sample requests', 'supportRequestsSampleState'));
    }

    private setupPackageOverridePage() {
        if (!this.user.isSystemUser) {
            return;
        }

        let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
        subpages.push(new SupportDropdownItem('Search for Infinity Package Override Requests', 'supportPackageOverrideSearchRequestsState'));
        subpages.push(new SupportDropdownItem('Request Infinity Package Override', 'supportPackageOverrideRequestsState'));

        this.pages.push(new SupportNavItem('Package Override Requests', 'Search for Infinity Package Override Requests', 'supportPackageOverrideSearchRequestsState', subpages, 0, false));
    }

    private setupDefaultPage() {
        let state: string = '';
        if (this.$state && this.$state.current) {
            state = this.$state.current.name;
        }
        else if (this.user.isFinanceApprover) {
            state = 'supportMyPendingBalanceWriteOffState';
        } else if (this.user.canManuallyImplementBalanceWriteOff) {
            state = 'supportMyPendingImplementBalanceWriteOffState';
        } else if (this.user.canApprovePricingRequests) {
            state = 'supportMyPendingPricingRequestState';
        } else if (this.user.isPricingAdminUser || this.user.canCreatePricingRequests) {
            state = 'createPricingStateV2';
        }

        if (state === '') {
            return;
        }

        const defaultPage: SupportNavItem = this.pages.filter(page => page.state === state)[0];

        if (defaultPage) {
            this.selectState(defaultPage);
            this.$timeout(() => {
                this.$state.go(state);
                },
                0);
        }
    }

    private refreshCount() {
        const writeOffPage: SupportNavItem =
            this.pages.filter(page => page.state === 'supportMyPendingBalanceWriteOffState')[0];

        const pricingPage: SupportNavItem =
            this.pages.filter(page => page.state === 'supportMyPendingPricingRequestState')[0];

        if (writeOffPage || pricingPage) {
            this.supportRequestsService.pendingRequestCount()
                .then((response) => {
                    if (writeOffPage) {
                        writeOffPage.count = response.data.balanceWriteOffCount;
                    }

                    if (pricingPage) {
                        pricingPage.count = response.data.pricingRequestCount;
                    }
                })
                .finally(() => {
                    this.loadingRequestCount = false;
                });
        }

        const implementWriteOffPage: SupportNavItem =
            this.pages.filter(page => page.state === 'supportMyPendingImplementBalanceWriteOffState')[0];

        if (implementWriteOffPage) {
            this.supportRequestsService.pendingImplementRequestCount()
                .then((response) => {
                    implementWriteOffPage.count = response.data.balanceWriteOffImplementCount;
                })
                .finally(() => {
                    this.loadingRequestCount = false;
                });
        }
    }

    private setSupportPermissions() {
        if (this.user.canViewCommissionRequests) {
            this.pages.push(new SupportNavItem('Commission Advance Seed', 'Search for Commission Advance Seed requests', 'supportRequestsCommissionAdvance'));

            this.pages.push(new SupportNavItem('Commission Advance Services', 'Search for Commission Advance Services requests', 'supportRequestsEncircaCommissionAdvance'));

            this.pages.push(new SupportNavItem('Monthly Draw Requests', 'Search for Monthly Draw requests', 'supportRequestsMonthlyDrawState'));

        }

        this.pages.push(
            new SupportNavItem('Replant Requests',
                'Search for Replant requests',
                'supportRequestsReplantState'));

        if (this.user.canEditDoaOverride) {
            this.pages.push(
                new SupportNavItem('DOA Override',
                    'DOA Overrides',
                    'supportDoaOverrideState'));
        }

        $fsLDFeatureFlagService.isEnabledAsync('OZ.609557.AgencyDiscountMasterSubmission')
            .then(enabled => {
                if (enabled) {
                    this.pages.push(
                        new SupportNavItem('Agency Discount Requests',
                            'Agency Discount Requests',
                            'supportAgencyDiscountMasterState'));
                    this.pages.sort((a, b) => a.name.localeCompare(b.name));
                }
            });

        if (this.user.canViewAgencyBalanceExceptions) {
            this.pages.push(
                new SupportNavItem(
                    'Agency Balance Exceptions',
                    'Search for Agency Balance Exceptions',
                    'supportRequestsAgencyBalanceExceptionV2State',
                    this.populateAgencyBalanceSubpages()
                ));
        }

        if (this.user.canViewBalanceWriteOff) {
            if (this.user.isFinanceApprover) {
                this.populateFinanceApproverPages();
            } else if (this.user.canManuallyImplementBalanceWriteOff) {
                this.pages.push(new SupportNavItem('Balance Write-off Requests',
                    'My Balance Write-off Implement Requests',
                    'supportMyPendingImplementBalanceWriteOffState',
                    null,
                    0,
                    true));
            }
            else {
                if (this.user.canCreateBalanceWriteOff) {
                    this.populateSalesSupportBalanceWritePages();

                } else {
                    this.pages.push(new SupportNavItem('Balance Write-off Requests',
                        'Search for Balance Write-off Requests',
                        'supportRequestsBalanceWriteOffState'));
                }
            }
        }

        if (this.user.canViewPricingRequests) {
            this.populatePricingRequestPages();
        }

        if (this.user.canViewLoanTypeChange) {
            this.populateSalesSupportLoanTypeChangePages();
        }
    }

    private populateFinanceApproverPages() {
        let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
        subpages.push(new SupportDropdownItem('My Balance Write-off Requests',
            'supportMyPendingBalanceWriteOffState'));
        subpages.push(new SupportDropdownItem('Search for Balance Write-off Requests',
            'supportRequestsBalanceWriteOffState'));

        this.pages.push(new SupportNavItem('Balance Write-off Requests',
            'My Balance Write-off Requests',
            'supportMyPendingBalanceWriteOffState',
            subpages,
            0,
            true));
    }

    private populateAgencyBalanceSubpages(): Array<SupportDropdownItem> {
        const subPages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();

        subPages.push(new SupportDropdownItem('Search for Agency Balance Exceptions', 'supportRequestsAgencyBalanceExceptionV2State'));

        if (this.user.canReviewAgencyBalanceExceptions) {
            subPages.push(new SupportDropdownItem('Search for Pending Agency Balance Exceptions', 'supportRequestsPendingAgencyBalanceExceptionV2State'));
        }

        if (this.user.canCreateAgencyBalanceExceptions) {
            subPages.push(new SupportDropdownItem('Request Agency Balance Exception', 'supportCreateAgencyBalanceExceptionV2State'));
        }

        return subPages;
    }

    private populateSalesSupportBalanceWritePages() {
        let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
        subpages.push(new SupportDropdownItem('Search for Balance Write-off Requests',
            'supportRequestsBalanceWriteOffState'));
        subpages.push(new SupportDropdownItem('Request Balance Write-off',
            'supportCreateBalanceWriteOffState'));

        this.pages.push(new SupportNavItem('Balance Write-off Requests',
            'Search for Balance Write-off Requests',
            'supportRequestsBalanceWriteOffState',
            subpages,
            0,
            false));
    }

    private populatePricingRequestPages() {
        if (this.user.canCreatePricingRequests && this.user.canApprovePricingRequests) {
            let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
            subpages.push(new SupportDropdownItem('My Pending Pricing Requests',
                'supportMyPendingPricingRequestState'));
            subpages.push(new SupportDropdownItem('Pricing Requests',
                'allPricingRequestsState'));

            subpages.push(new SupportDropdownItem('Product Pricing Request',
                'createPricingStateV2'));

            if (this.user.canCreatePricingTreatmentRequests) {
                subpages.push(new SupportDropdownItem('Treatment Pricing Request',
                    'createTreatmentPricingStateV2'));
            }

            this.pages.push(new SupportNavItem('Pricing Requests',
                'My Pending Pricing Requests',
                'supportMyPendingPricingRequestState',
                subpages,
                0,
                true));

        } else if (this.user.canCreatePricingRequests) {
            let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();

            subpages.push(new SupportDropdownItem('Create Pricing Request',
                'createPricingStateV2'));

            subpages.push(new SupportDropdownItem('Pricing Requests',
                'allPricingRequestsState'));

            this.pages.push(new SupportNavItem('Pricing Requests',
                'Create Pricing Request',
                'createPricingStateV2',
                subpages,
                0,
                false));

        } else if (this.user.canApprovePricingRequests) {
            let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
            subpages.push(new SupportDropdownItem('My Pending Pricing Requests',
                'supportMyPendingPricingRequestState'));
            subpages.push(new SupportDropdownItem('Pricing Requests',
                'allPricingRequestsState'));

            this.pages.push(new SupportNavItem('Pricing Requests',
                'My Pending Pricing Requests',
                'supportMyPendingPricingRequestState',
                subpages,
                0,
                true));
        }
    }

    private populateSalesSupportLoanTypeChangePages() {
        let subpages: Array<SupportDropdownItem> = new Array<SupportDropdownItem>();
        subpages.push(new SupportDropdownItem('Search for Loan Type Change Requests',
            'supportRequestsLoanTypeChangeState'));

        this.pages.push(new SupportNavItem('Loan Type Change Requests',
            'Search for Loan Type Change Requests',
            'supportRequestsLoanTypeChangeState',
            subpages,
            0,
            false));
    }
}
