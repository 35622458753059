export default class NumberOnlyDirective implements ng.IDirective {

    restrict: string = 'A';
    require: string = '?ngModel';

    link: (scope: angular.IScope,
           element: angular.IAugmentedJQuery,
           attrs: angular.IAttributes,
           ctrl: angular.INgModelController) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            element.bind('keypress', (event) => {
                if ((event.which < 48 || event.which > 57)) {
                    event.preventDefault();
                }
            });
        };

    static factory(): ng.IDirectiveFactory {
        const directive = () => new NumberOnlyDirective();
        return directive;
    }
}