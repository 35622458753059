import './loanTypeChangePendingRequests.scss';
import './allLoanTypeChangeRequests.scss';

import routing from './loanTypeChangeRequest.routes';
Application.registerConfig(routing);

import LoanTypeChangeNavigationComponent from './Navigation/loanTypeChangeNavigation.component';
Application.registerComponent('loanTypeChangeNavigation', LoanTypeChangeNavigationComponent.factory());

import LoanTypeChangePendingRequestsController from './loanTypeChangePendingRequests.controller';
Application.registerController('LoanTypeChangePendingRequestsController', LoanTypeChangePendingRequestsController);

import LoanTypeChangeRequestsService from './loanTypeChangeRequests.service';
Application.registerRepository('loanTypeChangeRequestsService', LoanTypeChangeRequestsService);

// DETAIL
import LoanTypeChangeRequestDetailController from './detail/loanTypeChangeRequestDetail.controller';
Application.registerController('LoanTypeChangeRequestDetailController', LoanTypeChangeRequestDetailController);

import LoanTypeChangeRequestDetailService from './detail/loanTypeChangeRequestDetail.service';
Application.registerService('loanTypeChangeRequestDetailService', LoanTypeChangeRequestDetailService);

import AllLoanTypeChangeRequestsController from './allLoanTypeChangeRequests.controller';
Application.registerController('AllLoanTypeChangeRequestsController', AllLoanTypeChangeRequestsController);