import { ISupportSearchPlanningRequestsResult } from './supportSearchPlanningRequestsResult.interface';
import { ISupportSearchPlanningRequestsService } from './supportSearchPlanningRequests.service.interface';

export default class SupportSearchPlanningService implements ISupportSearchPlanningRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(searchText): ng.IPromise<ng.IHttpPromiseCallbackArg<ISupportSearchPlanningRequestsResult[]>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/planning-requests/search/` + searchText);
    }

    deny(salesYear: number, territoryId: string) {
        this.$http.post(`{API-ApprovalTool}/bpp/requests/territory/` + salesYear + `/` + territoryId +`/deny`, 1);
    }

    approve(salesYear: number, territoryId: string) {
        this.$http.post(`{API-ApprovalTool}/bpp/requests/territory/` + salesYear + `/` + territoryId + `/approve`, 1);
    }
}
