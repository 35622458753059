export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-Pricing/', {
            template: require('../pricing/pricingRequests.html'),
            controller: 'PricingPendingRequestsController',
            controllerAs: 'vm'
        })
        .when('/requestType-Pricing/create', {
            template: require('./../pricing/request/pricingRequest.html'),
            controller: 'PricingRequestController',
            controllerAs: 'vm'
        })
        .when('/requestType-PricingTreatment/create', {
            template: require('./../pricing/request/pricingTreatmentRequest.html'),
            controller: 'PricingTreatmentRequestController',
            controllerAs: 'vm'
        })
        .when('/requestType-PricingTreatment/create/v2', {
            template: require('./../pricing/request/pricingTreatmentRequestV2.html'),
            controller: 'PricingTreatmentRequestV2Controller',
            controllerAs: 'vm'
        })
        .when('/requestType-Pricing/all', {
            template: require('../pricing/allPricingRequests.html'),
            controller: 'AllPricingRequestsController',
            controllerAs: 'vm'
        })
        .when('/requestType-Pricing/massProduct', {
            template: require('../pricing/massProductPricingRequests.html'),
            controller: 'MassProductPricingRequestsController',
            controllerAs: 'vm'
        })
        .when('/requestType-Pricing/massTreatment', {
            template: require('../pricing/massTreatmentPricingRequests.html'),
            controller: 'MassTreatmentPricingRequestsController',
            controllerAs: 'vm'
        });
}