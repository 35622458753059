export default class BalanceWriteOffRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/bwo/requests/pending`);
    };

    approve(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/balance-write-off/requests/${request.requestId}/approve`;
        const data: {} = request.requestId;

        return this.$http.post(url, data);
    };

    deny(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/balance-write-off/requests/${request.requestId}/deny`;
        const data: {} = request.requestId;

        return this.$http.post(url, data);
    };

    withdraw(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/balance-write-off/requests/${request.requestId}/withdraw`;
        const data: {} = request.requestId;

        return this.$http.post(url, data);
    };
}
