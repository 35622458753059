import './search.scss';

import './approver/_bootstrap';
import './approverAgencyBalanceExceptionV2/_bootstrap';
import './support/_bootstrap';
import './supportAgencyBalanceExceptionV2/_bootstrap';
import './supportCommissionAdvance/_bootstrap';
import './supportMonthlyDraw/_bootstrap';
import './supportRequests/_bootstrap';
import './supportReplant/_bootstrap';
import './supportSample/_bootstrap';
import './supportEncircaCommissionAdvance/_bootstrap';
import './supportBalanceWriteOff/_bootstrap';
import './supportLoanTypeChange/_bootstrap';
import './supportPlanning/_bootstrap';   
import './supportPlanningRequests/_bootstrap';
import './supportInfinityPackageOverride/_bootstrap';