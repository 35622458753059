import { IInfinityPackageOverrideComment } from './infinityPackageOverrideComment.model.interface';

export class InfinityPackageOverrideComment implements IInfinityPackageOverrideComment {

    constructor(
        public requestId: number,
        public comment: string,
        public requestCommentId: number = 0,
        public displayName: string = '',
        public commentDate: string = '1900-01-01'
    ) { }
}