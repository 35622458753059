export default class LessThanOrEqualValidator implements ng.IDirective {
    restrict: string = 'A';
    require: string = '^ngModel';

    link: (scope: angular.IScope,
        element: angular.IAugmentedJQuery,
        attrs: IValidatorAttributes,
        ctrl: angular.INgModelController) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: IValidatorAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            ctrl.$validators.lessThanOrEqualValidator = (modelValue, viewValue) => {
                if (ctrl.$isEmpty(viewValue)) {
                    return true;
                }

                if (Number(modelValue) <= attrs.lessThanOrEqualValidator) {
                    return true;
                }
                return false;
            };
        };

    static factory(): ng.IDirectiveFactory {
        return () => {
            return new LessThanOrEqualValidator();
        };
    }
}
export interface IValidatorAttributes extends ng.IAttributes {
    lessThanOrEqualValidator: number;
}