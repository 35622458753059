import { IProposalCpaComment } from './proposalcpaComment.model.interface';
import { IProposalCpaCommentsService } from './proposalcpaComments.service.interface';

export default class ProposalCpaCommentsService implements IProposalCpaCommentsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveComment(comment: IProposalCpaComment): ng.IPromise<any> {
        const uri: string = `{API-ApprovalTool}/requests/proposalcpa/comments/save`;

        return this.$http.post(uri, comment);
    }
}