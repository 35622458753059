import DiscountsV5ComponentController from './discountsV5.component.controller';

export default class DiscountsV5Component implements ng.IComponentOptions {
    template: string = require('./discountsV5.html');
    bindings: { [binding: string]: string } = {
        year: '<',
        shareCurrentDiscounts: '<',
        customerCurrentDiscounts: '<',
        customerPreviousDiscounts: '<',
        salesAgencyName: '<'
    };
    controller: Function = DiscountsV5ComponentController;

    static factory() {
        const component: Function = () => {
            return new DiscountsV5Component();
        };

        return component();
    }
}