export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-BalanceException/v2', {
            template: require('../agencyBalanceExceptionV2/balanceExceptionRequests.html'),
            controller: 'BalanceExceptionPendingRequestsControllerV2',
            controllerAs: 'vm'
        })
        .when('/requestType-BalanceException/v2/all', {
            template: require('../agencyBalanceExceptionV2/balanceExceptionRequests.html'),
            controller: 'BalanceExceptionAllPendingRequestsControllerV2',
            controllerAs: 'vm'
        })
        .when('/requestType-BalanceException/v2/create', {
            template: require('./../agencyBalanceExceptionV2/createBalanceExceptionRequest.html'),
            controller: 'CreateBalanceExceptionRequestControllerV2',
            controllerAs: 'vm'
        });
}
