import {CommissionAdvanceRequest} from './commissionAdvanceRequest';
import {ICommissionAdvanceRequestDetails} from './commissionAdvanceRequestDetails.interface';

export class CommissionAdvanceRequestRow {
    request: CommissionAdvanceRequest;
    details: ICommissionAdvanceRequestDetails;
    isLoadingDetails: boolean;
    isPending: number = 3;
    isApproved: number = 5;
    isDenied: number = 6;
    isExpired: number = 107;

    constructor(request: CommissionAdvanceRequest) {
        this.request = request;
        this.request.isApproved = request.statusId === this.isApproved;
        this.request.isDenied = request.statusId === this.isDenied;
        this.request.isPending = request.statusId === this.isPending;
        this.request.isExpired = request.statusId === this.isExpired;
        this.isLoadingDetails = true;
    }

    addDetails(details: ICommissionAdvanceRequestDetails) {
        this.details = details;
        this.isLoadingDetails = false;
    }
}