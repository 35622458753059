export default class EnhanceTableNavigation implements  ng.IDirective {
    restrict: string = 'A';
    link: (scope: angular.IScope,
           element: angular.IAugmentedJQuery,
           attrs: angular.IAttributes,
           ctrl: angular.INgModelController) => void = (scope: ng.IScope,
                                                        element: ng.IAugmentedJQuery) => {
        element.on('keydown',
            e => {
                const keyCode: { up: number; down: number; enter: number } = { up: 38, down: 40, enter: 13 };

                if ([keyCode.up, keyCode.down, keyCode.enter].indexOf(e.which) < 0) {
                    return;
                }

                const td: angular.IAugmentedJQuery = angular.element(e.target.closest('td'));
                const tr: HTMLElement = td.parent()[0];
                let moveToRow: angular.IAugmentedJQuery;

                
                switch (e.which) {
                    case keyCode.up:
                        moveToRow = angular.element(tr.previousElementSibling);
                        break;
                    case keyCode.down:
                    case keyCode.enter:
                        moveToRow = angular.element(tr.nextElementSibling);
                        break;
                }

                if (moveToRow) {

                    e.preventDefault();

                    const moveToCell: HTMLElement = moveToRow.children()[(td[0] as HTMLTableCellElement).cellIndex];
                    const input: JQuery = angular.element(moveToCell).children().find('input');
                    input.focus();
                }
            });
    }

    static factory(): ng.IDirectiveFactory {
        const directive: () => EnhanceTableNavigation = () => {
            return new EnhanceTableNavigation();
        };

        return directive;
    }
}