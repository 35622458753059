import './requestCountContainer.scss';

export default class RequestCountContainerComponent implements ng.IComponentOptions {
    template: string = require('./requestCountContainer.html');
    controllerAs: 'vm';
    bindings: { [binding: string]: string } = {
        count: '<',
        loading: '<'
    };

    static factory() {
        const component: Function = () => {
            return new RequestCountContainerComponent();
        };

        return component();
    }
}
