import { IMassPricingDetailRequest } from "./massPricingDetail.model.interface";

export default class MassPricingDetailService {
    constructor(private $http: ng.IHttpService) {
        // noop
    }

    sendDenyRequest(detailData: IMassPricingDetailRequest): ng.IPromise<any> {
        const target: string = `{API-ApprovalTool}/pricing/mass-approval/detail/`;

        return this.$http.post(target, detailData);
    }

    getComments(fileName: string, fileVersion: string): ng.IPromise<any> {
        const target: string = `{API-ApprovalTool}/pricing/mass-approval/comment/${fileName}/${fileVersion}`;
        return this.$http.get(target); 
    }
}