export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-Commission/', {
                template: require('./requestTypesCommission.html'),
                controller: 'RequestTypesCommissionController',
                controllerAs: 'vm'
            })
        .when('/requestType-Commission/search',
            {
                template: require('../commissionAdvance/approver/commissionAdvanceApproverSearch.html'),
                controller: 'CommissionAdvanceApproverSearchController',
                controllerAs: 'vm'
            });
}

let requestTypesCommissionState: ng.ui.IState = {
    name: 'requestType-Commission',
    url: '/requestType-Commission/',
    views: {
        '': {
            template: require('./requestTypesCommission.html'),
            controller: 'RequestTypesCommissionController',
            controllerAs: 'vm'
        },
        'advance-tab@requestType-Commission': {
            template: require('../commissionAdvance/commissionAdvanceRequests.html'),
            controller: 'CommissionAdvanceRequestController',
            controllerAs: 'vm'
        }
    }
};

angular.module('approval-tool').config(($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state(requestTypesCommissionState);
});
