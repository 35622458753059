import './requestTypes.scss';

import routing from './requestTypes.routes';
Application.registerConfig(routing);

import routingCommission from './requestTypesCommission.routes';
Application.registerConfig(routingCommission);

import routingMonthlyDraw from './requestTypesMonthlyDraw.routes';
Application.registerConfig(routingMonthlyDraw);

import routingReplant from './requestTypesReplant.routes';
Application.registerConfig(routingReplant);

import routingBalanceWriteOff from './requestTypesBalanceWriteOff.routes';
Application.registerConfig(routingBalanceWriteOff);

import routingBalanceException from './requestTypesBalanceException.routes';
Application.registerConfig(routingBalanceException);

import routingEncircaCommission from './requestTypesEncircaCommission.routes';
Application.registerConfig(routingEncircaCommission);

import routingPricing from './requestTypesPricing.routes';
Application.registerConfig(routingPricing);

import routingLtc from './requestTypesLoanTypeChange.routes';
Application.registerConfig(routingLtc);

import routingBusinessPartnerPlanning from './requestTypeBusinessPartnerPlanning.routes';
Application.registerConfig(routingBusinessPartnerPlanning);

import RequestTypesController from './requestTypes.controller';
Application.registerController('RequestTypesController', RequestTypesController);

import RequestTypesCommissionController from './requestTypesCommission.controller';
Application.registerController('RequestTypesCommissionController', RequestTypesCommissionController);

import RequestTypesMonthlyDrawController from './requestTypesMonthlyDraw.controller';
Application.registerController('RequestTypesMonthlyDrawController', RequestTypesMonthlyDrawController);

import RequestTypesReplantController from './requestTypesReplant.controller';
Application.registerController('RequestTypesReplantController', RequestTypesReplantController);

import RequestTypesBalanceWriteOffController from './requestTypesBalanceWriteOff.controller';
Application.registerController('RequestTypesBalanceWriteOffController', RequestTypesBalanceWriteOffController);

import RequestTypesEncircaCommissionController from './requestTypesEncircaCommission.controller';
Application.registerController('RequestTypesEncircaCommissionController', RequestTypesEncircaCommissionController);