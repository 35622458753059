import CustomerDiscountController from './customerDiscount.component.controller';

export default class CustomerDiscountComponent implements ng.IComponentOptions {
    template: string = require('./customerDiscount.html');
    bindings: { [binding: string]: string } = {
        formReference: '<',
        discounts: '<',
        planningYear: '<',
        canEdit: '<',
        onSaveDiscount: '&',
        onSaveLoadFlexFund: '&'
    };
    controller: Function = CustomerDiscountController;

    static factory() {
        const component: Function = () => {
            return new CustomerDiscountComponent();
        };

        return component();
    }
}