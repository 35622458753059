import { IProposalCpaComment } from './proposalcpaComment.model.interface';

export class ProposalCpaComment implements IProposalCpaComment {

    constructor(
        public requestId: number,
        public comment: string,
        public requestCommentId: number = 0,
        public displayName: string = '',
        public commentDate: string = '1900-01-01'
    ) { }
}