import ReplantCommentsController from './replantComments.controller';

export default class ReplantCommentsComponent implements ng.IComponentOptions {
    template: string = require('./replantComments.html');
    bindings: { [binding: string]: string } = {
        comments: '<',
        requestId: '<',
        allowComment: '<?'
    };
    controller: Function = ReplantCommentsController;

    static factory() {
        const component: Function = () => {
            return new ReplantCommentsComponent();
        };

        return component();
    }
}