import {IImpersonatedUserRepository} from './impersonatedUser.repository.interface';

export default class ImpersonatedUserRepository implements IImpersonatedUserRepository {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getUser(): ng.IPromise<any> {
        return this.$http.get(`{API-ApprovalTool}/usersessioninfo`);
    }
}
