import { IMassPricingDetailResponse, IMassPricingDetailRequest } from './massPricingDetail.model.interface';

export class MassPricingDetailResponse implements IMassPricingDetailResponse {
    constructor(
        public fileName: string,
        public fileVersion: string,
        public displayName: string = '',
        public comment: string = '',
        public commentDate: string = '1900-01-01'
    ) {
        // noop
    }
}

export class MassPricingDetailRequest implements IMassPricingDetailRequest{
    constructor(
        public fileName: string,
        public fileVersion: string,
        public isApproved: boolean,
        public massPricingApprovalTypeId: number,
        public commentText: string
    ) { }
}