import {IUser} from '../../../core/models/user.model.interface';
import {IUserService} from '../../../core/services/user.service.interface';

export default class BalanceWriteOffAllPendingRequestsController {

    navigationText: string;

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService,
        private userService: IUserService
    ) {
        
    }

    $onInit(): void {
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.user = this.userService.get();

        if (this.$location.path().indexOf('all') > -1) {
            this.navigationText = 'All pending requests';
        } else {
            this.navigationText = 'My pending requests';
        }
    }
}