import PlanningCustomerDetailsService from './planningCustomerDetails.service';
import { CustomerDetailsModel } from './customerDetails.model';
import { PlanningProductLineModel } from './planningProductLine.model';
import { PlannedDiscountModel } from './discounts/plannedDiscount.model';
import PlanningCustomerDiscountService from '../core/planningCustomerDiscount.service';
import { FlexFundProductLineModel } from './discounts/flexFundProductLine.model';
import { BusinessPartnerLineModel } from '../agencySummary/businessPartnerLine.model';
import { IScope } from 'angular';
import IPromise = angular.IPromise;

export default class PlanningCustomerDetailsController {

    loading: boolean = true;
    saving: boolean = false;
    discountColCount: number = 2;
    details: CustomerDetailsModel;
    currentIndex: number = 0;
    panelRef: any;

    constructor(
        public salesAgencyId: number,
        public customerId: number,
        public agencyCustomerId: number,
        public customerName: string,
        private territoryId: string,
        private $mdDialog: ng.material.IDialogService,
        private planningCustomerDetailsService: PlanningCustomerDetailsService,
        private planningCustomerDiscountService: PlanningCustomerDiscountService,
        private $q: ng.IQService,
        private $filter: ng.IFilterService,
        private customers: BusinessPartnerLineModel[],
        private $mdPanel: ng.material.IPanelService,
        private sortOrder: string,
        private $scope: IScope,
        private searchName: string) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): IPromise<void> {
        return this.loadSummary();
    }

    private setNavigation(customer: BusinessPartnerLineModel) {
        this.customerId = customer.customerId;
        this.agencyCustomerId = customer.agencyCustomerId;
        this.customerName = customer.name;
        this.loadSummary();
    }

    next() {
        let customers: Array<BusinessPartnerLineModel>;

        if (this.panelRef && this.panelRef.searchName) {
            this.searchName = this.panelRef.searchName;
            customers = _(this.customers).select(v => v.searchText.toLowerCase().indexOf(this.searchName.toLowerCase()) > -1).value();
        }
        else {
            customers = this.customers;
        }

        const customer: BusinessPartnerLineModel = _(customers).select(i => i.customerId === this.customerId).first();
        let index: number = customers.indexOf(customer) + 1;
        if (index === customers.length) {
            index = 0;
        }
        this.setNavigation(customers[index]);
    }

    back() {
        let customers: Array<BusinessPartnerLineModel>;

        if (this.panelRef && this.panelRef.searchName) {
            this.searchName = this.panelRef.searchName;
            customers = _(this.customers).select(v => v.searchText.toLowerCase().indexOf(this.searchName.toLowerCase()) > -1).value();
        }
        else {
            customers = this.customers;
        }

        const customer: BusinessPartnerLineModel = _(customers).select(i => i.customerId === this.customerId).first();
        let index: number = _(customers).indexOf(customer) - 1;
        if (index === -1) {
            index = customers.length - 1;
        }
        this.setNavigation(customers[index]);
    }

    private search: () => void = function () {
        this.searchName = this.panelRef.searchName;
        if (this.panelRef && this.panelRef.customerId) {
            const customer: BusinessPartnerLineModel = _(this.customers).select((i: BusinessPartnerLineModel) => i.customerId === this.panelRef.customerId).first();
            const index: number = _(this.customers).indexOf(customer);

            this.setNavigation(this.customers[index]);
        }
    }

    private loadSummary(): IPromise<void> {
        this.loading = true;

        return this.planningCustomerDetailsService.getCustomerDetails(this.territoryId, this.salesAgencyId, this.customerId, this.agencyCustomerId).then(t => {
            this.setData(t.data);
        }).finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    private saveTotalDiscount(totalDiscount: number): ng.IHttpPromise<void> {
        return this.planningCustomerDiscountService.saveTotalDiscountTargetPlan(this.agencyCustomerId, totalDiscount);
    }

    private saveDiscount(model: PlannedDiscountModel): ng.IHttpPromise<void> {
        return this.planningCustomerDiscountService.saveCustomerDiscountTargetAmount(this.salesAgencyId,
            this.agencyCustomerId,
            model.customerDiscountTypeId,
            model.amount);
    }

    private setData(model: CustomerDetailsModel) {
        if (!model.plannedDiscounts.formattedTotals) {
            model.plannedDiscounts.formattedTotals =
                new Array<string>(model.plannedDiscounts.previousTotalDiscounts.length);
        }

        if (!model.plannedDiscounts.flexFundDiscount.flexFund.formattedPercentages) {
            model.plannedDiscounts.flexFundDiscount.flexFund.formattedPercentages =
                new Array<string>(model.plannedDiscounts.flexFundDiscount.flexFund.previousPercentages.length);
        }

        model.plannedDiscounts.flexFundDiscount.productLines.forEach((value: FlexFundProductLineModel) => {
            if (!value.formattedPercentages) {
                value.formattedPercentages = new Array<string>(model.plannedDiscounts.previousTotalDiscounts.length);
            }
        });

        model.plannedDiscounts.discounts.forEach((value: PlannedDiscountModel) => {
            if (!value.formattedPercentages) {
                value.formattedPercentages = new Array<string>(model.plannedDiscounts.previousTotalDiscounts.length);
            }
        });

        for (let col: number = 0; col < model.plannedDiscounts.previousYears.length; ++col) {
            if (model.plannedDiscounts.previousYears[col] > 2019) {
                model.plannedDiscounts.flexFundDiscount.flexFund.formattedPercentages[col] = this.formatDiscountPercent(model.plannedDiscounts.flexFundDiscount.flexFund.previousPercentages[col]);
                model.plannedDiscounts.flexFundDiscount.productLines.forEach(
                    (value: FlexFundProductLineModel) => value.formattedPercentages[col] = this.formatDiscountPercent(value.previousPercentages[col]));
                model.plannedDiscounts.discounts.forEach(
                    (value: PlannedDiscountModel) => value.formattedPercentages[col] = this.formatDiscountPercent(value.previousPercentages[col]));
                model.plannedDiscounts.formattedTotals[col] = this.formatDiscountPercent(model.plannedDiscounts.previousTotalDiscounts[col]);
            }
        }

        this.details = model;
        this.discountColCount = this.details.plannedDiscounts.previousYears.length + 3;
    }

    private formatDiscountPercent(val: number) {
        return (this.$filter('percentage') as Filter.IFilterPercentage)(val, false, 2, true);
    }

    cancel() {
        this.$mdDialog.hide();
    }

    openCustomerSearch(): void { 

        var vm = this;

        if (this.panelRef) {
            this.panelRef.close();
        }

        const position: angular.material.IPanelPosition = this.$mdPanel.newPanelPosition()
            .right()
            .top();

        const config = {
            attachTo: angular.element(document.body),
            controller: 'PlanningCustomerDetailsSearchController',
            controllerAs: 'pcds',
            template: require('./search/customerSearch.html'),
            position: position,
            trapFocus: true,
            clickOutsideToClose: false,
            disableParentScroll: true,
            escapeToClose: false,
            hasBackdrop: true,
            zIndex: 1001,
            panelClass: 'layout-fill flex-xs-100 flex-sm-100 flex-md-90 flex-40',
            locals: {
                customers: this.customers,
                customerId: this.customerId,
                searchName: this.searchName
            },
            onRemoving: () => {
                this.search();
                this.panelRef = vm.panelRef;
            }
        };

        this.$mdPanel.open(config).then(function (panelRef) {
            vm.panelRef = panelRef;
        });

        this.panelRef = vm.panelRef;

        this.$scope.$on('$routeChangeStart', function () {
            if (vm.panelRef) {
                vm.panelRef.close();
            }
        });
    } 


    canEditDiscount(model: PlannedDiscountModel) {
        return !this.loading && this.details && this.details.canEdit;
    }

    saveProductLineUnits(model: PlanningProductLineModel, validator: ng.INgModelController): void {
        if (this.details.canEdit && validator.$valid && validator.$dirty) {
            this.saving = true;
            
            this.planningCustomerDetailsService.saveCustomerProductLineUnits(this.salesAgencyId,
                    this.agencyCustomerId,
                    model.productLineId,
                    model.doubleCrop,
                    model.units)
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    savePlannedDiscount(model: PlannedDiscountModel, totalDiscount: number): void {
        if (model) {
            this.saving = true;

            this.$q.all([this.saveDiscount(model), this.saveTotalDiscount(totalDiscount)])
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    saveLoadFlexFund(loadFlexFund: boolean) {
        this.saving = true;
        this.planningCustomerDiscountService.saveLoadFlexFund(this.salesAgencyId,
                this.agencyCustomerId,
                loadFlexFund)
            .finally(() => {
                this.saving = false;
            });
    }
}