import { ReplantComment } from './comments/replantComment.model';
import { ReplantRequestReplacementLineItem } from '../replantRequestReplacementLineItem.model';

export class ReplantDetail {

    constructor(
        public comments: ReplantComment[],
        public replantLineItems: ReplantRequestReplacementLineItem[]) {
        // no-op
    }

    static empty(): ReplantDetail {
        return new ReplantDetail([], []);
    }
}