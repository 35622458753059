import {IRedirectService} from '../../../core/services/redirect.service.interface';
import {ICommissionPermissionsService} from '../commission/commissionPermissions.service.interface';
import {ICommissionPermissions} from '../commission/commissionPermissions.interface';

export default class RequestTypesController {

    loading: boolean = true;
    navigationText: string;

    showCommissionAdvanceTab: boolean = false;
    showMonthlyDrawTab: boolean = false;

    constructor(
        private $location: ng.ILocationService,
        private redirectService: IRedirectService,
        private commissionPermissionsService: ICommissionPermissionsService,
        private $mdSidenav: ng.material.ISidenavService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.commissionPermissionsService.getPermissions()
            .then((permissions) => {
                this.setPermissions(permissions);
            });

        if (this.$location.path().indexOf('all') > -1) {
            this.navigationText = 'All pending requests';
        } else {
            this.navigationText = 'My pending requests';
        }
    }

    private setPermissions(permissions: ICommissionPermissions) {
        this.showMonthlyDrawTab = permissions.canApproveDenyCommissionRequests;
        if (sessionStorage.getItem('NavIconOpened') !== 'true') {
                this.openLeftNav();
        }
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
        sessionStorage.setItem('NavIconOpened', 'true');
    }
}