import {IMetatagService} from './metatag.service.interface';

export class MetatagService implements IMetatagService {
    get(name: string): string {
        const metas = document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === name) {
                return metas[i].getAttribute('content');
            }
        }

        return '';
    }

    getFlagStatus(name:string): boolean {
        return JSON.parse(this.get(name))
    }
}

