import SupportSearchAgencyBalanceExceptionResponse from './supportSearchAgencyBalanceExceptionResponse.model';
import { RequestStatus } from './../../agencyBalanceExceptionV2/core/requestStatus.model';

export default class SupportSearchAgencyBalanceExceptionServiceV2 {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<SupportSearchAgencyBalanceExceptionResponse>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/agency-balance-exception`, data);
    }

    getPendingSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<SupportSearchAgencyBalanceExceptionResponse>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/agency-balance-exception/pending`, data);
    }

    getRequestStatus(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestStatus>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/status`);
    }
}
