export default class SalesYearsComponentController {
    salesYears: any[];
    salesYear: any;

    onSalesYearChange: any;

    constructor() {
    }

    salesYearChanged(): void {
        const salesYear = this.salesYear;
        this.onSalesYearChange({ salesYear });
    }
}