export function httpHeaderInterceptor($q: ng.IQService) {

    const interceptor: ng.IHttpInterceptor = {

        // restore the header if it has been lost from the header due to a page refresh.
        request(config: ng.IRequestConfig): any {

            persistAgencyHeader(config);
            persistImpersonationHeader(config);
            persistAuthorizationHeader(config);

            return config || $q.when(config);
        }
    };

    return interceptor;

    function persistAgencyHeader(config: ng.IRequestConfig) {

        const headers: ng.IHttpRequestConfigHeaders = config.headers;
        const agencyHeader = sessionStorage.getItem('Agency');

        if (!headers['Agency'] && agencyHeader) {
            const agency = JSON.parse(agencyHeader);
            headers['Agency'] = agency.id;
        }

    }

    function persistImpersonationHeader(config: ng.IRequestConfig) {

        const headers: ng.IHttpRequestConfigHeaders = config.headers;
        const header: any = sessionStorage.getItem('Impersonate');

        if (!headers['Impersonate'] && header) {
            headers['Impersonate'] = JSON.parse(header).id;
        }

    }

    function persistAuthorizationHeader(config: ng.IRequestConfig) {

        const headers: ng.IHttpRequestConfigHeaders = config.headers;
        const header: any = sessionStorage.getItem('Authorization');
        const upn: any = sessionStorage.getItem('upn');

        if (!headers['Authorization'] && header) {
            headers['Authorization'] = header;
            headers["upn"] = upn;
        }

    }

}