import './replantDetail.scss';

import ReplantDetailService from './replantDetail.service';
Application.registerRepository('replantDetailService', ReplantDetailService);

import ReplantDetailController from './replantDetail.controller';
Application.registerController('ReplantDetailController', ReplantDetailController);

import ReplantDetailBarComponent from './detailBar/replantDetailBar.component';
Application.registerComponent('replantDetailBar', ReplantDetailBarComponent.factory());

import ReplantCommentsComponent from './comments/replantComments.component';
Application.registerComponent('replantComments', ReplantCommentsComponent.factory());

import ReplantCommentsService from './comments/replantComments.service';
Application.registerRepository('replantCommentsService', ReplantCommentsService);

import ReplantDetailDisplayService from './replantDetailDisplay.service';
Application.registerService('replantDetailDisplayService', ReplantDetailDisplayService);