export default class GreaterThanZero implements ng.IDirective {
    restrict: string = 'A';
    require: string = '^ngModel';

    link: (scope: angular.IScope,
        element: angular.IAugmentedJQuery,
        attrs: angular.IAttributes,
        ctrl: angular.INgModelController) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            ctrl.$validators.greaterThanZero = (modelValue: string, viewvalue: string) => {
                if (ctrl.$isEmpty(viewvalue)) {
                    return true;
                }
                if (Number(modelValue) > 0) {
                    return true;
                }
                return false;
            };
        };

    static factory(): ng.IDirectiveFactory {
        return () => new GreaterThanZero();
    }
}
