import { IUser } from '../../../core/models/user.model.interface';
import { IUserService } from '../../../core/services/user.service.interface';
import { SampleRequest } from './sampleRequest.model';
import { ISampleRequestsService } from './sampleRequests.service.interface';
import SampleDetailDisplayService from './detail/sampleDetailDisplay.service';

export default class SampleRequestsController  {
    requests: SampleRequest[];
    loading: boolean = true;
    canShowTable: () => boolean = (() => true);
    total: number = 0;
    visitedPages: number[] = [];
    requestGroups: Object;
    canPageForward: boolean = false;
    canPageBack: boolean = false;
    beginIndicator: number;
    endIndicator: number;
    nothingToApprove: () => boolean = this.nothingToApproveImpl;

    query: { order: string; limit: number; page: number; begin: number, end: number } = {
        order: 'requestedDate',
        limit: 10,
        page: 1,
        begin: 0,
        end: 10
    };

    constructor(
        private $mdSidenav: ng.material.ISidenavService,
        private sampleRequestsService: ISampleRequestsService,
        private userService: IUserService,
        private sampleDetailDisplayService: SampleDetailDisplayService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];

        this.activate();
    }

    user: IUser;

     openLeftNav(): void {
         this.$mdSidenav('left').open();
     }

    private activate() {
        this.user = this.userService.get();
        this.getPending();
    }

    private getPending(): void {
         this.sampleRequestsService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    private processResponse(response: any): void {
        this.requests = response.data.pendingRequestList;
        this.total = response.data.pendingRequestList.length;
        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests.slice(this.query.begin, this.query.end);
        angular.forEach(requests, (request) => {
            request.processing = false;
            request.isApproved = false;
        });
        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesYear + ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;

        if (this.visitedPages.indexOf(this.query.page) < 0) {
            this.visitedPages.push(this.query.page);
        }

        this.updatePagination();
    }

    private updatePagination(): void {
        this.canPageForward = this.canPaginateForward();
        this.canPageBack = this.canPaginateBackward();
        this.setIndicators();
    }

    private canPaginateForward(): boolean {
        return this.requests.length > this.query.end;
    }

    private canPaginateBackward(): boolean {
        return this.query.page > 1;
    }

    private setIndicators(): void {
        this.beginIndicator = this.query.begin + 1;

        this.endIndicator = this.requests.length > this.query.end ? this.query.end : this.requests.length;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    paginateForward(): void {
        this.query.page++;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    paginateBackward(): void {
        this.query.page--;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    approve($event: MouseEvent, sampleRequest: any): void {
        $event.stopPropagation();
         sampleRequest.processing = true;

         this.sampleRequestsService.approve(sampleRequest)
            .then(() => {
                sampleRequest.isApproved = true;
                this.setActionPermissions(sampleRequest, false, false);
            })
            .finally(() => sampleRequest.processing = false);
    }

    deny($event: MouseEvent, sampleRequest: any): void {
        $event.stopPropagation();
         sampleRequest.processing = true;

         this.sampleRequestsService.deny(sampleRequest)
             .then(() => {
                 this.requests.forEach((item) => {
                     if (item.requestId === sampleRequest.requestId) {
                         item.isDenied = true;
                         this.setActionPermissions(item, false, false);
                     }
                 });
             })
            .finally(() => sampleRequest.processing = false);
    }

    canApprove(sampleRequest: any): boolean {
        return this.user.canApprove && sampleRequest.canApprove && !sampleRequest.processing;
    }

    canDeny(sampleRequest: any): boolean {
        return this.user.canDeny && sampleRequest.canDeny && !sampleRequest.processing;
    }

    private setActionPermissions(request: any, canApprove: boolean, canDeny: boolean): void {
        request.canApprove = canApprove;
        request.canDeny = canDeny;
    }

    showDialog($event: MouseEvent, request: SampleRequest): void {
        this.sampleDetailDisplayService.showSampleDetails($event, request, 'SampleDetailV2Controller');
    }
}