import { IWindowService } from 'angular';

angular
    .module('approval-tool')
    .factory(
        'appInsightsSessionConfigurator',
        [
            'appInsightsService', '$window',
            function (appInsightsService: any, $window: IWindowService) {
                'use strict';

                const Key = 'telemetry-session';

                return {
                    configure: function (associativeArray: { [key: string]: any }): { [key: string]: any } {
                        if (!associativeArray[Key]) {
                            if ($window.sessionStorage.getItem(Key) === null) {
                                appInsightsService.generateAndSetToken(Key);
                            }
                            associativeArray[Key] = $window.sessionStorage.getItem(Key);
                        }

                        return associativeArray;
                    }
                };
            }
        ]
    );
