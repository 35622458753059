import { InfinityPackageOverrideRequestCriteria } from './models/infinityPackageOverrideRequest.criteria.model';
import { InfinityPackageOverrideRequestOptions } from './models/infinityPackageOverrideRequest.options.model';

import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';


export default class InfinityPackageOverrideRequestService {

    constructor(private $http: ng.IHttpService) { }

    getOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<InfinityPackageOverrideRequestOptions>> {
        return this.$http.get('{API-ApprovalTool}/requests/infinitypackageoverride/options');
    }

    getTerritories(salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>> {
        return this.$http.get(`{API-ApprovalTool}/requests/infinitypackageoverride/options/salesperiods/${salesPeriodId}/territory`);
    }

    getSalesAgencies(criteria: InfinityPackageOverrideRequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        const data = {
            salesPeriodId: criteria.salesSeason.salesPeriodId,
            territoryId: criteria.territory.orgId
        };

        return this.$http.post('{API-ApprovalTool}/requests/cpa/options/salesagencies', data);
    }

    getOperations(criteria: InfinityPackageOverrideRequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        const data = {
            salesPeriodId: criteria.salesSeason.salesPeriodId,
            salesAgencyId: criteria.salesAgency.id
        };

        return this.$http.post('{API-ApprovalTool}/requests/cpa/options/operations', data);
    }

    getInvoicesShares(salesPeriodId: number, operationId: number, salesAgencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/packageoverride/options/salesperiods/${salesPeriodId}/operations/${operationId}/salesAgency/${salesAgencyId}/invoices/shares`);
    }

    getPackages(salesPeriodId: number, territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/packageoverride/packagetypes/salesperiod/${salesPeriodId}/territory/${territoryId}`);
    }
    
    getInfinityInvoiceInformation(invoiceShareData: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: any = invoiceShareData;
        return this.$http.post('{API-ApprovalTool}/requests/packageoverride/packagedetails', data)
    }

    submit(criteria: InfinityPackageOverrideRequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/packageoverride/create`, criteria);
    }
}
