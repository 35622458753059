import DiscountDetailsDisplayService from './discountDetailsDisplay.service';
import { DiscountDisplayModel } from './models/discountDisplay.model';

export default class DiscountsV5ComponentController {
    shareCurrentDiscounts: any;
    customerCurrentDiscounts: any;
    previousCustomerDiscounts: any;
    year: number;
    salesAgencyName: string;
    discountDetailsDisplay: DiscountDisplayModel;

    previousYear: number;
    growthRetentionText: string;
    showDetails: boolean;
    showTotalDetails: boolean;
    showProgress: boolean;
    showReplantDetails: boolean;
    showDoubleCropDetails: boolean;
    showDoubleCropPendingDetails: boolean;
    showReplantPendingDetails: boolean;
    showCactusDetails: boolean;
    showCactusPendingDetails: boolean;

    constructor(
        private discountDetailsDisplayService: DiscountDetailsDisplayService,
        private $filter: ng.IFilterService) { }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.previousYear = this.year - 1;
        this.showDetails = false;
        this.showTotalDetails = false;
        this.showProgress = false;
        this.showReplantDetails = false;
        this.showDoubleCropDetails = false;
        this.showDoubleCropPendingDetails = false;
        this.showCactusDetails = false;
        this.showCactusPendingDetails = false;
        this.discountDetailsDisplay = new DiscountDisplayModel();
        this.showReplantPendingDetails = false;
    }

    private $onChanges(changes: any) {
        if (angular.isDefined(changes.customerPreviousDiscounts) && angular.isDefined(changes.customerPreviousDiscounts.currentValue)) {
            if (this.previousYear < 2020) {
                this.discountDetailsDisplayService.setPreviousYearData(
                    this.discountDetailsDisplay,
                    changes.customerPreviousDiscounts.currentValue);
            } else {
                this.discountDetailsDisplayService.setPreviousYearCustomerDataV3(
                    this.discountDetailsDisplay,
                    changes.customerPreviousDiscounts.currentValue);
            }
        }

        if (angular.isDefined(changes.customerCurrentDiscounts) && angular.isDefined(changes.customerCurrentDiscounts.currentValue)) {
            this.discountDetailsDisplayService.setCurrentCustomerDataV3(
                this.discountDetailsDisplay,
                changes.customerCurrentDiscounts.currentValue);
        }

        if (angular.isDefined(changes.shareCurrentDiscounts) && angular.isDefined(changes.shareCurrentDiscounts.currentValue)) {
            this.discountDetailsDisplayService.setCurrentShareDataV3(
                this.discountDetailsDisplay,
                changes.shareCurrentDiscounts.currentValue);
        }
    }

    public showDoubleCrop() {
        return this.discountDetailsDisplay.loaded() && (this.discountDetailsDisplay.doubleCrop.length > 0 || this.discountDetailsDisplay.doubleCropPending.length > 0);
    }

    public showCactus() {
        return this.discountDetailsDisplay.loaded() && (this.discountDetailsDisplay.cactus.length > 0 || this.discountDetailsDisplay.cactusPending.length > 0);
    }

    public showReplant() {
        return this.discountDetailsDisplay.loaded() && this.discountDetailsDisplay.replant.length > 0;
    }

    public toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    public toggleTotalDetails() {
        this.showTotalDetails = !this.showTotalDetails;
    }

    public toggleReplantDetails() {
        this.showReplantDetails = !this.showReplantDetails;
    }

    public toggleDoubleCropDetails() {
        this.showDoubleCropDetails = !this.showDoubleCropDetails;
    }

    public toggleDoubleCropPendingDetails() {
        this.showDoubleCropPendingDetails = !this.showDoubleCropPendingDetails;
    }

    public toggleCactusDetails() {
        this.showCactusDetails = !this.showCactusDetails;
    }

    public toggleCactusPendingDetails() {
        this.showCactusPendingDetails = !this.showCactusPendingDetails;
    }

    public toggleReplantPendingDetails() {
        this.showReplantPendingDetails = !this.showReplantPendingDetails;
    }

    public showDashes(percentage: number): boolean {
        return percentage === 0;
    }

    public formatFactor(calculationId: number, factor: number): string {
        if (calculationId === 1) {
            return (this.$filter('percentage') as Filter.IFilterPercentage)(factor);
        }

        if (calculationId === 2) {
            return this.$filter('currency')(factor, '$') + ' per unit';
        }

        return '';
    }
}