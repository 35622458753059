import { IReplantRequest } from '../replantRequest.model.interface';

export default class ReplantDetailDisplayService {

    constructor(private $mdDialog: ng.material.IDialogService) {
        // no-op
    }

    showReplantDetails($event: MouseEvent, request: IReplantRequest, controllerName: string): ng.IPromise<any> {
        return this.$mdDialog.show({
            template: require('./replantDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: controllerName,
            controllerAs: '$ctrl',
            locals: {
                requestLocal: request
            },
            fullscreen: true,
            escapeToClose: false
        });
    }
}