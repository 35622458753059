export function httpMethodInterceptor($q: ng.IQService) {
    const interceptor: ng.IHttpInterceptor = {
        request(config: ng.IRequestConfig): any {
            switch (config.method) {
                case 'OPTIONS':
                    // OPTIONS SHOULDN'T BE HANDLED - LET IT THROUGH!
                    break;
                case 'POST':
                case 'GET':
                    // POST and GET are allowed.  Everything else must be "spoofed" to get around DuPont Pioneer rules
                    break;
                default:
                    config.headers['X-HTTP-Method'] = config.method;
                    config.method = 'POST';
                    break;
            }
            return config || $q.when(config);
        }
    };
    return interceptor;
}