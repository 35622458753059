import { IRedirectService } from '../../services/redirect.service.interface';

import { IStorageService } from '../../services/storage.service.interface';
import { StorageKeyEnum } from '../../services/storageKey.enum';

import { IUserService } from '../../services/user.service.interface';
import { IUser } from '../../models/user.model.interface';

import { ILeftNavService } from './leftNav.service.interface';
import { RequestCount } from './requestCount.model';

import { IInfinityPackageOverrideNavigationService } from '../../../../app/views/requests/infinityPackageOverride/navigation/infinityPackageOverrideNavigation.service.interface'

export default class LeftNavController {
    impersonated: boolean;
    authUserName: string;
    authUserEmail: string;
    private requestCount: RequestCount = new RequestCount(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    private agencyDiscountMasterRequestCount: number;
    private loadingRequestCount: boolean = false;
    private isLeftNavOpen: any = false;
    user: IUser;
    isCommissionAdvanceRequestApprover: boolean = false;
    canViewPackageOverrideMenu: boolean = false;
    noConcurrencyEnabled: boolean = false;

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService,
        private $mdDialog: ng.material.IDialogService,
        private storageService: IStorageService,
        private userService: IUserService,
        private redirectService: IRedirectService,
        private leftNavService: ILeftNavService,
        private $scope: ng.IScope,
        private $element: ng.IRootElementService,
        private $q: ng.IQService,
        private infinityPackageOverrideNavigationService: IInfinityPackageOverrideNavigationService
    ) {
        
    }

    $onInit(): void {
        this.impersonated = false;
        this.activate();
        // workaround to determine the correct instance of controller to prevent firing watch from multiple instances of controller from different routes.
        this.isLeftNavOpen = function () {
            return this.$element[0].offsetParent !== null;
        };

        this.leftNavService.isCommissionAdvanceRequestApprover()
            .then((response) => this.isCommissionAdvanceRequestApprover = response.data);

        this.$scope.$watch(() => this.isLeftNavOpen(),
            (newValue: boolean, oldValue: boolean) => {
                if (newValue && newValue !== oldValue && this.$mdSidenav('left').isOpen()) {
                    this.getRequestCounts();
                }
            });

        $fsLDFeatureFlagService.isEnabledAsync('Sierra.NoConcurrency').then(ff => this.noConcurrencyEnabled = ff);
    }

    private activate() {
        this.user = this.userService.get();

        this.impersonated = this.storageService.exists(StorageKeyEnum.Impersonate);
        this.authUserName = this.user.name;
        this.authUserEmail = this.user.email;
    }

    canViewAgencyDiscountMaster(): boolean {
        return this.user.canViewAgencyDiscountMaster && $fsLDFeatureFlagService.isEnabled('OZ.609557.AgencyDiscountMasterSubmission');
    }

    canSeePending(): boolean {
        const isSystemUser: boolean = this.isSystemUser();
        return !isSystemUser || this.impersonated;
    }

    canSeeCustomerPlanning(): boolean {
        return this.user.canViewBusinessPartnerPlanning && $fsLDFeatureFlagService.isEnabled('Sierra.ApprovalTool.CustomerPlanning');
    }

    canViewAgencyBalanceRequests(): boolean {
        return this.user.canViewAgencyBalanceExceptions;
    }

    canViewPricingRequests(): boolean {
        return this.user.canViewPricingRequests;
    }

    canViewBalanceWriteOffRequests(): boolean {
        return this.user.canViewBalanceWriteOff;
    }

    canViewReplantRequests(): boolean {
        return this.user.canViewReplant;
    }

    canViewMonthlyDrawRequests(): boolean {
        return this.user.canViewMonthlyDraw;
    }

    canViewCommissionAdvancesRequests(): boolean {
        return this.user.canViewCommissionAdvances;
    }

    isSystemUser(): boolean {
        return this.user.isSystemUser;
    }

    navToPending(): void {
        this.redirectService.redirectToCPAPendingRequests();
    }

    navToAllPending(): void {
        this.redirectService.redirectToAllCPAPendingRequests();
    }

    navToSearch(): void {
        this.redirectService.redirectToSearch();
    }

    loadAgencyDiscountMaster(): void {
        this.$location.url('/agencydiscountrequests/');
    }

    loadCPARequests(): void {
        this.$location.url('/requestType/');
    }

    loadCommissionRequests(): void {
        this.$location.url('/requestType-Commission/');
    }

    loadEncircaCommissionRequests(): void {
        this.$location.url('/requestType-EncircaCommission/');
    }

    loadMonthlyDraw(): void {
        this.$location.url('/requestType-MonthlyDraw/');
    }

    loadReplantRequests(): void {
        this.$location.url('/requestType-Replant/');
    }

    loadAgencyBalanceRequests(): void {
        this.$location.url('/requestType-BalanceException/v2/');
    }

    loadBalanceWriteOffs(): void {
        this.$location.url('/requestType-BalanceWriteOff/');
    }

    loadPricingRequests(): void {
        this.$location.url('/requestType-Pricing/');
    }

    loadLTCRequests(): void {
        this.$location.url('/requestType-LoanTypeChange/');
    }

    loadBusinessPartnerPlanning(): void {
        this.$location.url('/businessPartnerPlanning');
    }

    loadBusinessPartnerPlanningPendingRequests(): void {
        this.$location.url('/businessPartnerPlanning/pendingRequests');
    }

    loadSampleRequests(): void {
        this.$location.url('/sample/pendingRequests');
    }

    loadProposalCpaRequests(): void {
        this.$location.url('/proposalcpa/pendingRequests');
    }

    loadInfinityPackageOverrideRequest(): void {
        this.$location.url('/infinityPackageOverride/pendingRequests');
    }

    hideNav(): void {
        this.$mdSidenav('left').close();
    }

    getPendingEncircaCommissionCount(): number {
        return 0;
    }

    getRequestCounts(): void {
        this.loadingRequestCount = true;

        if (this.noConcurrencyEnabled) {
            this.leftNavService.pendingRequestCountV2()
                .then((response) => {
                    this.requestCount = response.data;
                }).finally(() => this.loadingRequestCount = false);
        } else {
            this.leftNavService.pendingRequestCount()
                .then((response) => {
                    this.requestCount = response.data;
                }).finally(() => this.loadingRequestCount = false);
        }
        
        this.leftNavService.agencyDiscountRequestCount().then((response) => {
            this.agencyDiscountMasterRequestCount = response.data;
        })

        this.infinityPackageOverrideNavigationService.canOverrideRequest().then((response) => {
            this.canViewPackageOverrideMenu = response.data;
        });
    }

    canSeeCustomerPlanningPendingRequest(): boolean {
        return this.user.canViewBusinessPartnerPlanningPendingRequests && $fsLDFeatureFlagService.isEnabled('Sierra.ApprovalTool.BPPTerritoryApproval');
    }

    isBPPTerritoryApprovalOn(): boolean {
        return $fsLDFeatureFlagService.isEnabled('Sierra.ApprovalTool.BPPTerritoryApproval');
    }
}
