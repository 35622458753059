import './businessPartnerPlanningNavigation.scss';
import BusinessPartnerPlanningNavigationController from './businessPartnerPlanningNavigation.controller';

export default class BusinessPartnerPlanningNavigationComponent implements ng.IComponentOptions {
    template: string = require('./businessPartnerPlanningNavigation.html');
    controller: Function = BusinessPartnerPlanningNavigationController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new BusinessPartnerPlanningNavigationComponent();
        };

        return component();
    }
}