import {ISupportSearchEncircaCommissionAdvanceService} from './supportSearchEncircaCommissionAdvance.service.interface';
import {IEncircaCommissionAdvanceRequestService} from '../../encircaCommissionAdvance/encircaCommissionAdvanceRequest.service.interface';
import {ISupportSearchEncircaCommissionAdvanceController} from './supportSearchEncircaCommissionAdvance.controller.interface';
import {EncircaCommissionAdvanceRequestRow} from '../../encircaCommissionAdvance/encircaCommissionAdvanceRequestRow';
import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';

let async: any = require('async');

export default class SupportSearchEncircaCommissionAdvanceController implements ISupportSearchEncircaCommissionAdvanceController {

    maximumRowsToDisplay: number = 200;

    searching: boolean = false;
    exporting: boolean = false;
    criteria: string = '';
    requests: Array<EncircaCommissionAdvanceRequestRow>;
    totalRequests: number = -1;
    showApproveDeny: boolean = false;
    approvedList: number[];
    deniedList: number[];

    private concurrentDetailsCalls: number = 3;

    constructor(
        private $document: ng.IDocumentService,
        private supportSearchEncircaCommissionAdvanceService: ISupportSearchEncircaCommissionAdvanceService,
        private encircaCommissionAdvanceRequestService: IEncircaCommissionAdvanceRequestService
    ) {
        // no-op
    }

    $onInit(): void {
        this.approvedList = [];
        this.deniedList = [];
        this.requests = [];
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;
            this.approvedList = [];
            this.deniedList = [];

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.supportSearchEncircaCommissionAdvanceService.getSearchResults(this.criteria.replace('\\', ''))
                .then((requests) => {
                    this.processResponse(requests.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.totalRequests === 0;
    }

    approve($event: MouseEvent, row: EncircaCommissionAdvanceRequestRow, index: number): void {
        $event.stopPropagation();
        this.encircaCommissionAdvanceRequestService.approve({ requestId: row.request.requestId, agencyBalanceAmount: row.details.agencyBalanceData.agencyAccountBalance, advanceAmount: row.details.advanceAmount, netCommissionableRevenue: row.details.netCommissionableRevenue, totalAdvanceCommission: row.details.totalAdvanceCommission });

        this.approvedList.push(index);
    }

    deny($event: MouseEvent, row: EncircaCommissionAdvanceRequestRow, index: number): void {
        $event.stopPropagation();
        this.encircaCommissionAdvanceRequestService.deny({ requestId: row.request.requestId, agencyBalanceAmount: row.details.agencyBalanceData.agencyAccountBalance, advanceAmount: row.details.advanceAmount, netCommissionableRevenue: row.details.netCommissionableRevenue, totalAdvanceCommission: row.details.totalAdvanceCommission });
        this.deniedList.push(index);
    }

    private processResponse(requests: any): void {
        this.requests = requests.map((req: any) => new EncircaCommissionAdvanceRequestRow(req));
        this.loadDetailsForRequests();

        this.totalRequests = this.requests ? this.requests.length : 0;
    }

    public showApprovalButton(row: EncircaCommissionAdvanceRequestRow, index: number): boolean {
        return this.approvedList && this.approvedList.indexOf(index) < 0 && this.deniedList && this.deniedList.indexOf(index) < 0 && row.request.status === 'Pending';
    }

    private loadDetailsForRequests() {
        let queue = async.queue((row: EncircaCommissionAdvanceRequestRow, callback: Function) => {

            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private getRequestDetails(row: EncircaCommissionAdvanceRequestRow): ng.IPromise<any> {
        if (row.request.status === 'Pending') {
            return this.encircaCommissionAdvanceRequestService
                .getCommissionAdvanceDetail(row.request.requestId)
                .then(details => row.addDetails(details));
        }

        return this.encircaCommissionAdvanceRequestService
            .getCommissionAdvanceSavedDetails(row.request.requestId)
            .then(details => row.addDetails(details));
    }
}
