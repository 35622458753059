import {IUserService} from '../../../core/services/user.service.interface';
import BalanceWriteOffRequestsService from './balanceWriteOffRequests.service';
import BalanceWriteOffRequestsController from './balanceWriteOffRequests.controller';

export default class SupportBalanceWriteOffRequestsController extends BalanceWriteOffRequestsController {
    hideToolbar: boolean = true;

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected balanceWriteOffRequestsService: BalanceWriteOffRequestsService,
        protected $mdDialog: ng.material.IDialogService) {
        super($location, $mdSidenav, userService, balanceWriteOffRequestsService, $mdDialog);
    }
}