export function authorizationInterceptor($q: ng.IQService, $log: ng.ILogService, $location: ng.ILocationService) {

    const interceptor: ng.IHttpInterceptor = {

        // return to login if session has timed out
        request(config: ng.IRequestConfig) {

            if (shouldReturnToLogin(config)) {
                returnToLogin('Timeout');
            }

            return config || $q.when(config);
        },

        // return to login if not authenticated or authorized
        responseError(rejection: any) {

            if (rejection.status === 401 || rejection.status === 403) {
                if (!alreadyAtLogin()) {
                    returnToLogin(rejection.status);
                }
            }

            return $q.reject(rejection);
        }
    };

    function returnToLogin(status: string) {
        $log.warn(`Not authenticated or authorized.  Redirecting to the root URL. [${status}]`);
        $location.url('/');
    }

    function isTemplate(config: ng.IRequestConfig) {
        return config.cache != null; // != checks null + undefined
    }

    function missingAuthorizationHeader() {
        const header = sessionStorage.getItem('Authorization');
        return header == null; // == checks null + undefined
    }

    function alreadyAtLogin() {
        return $location.path() === '/' || $location.path() === '/login/' || $location.path() === '/login/timeout';
    }

    function shouldReturnToLogin(config: ng.IRequestConfig) {
        return !alreadyAtLogin() && missingAuthorizationHeader() && !isTemplate(config);
    }

    return interceptor;
}