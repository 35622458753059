import { IUser } from "../../../core/models/user.model.interface";
import { IUserService } from "../../../core/services/user.service.interface";

import { PendingPackageOverrideRequest } from "./pendingPackageOverrideRequest.model";

import { IInfinityPackageOverridePendingListService } from "./infinityPackageOverridePendingList.service.interface";

import InfinityPackageOverrideDetailController from '../infinityPackageOverride/detail/infinityPackageOverrideDetail.controller';

export default class InfinityPackageOverridePendingListController {

    requests: PendingPackageOverrideRequest[];
    loading: boolean = true;

    total: number = 0;
    visitedPages: number[] = [];
    requestGroups: Object;
    canPageForward: boolean = false;
    canPageBack: boolean = false;
    beginIndicator: number;
    endIndicator: number;
    nothingToApprove: () => boolean = this.nothingToApproveImpl;
    isPermissionStopDatePassed: boolean = true;
    user: IUser;

    query: { order: string; limit: number; page: number; begin: number, end: number } = {
        order: 'requestedDate',
        limit: 10,
        page: 1,
        begin: 0,
        end: 10
    };

    constructor(
        private $mdSidenav: ng.material.ISidenavService,
        private $mdDialog: ng.material.IDialogService,
        private infinityPackageOverridePendingListService: IInfinityPackageOverridePendingListService,
        private userService: IUserService) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.user = this.userService.get();
        this.getPending();
    }

    private getPending(): void {
        this.infinityPackageOverridePendingListService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    canApprove(request: PendingPackageOverrideRequest): boolean {
        return this.user.canApprove && request.canApprove && !request.processing && !this.isPermissionStopDatePassed;
    }

    canDeny(request: PendingPackageOverrideRequest): boolean {
        return this.user.canDeny && request.canDeny && !request.processing && !this.isPermissionStopDatePassed;
    }

    private processResponse(response: any): void {
        this.requests = response.data.pendingRequestList;
        this.total = response.data.pendingRequestList.length;
        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests.slice(this.query.begin, this.query.end);
        angular.forEach(requests, (request) => {
            request.processing = false;
        });
        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesYear + ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;

        // get single level array of IRequest from grouped by object
        const sorted: any[] = _.chain(this.requestGroups).values().flatten().value();

        if (this.visitedPages.indexOf(this.query.page) < 0) {
            this.visitedPages.push(this.query.page);
            this.verifyPermission(sorted);
        }

        this.updatePagination();
    }

    verifyPermission(requests: PendingPackageOverrideRequest[]): void {
        angular.forEach(requests, (request: PendingPackageOverrideRequest) => this.checkPermissionStopDatePassed(request));
    }

    checkPermissionStopDatePassed(request: PendingPackageOverrideRequest): void {
        request.loadingAdditionalData = true;
        this.infinityPackageOverridePendingListService.isPermissionStopDatePassed(request)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.isPermissionStopDatePassed = true;
                if (response.data.length > 0) {
                    this.isPermissionStopDatePassed = !_.any(response.data,
                        (s: any) => {
                            return s.salesYear === request.salesYear;
                        });
                }
            }).finally(() => this.setPermissions(request, this.isPermissionStopDatePassed));
    }

    private setPermissions(request: PendingPackageOverrideRequest, isPermissionStopDatePassed: boolean): void {
        request.canApprove = request.canApprove && !isPermissionStopDatePassed;
        request.canDeny = request.canDeny && !isPermissionStopDatePassed;
        request.loadingAdditionalData = false;
    }

    private setActionPermissions(request: PendingPackageOverrideRequest, canApprove: boolean, canDeny: boolean): void {
        request.canApprove = canApprove;
        request.canDeny = canDeny;
    }

    approve($event: MouseEvent, request: PendingPackageOverrideRequest): void {
        $event.stopPropagation();
        request.processing = true;

        this.infinityPackageOverridePendingListService.approve(request.requestId)
            .then(() => {
                this.setActionPermissions(request, false, false);
            })
            .finally(() => request.processing = false);
    }

    deny($event: MouseEvent, request: PendingPackageOverrideRequest): void {
        $event.stopPropagation();

        request.processing = true;

        this.infinityPackageOverridePendingListService.deny(request.requestId)
            .then(() => {
                this.setActionPermissions(request, false, false);
            })
            .finally(() => request.processing = false);
    }

    private updatePagination(): void {
        this.canPageForward = this.canPaginateForward();
        this.canPageBack = this.canPaginateBackward();
        this.setIndicators();
    }

    private canPaginateForward(): boolean {
        return this.requests.length > this.query.end;
    }

    private canPaginateBackward(): boolean {
        return this.query.page > 1;
    }

    private setIndicators(): void {
        this.beginIndicator = this.query.begin + 1;

        this.endIndicator = this.requests.length > this.query.end ? this.query.end : this.requests.length;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    paginateForward(): void {
        this.query.page++;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    paginateBackward(): void {
        this.query.page--;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    showDialog($event: MouseEvent, request: PendingPackageOverrideRequest): void {
        this.$mdDialog.show({
            template: require('../infinityPackageOverride/detail/infinityPackageOverrideDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: InfinityPackageOverrideDetailController,
            controllerAs: '$ctrl',
            locals: {
                requestLocal: request
            },
            fullscreen: true
        });
    }
}