import LoanTypeChangeRequestDetailService from './loanTypeChangeRequestDetail.service';
import {IUserService} from '../../../../core/services/user.service.interface';
import {IUser} from '../../../../core/models/user.model.interface';
import {LoanTypeChangeDetail} from './loanTypeChangeDetail.model';
import {LoanTypeChangeRequest} from '../loanTypeChangeRequest.model';
import LoanTypeChangeRequestsService from '../loanTypeChangeRequests.service';
import { LoanTypeChangeBalanceDetail } from '../request/models/loanTypeChangeBalanceDetail.model';

export default class LoanTypeChangeRequestDetailController {
    user: IUser;

    isLoading: boolean;
    detail: LoanTypeChangeDetail;
    allowComment: boolean = false;
    approvalType: string;

    constructor(private $mdDialog: ng.material.IDialogService,
        public request: LoanTypeChangeRequest,
        private loanTypeChangeRequestDetailService: LoanTypeChangeRequestDetailService,
        private loanTypeChangeRequestsService: LoanTypeChangeRequestsService,
        private userService: IUserService) {
    }

    $onInit(): void {
        this.user = this.userService.get();
        this.loadDetails();
        this.allowComment = (this.user.canApproveDenyLoanTypeChange || !this.user.canViewLoanTypeChange);
        this.approvalType = 'ltc';
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    private loadDetails(): void {
        this.isLoading = true;
        this.getDetail().finally(() => {
            this.isLoading = false;
        });
    }

    private getDetail() {

        return this.loanTypeChangeRequestDetailService.getDetail(this.request.requestId)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
               this.detail = response.data;
            });
    }

    canApprove(): boolean {
        return this.user.canApprove && !this.request.processing && this.request.canApprove;
    }

    canDeny(): boolean {
        return (this.user.canDeny || this.user.canApproveDenyLoanTypeChange) && !this.request.processing && this.request.canDeny;
    }

    approve(request: LoanTypeChangeRequest) {
        request.processing = true;
        this.loanTypeChangeRequestsService.approve(request.requestId, this.getBalance(request))
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                request.processing = false;
            });
    }

    deny(request: LoanTypeChangeRequest) {
        request.processing = true;
        this.loanTypeChangeRequestsService.deny(request.requestId, this.getBalance(request))
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                request.processing = false;
            });
    }

    showCurrentRateNotSet() {
        return this.request.currentLoanType.loanTypeDescription === 'NA';
    }

    private getBalance(request: LoanTypeChangeRequest): LoanTypeChangeBalanceDetail {
        return new LoanTypeChangeBalanceDetail(request.balances.netInvoiceAmount,
            request.balances.receipts.cropProtection,
            request.balances.receipts.encirca,
            request.balances.receipts.sellerService);
    }
}