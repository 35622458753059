import {IProposalCpaApproverSearchService} from './proposalCpaApproverSearch.service.interface';
import {IProposalCpaApproverSearchController} from './proposalCpaApproverSearch.controller.interface';

import { ProposalCpaRequest } from '../../proposal/proposalCpaRequest.model';

import {IProposalCpaApproverSearchOptions} from './models/proposalCpaApproverSearch.options.model.interface';
import {IProposalCpaApproverSearchModel} from './models/proposalCpaApproverSearch.model.interface';

import {IIdAndName} from '../../../../core/models/idAndName.model.interface';
import {IOrgIdAndName} from './models/orgIdAndName.model.interface';

import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';

import ProposalCpaDetailController from '../../proposal/detail/proposalcpaDetail.controller';


interface ISidenavService extends ng.material.ISidenavService {
    (component: string, enableWait: boolean): angular.IPromise<ng.material.ISidenavObject>;
}

export default class ProposalCpaApproverSearchController implements IProposalCpaApproverSearchController {

    maximumRowsToDisplay: number = 200;

    processing: boolean = false;
    exporting: boolean = false;
    searching: boolean = false;
    requests: ProposalCpaRequest[];
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;

    sideNavComponentId: string = 'search';

    options: IProposalCpaApproverSearchOptions;
    criteria: IProposalCpaApproverSearchModel = {
        areas: [],
        commercialUnits: [],
        competitivePriceAdjustmentStatuses: [],
        competitivePriceAdjustmentTypes: [],
        salesAgencies: [],
        salesPeriods: [],
        territories: []
    };

    selectedArea: IOrgIdAndName = undefined;
    selectedCommercialUnit: IOrgIdAndName = undefined;

    typesSearchTerm: string = '';
    areasSearchTerm: string = '';
    commercialUnitSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    territoriesSearchTerm: string = '';

    loading: {
        areas: boolean;
        commercialUnits: boolean;
        competitivePriceAdjustmentTypes: boolean;
        options: boolean;
        salesAgencies: boolean;
        territories: boolean;
    } = {
            areas: false,
            commercialUnits: false,
            competitivePriceAdjustmentTypes: false,
            options: false,
            salesAgencies: false,
            territories: false
        };

    noOptions: string = 'No results';

    label: {
        areas: string;
        commercialUnits: string;
        competitivePriceAdjustmentTypes: string;
        salesAgencies: string;
        territories: string;
    } = {
            areas: 'Area',
            commercialUnits: 'Commercial unit',
            competitivePriceAdjustmentTypes: 'Flex Fund types',
            salesAgencies: 'Sales agencies',
            territories: 'Territories'
        };

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $mdSidenav: ISidenavService,
        private $mdMedia: ng.material.IMedia,
        private $document: ng.IDocumentService,
        private $timeout: ng.ITimeoutService,
        private proposalCpaApproverSearchService: IProposalCpaApproverSearchService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.loading.options = true;

        if (this.$mdMedia('max-width: 1280px')) {
            this.openSearchNavAsync();
        }

        this.proposalCpaApproverSearchService.getSearchOptions()
            .then((response: ng.IHttpPromiseCallbackArg<IProposalCpaApproverSearchOptions>) => {
                this.processOptionsResponse(response.data);
                this.activateSalesPeriods();
            })
            .finally(() => {
                this.setHandlers();
                this.loading.options = false;
            });
    }

    canSearch(): boolean {
        return this.criteria.salesPeriods && this.criteria.salesPeriods.length > 0;
    }

    onSearch(): void {
        this.searching = true;

        (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

        this.proposalCpaApproverSearchService.getSearchResults(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.processResponse(response.data);

                if (this.$mdSidenav(this.sideNavComponentId).isOpen()) {
                    this.closeSearchNav();
                }
            })
            .catch(() => {
                this.requests = [];
                this.requestGroups = [];
            })
            .finally(() => {
                this.searching = false;
            });
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }
      
    private openSearchNavAsync(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId, true)
            .then((sideNavInstance: ng.material.ISidenavObject) => {
                return sideNavInstance.open();
            });
    }

    openSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).open();
    }

    closeSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).close();
    }

    showDialog($event: MouseEvent, request: ProposalCpaRequest): void {
        this.$mdDialog.show({
            template: require('../../proposal/detail/proposalcpaDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: ProposalCpaDetailController,
            controllerAs: '$ctrl',
            locals: {
                requestLocal: request
            },
            fullscreen: true,
            escapeToClose: false
        }).catch(() => { });
    }

    toggleSalesPeriods(period: IIdAndName): void {
        const idx: number = this.criteria.salesPeriods.map((period) => period.id).indexOf(period.id);
        if (idx > -1) {
            this.criteria.salesPeriods.splice(idx, 1);
        }
        else {
            this.criteria.salesPeriods.push(period);
        }

        this.clearTypes();
        this.getTypes();

        switch (this.options.permissions.role.toLowerCase()) {
            case 'am':
                this.clearSalesAgencies();

                this.getSalesAgencies();

                break;
            case 'dsm':
                this.clearSalesAgencies();
                this.clearTerritories();

                this.getTerritoriesSalesAgencies();

                break;
            case 'asm':
                this.clearAreas();
                this.clearSalesAgencies();
                this.clearTerritories();

                this.getAreas();

                break;
            case 'cu':
                this.clearCommercialUnits();
                this.clearAreas();
                this.clearSalesAgencies();
                this.clearTerritories();

                this.getCommercialUnitsAreas();

                break;
            case 'rvp':
                this.clearCommercialUnits();
                this.clearAreas();
                this.clearSalesAgencies();
                this.clearTerritories();

                this.getCommercialUnitsAreas();

                break;
            default:
                break;
        }
    }

    salesPeriodExists(period: IIdAndName): boolean {
        const idx: number = this.criteria.salesPeriods.map((period) => period.id).indexOf(period.id);
        return idx > -1;
    }

    toggleStatuses(status: IIdAndName): void {
        const idx: number = this.criteria.competitivePriceAdjustmentStatuses.map((status) => status.id).indexOf(status.id);
        if (idx > -1) {
            this.criteria.competitivePriceAdjustmentStatuses.splice(idx, 1);
        }
        else {
            this.criteria.competitivePriceAdjustmentStatuses.push(status);
        }
    }

    onCommercialUnitsSelect(): void {
        this.clearCommercialUnitsSearchTerm();

        if (this.selectedCommercialUnit) {
            this.criteria.commercialUnits = [];
            this.criteria.commercialUnits.push(this.selectedCommercialUnit);
        }

        this.clearAreas();
        this.clearTerritories();
        this.clearSalesAgencies();
        this.getAreas();
        this.getTerritoriesSalesAgencies();
    }

    onAreasSelect(): void {
        this.clearAreasSearchTerm();

        if (this.selectedArea) {
            this.criteria.areas = [];
            this.criteria.areas.push(this.selectedArea);
        }

        this.clearTerritories();
        this.clearSalesAgencies();
        this.getTerritoriesSalesAgencies();
    }

    onTerritoriesSelect(): void {
        this.clearTerritoriesSearchTerm();

        this.clearSalesAgencies();
        this.getSalesAgencies();
    }

    onAgenciesSelect(): void {
        this.clearSalesAgenciesSearchTerm();
    }

    onTypesSelect(): void {
        this.clearTypesSearchTerm();
    }

    canSelectCommercialUnits(): boolean {
        return !this.loading.commercialUnits && this.options.commercialUnits && this.options.commercialUnits.length > 0;
    }

    canSelectAreas(): boolean {
        return !this.loading.areas && this.options.areas && this.options.areas.length > 0;
    }

    canSelectTerritories(): boolean {
        return !this.loading.territories && this.options.territories && this.options.territories.length > 0;
    }

    canSelectSalesAgencies(): boolean {
        return !this.loading.salesAgencies && this.options.salesAgencies && this.options.salesAgencies.length > 0;
    }

    canSelectCompetitivePriceAdjustmentTypes(): boolean {
        return !this.loading.competitivePriceAdjustmentTypes && this.options.competitivePriceAdjustmentTypes && this.options.competitivePriceAdjustmentTypes.length > 0;
    }

    // Select dropdown labels

    getCommercialUnitsLabel(): string {
        return this.options.commercialUnits && this.options.commercialUnits.length > 0 ? this.label.commercialUnits : this.noOptions;
    }

    getAreasLabel(): string {
        return this.options.areas && this.options.areas.length > 0 ? this.label.areas : this.noOptions;
    }

    getSalesAgenciesLabel(): string {
        if (this.isCU() && this.criteria.areas.length === 0) {
            return 'Must select area';
        }
        else if (this.isVP() && (this.criteria.commercialUnits.length === 0 && this.criteria.areas.length === 0)) {
            return 'Must select commercial unit or area';
        }

        return this.options.salesAgencies && this.options.salesAgencies.length > 0 ? this.label.salesAgencies : this.noOptions;
    }

    getTerritoriesLabel(): string {
        if (this.isCU() && this.criteria.areas.length === 0) {
            return 'Must select area';
        }
        else if (this.isVP() && (this.criteria.commercialUnits.length === 0 && this.criteria.areas.length === 0)) {
            return 'Must select commercial unit or area';
        }

        return this.options.territories && this.options.territories.length > 0 ? this.label.territories : this.noOptions;
    }

    getCompetitivePriceAdjustmentTypesLabel(): string {
        return this.options.competitivePriceAdjustmentTypes && this.options.competitivePriceAdjustmentTypes.length > 0 ?
            this.label.competitivePriceAdjustmentTypes : this.noOptions;
    }

    private setCompetitivePriceAdjustmentTypesLabel(): string {
        this.label.competitivePriceAdjustmentTypes = '';

        if (this.loading.competitivePriceAdjustmentTypes) {
            return 'Loading Flex Fund types';
        }
        return this.options.competitivePriceAdjustmentTypes.length > 0 ? 'Flex Fund types' : this.noOptions;
    }

    // permissions checks

    private isCU(): boolean {
        return this.options.permissions && this.options.permissions.role.toLowerCase() === 'cu';
    }

    private isVP(): boolean {
        const vpRoles: string[] = ['rvp', 'gvp'];

        return this.options.permissions && vpRoles.indexOf(this.options.permissions.role.toLowerCase()) > -1;
    }

    // Select dropdown search term clearing

    private clearTypesSearchTerm(): void {
        this.typesSearchTerm = '';
    }

    private clearAreasSearchTerm(): void {
        this.areasSearchTerm = '';
    }

    private clearCommercialUnitsSearchTerm(): void {
        this.commercialUnitSearchTerm = '';
    }

    private clearSalesAgenciesSearchTerm(): void {
        this.salesAgenciesSearchTerm = '';
    }

    private clearTerritoriesSearchTerm(): void {
        this.territoriesSearchTerm = '';
    }

    private clearSalesAgencies(): void {
        this.options.salesAgencies = [];
        this.criteria.salesAgencies = [];
    }

    private clearTerritories(): void {
        this.options.territories = [];
        this.criteria.territories = [];
    }

    private clearAreas(): void {
        this.options.areas = [];
        this.criteria.areas = [];
        this.selectedArea = undefined;
    }

    private clearCommercialUnits(): void {
        this.options.commercialUnits = [];
        this.criteria.commercialUnits = [];
        this.selectedCommercialUnit = undefined;
    }

    private clearTypes(): void {
        this.options.competitivePriceAdjustmentTypes = [];
        this.criteria.competitivePriceAdjustmentTypes = [];
    }

    private getAreas(): void {
        this.loading.areas = true;

        this.proposalCpaApproverSearchService.getAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>) => { this.processAreasResponse(response.data); })
            .finally(() => { this.loading.areas = false; });
    }

    private getTypes(): void {
        this.loading.competitivePriceAdjustmentTypes = true;

        this.label.competitivePriceAdjustmentTypes = this.setCompetitivePriceAdjustmentTypesLabel();

        this.proposalCpaApproverSearchService.getTypes(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<string[]>) => { this.processTypesResponse(response.data); })
            .finally(() => {
                this.loading.competitivePriceAdjustmentTypes = false;
                this.label.competitivePriceAdjustmentTypes = this.setCompetitivePriceAdjustmentTypesLabel();
            });
    }

    private getCommercialUnitsAreas(): void {
        this.loading.commercialUnits = true;
        this.loading.areas = true;

        this.proposalCpaApproverSearchService.getCommercialUnitsAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processCommercialUnitsAreasResponse(response.data); })
            .finally(() => {
                this.loading.commercialUnits = false;
                this.loading.areas = false;
            });
    }

    private getSalesAgencies(): void {
        this.loading.salesAgencies = true;

        this.proposalCpaApproverSearchService.getSalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<IIdAndName[]>) => {
                this.processSalesAgenciesResponse(response.data);
            })
            .finally(() => {
                this.loading.salesAgencies = false;
            });
    }

    private getTerritoriesSalesAgencies(): void {
        this.loading.territories = true;
        this.loading.salesAgencies = true;

        this.proposalCpaApproverSearchService.getTerritoriesSalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processTerritoriesSalesAgenciesResponse(response.data); })
            .finally(() => {
                this.loading.territories = false;
                this.loading.salesAgencies = false;
            });
    }

    // Select dropdown key event handlers to allow use of select search bar
    private setHandlers(): void {
        const selectors: string[] = [
            'competitivePriceAdjustmentTypes',
            'areas',
            'commercialUnits',
            'agencies',
            'territories'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    // Search options data processing
    private processOptionsResponse(options: IProposalCpaApproverSearchOptions): void {
        this.options = options;

        switch (this.options.permissions.role.toLowerCase()) {
            case 'am':
                this.getSalesAgencies();
                break;
            case 'asm':
                this.getTerritoriesSalesAgencies();
                break;
            default:
                break;
        }
    }

    private activateSalesPeriods(): void {
        this.options.salesPeriods.forEach((salesPeriod: IIdAndName) => this.criteria.salesPeriods.push(salesPeriod));
    }

    private processAreasResponse(areas: IOrgIdAndName[]): void {
        this.options.areas = areas;
    }

    private processTypesResponse(types: string[]): void {
        this.options.competitivePriceAdjustmentTypes = types;
    }

    private processCommercialUnitsAreasResponse(data: { commercialUnits: IOrgIdAndName[]; areas: IOrgIdAndName[] }): void {
        this.options.commercialUnits = data.commercialUnits;
        this.options.areas = data.areas;
    }

    private processSalesAgenciesResponse(agencies: IIdAndName[]): void {
        this.options.salesAgencies = agencies;
    }

    private processTerritoriesResponse(territories: IOrgIdAndName[]): void {
        this.options.territories = territories;
    }

    private processTerritoriesSalesAgenciesResponse(data: { territories: IOrgIdAndName[]; agencies: IIdAndName[] }): void {
        this.options.salesAgencies = data.agencies;
        this.options.territories = data.territories;
    }

    // Search result data processing
    private processResponse(data: { requestList: ProposalCpaRequest[]; totalRequests: number; }): void {
        this.requests = data.requestList;
        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;

        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests;

        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesYear+ ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }
}