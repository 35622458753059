import {ISupportSearchCommissionAdvanceService} from './supportSearchCommissionAdvance.service.interface';
import {ICommissionAdvanceRequestService} from '../../commissionAdvance/commissionAdvanceRequest.service.interface';
import {ISupportSearchCommissionAdvanceSearchResult} from './supportSearchCommissionAdvanceSearchResult.interface';
import {CommissionAdvanceDetailController} from '../../commissionAdvance/commissionAdvanceDetail.controller';

import {CommissionAdvanceRequestRow} from '../../commissionAdvance/commissionAdvanceRequestRow';
import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';

let async: any = require('async');

export default class SupportSearchCommissionAdvanceController {

    maximumRowsToDisplay: number = 200;

    searching: boolean = false;
    exporting: boolean = false;
    criteria: string = '';
    requests: Array<CommissionAdvanceRequestRow>;
    totalRequests: number = 0;
    shownRequests: number = 0;
    private concurrentDetailsCalls: number = 3;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchCommissionAdvanceService: ISupportSearchCommissionAdvanceService,
        private commissionAdvanceRequestService: ICommissionAdvanceRequestService
    ) {
        // no-op
    }

    $onInit(): void {
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

            this.supportSearchCommissionAdvanceService.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    // this is a short term hack to get around the fact that status is a string
    // in IRequest and an number in CommissionAdvanceRequest.  This should be made consistent
    // in the future
    getStatusName(statusId: number): string {
        if (statusId === 3) {
            return 'Requested';
        } else if (statusId === 5) {
            return 'Approved';
        } else if (statusId === 6) {
            return 'Denied';
        } else if (statusId === 107) {
            return 'Expired';
        }

        return statusId.toString();
    }

    viewDetails($event: MouseEvent, row: CommissionAdvanceRequestRow) {

        if (row.isLoadingDetails) {
            return;
        }

        this.$mdDialog.show({
            template: require('../../commissionAdvance/commissionAdvanceDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: CommissionAdvanceDetailController,
            controllerAs: '$ctrl',
            locals: {
                request: row.request,
                details: row.details
            },
            fullscreen: true
        }).finally(() => {
            this.reloadData(row);
        });
    }

    approve($event: MouseEvent, row: CommissionAdvanceRequestRow): void {
        $event.stopPropagation();

        if (row.details.advanceAmount <= 0) {
            return;
        }

        row.request.processing = true;
        this.commissionAdvanceRequestService.approve(row).finally(() => {
            this.refreshSavedRow(row);
        });
    }

    deny($event: MouseEvent, row: CommissionAdvanceRequestRow): void {
        $event.stopPropagation();
        row.request.processing = true;
        this.commissionAdvanceRequestService.deny(row).finally(() => {
            this.refreshSavedRow(row);
        });
    }

    private reloadData(commissionAdvanceRequestRow: CommissionAdvanceRequestRow): any {
        if (!commissionAdvanceRequestRow.request.isPending) {
            this.refreshSavedRow(commissionAdvanceRequestRow);
        }
    }

    private refreshSavedRow(row: CommissionAdvanceRequestRow): void {
        this.commissionAdvanceRequestService
            .getSavedCommissionAdvanceRequest(row.request.requestId)
            .then(newRow => {
                row.request = newRow.request;
                row.details = newRow.details;
            })
            .finally(() => {
                row.request.processing = false;
            });
    }

    private getDisplayDate(): string {
        const date: Date = new Date();
        return `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
    }

    private processResponse(data: ISupportSearchCommissionAdvanceSearchResult): void {
        this.requests = data.requestList.map(req => this.createRow(req));
        this.loadDetailsForRequests();

        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }

    private loadDetailsForRequests() {
        let queue = async.queue((row: CommissionAdvanceRequestRow, callback: Function) => {

            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private getRequestDetails(row: CommissionAdvanceRequestRow): ng.IPromise<any> {
        return this.commissionAdvanceRequestService
                .getRequestDetails(row.request)
                .then(details => row.addDetails(details));
    }

    private createRow(request: any): CommissionAdvanceRequestRow {

        let row: CommissionAdvanceRequestRow = new CommissionAdvanceRequestRow(request);

        // TODO: should be handled in one place once we standardize what status means
        row.request.status = row.request.statusId;
        row.request.isApproved = request.statusId === 5;
        row.request.isDenied = request.statusId === 6;
        row.request.isPending = request.statusId === 3;

        return row;
    }
}
