import {ISupportSearchService} from './supportSearch.service.interface';
import {IApproverSearchModel} from '../approver/models/approverSearch.model.interface';

export default class SupportSearchService implements ISupportSearchService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch`, data, { timeout: 600000 });
    }

    export(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/export`, { criteria: criteria }, { timeout: 600000 });
    }
}
