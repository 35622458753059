module.exports = function () {

    const filter = function(collection, property) {
        'option strict';

        var result = collection;

        if (collection && property) {
            result = _.pluck(collection, property);
        }

        return result;
    };

    return filter;

};