import {IMonthlyDrawRequestService} from './monthlyDrawRequest.service.interface';
import {IMonthlyDrawRequest} from './monthlyDrawRequest.interface';
import {IMonthlyDrawRequestDetails} from './monthlyDrawRequestDetails.interface';
import {MonthlyDrawRequestRow} from './monthlyDrawRequestRow';
import {IMonthlyDrawRequestRow} from './monthlyDrawRequestRow.interface';
import {ICommissionRequestActivity} from '../commission/commissionRequestActivity.interface';

export default class MonthlyDrawRequestService implements IMonthlyDrawRequestService {

    static $inject: string[] = ['$http'];

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    listPendingRequests(): ng.IPromise<IMonthlyDrawRequest[]> {
        return this.$http.get<IMonthlyDrawRequest[]>('{API-ApprovalTool}/monthly-draw-requests')
            .then((response) => {
                return response.data;
            });
    }

    getMonthlyDrawDetails(salesYear: number, salesAgencyId: number, monthlyDrawDateId: number): ng.IPromise<IMonthlyDrawRequestDetails> {
        return this.$http.get<IMonthlyDrawRequestDetails>(`{API-ApprovalTool}/monthly-draw-pending-detail/salesagencies/${salesAgencyId}/salesyears/${salesYear}/monthlyDrawDates/${monthlyDrawDateId}`)
            .then((response) => {
                return response.data;
            });
    }

    approveDeny(monthlyDrawRequest: IMonthlyDrawRequestRow): ng.IPromise<any> {
        if (monthlyDrawRequest.details.overrideDrawAmount > 0) {
            monthlyDrawRequest.details.drawAmount = monthlyDrawRequest.details.overrideDrawAmount;
        }

        return this.$http.post(`{API-ApprovalTool}/monthly-draw-requests`, monthlyDrawRequest);
    }

    getSavedDetails(requestId: number): ng.IPromise<IMonthlyDrawRequestDetails> {
        return this.$http.get<IMonthlyDrawRequestDetails>(`{API-ApprovalTool}/monthly-draw-request/${requestId}/detail`).then((response) => {
            return response.data;
        });
    }

    getSavedMonthlyDrawRequest(requestId: number): ng.IPromise<IMonthlyDrawRequestRow> {
        return this.$http.get<IMonthlyDrawRequestRow>(`{API-ApprovalTool}/monthly-draw-requests/${requestId}/saved`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                const row: MonthlyDrawRequestRow = new MonthlyDrawRequestRow(response.data.request);
                row.addDetails(response.data.details);

                return row;
            });
    }

    getActivity(requestId: number): ng.IPromise<Array<ICommissionRequestActivity>> {
        return this.$http.get<Array<ICommissionRequestActivity>>(`{API-ApprovalTool}/monthly-draw-requests/requests/${requestId}/activities`)
            .then((response) => {
                return response.data;
            });
    }
}