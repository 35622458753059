import {IApproverSearchService} from './approverSearch.service.interface';
import {IApproverSearchModel} from './models/approverSearch.model.interface';

export default class ApproverSearchService implements IApproverSearchService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/approversearch`, {timeout: 600000});
    }

    getSearchResults(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch`, criteria, { timeout: 600000 });
    }

    getTypes(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/cpatypes`, criteria, { timeout: 180000 });
    }

    getSalesAgencies(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }

    export(criteria: IApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/export`, { criteria: criteria }, { timeout: 600000 });
    }
}
