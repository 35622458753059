export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/loanTypeChangeRequests/', {
            template: require('./loanTypeChangePendingRequests.html'),
            controller: 'LoanTypeChangePendingRequestsController',
            controllerAs: 'vm'
        })
        .when('/ltcRequest/', {
            template: require('./request/loanTypeChangeRequest.html'),
            controller: 'loanTypeChangeRequestController',
            controllerAs: 'vm'
        });
}