import { AgencyBalanceExceptionTypeMaterial } from './core/agencyBalanceExceptionTypeMaterial.model';
import BalanceExceptionRequestCriteria from './core/balanceExceptionRequestMaterial.criteria.model';
import BalanceExceptionRequestMaterialCriteria from './core/balanceExceptionRequestMaterial.criteria.model';
import { CreateRequestMaterialData } from './core/createRequestMaterialData.model';
import {CreateUnitSettlementChargesRequestData} from './createComponents/unitSettlementCharges/createUnitSettlementChargesRequestData.model';
import { RequestOptionsMaterial } from './core/requestOptionsMaterial.model';
import {RequestLine} from './core/requestLine.model';

import {IOrgIdAndName} from '../../../core/models/orgIdAndName.model.interface';
import {SalesAgency} from './core/salesAgency.model';
import { CreateRequestMaterialDataV2 } from './core/createRequestMaterialDataV2.model';

export default class BalanceExceptionRequestsServiceV2 {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getPendingList(): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestLine>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/pending`);
    }

    getAllPendingList(): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestLine>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/all-pending`);
    }

    getOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestOptionsMaterial>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/options`);
    }

    getCurrentTerritories(): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<IOrgIdAndName>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/territories`);
    }

    getSalesAgenciesWithInactive(salesPeriodId: number, territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<SalesAgency>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/agencies/territories/${territoryId}/salesperiods/${salesPeriodId}/v2`);
    }

    getExceptionTypes(salesPeriodId: number, territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<AgencyBalanceExceptionTypeMaterial>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/sales-period/${salesPeriodId}/territory/${territoryId}/exception-types/material`);
    }

    getAgencyExceptionTypes(salesPeriodId: number, agencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<AgencyBalanceExceptionTypeMaterial>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/sales-period/${salesPeriodId}/sales-agency/${agencyId}/exception-types/material`);
    }

    getDetails(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/${requestId}/details`);
    }

    createV2(criteria: BalanceExceptionRequestMaterialCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        if (criteria.agencyBalanceExceptionType.requestType === 4) {
            const data: CreateUnitSettlementChargesRequestData = this.createUnitSettlementChargesDataFromCriteria(criteria);

            return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/waive-unit-settlement-charges/create`, data);
        } else {
            const data: CreateRequestMaterialData = this.createDataFromCriteriaV2(criteria);

            return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/create/v3`, data);
        }
    }

    approve(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/approve`, 1);
    }

    deny(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/deny`, 1);
    }

    review(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/review`, 1);
    }

    implement(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/manually-implement`, 1);
    }

    withdraw(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/withdraw`, 1);
    }

    getMaterial(salesPeriodId: number, territoryId: string, productLineId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/agency-balance/sales-period/${salesPeriodId}/territory/${territoryId}/product-line/${productLineId}/material`);
    }

    getAllMaterial(productLineId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/agency-balance/product-line/${productLineId}/material`);
    }

    isUserOriginalRequester(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<boolean>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/user-is-requester/${requestId}`);
    }

    private createDataFromCriteria(criteria: BalanceExceptionRequestMaterialCriteria): CreateRequestMaterialData {

        const data: CreateRequestMaterialData = new CreateRequestMaterialData(-1,
            0,
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.salesSeason.salesPeriodId,
            criteria.territory.orgId,
            criteria.agencyBalanceExceptionType.typeId,
            criteria.agencyBalanceExceptionType.name,
            criteria.agencyBalanceExceptionType.requestType,
            criteria.amount,
            undefined,
            criteria.comments,
            criteria.selectedProduct,
            criteria.selectedSubproduct);

        return data;
    }

    private createDataFromCriteriaV2(criteria: BalanceExceptionRequestMaterialCriteria): CreateRequestMaterialDataV2 {

        const data: CreateRequestMaterialDataV2 = new CreateRequestMaterialDataV2(-1,
            0,
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.salesSeason.salesPeriodId,
            criteria.territory.orgId,
            criteria.agencyBalanceExceptionType.typeId,
            criteria.agencyBalanceExceptionType.name,
            criteria.agencyBalanceExceptionType.requestType,
            criteria.amount,
            undefined,
            criteria.comments,
            criteria.selectedProduct,
            criteria.selectedSubproduct,
            criteria.units === 0 ? 1 : criteria.units,
            criteria.units === 1 || criteria.units === 0 ? criteria.amount : criteria.perUnit);

        return data;
    }

    private createUnitSettlementChargesDataFromCriteria(criteria: BalanceExceptionRequestCriteria): CreateUnitSettlementChargesRequestData {
        const data: CreateUnitSettlementChargesRequestData = new CreateUnitSettlementChargesRequestData(
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.salesSeason.salesPeriodId,
            criteria.territory.orgId,
            criteria.unitSettlementChargesReason.typeId,
            criteria.unitSettlementChargesReason.name,
            criteria.agencyBalanceExceptionType.requestType,
            criteria.comments,
            criteria.unitSettlementChargesProduct.productLineId,
            criteria.unitSettlementChargesProduct.materials);

        return data;
    }
}
