import CreatePricingRequestService from './createPricingRequest.service';
import { PricingTreatmentRequestCriteriaV2 } from './models/pricingTreatmentRequestCriteriaV2.model';
import { PricingSalesPeriod } from './models/pricingSalesPeriod.model';
import { PricingRequestLoading } from './models/pricingRequestLoading.model';
import { PricingProductLine } from './models/pricingProductLine.model';
import { PricingTreatment } from './models/pricingTreatment.model';
import { PricingTreatmentPrice } from './models/pricingTreatmentPrice.model';
import { PricingCreateRequestDataV2 } from './models/pricingCreateRequestDataV2.model';
import { IPricingRequestFormController } from './models/pricingRequestForm.interface';

export default class PricingTreatmentRequestV2Controller {
    criteria: PricingTreatmentRequestCriteriaV2 = {
        salesPeriod: undefined,
        districts: undefined,
        productLine: undefined,
        treatment: undefined,
        price: undefined,
        requestPrice: undefined,
        comment: undefined
    };

    temp: PricingTreatmentRequestCriteriaV2 = {
        salesPeriod: undefined,
        districts: undefined,
        productLine: undefined,
        treatment: undefined,
        price: undefined,
        requestPrice: undefined,
        comment: undefined
    };

    loading: PricingRequestLoading = {
        salesPeriods: undefined,
        districts: undefined,
        productLines: undefined,
        products: undefined,
        prices: undefined,
        priceTypes: undefined,
        submit: undefined,
        treatments: undefined
    };

    searchText: string = null;
    salesPeriods: Array<PricingSalesPeriod>;
    districts: Array<string>;
    productLines: Array<PricingProductLine>;
    treatments: Array<PricingTreatment>;
    prices: Array<PricingTreatmentPrice>;
    requestForm: IPricingRequestFormController;
    pendingRequestTypes: Array<string> = [];

    isInvalidTreatment: boolean = false;
    maxPriceAmount: number = 99999;
    minPriceAmount: number = -99999;
    maxUnits: number = 9999999;
    minUnits: number = 1;

    constructor(
        protected createPricingRequestService: CreatePricingRequestService,
        protected $mdToast: ng.material.IToastService
    ) {
        this.$onInit(); 
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.getSalesPeriod();
        this.getProductLine();
    }

    submit(): void {

        if (!this.canSubmit()) {
            return;
        }

        this.loading.submit = true;

        // Send one price request with all districts selected. API will then make a request per district.
        this.createPricingRequestService.createPricingTreatmentRequestV2(this.createDataFromCriteria(this.criteria))
            .then(() => {
                this.loading.submit = false;
                this.$mdToast.show(
                    this.$mdToast.simple().textContent('Pricing request(s) saved')
                        .position('bottom center'));
                this.resetForm();
            });
    }

    resetForm(): void {
        this.clearSalesPeriod();
        this.clearDistricts();
        this.clearPrice();
        this.clearProductLine();
        this.clearTreatment();
        this.clearRequestPrice();
        this.clearComment();
        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
        this.resetSalesPeriod();
    }

    private getSalesPeriod() {
        this.loading.salesPeriods = true;

        return this.createPricingRequestService.getPricingSalesperiods()
            .then((response) => {
                this.processSalesPeriodResponse(response.data);
                if (response.data.length === 1) {
                    this.setInitialDropdownValues();
                    this.getDistrict();
                }
            })
            .finally(() => this.loading.salesPeriods = false);

    }

    private resetSalesPeriod() {
        if (this.salesPeriods.length === 1) {
            this.criteria.salesPeriod = this.salesPeriods[0];
            this.getDistrict();
        }
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit;
    }

    private processSalesPeriodResponse(options: Array<PricingSalesPeriod>): void {
        this.salesPeriods = options;
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (this.requestForm && formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    private setInitialDropdownValues() {
        this.setCurrentSalesPeriod();
    }

    private setCurrentSalesPeriod() {
        if (this.salesPeriods.filter(y => y.isCurrent).length === 0) {
            if (this.salesPeriods.length === 1) {
                this.criteria.salesPeriod = this.salesPeriods[0];
            }
        } else {
            this.criteria.salesPeriod = this.salesPeriods.filter(y => y.isCurrent).shift();
        }
    }

    private getProductLine() {
        this.loading.productLines = true;

        return this.createPricingRequestService.getPricingProductLine()
            .then((response) => {
                this.processProductLineResponse(response.data);
            })
            .finally(() => this.loading.productLines = false);
    }

    private processProductLineResponse(options: Array<PricingProductLine>): void {
        this.productLines = options;
    }

    private getDistrict() {
        this.loading.districts = true;

        return this.createPricingRequestService.getPricingDistricts(this.criteria.salesPeriod.salesPeriodId)
            .then((response) => {
                this.processDistrictResponse(response.data);
            })
            .finally(() => this.loading.districts = false);
    }

    private processDistrictResponse(options: Array<string>) {
        this.districts = options;
        if (this.districts.length === 1) {
            this.criteria.districts = this.districts;
        }
    }

    private getTreatment() {
        this.loading.treatments = true;

        return this.createPricingRequestService.getPricingTreatment(this.criteria.productLine.connexProductLineId)
            .then((response) => {
                this.processTreatmentResponse(response.data);
            })
            .finally(() => this.loading.treatments = false);
    }

    private processTreatmentResponse(options: Array<PricingTreatment>) {
        this.treatments = options;
    }

    private getPrice() {
        if (this.criteria.salesPeriod && this.criteria.districts && (this.criteria.treatment || this.searchText) && this.criteria.productLine) {
            this.loading.prices = true;
            this.prices = [];
            this.pendingRequestTypes = []

            this.criteria.districts.map((district) => {
                this.createPricingRequestService.getTreatmentPrice(this.criteria.salesPeriod.salesPeriodId,
                    district,
                    this.criteria.productLine.connexProductLineId,
                    this.getTreatmentId())
                    .then((response) => {
                        this.processPriceResponse(response.data.prices);
                        this.processPendingRequestResponse(response.data.pendingRequestTypes);
                    });
            });

            this.loading.prices = false
        }
    }

    private processPriceResponse(options: Array<PricingTreatmentPrice>) {
        this.prices = _.union(this.prices, options);
    }

    private processPendingRequestResponse(pendingRequestTypes: Array<string>) {
        this.pendingRequestTypes = _.union(this.pendingRequestTypes, pendingRequestTypes);
    }

    private isPendingRequestExist() {
        return (this.pendingRequestTypes.indexOf(this.getTreatmentId()) > -1);
    }

    private createDataFromCriteria(criteria: PricingTreatmentRequestCriteriaV2): PricingCreateRequestDataV2 {
        const startTypeAndPrice: PricingTreatmentPrice = this.getStartPriceAndTreatment();
        const data: PricingCreateRequestDataV2 = new PricingCreateRequestDataV2(-1,
            criteria.salesPeriod.salesPeriodId,
            criteria.districts,
            criteria.productLine.connexProductLineId,
            this.getTreatmentId(),
            (criteria.treatment === null) ? '' : criteria.treatment.Name,
            '',
            '',
            criteria.requestPrice.pricePerUnit,
            criteria.requestPrice.numberOfUnits,
            criteria.requestPrice.estimatedValue,
            '',
            '',
            startTypeAndPrice.price,
            criteria.comment,
            true);

        return data;
    }

    private treatmentQuerySearch(query: string) {
        return query && this.treatments ? this.treatments.filter(treatment => treatment.TreatmentId.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.treatments;
    }

    private getTreatmentId() {
        return (this.criteria.treatment === null) ? this.searchText : this.criteria.treatment.TreatmentId;
    }

    onSalesYearSelect(): void {
        if (this.hasChanged(this.temp.salesPeriod, this.criteria.salesPeriod)) {
            this.clearDistricts();
            this.getDistrict();
        }
    }

    onDistrictSelect(): void {
        if (this.hasChanged(this.temp.districts, this.criteria.districts)) {
            this.getPrice();
        }
    }

    onProductLineSelect(): void {
        if (this.hasChanged(this.temp.productLine, this.criteria.productLine)) {
            this.clearTreatment();
            this.clearPrice();
            this.clearRequestPrice();
            this.getTreatment();
        }
    }

    private onTreatmentSelect(item: any, form: any) {
        if (item === undefined) {
            return;
        }

        this.treatmentSearchTextChange(this.searchText, form);
        this.clearRequestPrice();
        this.getPrice();
    }

    validatePendingRequest(): void {
        if (this.isPendingRequestExist()) {
            this.requestForm.treatmentAutocomplete.$setValidity('nopendingrequest', false);
        } else {
            this.requestForm.treatmentAutocomplete.$setValidity('nopendingrequest', true);
        }
    }

    calculateEstimatedPrice(): void {
        if (!this.criteria.treatment && !this.searchText) {
            return;
        }

        this.setPriceAndUnitValidity();

        let startPrice: PricingTreatmentPrice = this.getStartPriceAndTreatment();

        this.criteria.requestPrice.estimatedValue = (this.criteria.requestPrice.pricePerUnit - startPrice.price) *
            this.criteria.requestPrice.numberOfUnits;
    }

    setPriceAndUnitValidity(): void {
        this.setPriceValidity();
        this.setUnitValidity();
    }

    setPriceValidity(): void {
        if (this.criteria.requestPrice.pricePerUnit > this.maxPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('maxprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('maxprice', true);
        }

        if (this.criteria.requestPrice.pricePerUnit < this.minPriceAmount) {
            this.requestForm.pricePerUnit.$setValidity('minprice', false);
        } else {
            this.requestForm.pricePerUnit.$setValidity('minprice', true);
        }
    }

    setUnitValidity(): void {
        if (this.criteria.requestPrice.numberOfUnits > this.maxUnits) {
            this.requestForm.numberOfUnits.$setValidity('maxunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('maxunits', true);
        }

        if (this.criteria.requestPrice.numberOfUnits < this.minUnits) {
            this.requestForm.numberOfUnits.$setValidity('minunits', false);
        } else {
            this.requestForm.numberOfUnits.$setValidity('minunits', true);
        }
    }

    private getStartPriceAndTreatment(): PricingTreatmentPrice {

        if (!this.prices) {
            return new PricingTreatmentPrice('', '', 0);
        }
        let selectedTypeExistingPrice: PricingTreatmentPrice = this.prices.filter(p => p.treatmentId === this.getTreatmentId())[0];

        if (_.isUndefined(selectedTypeExistingPrice)) {
            return new PricingTreatmentPrice('', '', 0);
        }

        return selectedTypeExistingPrice;
    }

    private clearSalesPeriod(): void {
        this.resetElement(this.requestForm.salesYear);
        this.criteria.salesPeriod = undefined;
    }

    private clearRequestPrice(): void {
        this.resetElement(this.requestForm.pricePerUnit);
        this.resetElement(this.requestForm.numberOfUnits);
        if (this.criteria.requestPrice) {
            this.criteria.requestPrice = undefined;
        }
    }

    private clearDistricts(): void {
        this.resetElement(this.requestForm.district);
        this.districts = [];
        this.criteria.districts = undefined;
        this.temp.districts = undefined;
    }

    private clearTreatment(): void {
        this.resetElement(this.requestForm.treatmentAutocomplete);
        this.treatments = [];
        this.criteria.treatment = undefined;
        this.temp.treatment = undefined;
        this.isInvalidTreatment = false;
    }

    private clearProductLine(): void {
        this.resetElement(this.requestForm.productLine);
        this.criteria.treatment = undefined;
        this.temp.treatment = undefined;
    }

    private clearPrice(): void {
        this.prices = undefined;
    }

    private clearComment(): void {
        this.criteria.comment = '';
        this.resetElement(this.requestForm.comment);
        this.criteria.comment = undefined;
    }

    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    treatmentSearchTextChange(searchText: string, form: any) {
        this.validateTreatment(searchText, form);
        this.isInvalidTreatment = (searchText === '') ? false : this.invalidTreatment(searchText);
    }

    validateTreatment(query: string, form: any) {
        const isInvalid: boolean = (query === '');
        form.treatmentAutocomplete.$error.unknownTreatmentError = isInvalid;
        form.treatmentAutocomplete.$setValidity('unknownTreatmentError', !isInvalid);
    }

    invalidTreatment(query: string) {
        const x: PricingTreatment[] = query ? this.treatments.filter(treatment => treatment.TreatmentId.toLowerCase() === query.toLowerCase()) : this.treatments;
        return x.length <= 0;
    }

    // Field enable disable
    canSelectDistricts(): boolean {
        return !_.isEmpty(this.districts) && !_.isUndefined(this.criteria.salesPeriod);
    }

    canSelectTreatments(): boolean {
        return (!_.isUndefined(this.criteria.productLine));
    }

    canShowNotPriced(): boolean {
        return _.isEmpty(this.prices) && !_.isUndefined(this.prices);
    }
}