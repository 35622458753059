import { ISupportSearchPlanningService } from '../supportPlanning/supportSearchPlanning.service.interface';

export default class SupportSearchPlanningController {

    vm = this;
    searchText: string;
    territories: any;
    isLoading: boolean = false; 

    newState;

    constructor(
        private $location: ng.ILocationService,
        private supportSearchPlanningService: ISupportSearchPlanningService,
    ) {
        
    }

    $onInit(): void {
        this.load();
    }

    public querySearch(query) {
        return query ? this.territories.filter(this.createFilterFor(query)) : this.territories;
    }

    load() {
    this.isLoading = true;
        this.supportSearchPlanningService.getTerritories()
            .then((response) => {
                this.territories = response.data;

            }).catch(() => {
                this.territories = [];
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();

        return function filterFn(state) {
            return (state.name.toLowerCase().indexOf(lowercaseQuery) !== -1);
        };

    }

    selectedItemChange(territoryId) {
        this.$location.url('/businessPartnerPlanning/agencyPlanning/' + territoryId.toUpperCase());
    }
}