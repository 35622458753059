import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';
import { ICpaRequest } from '../../cpa/cpaRequest.model.interface';
import CpaDetailDisplayService from '../../cpa/detail/cpaDetailDisplay.service';
import { ISupportSearchController } from './supportSearch.controller.interface';
import { ISupportSearchService } from './supportSearch.service.interface';

export default class SupportSearchController implements ISupportSearchController {

    maximumRowsToDisplay: number = 1000;

    searching: boolean = false;
    exporting: boolean = false;
    criteria: string = '';
    requests: ICpaRequest[];
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;

    constructor(
        private $document: ng.IDocumentService,
        private supportSearchService: ISupportSearchService,
        private cpaDetailDisplayService: CpaDetailDisplayService) {
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.supportSearchService.getSearchResults(this.getFriendlyCriteria())
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                    this.requestGroups = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    export(): void {
        this.exporting = true;

        this.supportSearchService.export(this.getFriendlyCriteria())
            .then((response: ng.IHttpPromiseCallbackArg<string>) => { this.downloadResults(response.data); })
            .finally(() => { this.exporting = false; });
    }

    private getFriendlyCriteria(): string {
        return this.criteria.replace('\\', '').replace('"', '');
    }

    private downloadResults(data: string) {
        const dataType: string = 'data:text/csv;charset=utf-8,';

        const displayDate: string = this.getDisplayDate();
        const filename: string = `Flex Funds Export ${displayDate}.csv`;

        let dataBlob: Blob = new Blob([data], { type: dataType });
        let dataUrl: string = URL.createObjectURL(dataBlob);
        let a: HTMLElement = document.createElement('a');

        a.setAttribute('href', dataUrl);
        a.setAttribute('download', filename);

        a.click();
    }

    private getDisplayDate(): string {
        const date: Date = new Date();
        return `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
    }

    showDialog($event: MouseEvent, request: ICpaRequest): void {
        this.cpaDetailDisplayService.showCpaDetails($event, request, 'SupportSearchCpaDetailV2Controller')
            .then(() => {})
            .catch(() => {})
            .finally(() => {});
    }

    private processResponse(data: any): void {

        this.requests = data.requestList;
        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;

        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests;

        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesPeriod.period + ' - ' +
                request.organizationalUnitId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.salesPeriod.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;
    }
}
