import { IRequestOptions } from './models/request.options.model.interface';
import { IRequestLoading } from './models/request.loading.model.interface';
import { RequestCriteria } from './models/request.criteria.model';

import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';
import { Invoice } from '../../core/models/invoice.model';
import { Share } from '../../core/models/share.model';

import { IRate } from './models/programRateReason/rate.model.interface';
import { IReason } from './models/programRateReason/reason.model.interface';
import { IProgramsRatesReasonsResponse } from './models/programRateReason/programsRatesReasonsResponse.model.interface';
import { Discount } from './models/discount/discount.model';

import RequestService from './request.service';

import { IRequestFormController } from './models/requestForm.interface';

export default class RequestController {
    navigationText: string = 'Request Flex Funds';

    loading: IRequestLoading = {
        options: false,
        salesSeasons: false,
        agencies: false,
        territories: false,
        operations: false,
        accountDescriptions: false,
        businessPartners: false,
        programs: false,
        rates: false,
        reasons: false,
        discount: false,
        submit: false
    };

    options: IRequestOptions = {
        salesSeasons: [],
        territories: [],
        agencies: [],
        operations: [],
        accountDescriptions: [],
        businessPartners: [],
        programs: [],
        rates: [],
        reasons: []
    };

    criteria: RequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        program: undefined,
        rate: undefined,
        reason: undefined,
        comment: undefined,
        discount: {
            score: {
                invoiceId: undefined,
                shareId: undefined,
                description: undefined,
                operationPriorYearGrossInvoiceValue: undefined,
                operationGrossInvoiceValue: undefined,
                operationProfiledAcres: undefined,
                operationInvoiceAcres: undefined,
                shareGrossInvoiceValue: undefined,
                shareElectiveDiscountValue: undefined
            },
            discountValue: undefined,
            invoiceValue: undefined
        }
    };

    temp: RequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        program: undefined,
        rate: undefined,
        reason: undefined,
        comment: undefined,
        discount: {
            score: {
                invoiceId: undefined,
                shareId: undefined,
                description: undefined,
                operationPriorYearGrossInvoiceValue: undefined,
                operationGrossInvoiceValue: undefined,
                operationProfiledAcres: undefined,
                operationInvoiceAcres: undefined,
                shareGrossInvoiceValue: undefined,
                shareElectiveDiscountValue: undefined,
            },
            discountValue: undefined,
            invoiceValue: undefined
        }
    };

    label: {
        noOptions: string;
        agencies: string;
        territories: string;
        operations: string;
        accountDescriptions: string,
        businessPartners: string,
        programs: string;
    } = {
            noOptions: 'No results',
            agencies: 'Sales agency',
            territories: 'Territory',
            operations: 'Operation',
            accountDescriptions: 'Account description',
            businessPartners: 'Business partner',
            programs: 'Program'
        };

    // Search terms
    territoriesSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    operationsSearchTerm: string = '';
    programsSearchTerm: string = '';

    // Rates
    canSeeRatePlaceholder: boolean = true;
    canSeePercentageRates: boolean = false;
    canSeePerUnitRates: boolean = false;
    canSeeFlatRates: boolean = false;

    requestForm: IRequestFormController;
    flatRatePattern: RegExp = /^([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $document: ng.IDocumentService,
        private $q: ng.IQService,
        private requestService: RequestService,
        private $mdSidenav: ng.material.ISidenavService,
        protected $mdToast: ng.material.IToastService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.setHandlers();

        this.loading.options = true;
        this.requestService.getOptions()
            .then((response) => {
                this.processOptionsResponse(response.data);
                this.setInitialDropdownValues();
            })
            .finally(() => this.loading.options = false);
    }


    private setHandlers(): void {
        const selectors: string[] = [
            'territories',
            'agencies',
            'operations'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }



    /**
     * Sets respective temp criteria object property for comparison on menu close
     * or to undefined if criteria property not found/defined
     * @param property
     */
    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    // Select callbacks
    onSalesSeasonsSelect(): void {
        if (this.hasChanged(this.temp.salesSeason, this.criteria.salesSeason)) {
            this.clearTerritories();
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.clearPrograms();
            this.clearRates();
            this.clearReasons();

            this.getTerritories();
        }
    }

    showBudgetInfo(): boolean {
        if (this.criteria.salesSeason) {
            return this.criteria.salesSeason.year > 2019;
        }

        return false;
    }

    onTerritoriesSelect(): void {
        this.territoriesSearchTerm = '';

        if (this.hasChanged(this.temp.territory, this.criteria.territory)) {
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.clearPrograms();
            this.clearRates();
            this.clearReasons();

            this.getSalesAgencies();
        }
    }

    onSalesAgenciesSelect(): void {
        this.salesAgenciesSearchTerm = '';

        if (this.hasChanged(this.temp.salesAgency, this.criteria.salesAgency)) {
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.clearPrograms();
            this.clearRates();
            this.clearReasons();

            this.getOperations();
        }
    }

    onOperationsSelect(): void {
        this.operationsSearchTerm = '';

        if (this.hasChanged(this.temp.operation, this.criteria.operation)) {
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.clearPrograms();
            this.clearRates();
            this.clearReasons();

            this.getInvoicesShares();
        }
    }

    onAccountDescriptionsSelect(): void {
        if (this.hasChanged(this.temp.accountDescription, this.criteria.accountDescription)) {
            this.criteria.businessPartner = undefined;

            this.clearPrograms();
            this.clearRates();
            this.clearReasons();

            this.setBusinessPartners(this.getFilteredBusinessPartners());
        }
    }

    onBusinessPartnersSelect(): void {
        if (this.hasChanged(this.temp.businessPartner, this.criteria.businessPartner)) {
            this.clearPrograms();
            this.clearRates();
            this.clearReasons();
            this.getProgramsRatesReasons();
        }
    }

    onProgramsSelect(): void {
        if (this.hasChanged(this.temp.program, this.criteria.program)) {
            this.canSeeRatePlaceholder = true;
            this.canSeePercentageRates = false;
            this.canSeePerUnitRates = false;
            this.canSeeFlatRates = false;

            this.criteria.rate = undefined;
            this.temp.rate = undefined;
            this.criteria.reason = undefined;
            this.temp.reason = undefined;

            if (this.criteria.program) {
                this.setRatesVisibility();
                this.setRates(this.getFilteredRates());
            }
        }
    }

    onRatesSelect(): void {
        if (this.hasChanged(this.temp.rate, this.criteria.rate)) {
            this.criteria.reason = undefined;
            this.temp.reason = undefined;
            this.clearDiscountInformation();

            this.setReasons(this.getFilteredReasons());
        }
    }

    onRatesBlur(): void {
        this.clearDiscountInformation();

        if (this.criteria.rate && this.isValid(this.requestForm.rate) && this.criteria.reason) {
            this.getDiscountInformation();
        }
        else if (this.criteria.rate) {
            this.setReasons(this.getFilteredReasons());
        }
    }

    onReasonsSelect(): void {
        if (this.hasChanged(this.temp.reason, this.criteria.reason) && this.isValid(this.requestForm.rate)) {
            this.clearDiscountInformation();
            this.getDiscountInformation();
        }
    }

    private setRatesVisibility(): void {
        this.canSeeRatePlaceholder = false;

        if (this.criteria.program) {
            switch (this.criteria.program.calculationId) {
                case 1:
                    this.canSeePercentageRates = true;
                    break;
                case 2:
                    this.canSeePerUnitRates = true;
                    break;
                case 3:
                    this.canSeeFlatRates = true;
                    break;
                default:
                    break;
            }
        }
    }

    // Getters
    private getTerritories(): ng.IPromise<any> {
        this.loading.territories = true;

        return this.requestService.getTerritories()
            .then((response) => {
                this.processTerritoriesResponse(response.data);
                this.setTerritory();
            })
            .finally(() => this.loading.territories = false);
    }

    private getSalesAgencies(): ng.IPromise<any> {
        this.loading.agencies = true;

        return this.requestService.getSalesAgencies(this.criteria)
            .then((response) => {
                this.processSalesAgenciesResponse(response.data);
                this.setSalesAgency();
            })
            .finally(() => this.loading.agencies = false);
    }

    private getOperations(): void {
        this.loading.operations = true;

        this.requestService.getOperations(this.criteria)
            .then((response) => {
                this.processOperationsResponse(response.data);
                this.setOperation();
            })
            .finally(() => this.loading.operations = false);
    }

    private getInvoicesShares(): ng.IPromise<any> {
        this.loading.accountDescriptions = true;
        this.loading.businessPartners = true;

        return this.requestService
            .getInvoicesSharesV2(this.criteria.salesSeason.salesPeriodId, this.criteria.operation.id, this.criteria.salesAgency.id)
            .then((response) => {
                this.processInvoicesSharesResponse(response.data);
                this.setAccountDescriptions();
                this.setBusinessPartners(this.getFilteredBusinessPartners());
            })
            .finally(() => {
                this.loading.accountDescriptions = false;
                this.loading.businessPartners = false;
            });        
    }

    private getProgramsRatesReasons(): ng.IPromise<any> {
        this.loading.programs = true;
        this.loading.rates = true;
        this.loading.reasons = true;

        return this.requestService.getProgramsRatesReasons(this.criteria.salesSeason.salesPeriodId, this.criteria.operation.id, this.criteria.businessPartner.shareId)
            .then((response) => {
                this.processProgramsRatesReasonsResponse(response.data);
            })
            .finally(() => {
                this.loading.programs = false;
                this.loading.rates = false;
                this.loading.reasons = false;
            });
    }

    private getDiscountInformation(): ng.IPromise<any> {
        this.loading.discount = true;

        return this.requestService.getDiscountInformationV2(
            this.criteria.accountDescription.invoiceId,
            this.criteria.businessPartner.shareId,
            this.criteria.rate,
            this.criteria.program.competitivePriceAdjustmentId)
            .then((response) => {
                this.processDiscountResponse(response.data);
            })
            .finally(() => {
                this.loading.discount = false;
            });
    }

    // Select options and criteria reset
    private clearTerritories(): void {
        this.resetElement(this.requestForm.territory);

        this.options.territories = [];
        this.criteria.territory = undefined;
        this.temp.territory = undefined;
    }

    private clearSalesAgencies(): void {
        this.resetElement(this.requestForm.salesAgency);

        this.options.agencies = [];
        this.criteria.salesAgency = undefined;
        this.temp.salesAgency = undefined;
    }

    private clearOperations(): void {
        this.resetElement(this.requestForm.operation);

        this.options.operations = [];
        this.criteria.operation = undefined;
        this.temp.operation = undefined;
    }

    private clearAccountDescriptions(): void {
        this.resetElement(this.requestForm.accountDescription);

        this.options.accountDescriptions = [];
        this.criteria.accountDescription = undefined;
        this.temp.accountDescription = undefined;
    }

    private clearBusinessPartners(): void {
        this.resetElement(this.requestForm.businessPartner);

        this.options.businessPartners = [];
        this.criteria.businessPartner = undefined;
        this.temp.businessPartner = undefined;
    }

    private clearPrograms(): void {
        this.resetElement(this.requestForm.program);

        this.options.programs = [];
        this.criteria.program = undefined;
        this.temp.program = undefined;
    }

    private clearRates(): void {
        this.resetElement(this.requestForm.rate);

        this.options.rates = [];
        this.criteria.rate = undefined;
        this.temp.rate = undefined;

        this.canSeePercentageRates = false;
        this.canSeePerUnitRates = false;
        this.canSeeFlatRates = false;
    }

    private clearReasons(): void {
        this.resetElement(this.requestForm.reason);

        this.options.reasons = [];
        this.criteria.reason = undefined;
        this.temp.reason = undefined;
    }

    private clearComment(): void {
        this.resetElement(this.requestForm.comment);

        this.criteria.comment = undefined;
    }

    private clearDiscountInformation(): void {
        this.criteria.discount.score = undefined;
        this.criteria.discount.discountValue = undefined;
    }

    // Labels
    getTerritoriesLabel(): string {
        if (this.loading.territories) {
            return 'Loading territories...';
        }
        else if (!this.criteria.salesSeason) {
            return this.label.territories;
        }
        else if (_.isEmpty(this.options.territories)) {
            return this.label.noOptions;
        }
        else {
            return this.label.territories;
        }
    }

    getSalesAgenciesLabel(): string {
        if (this.loading.agencies) {
            return 'Loading sales agencies...';
        }
        else if (!this.criteria.territory) {
            return this.label.agencies;
        }
        else if (_.isEmpty(this.options.agencies)) {
            return this.label.noOptions;
        }
        else {
            return this.label.agencies;
        }
    }

    getOperationsLabel(): string {
        if (this.loading.operations) {
            return 'Loading operations...';
        }
        else if (!this.criteria.salesAgency) {
            return this.label.operations;
        }
        else if (_.isEmpty(this.options.operations)) {
            return this.label.noOptions;
        }
        else {
            return this.label.operations;
        }
    }

    getAccountDescriptionsLabel(): string {
        if (this.loading.accountDescriptions) {
            return 'Loading account descriptions...';
        }
        else if (!this.criteria.operation) {
            return this.label.accountDescriptions;
        }
        else if (_.isEmpty(this.options.accountDescriptions)) {
            return this.label.noOptions;
        }
        else {
            return this.label.accountDescriptions;
        }
    }

    getBusinessPartnersLabel(): string {
        if (this.loading.businessPartners) {
            return 'Loading business partners...';
        }
        else if (!this.criteria.accountDescription) {
            return this.label.businessPartners;
        }
        else if (_.isEmpty(this.options.businessPartners)) {
            return this.label.noOptions;
        }
        else {
            return this.label.businessPartners;
        }
    }

    getProgramsLabel(): string {
        if (this.loading.programs) {
            return 'Loading programs...';
        }
        else if (!this.criteria.businessPartner) {
            return this.label.programs;
        }
        else if (_.isEmpty(this.options.programs)) {
            return this.label.noOptions;
        }
        else {
            return this.label.programs;
        }
    }

    // Field enable disable
    canSelectTerritories(): boolean {
        return !_.isEmpty(this.options.territories) && !_.isUndefined(this.criteria.salesSeason);
    }

    canSelectSalesAgencies(): boolean {
        return !_.isEmpty(this.options.agencies) && !_.isUndefined(this.criteria.territory);
    }

    canSelectOperations(): boolean {
        return !_.isEmpty(this.options.operations) && !_.isUndefined(this.criteria.salesAgency);
    }

    canSelectAccountDescriptions(): boolean {
        return !_.isEmpty(this.options.accountDescriptions) && !_.isUndefined(this.criteria.operation);
    }

    canSelectBusinessPartners(): boolean {
        return !_.isEmpty(this.options.businessPartners) && !_.isUndefined(this.criteria.accountDescription);
    }

    canSelectPrograms(): boolean {
        return !_.isEmpty(this.options.programs) && !_.isUndefined(this.criteria.businessPartner);
    }

    canSelectReasons(): boolean {
        return !_.isUndefined(this.criteria.rate) && this.isValid(this.requestForm.rate);
    }

    canComment(): boolean {
        return !_.isUndefined(this.criteria.reason);
    }

    // Form
    showHint(formElement: ng.INgModelController): boolean {
        return formElement.$pristine || formElement.$valid;
    }

    private resetForm(): void {
        this.criteria.territory = undefined;

        this.clearSalesAgencies();

        this.clearOperations();
        this.clearAccountDescriptions();
        this.clearBusinessPartners();

        this.clearPrograms();
        this.clearRates();
        this.clearReasons();

        this.clearComment();
        this.clearDiscountInformation();

        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (this.requestForm && formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    private isValid(formElement: ng.INgModelController): boolean {
        return formElement && formElement.$valid;
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit && this.criteria.discount != null;
    }

    submit(): void {
        this.loading.submit = true;

        if (this.criteria.discount.score === undefined) {

            this.requestService.getDiscountInformationV2(
                    this.criteria.accountDescription.invoiceId,
                    this.criteria.businessPartner.shareId,
                    this.criteria.rate,
                    this.criteria.program.competitivePriceAdjustmentId)
                .then((response) => {
                    this.processDiscountResponse(response.data);
                    this.requestService.submit(this.criteria)
                        .then(() => {
                            this.loading.submit = false;
                            this.resetForm();
                            this.$mdToast.show(
                                this.$mdToast.simple().textContent('Request created.')
                                    .position('bottom center'));
                        });
                });
        } else {
            this.requestService.submit(this.criteria)
                .then(response => {
                    this.loading.submit = false;
                    this.resetForm();
                    this.$mdToast.show(
                        this.$mdToast.simple().textContent('Request created.')
                            .position('bottom center'));
                });
        }
    }

    isProcessing(): boolean {
        return this.loading.options || this.loading.submit;
    }

    // API data processing
    private processOptionsResponse(options: IRequestOptions): void {
        this.options = options;
    }

    private processTerritoriesResponse(territories: IOrgIdAndName[]): void {
        this.options.territories = territories;
    }

    private processSalesAgenciesResponse(agencies: IIdAndName[]): void {
        this.options.agencies = agencies;
    }

    private processOperationsResponse(operations: IIdAndName[]): void {
        this.options.operations = operations;
    }

    private processInvoicesSharesResponse(invoicesShares: { invoices: Invoice[]; shares: Share[] }) {
        this.options.accountDescriptions = invoicesShares.invoices;
        this.options.businessPartners = invoicesShares.shares;
    }

    private processProgramsRatesReasonsResponse(data: IProgramsRatesReasonsResponse): void {
        this.options.programs = data.competitivePriceAdjustments;
        this.options.rates = data.competitivePriceAdjustmentSchedules;
        this.options.reasons = data.competitivePriceAdjustmentReasons;
    }

    private processDiscountResponse(data: Discount): void {
        this.criteria.discount = data;
    }

    // Set dropdown values
    private setInitialDropdownValues() {
        this.setCurrentSalesPeriod();

        this.$timeout(() => {
            this.setTerritory();
            this.setSalesAgency();
        });
    }

    private setCurrentSalesPeriod() {
        this.criteria.salesSeason = this.options.salesSeasons.filter(salesSeason => salesSeason.isCurrent).shift();
    }

    private setTerritory(): void {
        if (this.isSingular(this.options.territories)) {
            this.criteria.territory = this.options.territories[0];
            this.getSalesAgencies();
        }
    }

    private setSalesAgency(): void {
        if (this.isSingular(this.options.agencies)) {
            this.criteria.salesAgency = this.options.agencies[0];
            this.getOperations();
        }
    }

    private setOperation(): void {
        if (this.isSingular(this.options.operations)) {
            this.criteria.operation = this.options.operations[0];
            this.getInvoicesShares();
        }
    }

    private setAccountDescriptions(): void {
        if (this.isSingular(this.options.accountDescriptions)) {
            this.criteria.accountDescription = this.options.accountDescriptions[0];
        }
    }

    private setBusinessPartners(filteredBusinessPartners: Share[]): void {
        if (this.isSingular(filteredBusinessPartners)) {
            this.criteria.businessPartner = filteredBusinessPartners[0];
            this.getProgramsRatesReasons();
        }
    }

    private setRates(filteredRates: IRate[]): void {
        if (this.isSingular(filteredRates)) {
            this.criteria.rate = filteredRates[0].factor;
            this.onRatesSelect();
        }
    }

    private setReasons(filteredReasons: IReason[]): void {
        if (this.isSingular(filteredReasons)) {
            this.criteria.reason = filteredReasons[0];

            this.$timeout(() => {
                this.onReasonsSelect();
            });
        }
    }

    // get filtered results
    private getFilteredBusinessPartners(): Share[] {
        if (!_.isUndefined(this.criteria.accountDescription)) {
            return this.options.businessPartners.filter((businessPartner) => {
                return businessPartner.invoiceId === this.criteria.accountDescription.invoiceId;
            });
        }

        return this.options.businessPartners;
    }

    getFilteredRates(): IRate[] {
        if (this.criteria.program) {
            return this.options.rates.filter((rate) => {
                return rate.competitivePriceAdjustmentId === this.criteria.program.competitivePriceAdjustmentId;
            });
        }

        return [];
    }

    getFilteredReasons(): IReason[] {
        if (this.criteria.program) {
            return this.options.reasons.filter((reason) => {
                return reason.competitivePriceAdjustmentId === this.criteria.program.competitivePriceAdjustmentId;
            });
        }

        return [];
    }

    // Helper methods

    /**
     * Checks if collection has only one (1) item
     * @param values - collection of options objects
     * @returns {Boolean}
     */
    private isSingular(values: any[]): boolean {
        return _.isArray(values) && _.size(values) === 1;
    }

    /**
     * Compares criteria objects to determine if new value has been selected
     * @param current
     * @param selected
     * @returns {Boolean} - true if respective criteria object property has changed
     */
    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    noFilteredResults(length: number, canSelect: boolean): boolean {
        return canSelect && length === 0;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }
}