export enum StorageKeyEnum {
    Impersonate = 'Impersonate' as any,
    SessionActiveUntil = 'SessionActiveUntil' as any,
    Authorization = 'Authorization' as any,
    AuthInfo = 'AuthInfo' as any,
    ImpAuthInfo = 'ImpAuthInfo' as any,
    MsalIdTokenClaims = 'idTokenClaims' as any,
    MsalAccessToken = 'accessToken' as any,
    MsalTokens = 'MsalTokens' as any,
    UPN = 'upn' as any
}

const storageKeyEnum = Object.freeze({
    Impersonate : 'Impersonate',
    SessionActiveUntil : 'SessionActiveUntil',
    Authorization : 'Authorization',
    AuthInfo : 'AuthInfo',
    ImpAuthInfo : 'ImpAuthInfo',
    MsalIdToken : 'idToken',
    MsalAccessToken : 'accessToken',
    MsalTokens : 'MsalTokens',
    UPN : 'upn'
});

export { storageKeyEnum }