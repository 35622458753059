import {RequestLine} from './core/requestLine.model';
import {RequestDetails} from './core/requestDetails.model';
import {RequestStatus} from './core/requestStatus.model';
import BalanceExceptionRequestsServiceV2 from './balanceExceptionRequests.service';
import { IUserService } from '../../../core/services/user.service.interface';
import Usermodelinterface = require('../../../core/models/user.model.interface');
import IUser = Usermodelinterface.IUser;

export default class BalanceExceptionRequestDetailsControllerV2 {

    details: RequestDetails;
    isLoading: boolean;
    totalUnits: number;
    allowComment: boolean = false;
    userIsOriginalRequester: boolean = false;

    private pendingDecisions: Array<string> = ['Pending', 'Reviewing', 'Needs implemented'];

    constructor(
        public request: RequestLine,
        private balanceExceptionRequestsServiceV2: BalanceExceptionRequestsServiceV2,
        private $mdDialog: ng.material.IDialogService,
        private userService: IUserService) {

    }

    $onInit(): void {
        const user: IUser = this.userService.get();

        if (user.canApprove && $fsLDFeatureFlagService.isEnabled('Foxtrot.276752.RequesterCannotApproveAgencyBalanceException')) {
            this.isUserOriginalRequester(this.request.requestId);
        }

        this.allowComment = user.canApprove;

        this.loadDetails();
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    approve($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceExceptionRequestsServiceV2.approve(this.request.requestId)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceExceptionRequestsServiceV2.deny(this.request.requestId)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    review($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceExceptionRequestsServiceV2.review(this.request.requestId)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    implement($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceExceptionRequestsServiceV2.implement(this.request.requestId)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    withdraw($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceExceptionRequestsServiceV2.withdraw(this.request.requestId)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    private loadDetails(): void {
        this.isLoading = true;
        this.balanceExceptionRequestsServiceV2.getDetails(this.request.requestId).then((response) => {
            if (response) {
                this.details = response.data.details;
                this.totalUnits = this.details.materials.reduce((sum, material) => sum + material.units, 0);
                for (let approval of this.details.requiredApprovals) {
                    approval.showDate = !this.pendingDecisions.some(decision => decision === approval.decision);
                }

                if ($fsLDFeatureFlagService.isEnabled('Foxtrot.276752.RequesterCannotApproveAgencyBalanceException')) {
                    this.updateRowStatusV2(response.data.status);
                }
                else {
                    this.updateRowStatus(response.data.status);
                }
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }

    private isUserOriginalRequester(requestId): void {
        this.balanceExceptionRequestsServiceV2.isUserOriginalRequester(requestId)
            .then((response) => {
                if (response) {
                    this.updateUserIsOriginalRequester(response.data);
                }
        })
    }

    private updateUserIsOriginalRequester(response: any): void {
        this.userIsOriginalRequester = response;
    }

    private updateRowStatus(requestStatus: RequestStatus): void {
        this.request.statusId = requestStatus.statusId;
        this.request.status = requestStatus.status;
        this.request.canApprove = requestStatus.canApprove;
        this.request.canDeny = requestStatus.canDeny;
        this.request.canReview = requestStatus.canReview;
        this.request.canManuallyImplement = requestStatus.canManuallyImplement;
        this.request.canWithdraw = requestStatus.canWithdraw;
    }

    private updateRowStatusV2(requestStatus: RequestStatus): void {
        this.request.statusId = requestStatus.statusId;
        this.request.status = requestStatus.status;
        this.request.canApprove = requestStatus.canApprove && !this.userIsOriginalRequester;
        this.request.canDeny = requestStatus.canDeny;
        this.request.canReview = requestStatus.canReview;
        this.request.canManuallyImplement = requestStatus.canManuallyImplement;
        this.request.canWithdraw = requestStatus.canWithdraw;
    }
}