//import * as LDClient from 'launchdarkly-js-client-sdk';
//const LDClient = require('launchdarkly-js-client-sdk');

// Documentation on usage can be found here:
// https://agcompany.sharepoint.com/sites/ConnexIT/Connex%20Wiki/Connex%20Feature%20Flags.aspx

// Given that this file is directly loaded into the browser and not transpiled to ES5,
// it cannot be written using ES6 or it will not run on early versions of iOS 12 or lower. 
// Even though as of this time we do not support iOS < 12 there are still a lot of folks
// using earlier versions and Launch Darkly is a core component and must work.
// Specifically, arrow functions () => within ES6 classes do not load.

/* eslint-disable angular/window-service, angular/document-service */
window.LDFeatureFlagService = function (clientId) { // eslint-disable-line no-unused-vars
    this.clientId = clientId;

    this.initialize = function (upn) {
        const userObj = {
            'key': upn,
            'anonymous': upn.length === 0
        };

        window.__ldClient = LDClient.initialize(this.clientId, userObj);

        __ldClient.on('ready', function () {
            window.__ldClient.identify(userObj, null, () => {
                console.log(`LD initialized for user [${upn.length > 0 ? upn : 'anonymous user'}]`);
            });
        });
    };

    this.isEnabled = function (key) {
      return __ldClient.variation(key, false);
    }

    this.isEnabledAsync = function (key) {
        return __ldClient.waitUntilReady().then(function() {
            return __ldClient.variation(key, false);
        });
    }


    this.client = function () {
        return __ldClient;
    }
};
