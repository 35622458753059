import {IPendingRequestService} from './pendingRequests.service.interface';

export default class PendingRequestService implements IPendingRequestService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(pageNumber: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pending/pages/${pageNumber}`, { timeout: 125000 });
    }

    getAllPending(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/pending/all`, { timeout: 125000 });
    }
}
