import LeftNavComponent from './leftNav/leftNav.component';
Application.registerComponent('leftNav', LeftNavComponent.factory());

import NavigationComponent from './navigation/navigation.component';
Application.registerComponent('navigation', NavigationComponent.factory());

import ImpersonateComponent from './impersonate/impersonate.component';
Application.registerComponent('impersonate', ImpersonateComponent.factory());

import LeftNavService from './leftNav/leftNav.service';
Application.registerService('leftNavService', LeftNavService);

import RequestCountContainerComponent from './leftNav/requestCountContainer/requestCountContainer.component';
Application.registerComponent('requestCountContainer', RequestCountContainerComponent.factory());

