import { IUserService } from '../../../core/services/user.service.interface';
import LoanTypeChangeRequestsService from './loanTypeChangeRequests.service';
import { LoanTypeChangeRequest } from './loanTypeChangeRequest.model';
import { IUser } from '../../../core/models/user.model.interface';
import LoanTypeChangeRequestDetailController from './detail/loanTypeChangeRequestDetail.controller';
import LoanTypeChangeBalanceDetailmodel = require('./request/models/loanTypeChangeBalanceDetail.model');
import LoanTypeChangeBalanceDetail = LoanTypeChangeBalanceDetailmodel.LoanTypeChangeBalanceDetail;

export default class LoanTypeChangePendingRequestsController {

    hideToolbar: boolean = false;
    navigationText: string;
    requests: LoanTypeChangeRequest[];
    loading: boolean = true;
    canShowTable: () => boolean = (() => true);
    allowManuallyImplement: boolean = false;

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected loanTypeChangeRequestsService: LoanTypeChangeRequestsService,
        protected $mdDialog: ng.material.IDialogService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    viewDetails($event: MouseEvent, request: LoanTypeChangeRequest): void {
        this.$mdDialog.show({
            template: require('./detail/loanTypeChangeRequestDetail.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                controller: LoanTypeChangeRequestDetailController,
                controllerAs: 'vm',
                locals: {
                    request: request
                },
                fullscreen: true
            })
            .finally(() => this.getPending());
    }

    showCurrentRateNotSet(request: LoanTypeChangeRequest): boolean {
        return request.currentLoanType.loanTypeDescription === 'NA';
    }

    private activate() {
        this.user = this.userService.get();

        this.getPending();

        this.navigationText = 'My Pending Loan Type Change Requests';
    }

    private getPending(): void {
        this.loanTypeChangeRequestsService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private approve($event: MouseEvent, request: LoanTypeChangeRequest): void {
        $event.stopPropagation();
        request.processing = true;

        this.loanTypeChangeRequestsService.approve(request.requestId, this.getBalance(request))
            .then(() => {
                _.remove(this.requests,
                    (line: LoanTypeChangeRequest) => {
                        return line.requestId === request.requestId;
                    });
            })
            .finally(() => {
                request.processing = false;
            });
    }

    private deny($event: MouseEvent, request: LoanTypeChangeRequest): void {
        $event.stopPropagation();
        request.processing = true;

        this.loanTypeChangeRequestsService.deny(request.requestId, this.getBalance(request))
            .then(() => {
                _.remove(this.requests,
                    (line: LoanTypeChangeRequest) => {
                        return line.requestId === request.requestId;
                    });
            })
            .finally(() => {
                request.processing = false;
            });
    }

    private getBalance(request: LoanTypeChangeRequest): LoanTypeChangeBalanceDetail {
        return new LoanTypeChangeBalanceDetail(request.balances.netInvoiceAmount,
            request.balances.receipts.cropProtection,
            request.balances.receipts.encirca,
            request.balances.receipts.sellerService);
    }

    private processResponse(response: any): void {
        let requestData: Array<any> = response.data;
        this.requests = _.sortByAll(requestData, ['requestedDate', 'salesYear', 'territoryId', 'salesAgency.name', 'businessPartner.name']);
    }

    private done(): void {
        this.loading = false;
    }
}