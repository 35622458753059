import { PendingPackageOverrideRequest } from '../../infinityPackageOverride/pendingPackageOverrideRequest.model';
import { ISupportInfinityPackageOverrideService } from "./supportInfinityPackageOverride.service.interface";
import InfinityPackageOverrideDetailController from '../../infinityPackageOverride/detail/infinityPackageOverrideDetail.controller';
import { IActiveElementHTMLElement } from "../../../../core/interfaces/activeElementHTMLElement.interface";

export default class SupportInfinityPackageOverrideSearchController {
    requests: PendingPackageOverrideRequest[];
    searching: boolean = false;
    criteria: string = ''; 
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;
    maximumRowsToDisplay: number = 1000;

    constructor(private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportInfinityPackageOverrideService: ISupportInfinityPackageOverrideService) {    
    }

    async onSearch() {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

           await this.supportInfinityPackageOverrideService.getSupportSearchResult(this.getFormattedCriteria())
                .then((response) => {
                    this.processResponse(response);
                })
                .catch(() => {
                    this.requests = [];
                    this.requestGroups = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    private getFormattedCriteria(): string {
        return this.criteria.replace('\\', '').replace('"', '');
    }

    private processResponse(data: any): void {
        this.requests = data.results;
        this.totalRequests = data.count;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;

        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests;

        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return `${request.salesYear} - ${request.territoryId} - ${request.salesAgency.name} (${request.salesAgency.id})`;
        });

        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, 'requestedDate', 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;
    }
  
    showDialog($event: MouseEvent, request: PendingPackageOverrideRequest): void {
        this.$mdDialog.show({
            template: require('../../infinityPackageOverride/detail/infinityPackageOverrideDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: InfinityPackageOverrideDetailController,
            controllerAs: '$ctrl',
            locals: {
                requestLocal: request
            },
            fullscreen: true
        });
    }
}