import './balanceWriteOffRequests.scss';

import routing from './balanceWriteOffRequests.routes';
Application.registerConfig(routing);

import routingSearch from './balanceWriteOffSearch.routes';
Application.registerConfig(routingSearch);

import routingCreateRequest from './balanceWriteOffCreateRequest.routes';
Application.registerConfig(routingCreateRequest);

import BalanceWriteOffRequestsController from './balanceWriteOffRequests.controller';
Application.registerController('BalanceWriteOffRequestsController', BalanceWriteOffRequestsController);

import BalanceWriteOffNavigationComponent from './Navigation/balanceWriteOffNavigation.component';
Application.registerComponent('balanceWriteOffNavigation', BalanceWriteOffNavigationComponent.factory());

import BalanceWriteOffSearchController from './balanceWriteOffSearch.controller';
Application.registerController('BalanceWriteOffSearchController', BalanceWriteOffSearchController);

import BalanceWriteOffCreateRequestController from './request/bwoRequest.controller';
Application.registerController('bwoRequestController', BalanceWriteOffCreateRequestController);

import BalanceWriteOffRequestsService from './balanceWriteOffRequests.service';
Application.registerRepository('balanceWriteOffRequestsService', BalanceWriteOffRequestsService);

import BalanceWriteOffAllPendingRequestsController from './balanceWriteOffAllPendingRequests.controller';
Application.registerController('BalanceWriteOffAllPendingRequestsController', BalanceWriteOffAllPendingRequestsController);

// DETAIL
import BalanceWriteOffDetailController from './detail/balanceWriteOffDetail.controller';
Application.registerController('BalanceWriteOffDetailController', BalanceWriteOffDetailController);

import BalanceWriteOffDetailService from './detail/balanceWriteOffDetail.service';
Application.registerService('balanceWriteOffDetailService', BalanceWriteOffDetailService);

import SupportBalanceWriteOffRequestsController from './supportBalanceWriteOffRequests.controller';
Application.registerController('SupportBalanceWriteOffRequestsController', SupportBalanceWriteOffRequestsController);

import SupportBalanceWriteOffManualImplementRequestsController from './supportBalanceWriteOffManualImplementRequests.controller';
Application.registerController('SupportBalanceWriteOffManualImplementRequestsController', SupportBalanceWriteOffManualImplementRequestsController);

import SupportBalanceWriteOffManualImplementRequestsService from './supportBalanceWriteOffManualImplementRequests.service';
Application.registerService('supportBalanceWriteOffManualImplementRequestsService', SupportBalanceWriteOffManualImplementRequestsService);
