import ProposalCpaCommentsController from './proposalcpaComments.controller';

export default class ProposalCpaCommentsComponent implements ng.IComponentOptions {
    template: string = require('./proposalcpaComments.html');
    bindings: { [binding: string]: string } = {
        comments: '<',
        requestId: '<',
        allowComment: '<?'
    };
    controller: Function = ProposalCpaCommentsController;

    static factory() {
        const component: Function = () => {
            return new ProposalCpaCommentsComponent();
        };

        return component();
    }
}