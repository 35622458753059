import './bwoRequest.scss';

import routes from './bwoRequest.routes';
Application.registerConfig(routes);

import { BwoRequestService } from './bwoRequest.service';
Application.registerService('bwoRequestService', BwoRequestService);

import BwoRequestController from './bwoRequest.controller';
Application.registerController('BwoRequestController', BwoRequestController);

import SupportCreateBwoRequestController from './supportCreateBwoRequest.controller';
Application.registerController('SupportCreateBwoRequestController', SupportCreateBwoRequestController);