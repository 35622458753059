import './leftNav.scss';

import LeftNavController from './leftNav.controller';

Application.registerController('LeftNavController', LeftNavController);


export default class LeftNavComponent {
    template: string = require('./leftNav.html');
    controller: any = LeftNavController;

    static factory() {
        const component: any = () => {
            return new LeftNavComponent();
        };

        return component();
    }
}
