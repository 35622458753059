export class BwoRequestData {

    constructor(
        public invoiceId: number,
        public shareId: number,
        public shareName: string,
        public amount: number,
        public comment: string,
        public bwoType: number,
        public territoryId: string,
        public operationId: number,
        public operationName: string,
        public salesAgencyId: number,
        public salesAgencyName: string,
        public accountDescriptionName: string,
        public businessPartnerId: number,
        public year: number,
        public seasonId: string,
        public salesPeriodId: number,
        public isFullBalanceWriteOff: boolean,
        public requestedDate: Date
    ) { }
}