import { IRedirectService } from '../../../../core/services/redirect.service.interface';
import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';

export default class BusinessPartnerPlanningNavigationController {
    // bindings
    page: string;
    user: IUser;
   
    constructor(
        private redirectService: IRedirectService,
        private userService: IUserService,
        private $mdSidenav: ng.material.ISidenavService,
        private $location: ng.ILocationService,
        private $state: ng.ui.IStateService) { }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    navigateToSalesSupportSearch(): void {
        this.$state.go('supportPlanningRequestState'); 
    } 

    navToAgencyPlanning(): void {
        this.redirectService.redirectToAgencyPlanning();
    }

    navToAgencyPlanningPendingRequests(): void {
        this.redirectService.redirectToAgencyPlanningPendingRequests();
    }

    canSeeCustomerPlanning(): boolean {
        return this.user.canViewBusinessPartnerPlanning && $fsLDFeatureFlagService.isEnabled('Sierra.ApprovalTool.CustomerPlanning');
    }

    canSeeCustomerPlanningPendingRequest(): boolean {
        return this.user.canViewBusinessPartnerPlanningPendingRequests && $fsLDFeatureFlagService.isEnabled('Sierra.ApprovalTool.BPPTerritoryApproval');
    }

    canUseSupportPage(): boolean {
        return this.user.useSupportPage;
    }
}