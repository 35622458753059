import KeepSessionAliveDirective from './keepSessionAlive.directive';
Application.registerDirective('ngClick', KeepSessionAliveDirective.factory());
Application.registerDirective('ngHref', KeepSessionAliveDirective.factory());

import FixedHeaderDirective from './fixedHeader.directive';
Application.registerDirective('fixedHeader', FixedHeaderDirective.factory());

import FocusInputDirective from './focusInput.directive';
Application.registerDirective('focusInput', FocusInputDirective.factory());

import Percent from './percent.directive';
Application.registerDirective('percent', Percent.factory());

import GreaterThanZero from './greaterThanZero.directive';
Application.registerDirective('greaterThanZero', GreaterThanZero.factory());

import LessThanValidator from './lessThanValidator.directive';
Application.registerDirective('lessThanValidator', LessThanValidator.factory());

import LessThanOrEqualValidator from './lessThanOrEqualValidator.directive';
Application.registerDirective('lessThanOrEqualValidator', LessThanOrEqualValidator.factory());

import GreaterThanOrEqualValidator from './greaterThanOrEqualValidator.directive';
Application.registerDirective('greaterThanOrEqualValidator', GreaterThanOrEqualValidator.factory());

import CurrencyInputDirective from './currencyInput.directive';
Application.registerDirective('currencyInput', CurrencyInputDirective.factory());

import NumberInputDirective from './numberInput.directive';
Application.registerDirective('numberInput', NumberInputDirective.factory());

import NumberOnlyDirective from './numberOnly.directive';
Application.registerDirective('numberOnly', NumberOnlyDirective.factory());

import NumberDecimalOnlyDirective from './numberDecimalOnly.directive';
Application.registerDirective('numberDecimalOnly', NumberDecimalOnlyDirective.factory());

import AbsoluteDecimalOnlyDirective from './absoluteDecimalOnly.directive';
Application.registerDirective('absoluteDecimalOnly', AbsoluteDecimalOnlyDirective.factory());

import EnhanceTableNavigation  from './enhanceTableNavigation.directive';
Application.registerDirective('enhanceTableNavigation', EnhanceTableNavigation.factory());