import SalesYearsComponentController from './salesYear.component.controller';

export default class SalesYearsComponent implements ng.IComponentOptions {
    template: string = require('./salesYear.html');
    bindings: { [binding: string]: string } = {
        salesYears: '<',
        onSalesYearChange: '&'
    };
    controller: Function = SalesYearsComponentController;

    static factory() {
        const component: Function = () => {
            return new SalesYearsComponent();
        };

        return component();
    }
}