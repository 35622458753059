
import BalanceWriteOffDetailController from './detail/balanceWriteOffDetail.controller';
import { BalanceWriteOffRequest } from './balanceWriteOffRequest.model';
import SupportBalanceWriteOffManualImplementRequestsService from './supportBalanceWriteOffManualImplementRequests.service';

export default class SupportBalanceWriteOffManualImplementRequestsController {

    hideToolbar: boolean = true;
    navigationText: string = 'My pending balance write-off requests';
    requests: Array<BalanceWriteOffRequest>;
    loading: boolean = true;
    canShowTable: () => boolean = (() => true);
    nothingToApprove: () => boolean = this.nothingToApproveImpl;
    allowManuallyImplement: boolean = true;

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected supportBalanceWriteOffManualImplementRequestsService: SupportBalanceWriteOffManualImplementRequestsService,
        protected $mdDialog: ng.material.IDialogService
    ) {
        
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    public viewDetails($event: MouseEvent, request: BalanceWriteOffRequest) {
        this.$mdDialog.show({
            template: require('./detail/balanceWriteOffDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: BalanceWriteOffDetailController,
            controllerAs: 'vm',
            locals: {
                requestLocal: request
            },
            fullscreen: true
        }).finally(() => {
            this.getPending();
        });

    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.getPending();
    }

    private getPending(): void {
        this.supportBalanceWriteOffManualImplementRequestsService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private processResponse(response: any): void {
        let requestData: Array<any> = response.data.pendingRequestList;
        this.requests = _.sortByAll(requestData, ['transactionDate', 'period', 'territoryId', 'salesAgency.name']);
    }

    private done(): void {
        this.loading = false;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    manuallyImplement($event: MouseEvent, bwoRequest: any): void {
        $event.stopPropagation();
        bwoRequest.processing = true;

        this.supportBalanceWriteOffManualImplementRequestsService.manuallyImplement(bwoRequest)
            .then(() => {
                bwoRequest.isImplemented = true;
                this.setActionPermissions(bwoRequest, false);
            })
            .finally(() => {
                bwoRequest.processing = false;
                this.getPending();
            });
    }

    canApprove(): boolean {
        return false;
    }

    canDeny(): boolean {
        return false;
    }

    private setActionPermissions(request: BalanceWriteOffRequest, canManuallyImplement: boolean): void {
        request.canManuallyImplement = canManuallyImplement;
    }
}