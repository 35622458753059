export default class SupportRequestsService  {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    pendingRequestCount(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/support/request-count/pending-requests`);
    }

    pendingImplementRequestCount(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/support/request-count/pending-implement-requests`);
    }
}
