import LoanTypeChangeBalanceDetailmodel = require('./request/models/loanTypeChangeBalanceDetail.model');
import LoanTypeChangeBalanceDetail = LoanTypeChangeBalanceDetailmodel.LoanTypeChangeBalanceDetail;

export default class LoanTypeChangeRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change/requests/pending`);
    };

    getAllRequestListBySalesPeriod(salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change/salesperiods/${salesPeriodId}/requests/all`);
    };

    getAllLoanTypeChangeFiltersSalesperiods(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change/requests/all/filters/salesperiods`);
    }

    approve(requestId: number, data: LoanTypeChangeBalanceDetail): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/loan-type-change/requests/${requestId}/approve`, data);
    };

    deny(requestId: number, data: LoanTypeChangeBalanceDetail): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/loan-type-change/requests/${requestId}/deny`, data);
    };
}
