var Authentication;
(function (Authentication) {
    angular
        .module('Authentication.Sessions', []);
    angular
        .module('Authentication', ['Authentication.Sessions']);
    angular.module('Authentication').config([
        '$httpProvider',
        function ($httpProvider) {
            $httpProvider.interceptors.push('AuthEncodeURIInterceptor');
        }
    ]);
})(Authentication || (Authentication = {}));


angular.module('Authentication').factory('AuthEncodeURIInterceptor', [
    '$q', function ($q) {

        var transformFormData = function (obj) {
            var str = [];
            for (var p in obj) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
            return str.join('&');
        };

        return {
            request: function (config) {
                if (config.method.toLowerCase() === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=UTF-8') {
                    config.transformRequest = transformFormData;
                    return config || $q.when(config);
                }

                return config;
            }
        };
    }
]);
var Authentication;
(function (Authentication) {
    var Sessions;
    (function (Sessions) {
        var Expiration = (function () {
            function Expiration(storage, sessionTimeout, timeoutWarning) {
                this.Key = 'authentication-session-active-until';
                this.SessionTimeoutKey = 'authentication-session-timeout';
                this.SessionTimeoutWarningKey = 'authentication-session-timeout-warning';

                this.ThirtyMinutes = 1800000;
                this.SessionTimeout = sessionTimeout ? sessionTimeout : this.ThirtyMinutes;
                this.TimeoutWarning = timeoutWarning ? timeoutWarning : 0;
                this.DateMinValue = new Date(-8640000000000000);

                this.storage = storage;
                this.WarningSent = false;
                this.storage.setItem(this.SessionTimeoutKey, JSON.stringify(this.SessionTimeout));
                this.storage.setItem(this.SessionTimeoutWarningKey, JSON.stringify(this.TimeoutWarning));
            }

            Expiration.prototype.getExpiration = function () {
                // by default, return a Date that expired a long time ago.
                var result = this.DateMinValue;
                var item = this.storage.getItem(this.Key);
                if (item) {
                    result = new Date(JSON.parse(item));
                }
                return result;
            };

            Object.defineProperty(Expiration.prototype, 'isExpired', {
                get: function () {
                    var expiration = this.getExpiration();
                    return expiration.getTime() <= new Date().getTime();
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Expiration.prototype, 'shouldWarn', {
                get: function () {
                    var expiration = this.getExpiration();
                    var warn = expiration.getTime() - this.TimeoutWarning <= new Date().getTime();
                    if (warn && this.WarningSent) {
                        return false;
                    }
                    else {
                        this.WarningSent = warn;
                        return warn;
                    }
                },
                enumerable: true,
                configurable: true
            });
            Expiration.prototype.reset = function () {
                if (window.location.hash !== '#/login/logout') {
                    var stringTimeout = this.storage.getItem(this.SessionTimeoutKey);
                    this.SessionTimeout = Number(JSON.parse(stringTimeout));
                    var value = new Date(new Date().getTime() + this.SessionTimeout);
                    this.storage.setItem(this.Key, JSON.stringify(value));

                    var stringTimeoutWarning = this.storage.getItem(this.SessionTimeoutWarningKey);
                    this.TimeoutWarning = Number(JSON.parse(stringTimeoutWarning));
                }
            };
            Expiration.prototype.clear = function () {
                if (window.location.hash === '#/login/logout' || window.location.hash === '#/login/inactivity') {
                    this.storage.removeItem(this.Key);
                }

            };
            Expiration.prototype.resetLogin = function () {
                var value = new Date(new Date().getTime() + this.SessionTimeout);
                this.storage.setItem(this.Key, JSON.stringify(value));
            };
            return Expiration;
        })();
        Sessions.Expiration = Expiration;

    })(Sessions = Authentication.Sessions || (Authentication.Sessions = {}));
})(Authentication || (Authentication = {}));


var Authentication;
(function (Authentication) {
    var Sessions;
    (function (Sessions) {
        var Session = (function () {
            function Session($window, $injector, sessionTimeout, timeoutWarning, warningCallback, timeoutCallback) {
                this.$injector = $injector;
                this.warningCallback = warningCallback;
                this.timeoutCallback = timeoutCallback;
                if ($window == null) {
                    throw new Error('$window must not be null.');
                }
                if (!this.timeoutCallback) {
                    this.timeoutCallback = function () { };
                }
                $window.onmousemove = this.userActivityHandler.bind(this);
                $window.onclick = this.userActivityHandler.bind(this);
                $window.onscroll = this.userActivityHandler.bind(this);
                $window.onkeypress = this.userActivityHandler.bind(this);
                $window.onmousedown = this.userActivityHandler.bind(this);
                this.timer = new Sessions.SessionTimer(this, $window);
                this.expiration = new Sessions.Expiration($window.localStorage, sessionTimeout, timeoutWarning);
            }            
            Session.prototype.checkExperation = function () {
                if (this.expiration.isExpired) {
                    this.kill();
                }
                this.checkExpirationWarning();
            };
            Session.prototype.checkExpirationWarning = function () {
                if (this.warningCallback) {
                    if (this.expiration.shouldWarn) {
                        this.warningCallback(this.$injector);
                    }
                }
            };
            Session.prototype.start = function () {
                this.timer.start();
                this.expiration.resetLogin();
            };
            Session.prototype.isExpired = function () {
                return this.expiration.isExpired;
            };
            Session.prototype.resume = function () {
                this.timer.start();
            };
            Session.prototype.keepAlive = function () {
                var expired = this.expiration.isExpired;
                if (expired) {
                    this.kill();
                }
                else {
                    this.expiration.reset();
                }
                return expired;
            };
            Session.prototype.kill = function () {
                this.timer.stop();
                this.expiration.clear();
                this.timeoutCallback(this.$injector);
            };
            Session.prototype.userActivityHandler = function () {
                if (!this.timer.isInitialized) {
                    return true;
                }
                this.expiration.reset();
                return true;
            };
            Session.$inject = ['$window', '$injector'];
            return Session;
        })();
        Sessions.Session = Session;
        angular.module('Authentication.Sessions')
            .service('Session', Sessions.Session);
    })(Sessions = Authentication.Sessions || (Authentication.Sessions = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Sessions;
    (function (Sessions) {
        var SessionProvider = (function () {
            function SessionProvider() {
                var _this = this;
                this.$get = ['$window', '$injector', function ($window, $injector) {
                    return new Sessions.Session($window, $injector, _this.sessionTimeout, _this.timeoutWarning, _this.warningCallback, _this.timeoutCallback);
                }];
            }
            SessionProvider.prototype.setSessionTimeout = function (duration) {
                this.sessionTimeout = duration;
                return this;
            };
            SessionProvider.prototype.setTimeoutWarning = function (duration, callback) {
                this.timeoutWarning = duration;
                this.warningCallback = callback;
                return this;
            };
            SessionProvider.prototype.setTimeoutCallback = function (callback) {
                this.timeoutCallback = callback;
                return this;
            };
            return SessionProvider;
        })();
        Sessions.SessionProvider = SessionProvider;
        angular.module('Authentication.Sessions')
            .provider('Session', new SessionProvider());
    })(Sessions = Authentication.Sessions || (Authentication.Sessions = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Sessions;
    (function (Sessions) {
        var SessionTimer = (function () {
            function SessionTimer(session, $window) {
                this.session = session;
                this.$window = $window;
                this.FiveSeconds = 5000;
                this.Uninitialized = -1;
                this.timerHandle = this.Uninitialized;
                // noop
            }
            SessionTimer.prototype.start = function () {
                if (this.timerHandle !== this.Uninitialized) {
                    this.stop();
                }
                this.timerHandle = this.$window.setInterval(this.session.checkExperation.bind(this.session), this.FiveSeconds);
            };
            SessionTimer.prototype.stop = function () {
                if (this.timerHandle !== this.Uninitialized) {
                    this.$window.clearInterval(this.timerHandle);
                    this.timerHandle = this.Uninitialized;
                }
            };
            Object.defineProperty(SessionTimer.prototype, 'isInitialized', {
                get: function () {
                    return this.timerHandle !== -1;
                },
                enumerable: true,
                configurable: true
            });
            return SessionTimer;
        })();
        Sessions.SessionTimer = SessionTimer;
    })(Sessions = Authentication.Sessions || (Authentication.Sessions = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Results;
    (function (Results) {
        var AuthenticationError = (function () {
            function AuthenticationError() {
            }
            AuthenticationError.prototype.appliesTo = function (httpStatusCode) {
                return httpStatusCode === 0 || httpStatusCode === 500;
            };
            AuthenticationError.prototype.createNew = function (session) {
                return this;
            };
            AuthenticationError.prototype.message = function () {
                return 'Sorry, something unrelated to authentication happened.';
            };
            return AuthenticationError;
        })();
        Results.AuthenticationError = AuthenticationError;
    })(Results = Authentication.Results || (Authentication.Results = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Results;
    (function (Results) {
        var Authorized = (function (data) {
            function Authorized(data) {
                this.data = data;
            }
            Authorized.prototype.appliesTo = function (httpStatusCode) {
                return httpStatusCode === 200;
            };
            Authorized.prototype.createNew = function (session) {
                if (session == null) {
                    throw new Error('session must not be null');
                }
                session.start();
                return this;
            };
            Authorized.prototype.message = function () {
                return '';
            };
            return Authorized;
        })();
        Results.Authorized = Authorized;
    })(Results = Authentication.Results || (Authentication.Results = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Results;
    (function (Results) {
        var Forbidden = (function () {
            function Forbidden() {
            }
            Forbidden.prototype.appliesTo = function (httpStatusCode) {
                return httpStatusCode === 403;
            };
            Forbidden.prototype.createNew = function (session) {
                return this;
            };
            Forbidden.prototype.message = function () {
                return "Sorry, we haven't set you up to use the app yet.";
            };
            return Forbidden;
        })();
        Results.Forbidden = Forbidden;
    })(Results = Authentication.Results || (Authentication.Results = {}));
})(Authentication || (Authentication = {}));


var Authentication;
(function (Authentication) {
    var Results;
    (function (Results) {
        var ResultFactory = (function () {
            function ResultFactory(session) {
                this.session = session;
                this.results = [
                    new Results.Authorized(),
                    new Results.Unauthorized(),
                    new Results.Forbidden(),
                    new Results.AuthenticationError()
                ];
                // noop
            }
            ResultFactory.prototype.fromHttpStatusCode = function (httpStatus) {
                var result = null;
                for (var _i = 0, _a = this.results; _i < _a.length; _i++) {
                    var item = _a[_i];
                    if (item.appliesTo(httpStatus)) {
                        result = item.createNew(this.session);
                    }
                }
                return result;
            };
            ResultFactory.prototype.authorized = function () {
                return new Results.Authorized();
            };
            ResultFactory.prototype.unauthorized = function () {
                return new Results.Unauthorized();
            };
            ResultFactory.prototype.forbidden = function () {
                return new Results.Forbidden();
            };
            return ResultFactory;
        })();
        Results.ResultFactory = ResultFactory;
    })(Results = Authentication.Results || (Authentication.Results = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Results;
    (function (Results) {
        var Unauthorized = (function () {
            function Unauthorized() {
            }
            Unauthorized.prototype.appliesTo = function (httpStatusCode) {
                return httpStatusCode === 401;
            };
            Unauthorized.prototype.createNew = function (session) {
                return this;
            };
            Unauthorized.prototype.message = function () {                
                return 'Your username or password is incorrect or expired.';                
            };
            return Unauthorized;
        })();
        Results.Unauthorized = Unauthorized;
    })(Results = Authentication.Results || (Authentication.Results = {}));
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var ApiGatewayAuthenticationService = (function () {
        function ApiGatewayAuthenticationService($q, $http, $location, session, applicationPathService) {
            this.$q = $q;
            this.$http = $http;
            this.session = session;
            this.factory = new Authentication.Results.ResultFactory(session);
            this.url = applicationPathService.getApplicationPath() + '/account/processlogin';
        }
        ApiGatewayAuthenticationService.prototype.authenticate = function (credential) {
            var _this = this;
            var deferred = this.$q.defer();
            if (credential.isValid) {
                credential
                    .post(this.url, this.$http)
                    .then(function (response) {
                        if (response === '' || (response.data && response.data.authenticatedEmail === '')) { // <--- This is coded wrong!!  response.data is null
                            sessionStorage.removeItem('AuthInfo');
                            deferred.resolve(_this.factory.forbidden());
                        } else {
                            _this.session.start();
                            sessionStorage.setItem('AuthInfo', response.data);
                            if (response.data.authInfo && response.data.authInfo.upn && response.data.authInfo.upn !== '') {
                                sessionStorage.setItem('upn', response.data.authInfo.upn);
                            }
                            deferred.resolve(_this.factory.authorized());
                        }
                    })
                    .catch(function (response) {
                        return deferred.resolve(_this.factory.fromHttpStatusCode(response.status));
                    });
            }
            else {
                this.factory.unauthorized();
            }
            return deferred.promise;
        };
        ApiGatewayAuthenticationService.prototype.resumeSession = function () {
            if (!this.session.isExpired()) {
                this.session.resume();
            }
        };
        ApiGatewayAuthenticationService.$inject = ['$q', '$http', '$location', 'Session', 'ApplicationPathService'];
        return ApiGatewayAuthenticationService;
    })();
    Authentication.ApiGatewayAuthenticationService = ApiGatewayAuthenticationService;
    angular.module('Authentication')
        .service('ApiGatewayAuthenticationService', ApiGatewayAuthenticationService);
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var ApplicationPathService = (function () {
        function ApplicationPathService($location) {
            this.baseUrl = '';
            if ($location.host().indexOf('localhost') > -1) {
                this.baseUrl = 'http://localhost/approvaltool/api';
            }
        }
        ApplicationPathService.prototype.setApplicationPath = function (path) {
            this.applicationPath = path;
        };
        ApplicationPathService.prototype.getApplicationPath = function () {
            if (this.applicationPath !== undefined) {
                return this.applicationPath;
            }
            else {
                return this.baseUrl;
            }
        };
        ApplicationPathService.$inject = ['$location'];
        return ApplicationPathService;
    })();
    Authentication.ApplicationPathService = ApplicationPathService;
    angular.module('Authentication')
        .service('ApplicationPathService', ApplicationPathService);
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var AuthenticationService = (function () {
        function AuthenticationService($q, session, logoutService, gateway) {
            this.$q = $q;
            this.session = session;
            this.logoutService = logoutService;
            this.gateway = gateway;
            // noop
        }
        AuthenticationService.prototype.logout = function () {
            var deferred = this.$q.defer();
            this.logoutService
                .logout()
                .then(deferred.resolve, deferred.resolve);
            return deferred.promise;
        };
        AuthenticationService.prototype.authenticate = function (credential) {
            var _this = this;
            this.session.kill();
            var deferred = this.$q.defer();
            this.logout().then(function () { return _this.gateway.authenticate(credential).then(deferred.resolve); });
            return deferred.promise;
        };
        AuthenticationService.$inject = ['$q', 'Session', 'LogoutService', 'ApiGatewayAuthenticationService'];
        return AuthenticationService;
    })();
    Authentication.AuthenticationService = AuthenticationService;
    angular.module('Authentication')
        .service('AuthenticationService', AuthenticationService);
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var Credential = (function () {
        function Credential(user, password) {
            this.user = user;
            this.password = password;
            this.valid = !this.isEmpty(user) && !this.isEmpty(password);
        }
        Credential.prototype.isEmpty = function (text) {
            return (!text || 0 === text.length);
        };
        Object.defineProperty(Credential.prototype, 'isValid', {
            get: function () {
                return this.valid;
            },
            enumerable: true,
            configurable: true
        });
        Credential.prototype.toQueryString = function (obj) {
            var parts = [];
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
                }
            }
            return parts.join('&');
        };
        Credential.prototype.post = function (url, $http) {
            return $http({
                method: 'POST',
                url: url,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                transformRequest: this.toQueryString,
                data: {
                    username: this.user,
                    password: this.password
                }
            });
        };
        return Credential;
    })();
    Authentication.Credential = Credential;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var LogoutService = (function () {
        function LogoutService($q, $http, applicationPathService) {
            this.$q = $q;
            this.$http = $http;
            this.url = applicationPathService.getApplicationPath() + '/account/logout';
        }
        LogoutService.prototype.logout = function () {
            var deferred = this.$q.defer();
            this.$http
                .get(this.url)
                .then(function () {
                    localStorage.setItem('authentication-session-active-until', null);
                    return deferred.resolve();
                })
                .catch(function (response) { return deferred.reject(response.status); });
            return deferred.promise;
        };
        LogoutService.$inject = ['$q', '$http', 'ApplicationPathService'];
        return LogoutService;
    })();
    Authentication.LogoutService = LogoutService;
    angular.module('Authentication')
        .service('LogoutService', LogoutService);
})(Authentication || (Authentication = {}));

module.exports = Authentication;