import { IProposalCpaRequestsService } from './proposalcpaRequests.service.interface';
import { ProposalCpaRequest } from './proposalcpaRequest.model'; 

export default class ProposalCpaRequestsService implements IProposalCpaRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/proposalcpa/requests/pending`);
    }

    approve(requestId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/proposalcpa/requests/${requestId}/approve`;
        return this.$http.post(url, { requestId });
    };

    approveV2(requestId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/v2/proposalcpa/requests/${requestId}/approve`;
        return this.$http.post(url, { requestId });
    };

    deny(requestId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/proposalcpa/requests/${requestId}/deny`;
        return this.$http.post(url, { requestId });
    };

    isPermissionStopDatePassed(request: ProposalCpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/requests/competitivepriceadjustment/startstop/permissions/${request.territoryId}`;
        return this.$http.get(url);
    };

    getShareGrossAndDiscountAmount(request: ProposalCpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/proposalcpa/proposals/${request.proposalId}/invoices/${request.invoice.id}/shares/${request.shareId}/competitivePriceAdjustment/${request.competitivePriceAdjustment.id}/pendingdiscountamount`;
        return this.$http.get(url);
    };
}