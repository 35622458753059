import { ShareDiscount } from './models/shareDiscount.model';

export default class DiscountsV2ComponentController {
    shareDiscounts: ShareDiscount;
    year: number;

    previousYear: number;
    growthRetentionText: string;
    showDetails: boolean;
    showTotalDetails: boolean;

    constructor(
        private $filter: ng.IFilterService) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.previousYear = this.year - 1;
        this.showDetails = false;
        this.showTotalDetails = false;
        this.growthRetentionText = this.year < 2019 ? 'Growth and Retention' : 'Qualified Discounts(Hard Coded)';
    }

    public toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    public toggleTotalDetails() {
        this.showTotalDetails = !this.showTotalDetails;
    }

    public showDashes(percentage: number): boolean {
        return percentage === 0;
    }

    public formatFactor(calculationId: number, factor: number): string {
        if (calculationId === 1) {
            return (this.$filter('percentage') as Filter.IFilterPercentage)(factor);
        }

        if (calculationId === 2) {
            return this.$filter('currency')(factor, '$') + ' per unit';
        }

        return '';
    }
}