import { AgencyPlanningModel } from './agencyPlanning.model';

export default class AgencyPlanningService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getList(territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<AgencyPlanningModel>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/agency-planning/territories/${territoryId}`);
    }

    saveTotalDiscountTarget(agencyId: number, totalDiscountTarget: number): ng.IPromise<ng.IHttpPromiseCallbackArg<void>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/agency-planning/agencies/${agencyId}/total-discount-target/save`, totalDiscountTarget);
    }

    saveFlexFundTarget(agencyId: number, flexFundTarget: number): ng.IPromise<ng.IHttpPromiseCallbackArg<void>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/agency-planning/agencies/${agencyId}/flex-fund-target/save`, flexFundTarget);
    }

    submitForApproval(territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<void>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/territory-planning/territories/${territoryId}/create-approval-request`, 1);
    }
}
