import {CommissionAdvanceRequest} from './commissionAdvanceRequest';
import {CommissionAdvanceRequestRow} from './commissionAdvanceRequestRow';
import {ICommissionAdvanceRequestService} from './commissionAdvanceRequest.service.interface';
import {ICommissionAdvanceRequestDetails} from './commissionAdvanceRequestDetails.interface';
import {ICommissionRequestActivity} from '../commission/commissionRequestActivity.interface';
import { IIdAndName } from '../../../core/models/idAndName.model.interface';

export class CommissionAdvanceDetailController {

    isLoading: boolean;

    overrideAdvanceList: Array<IIdAndName> = [{ name: '50%', id: 0.5 }, { name: '75%', id: 0.75 }, { name: 'Clear override', id: 0 }];
    overrideAdvancePercent: IIdAndName;
    displayAdvancePercent: number;

    row: CommissionAdvanceRequestRow;
    activity: Array<ICommissionRequestActivity>;

    constructor(private $mdDialog: ng.material.IDialogService,
        public request: CommissionAdvanceRequest,
        public details: ICommissionAdvanceRequestDetails,
        private commissionAdvanceRequestService: ICommissionAdvanceRequestService) { }

    $onInit(): void {

        this.isLoading = true;

        if (this.details == null) {
                    this.getDetails();
                } else {
                    this.loadDetails(this.details);
                    this.doneLoading();
                }

                this.loadActivity();
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    approve(): void {
        if (this.row.details.advanceAmount <= 0) {
            return;
        }

        this.savePercentAdvanceOverride();

        this.request.processing = true;

        this.commissionAdvanceRequestService.approve(this.row)
            .then(() => {
                this.reload();

                this.loadActivity();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;
        this.commissionAdvanceRequestService.deny(this.row)
            .then(() => {
                this.reload();

                this.loadActivity();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    savePercentAdvanceOverride(): void {
        if (!angular.isDefined(this.overrideAdvancePercent)) {
            return;
        }

        if (this.overrideAdvancePercent.id === 0) {
            this.commissionAdvanceRequestService.deleteCommissionAdvanceOverride(this.request.salesAgencyId);
        }
        else {
            this.commissionAdvanceRequestService.saveCommissionAdvanceOverride(this.request.salesAgencyId, this.overrideAdvancePercent.id);
        }
        
    }

    onPercentAdvanceOverrideChange(): void {
        if (angular.isDefined(this.overrideAdvancePercent)) {
            this.isLoading = true;
            this.request.processing = true;
            this.commissionAdvanceRequestService.calculateCommissionAdvanceData(this.overrideAdvancePercent.id,
                    this.request.salesYear,
                    this.request.salesAgencyId)
                .then(details => this.loadDetails(details))
                .finally(() => this.doneLoading());
        }
    }

    getMaxAdvanceAmount(): number {
        let maxValue: number = 0;

        this.details.commissions.forEach((itm) => { maxValue += itm.commission; });

        return maxValue * (this.details.overrideAdvancePercent > 0 ? this.details.overrideAdvancePercent : this.details.advancePercent);
    }

    private getDetails() {
        this.isLoading = true;

        this.commissionAdvanceRequestService
            .getCommissionAdvanceDetails(this.request.salesYear, this.request.salesAgencyId)
            .then(details => this.loadDetails(details))
            .finally(() => this.doneLoading());
    }

    private loadDetails(details: ICommissionAdvanceRequestDetails) {
        this.details = details;
        if (!this.details.overrideAdvancePercent || this.details.overrideAdvancePercent === 0) {
            this.details.overrideAdvancePercent = undefined;
            this.displayAdvancePercent = this.details.advancePercent * 100;
        } else {
            this.displayAdvancePercent = this.details.overrideAdvancePercent * 100;
        }

        this.row = new CommissionAdvanceRequestRow(this.request);
        this.row.addDetails(this.details);
        const results: Array<IIdAndName> =
            this.overrideAdvanceList.filter(item => item.id === this.details.overrideAdvancePercent);
        if (results.length > 0) {
            this.overrideAdvancePercent = results[0];
        }
    }

    private doneLoading() {
        this.isLoading = false;
        this.request.processing = false;
    }

    private reload() {
        const requestId: number = this.request.requestId;

        this.commissionAdvanceRequestService.getSavedCommissionAdvanceRequest(requestId)
            .then(row => {
                this.row = row;

                this.request.isApproved = row.request.isApproved;
                this.request.isDenied = row.request.isDenied;
                this.request.isPending = row.request.isPending;
                this.request.statusId = row.request.statusId;
                this.request.status = row.request.status;
                this.request.isExpired = row.request.isExpired;
                this.details = row.details;
                this.doneLoading();
            });
    }

    private loadActivity() {
        const requestId: number = this.request.requestId;

        this.commissionAdvanceRequestService.getActivity(requestId)
            .then(result => {
                this.activity = result;
            });
    }
}