import {SupportDropdownItem} from './supportDropdownItem.model';

export class SupportNavItem {
    constructor(
        public name: string,
        public title: string,
        public state: string,
        public subPages: Array<SupportDropdownItem> = null,
        public count: number = 0,
        public showCount: boolean = false) {
        // no-op
    }
}