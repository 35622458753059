import { IDoaOverrideService } from "./doaOverride.service.interface";
import { DoaOverrideRequest } from './doaOverride.model';
import AddDoaOverrideController from "./dialog/addDoaOverride.dialog.controller";

export default class DoaOverrideController {
    loading: boolean = true;
    doaOverrides: Array<DoaOverrideRequest>;

    constructor(private doaOverrideService: IDoaOverrideService,
        private $mdDialog: ng.material.IDialogService,
        private $mdSidenav: ng.material.ISidenavService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.loading = true;
        this.doaOverrideService.get()
            .then((data) => {
                this.doaOverrides = data;
            })
            .finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    private addDoaOverride() {
        let vm = this;
        var add = this.$mdDialog.show({
            template: require('./dialog/addDoaOverride.dialog.html'),
            parent: angular.element(document.body),
            controller: AddDoaOverrideController,
            controllerAs: 'vm',
            fullscreen: false
        });

        add.then(function () {
            vm.activate();
        });
    }

    private showDelete(doaOverride) {
        let vm = this;
        var confirm = vm.$mdDialog.confirm()
            .title('Are you sure you want to delete ' + doaOverride.userId + ' from the DOA?')
            .targetEvent(doaOverride)
            .ok('Yes')
            .cancel('No');

        this.$mdDialog.show(confirm).then(function () {
            vm.doaOverrideService.delete(doaOverride).then(() => vm.activate());
        }, function () {
            //noop
        });
    };
}