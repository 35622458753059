import {ICpaRequest} from './cpaRequest.model.interface';
import {ICpaRequestService} from './cpaRequest.service.interface';

export default class CpaRequestService implements ICpaRequestService {

    constructor(private $http: ng.IHttpService) {
    }

    getLineScore(cpaRequest: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        if (!cpaRequest.productLineId || (cpaRequest.productLineId && cpaRequest.productLineId.length === 0)) {
            cpaRequest.productLineId = '00000';
        }

        const target: string = `{API-ApprovalTool}/requests/cpa/invoices/${cpaRequest.invoice.id}/shares/${cpaRequest.share.id}/elections/${cpaRequest.competitivePriceAdjustmentElectionId}/product-lines/${cpaRequest.productLineId}/score/v2`;
        return this.$http.get(target);
    }

    getTruChoiceCropProtection(cpaRequest: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const target: string = `{API-ApprovalTool}/requests/TruChoice/invoices/${cpaRequest.invoice.id}/shares/${cpaRequest.share.id}`;
        return this.$http.get(target);
    }

    processLineResponse(cpaRequest: ICpaRequest, response: ng.IHttpPromiseCallbackArg<any>): void {
        const data: any = response.data;

        if (cpaRequest && data) {
            cpaRequest.discountAmount = data.shareElectiveDiscountValue;
            cpaRequest.invoiceValue = data.shareGrossInvoiceValue;
        }
    }
}
