export default class LessThanValidator implements ng.IDirective {
    restrict: string = 'A';
    require: string = '^ngModel';

    link: (scope: angular.IScope,
        element: angular.IAugmentedJQuery,
        attrs: IValidatorAttributes,
        ctrl: angular.INgModelController) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: IValidatorAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            ctrl.$validators.lessThanValidator = (modelValue, viewValue) => {
                if (ctrl.$isEmpty(viewValue)) {
                    return true;
                }
                if (Number(modelValue) < attrs.lessThanValidator) {
                    return true;
                }
                return false;
            };
        };

    static factory(): ng.IDirectiveFactory {
        return () => {
            return new LessThanValidator();
        };
    }
}
export interface IValidatorAttributes extends ng.IAttributes {
    lessThanValidator: number;
}