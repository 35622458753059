import {IEncircaCommissionAdvanceRequestDetails} from '../encircaCommissionAdvanceRequestDetails.interface';
import {IApproverSearchBarCriteriaModel} from '../../components/approverSearchBar/models/approverSearchBarCriteria.model.interface';

export default class ServicesCommissionAdvanceApproverSearchService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/services-commission-advance-requests/approver-search`, {timeout: 600000});
    }

    getCommissionAdvanceDetail(requestId: number): ng.IPromise<IEncircaCommissionAdvanceRequestDetails> {
        return this.$http
            .get<IEncircaCommissionAdvanceRequestDetails>(`{API-ApprovalTool}/encirca-commission-advance-detail/request/${requestId}`)
            .then((response) => {
                return response.data;
            });
    }

    getCommissionAdvanceSavedDetails(requestId: number): ng.IPromise<IEncircaCommissionAdvanceRequestDetails> {
        return this.$http.get<IEncircaCommissionAdvanceRequestDetails>(`{API-ApprovalTool}/encirca-commission-advance-detail/requests/${requestId}`)
            .then((response) => {
                return response.data;
            });
    }

    getSearchResults(criteria: IApproverSearchBarCriteriaModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/services-commission-advance-requests/approver-search`, criteria, { timeout: 600000 });
    }
}
