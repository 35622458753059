import {IUserAuthService} from './userAuth.service.interface';
import {ILocalAuthService} from './localAuth.service.interface';
import { IMetatagService } from '../../core/services/metatag.service.interface';

export class UserAuthService implements IUserAuthService {

    constructor(
        private $http: ng.IHttpService,
        private localAuthService: ILocalAuthService,
        private AuthenticationService: Authentication.AuthenticationService,
        private metatagService: IMetatagService
    ) {
        // noop
    }

    processLogin(username: string, password: string): ng.IPromise<ng.IHttpPromiseCallbackArg<number>> {
        if (this.metatagService.getFlagStatus('use-msal-auth')) {
            return this.getUserPriveleges()
        } else {
            if (this.localAuthService.isLocal()) {
                return this.localAuthService.authenticate(username, password);
            } else {
                return this.AuthenticationService.authenticate(new Authentication.Credential(username, password));
            }
        }
    }

    private getUserPriveleges(): ng.IPromise<ng.IHttpPromiseCallbackArg<number>> {
        const config: ng.IRequestShortcutConfig = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        };

        return this.$http.get('{API-ApprovalTool}/logon/success', config)
            .then((response) => {
                if (response && response.data) {
                    if (response.data === '' || response.data['authInfo']['authenticatedName'] === '') {
                        return new Authentication.Results.Forbidden;
                    }
                    return new Authentication.Results.Authorized;
                } else {
                    return new Authentication.Results.Unauthorized;
                }
            }
            ).catch(() => {
                return new Authentication.Results.AuthenticationError;
            });
    }
    
}
