export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/balanceWriteOffRequests/', {
            template: require('./balanceWriteOffRequests.html'),
            controller: 'BalanceWriteOffRequestsController',
            controllerAs: 'vm'
        })
        .when('/balanceWriteOffRequests/all/', {
            template: require('./balanceWriteOffAllPendingRequests.html'),
            controller: 'BalanceWriteOffAllPendingRequestsController',
            controllerAs: 'vm'
      });
}