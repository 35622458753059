/* eslint-disable angular/di */
/* eslint-disable angular/di-unused */
/* eslint-disable angular/component-limit, angular/factory-name */
(() => {
    angular
        .module('approval-tool')
        .factory('appInsightsServerPerformanceInterceptorFactory', appInsightsServerPerformanceInterceptorFactory);

    appInsightsServerPerformanceInterceptorFactory.$inject = [
        'appInsightsService',
        'appInsightsRequestService',
        'appInsightsServerPerformanceQueue',
        '$injector'
    ];

    function appInsightsServerPerformanceInterceptorFactory(
        appInsightsService: any,
        requestService: any,
        queue: any,
        $injector: any
    ) {
        const key = 'telemetry-sensors-performance-server-request-start';

        const isTemplate = (config: any) => {
            return config.cache !== undefined;
        };

        const shouldMonitor = (config: any) => {
            return !isTemplate(config);
        };

        const startTimer = (config: any) => {
            config.headers[key] = new Date().toUTCString();
        };

        const elapsedTime = (config: any) => {
            const start = new Date(config.headers[key]);
            const end = new Date();
            return Math.abs(end.getTime() - start.getTime());
        };

        const detect = (config: any) => {
            const signal = {
                url: config.url,
                method: config.method,
                elapsedTime: elapsedTime(config),
                occurredOn: new Date().toISOString(),
                session: appInsightsService.generateAndSetToken('telemetry-session'),
                request: requestService.getToken(config.headers)
            };
            queue.enqueue(signal);
        };

        return {
            request: (config: any) => {
                if (shouldMonitor(config)) {
                    startTimer(config);
                }
                return config;
            },
            response: (response: any) => {
                const skipMonitoring = response.config === undefined;
                if (!skipMonitoring && shouldMonitor(response.config)) {
                    detect(response.config);
                }
                return response;
            }
        };
    }
})();