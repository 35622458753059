import {ILocalAuthService} from './localAuth.service.interface';

export class LocalAuthService implements ILocalAuthService {

    constructor(
        private $http: ng.IHttpService,
        private $location: ng.ILocationService
    ) {
        // noop
    }

    isLocal(): boolean {
        if (this.$location.host().indexOf('localhost') > -1) {
            return true;
        } else {
            return false;
        }
    }
    authenticate(user: string, password: string): ng.IPromise<ng.IHttpPromiseCallbackArg<number>> {

        const config: ng.IRequestShortcutConfig = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        };

        return this.$http.get('{API-ApprovalTool}/logon/success', config)
            .then((response) => {
                if (response && response.data) {
                    if (response.data === '' || response.data['authInfo']['authenticatedEmail'] === '') {
                        return new Authentication.Results.Forbidden;
                    }
                    return new Authentication.Results.Authorized;
                } else {
                    return new Authentication.Results.Unauthorized;
                }
            }
            ).catch(() => {
                return new Authentication.Results.AuthenticationError;
            });
    }
}
