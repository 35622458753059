import BalanceExceptionHistoryComponentController from './balanceExceptionHistory.controller';

export default class BalanceExceptionHistoryComponentV2 implements ng.IComponentOptions {
    template: string = require('./balanceExceptionHistory.html');
    controller: Function = BalanceExceptionHistoryComponentController;
    bindings: { [binding: string]: string } = {
        salesAgencyId: '<',
        salesPeriodId: '<',
        requestId: '<?'
    };

    static factory() {
        const component: () => ng.IComponentOptions = () => {
            return new BalanceExceptionHistoryComponentV2();
        };

        return component();
    }
}