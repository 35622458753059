export default class PlanningCustomerDiscountService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    saveCustomerDiscountTargetAmount(salesAgencyId: number, agencyCustomerId: number, customerDiscountTypeId: number, targetAmount: number): ng.IHttpPromise<void> {
        return this.$http.post(`{API-ApprovalTool}/bpp/sales-agencies/${salesAgencyId}/agency-summary/agency-customer/${agencyCustomerId}/discount-types/${customerDiscountTypeId}/target-amount`, targetAmount);
    }

    saveTotalDiscountTargetPlan(agencyCustomerId: number, discountTargetPlan: number): ng.IHttpPromise<void> {
        return this.$http.post(`{API-ApprovalTool}/bpp/agency-summary/agency-customer/${agencyCustomerId}/save/discounttargetplan`, discountTargetPlan);
    }

    saveLoadFlexFund(salesAgencyId: number, agencyCustomerId: number, loadFlexFund: boolean): ng.IHttpPromise<void> {
        return this.$http.post(`{API-ApprovalTool}/bpp/sales-agencies/${salesAgencyId}/agency-customer/${agencyCustomerId}/load-flex-fund`, loadFlexFund);
    }
}
