import {IOrgIdAndName} from '../../../../core/models/orgIdAndName.model.interface';
import {RequestCriteria} from './models/loanTypeChangeRequest.criteria.model';
import {IIdAndName} from '../../../../core/models/idAndName.model.interface';
import {LoanTypeChangeRequestOptions} from './models/loanTypeChangeRequest.options.model';
import LoanTypeChangeLoanDetailAndTypesmodel = require('./models/loanTypeChangeLoanDetailAndTypes.model');
import LoanTypeChangeLoanDetailAndTypes = LoanTypeChangeLoanDetailAndTypesmodel.LoanTypeChangeLoanDetailAndTypes;
import LoanTypeChangeBalanceDetailmodel = require('./models/loanTypeChangeBalanceDetail.model');
import LoanTypeChangeBalanceDetail = LoanTypeChangeBalanceDetailmodel.LoanTypeChangeBalanceDetail;
import {LoanTypeChangeRequestData} from './models/loanTypeChangeRequest.data.model';
import {LoanTypeChangeInterestCalculator} from './models/loanTypeChangeInterestCalculator.model';
import {LoanTypeChangeLoanAndPendingRequest} from './models/loanTypeChangeLoanAndPendingRequest.model';
import {LoanTypeChangeBusinessPartnerTerritory} from './models/LoanTypeChangeBusinessPartnerTerritory.model';

export class LoanTypeChangeRequestService {

    constructor(
        private $http: ng.IHttpService,
        private $q: ng.IQService) { }

    getSalesPeriods(): ng.IPromise<ng.IHttpPromiseCallbackArg<LoanTypeChangeRequestOptions>> {
        return this.$http.get('{API-ApprovalTool}/loan-type-change-requests/sales-periods/list');
    }

    getTerritories(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>> {
        return this.$http.get('{API-ApprovalTool}/loan-type-change-requests/create-options/territories');
    }

    getSalesAgencies(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change-requests/create-options/salesagencies-by-territory/${criteria.territory.orgId}`);
    }

    getOperations(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change-requests/create-options/operations-by-salesagency/${criteria.salesAgency.id}`);
    }

    getBusinessPartners(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<LoanTypeChangeBusinessPartnerTerritory[]>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change-requests/create-options/operation/${criteria.operation.id}/salesPeriod/${criteria.salesPeriod.salesPeriodId}/businesspartners`);
    }

    getLoanInfoAndType(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<LoanTypeChangeLoanAndPendingRequest>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change-requests/loan-info-and-type/customer/${criteria.businessPartner.id}/salesagencyid/${criteria.salesAgency.id}/territory/${criteria.businessPartner.territoryId}/salesyear/${criteria.salesPeriod.year}`);
    }

    getBalanceInfo(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<LoanTypeChangeBalanceDetail>> {
        return this.$http.get(`{API-ApprovalTool}/loan-type-change/customer-balance-detail/customers/${criteria.businessPartner.id}/salesyears/${criteria.salesPeriod.year}`);
    }

    getInterestAmount(criteria: RequestCriteria, options: LoanTypeChangeRequestOptions): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: LoanTypeChangeInterestCalculator = this.getInterestData(criteria, options);
        return this.$http.post(`{API-ApprovalTool}/loan-type-change/interest-loss-gain/`, data);
    }

    submit(criteria: RequestCriteria, options: LoanTypeChangeRequestOptions): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: LoanTypeChangeRequestData = this.getData(criteria, options);
        return this.$http.post(`{API-ApprovalTool}/requests/ltc/create`, data);
    }

    private getInterestData(criteria: RequestCriteria, options: LoanTypeChangeRequestOptions): LoanTypeChangeInterestCalculator {
        return new LoanTypeChangeInterestCalculator(
            options.loanData.interestRate,
            criteria.newLoanType.interestRate,
            options.balanceData.netAmount,
            options.balanceData.cropProtection,
            options.balanceData.encirca,
            options.balanceData.sellerService
        );
    }

    private getData(criteria: RequestCriteria, options: LoanTypeChangeRequestOptions): LoanTypeChangeRequestData {
        return new LoanTypeChangeRequestData(
            criteria.salesPeriod.salesPeriodId,
            criteria.salesPeriod.year,
            criteria.territory.orgId,
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.operation.id,
            criteria.operation.name,
            criteria.businessPartner.id,
            criteria.businessPartner.name,
            options.loanData.loanDetail.loanType,
            options.loanData.loanDetail.rate,
            options.loanData.interestRate,
            options.loanData.loanDetail.epsImpact,
            '',
            '',
            criteria.newLoanType.loanType,
            criteria.newLoanType.rate,
            criteria.newLoanType.interestRate,
            criteria.newLoanType.epsImpact,
            criteria.interestAmount,
            criteria.comment,
            new LoanTypeChangeBalanceDetail(options.balanceData.netAmount,
                options.balanceData.cropProtection,
                options.balanceData.encirca,
                options.balanceData.sellerService)
        );
    }
}