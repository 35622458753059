import {ISupportSearchCommissionAdvanceService} from './supportSearchCommissionAdvance.service.interface';
import {IApproverSearchModel} from '../approver/models/approverSearch.model.interface';
import {ISupportSearchCommissionAdvanceSearchResult} from './supportSearchCommissionAdvanceSearchResult.interface';

export default class SupportSearchCommissionAdvanceService implements ISupportSearchCommissionAdvanceService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<ISupportSearchCommissionAdvanceSearchResult>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/commission-advance`, data);
    }
}
