import './supportSearchAgencyBalanceExceptionV2.scss';

import SupportSearchAgencyBalanceExceptionServiceV2 from './supportSearchAgencyBalanceException.service';
Application.registerRepository('supportSearchAgencyBalanceExceptionServiceV2', SupportSearchAgencyBalanceExceptionServiceV2);

import SupportSearchAgencyBalanceExceptionControllerV2 from './supportSearchAgencyBalanceException.controller';
Application.registerController('SupportSearchAgencyBalanceExceptionControllerV2', SupportSearchAgencyBalanceExceptionControllerV2);

import SupportSearchPendingAgencyBalanceExceptionControllerV2 from './supportSearchPendingAgencyBalanceException.controller';
Application.registerController('SupportSearchPendingAgencyBalanceExceptionControllerV2', SupportSearchPendingAgencyBalanceExceptionControllerV2);

