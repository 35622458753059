export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/balanceExceptionRequests/v2', {
            template: require('./balanceExceptionRequests.html'),
            controller: 'BalanceExceptionPendingRequestsControllerV2',
            controllerAs: 'vm'
        })
        .when('/balanceExceptionRequests/v2/all', {
            template: require('./balanceExceptionRequests.html'),
            controller: 'BalanceExceptionAllPendingRequestsControllerV2',
            controllerAs: 'vm'
        })
        .when('/balanceExceptionRequests/v2/create/', {
            template: require('./createBalanceExceptionRequest.html'),
            controller: 'CreateBalanceExceptionRequestControllerV2',
            controllerAs: 'vm'
        });
}