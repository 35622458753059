import './infinityPackageOverrideNavigation.scss';

import InfinityPackageOverrideNavigationComponentController from './infinityPackageOverrideNavigation.controller';

export default class InfinityPackageOverrideNavigationComponent implements ng.IComponentOptions {
    template: string = require('./infinityPackageOverrideNavigation.html');
    controller: Function = InfinityPackageOverrideNavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new InfinityPackageOverrideNavigationComponent();
        };

        return component();
    }
}