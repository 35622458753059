import { IViewDocument } from "./viewDocument.model.interface"

export class ViewDocument implements IViewDocument {
    InvoiceId: number;
    ShareId: number;
    ProposalId: number;
    BusinessPartnerId: number;
    BusinessPartnerName: string;

    constructor(
        public invoiceId: number,
        public shareId: number,
        public proposalId: number,
        public businessPartnerId: number,
        public buinessPartnerName: string
    ) {
        this.InvoiceId = invoiceId;
        this.ShareId = shareId;
        this.ProposalId = proposalId;
        this.BusinessPartnerId = businessPartnerId;
        this.BusinessPartnerName = buinessPartnerName;
    }
}