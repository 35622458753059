import './proposalCpaRequests.scss';

import routing from './proposalCpaRequests.routes';
Application.registerConfig(routing);

import ProposalCpaRequestsController from './proposalcpaRequests.controller';
Application.registerController('ProposalCpaRequestsController', ProposalCpaRequestsController);

import ProposalCpaNavigationComponent from './navigation/proposalCpaNavigation.component';
Application.registerComponent('proposalCpaNavigation', ProposalCpaNavigationComponent.factory());

import ProposalCpaRequestsService from './proposalcpaRequests.service';
Application.registerRepository('proposalCpaRequestsService', ProposalCpaRequestsService);
