export class ShareGrossAndDiscountValue {

    constructor(
        public shareGrossProposalValue: number,
        public ShareRegularProposalValue: number,
        public discountAmount: number) {
        // no-op
    }

    static empty(): ShareGrossAndDiscountValue {
        return new ShareGrossAndDiscountValue(
            undefined,
            undefined,
            undefined);
    }
}