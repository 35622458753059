export default class BalanceExceptionNavigationComponentController  {
    // bindings
    page: string;

    constructor(
        private $location: ng.ILocationService) {
    }

    navToPending(): void {
        this.$location.url('/requestType-BalanceException/v2');
    }

    navToAllPending(): void {
        this.$location.url('/requestType-BalanceException/v2/all');
    }

    navToSearch(): void {
        this.$location.url('/balanceExceptionRequests/v2/search');
    }

    navToCreateRequest(): void {
        this.$location.url('/requestType-BalanceException/v2/create');
    }
}