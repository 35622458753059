import { BusinessPartnerPlanningModel } from './businessPartnerPlanning.model';

export default class TerritoryService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<BusinessPartnerPlanningModel>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/territories`);
    }
}
