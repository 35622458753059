export default class HistoryDialogController {
    
    constructor(
        public masterList: object[],
        private $mdDialog: ng.material.IDialogService) {
       
    }
    $onInit(): void {

    }
    cancel(): void {
        this.$mdDialog.cancel();
    }
    loyalStatus(loyal): string {
        return loyal ? 'Yes' : 'No';
    }
    activityType(type): string {
        switch (type) {
            case 5:
            case 100:
            case 105:
                return "Approved";
            case 101:
                return "Modified";
            case 3:
                return "Requested";
            default:
                return "Other";
        }
    }
    date(dateTime: string): string {
        return dateTime.split('T')[0];
    }
};