import { IBudgetInfoService } from './budgetInfo.service.interface';

export default class BudgetInfoComponentController {
    shareId: number;
    invoiceId: number;
    proposalId: number;

    showBudget: boolean;
    budgetInfo: any;

    showProductLineBudgetInfo: boolean;
    proposalFlexFundApprovalEnabled: boolean;
    proposalFlexFundApprovalRequired: boolean;

    constructor(
        private budgetInfoService: IBudgetInfoService,
        private $filter: ng.IFilterService,
        private $scope: ng.IScope) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.showBudget = false;

        $fsLDFeatureFlagService.isEnabledAsync('OZ.ProposalFlexFundApproval')
            .then((enabled) => {
                this.proposalFlexFundApprovalEnabled = enabled;
            });

        this.$scope.$watchGroup(['$ctrl.shareId', '$ctrl.invoiceId'], () => {
            this.getBudgetInfo();
        });

        
    }

    private getBudgetInfo() {
        if (this.shareId === undefined || this.invoiceId === undefined) {
            this.budgetInfo = null;
            return;
        }

        this.budgetInfoService.getBudgetInfo(this.shareId, this.invoiceId)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.budgetInfo = response.data;
                this.showProductLineBudgetInfo = this.budgetInfo.cpaProductLineBudgetModels.length > 0;
                this.getProposalApprovalAvailability();
            });
    }


    private getProposalApprovalAvailability() {
        if (this.proposalFlexFundApprovalEnabled && this.proposalId > 0) {
            this.budgetInfoService.getProposalFlexFundApprovalAvailability(this.proposalId)
                .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                    this.proposalFlexFundApprovalRequired = response.data;

                    if (this.proposalFlexFundApprovalRequired) {
                        this.budgetInfoService.getProposalCustomerFlexFundBudgetInfo(this.shareId, this.invoiceId, this.proposalId)
                            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                                let customerBudgetData = response.data;
                                this.transformCustomerBudgetData(customerBudgetData);
                            });
                    }
                });
        }
    }

    private transformCustomerBudgetData(data) {
        // Not opted for product line flex fund budgeting
        if (data.customerBudget !== null) { 

            this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.customer.rate = data.customerBudget.flexFundBudgetRate;
            this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.customer.amount = data.customerBudget.flexFundBudgetLimitValue;

            this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.customer.rate = data.customerBudget.flexFundImplementedRate;
            this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.customer.amount = data.customerBudget.flexFundImplemented;

            this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingBudget.customer.amount = data.customerBudget.flexFundRemaining;
            this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingBudget.customer.rate = data.customerBudget.flexFundRemainingRate

            this.budgetInfo.cpaBudgetModelFlexFunds.cpaPendingBudget.customer.amount = data.customerBudget.flexFundPending;
            this.budgetInfo.cpaBudgetModelFlexFunds.cpaPendingBudget.customer.rate = data.customerBudget.flexFundPendingRate;

            this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingPendingBudget.customer.amount = data.customerBudget.flexFundRemainingPending;
            this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingPendingBudget.customer.rate = data.customerBudget.flexFundRemainingPendingRate;

        }
        else if (data.customerProductLineFlexFundBudgetModel.length > 0) {
            var budgetDiscounts = [];
                
            data.customerProductLineFlexFundBudgetModel.forEach(function (item) {

                var productLineItem: any = {};
                productLineItem.productLineName = item.name;

                productLineItem.productLineFlexFundLimitRate = item.productLineFlexFundBudgetRate;
                productLineItem.productLineFlexFundLimit = item.productLineFlexFundBudgetLimitValue;

                productLineItem.productLineFlexFundImplementedRate = item.productLineFlexFundImplementedRate;
                productLineItem.productLineFlexFundImplemented = item.productLineFlexFundImplemented

                productLineItem.productLineFlexFundRemainingRate = item.productLineFlexFundRemainingRate;
                productLineItem.productLineFlexFundRemaining = item.productLineFlexFundRemaining;

                productLineItem.productLineFlexFundPendingRate = item.productLineFlexFundPendingRate;
                productLineItem.productLineFlexFundPending = item.productLineFlexFundPending;

                productLineItem.productLineFlexFundRemainingPendingRate = item.productLineFlexFundRemainingPendingRate;
                productLineItem.productLineFlexFundRemainingPending = item.productLineFlexFundRemainingPending;
                    

                budgetDiscounts.push(productLineItem);
            });

            this.budgetInfo.cpaProductLineBudgetModels = budgetDiscounts;
            this.showProductLineBudgetInfo = budgetDiscounts.length > 0;
        }            
    }

    public toggleBudget() {
        this.showBudget = !this.showBudget;
    }

    public formatCurrency(value: number) {
        return this.$filter('currency')(value);
    }

    public formatPercentage(value: number) {
        return (this.$filter('percentage') as Filter.IFilterPercentage)(value);
    }

    public highlightValue(budgetAmount: number, amount: number) {
        if (!this.budgetInfo) {
            return false;
        }

        // Highlight Implemented $ if higher than Budget $
        return amount > budgetAmount;
    }

    public highlightImplementedBusiness() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.customer.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.customer.amount;
    }

    public highlightImplementedBusinessProductLine() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.customer.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.customer.amount;
    }

    public highlightImplementedBusinessTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.customer.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.customer.amount;
    }

    public highlightBudgetRemainingBusiness() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingBudget.customer.amount < 0;
    }

    public highlightBudgetRemainingBusinessTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingBudget.customer.amount < 0;
    }

    public highlightPendingBusiness() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaPendingBudget.customer.amount + this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.customer.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.customer.amount;
    }

    public highlightPendingBusinessTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaPendingBudget.customer.amount + this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.customer.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.customer.amount;
    }

    public highlightBudgetRemainingPendingBusiness() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingPendingBudget.customer.amount < 0;
    }

    public highlightBudgetRemainingPendingBusinessTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingPendingBudget.customer.amount < 0;
    }

    public highlightImplementedAgency() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.salesAgency.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.salesAgency.amount;
    }

    public highlightImplementedAgencyTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.salesAgency.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.salesAgency.amount;
    }

    public highlightBudgetRemainingAgency() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingBudget.salesAgency.amount < 0;
    }

    public highlightBudgetRemainingAgencyTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingBudget.salesAgency.amount < 0;
    }

    public highlightPendingAgency() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaPendingBudget.salesAgency.amount + this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.salesAgency.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.salesAgency.amount;
    }

    public highlightPendingAgencyTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaPendingBudget.salesAgency.amount + this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.salesAgency.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.salesAgency.amount;
    }

    public highlightBudgetRemainingPendingAgency() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingPendingBudget.salesAgency.amount < 0;
    }

    public highlightBudgetRemainingPendingAgencyTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingPendingBudget.salesAgency.amount < 0;
    }

    public highlightImplementedTerritory() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.territory.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.territory.amount;
    }
    public highlightImplementedTerritoryTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.territory.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.territory.amount;
    }

    public highlightBudgetRemainingTerritory() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingBudget.territory.amount < 0;
    }

    public highlightBudgetRemainingTerritoryTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingBudget.territory.amount < 0;
    }

    public highlightPendingTerritory() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaPendingBudget.territory.amount + this.budgetInfo.cpaBudgetModelFlexFunds.cpaImplementedBudget.territory.amount > this.budgetInfo.cpaBudgetModelFlexFunds.cpaBudgets.territory.amount;
    }

    public highlightPendingTerritoryTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaPendingBudget.territory.amount + this.budgetInfo.cpaBudgetModelTotalDiscount.cpaImplementedBudget.territory.amount > this.budgetInfo.cpaBudgetModelTotalDiscount.cpaBudgets.territory.amount;
    }

    public highlightBudgetRemainingPendingTerritory() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelFlexFunds.cpaRemainingPendingBudget.territory.amount < 0;
    }

    public highlightBudgetRemainingPendingTerritoryTotalDiscount() {
        return this.budgetInfo && this.budgetInfo.cpaBudgetModelTotalDiscount.cpaRemainingPendingBudget.territory.amount < 0;
    }
}