import './supportSearchBalanceWriteOff.scss';

import SupportSearchBalanceWriteOffService from './supportSearchBalanceWriteOff.service';
Application.registerRepository('supportSearchBalanceWriteOffService', SupportSearchBalanceWriteOffService);

import SupportSearchBalanceWriteOffController from './supportSearchBalanceWriteOff.controller';
Application.registerController('SupportSearchBalanceWriteOffController', SupportSearchBalanceWriteOffController);

////import SupportSearchPendingAgencyBalanceExceptionController from './supportSearchPendingAgencyBalanceException.controller';
////Application.registerController('SupportSearchPendingAgencyBalanceExceptionController', SupportSearchPendingAgencyBalanceExceptionController);

