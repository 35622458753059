import {IRedirectService} from './redirect.service.interface';

export class RedirectService implements IRedirectService {

    constructor(private $location: ng.ILocationService, private $timeout: ng.ITimeoutService) {
        // noop
    }

    redirectToRoot() {
        this.redirectNow('/');
    }

    redirectToLogin(reason?: string) {
        if (reason) {
            this.redirectNow('/login/' + reason);
        } else {
            this.redirectNow('/login');
        }
    }

    redirectToSearch(): void {
        this.redirectNow('/search');
    }

    redirectToSupportRequests(): void {
        this.redirectNow('/support/requests');
    }

    redirectToBalanceWriteOffSearch(): void {
        this.redirectNow('/balanceWriteOffSearch');
    }

    redirectToRequestCPA(): void {
        this.redirectNow('/request');
    }

    // uses $timeout to force the action to happen on the next digest cycle, not whenever its mood is right
    private redirectNow(url: string) {
        this.$timeout(() => {
            this.$location.url(url);
        }, 0);
    }

    redirectToPendingRequestTypes(): void {
        this.redirectNow('/requestType');
    }

    redirectToAllPendingRequestTypes(): void {
        this.redirectNow('/requestType/all');
    }

    redirectToCPAPendingRequests(): void {
        this.redirectNow('/pendingcpa');
    }

    redirectToAllCPAPendingRequests(): void {
        this.redirectNow('/pendingcpa/all');
    }

    redirectToBalanceWriteOffPendingRequests(): void {
        this.redirectNow('/balanceWriteOffRequests');
    }

    redirectToAllBalanceWriteOffPendingRequests(): void {
        this.redirectNow('/balanceWriteOffRequests/all');
    }

    redirectToBalanceWriteOffCreateRequest(): void {
        this.redirectNow('/bwoRequest');
    }

    redirectToAgencyPlanning(): void {
        this.redirectNow('/businessPartnerPlanning/agencyPlanning');
    }

    redirectToAgencyPlanningPendingRequests(): void {
        this.redirectNow('/businessPartnerPlanning/pendingRequests');
    }
}
