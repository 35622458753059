angular
    .module('approval-tool')
    .factory(
        'appInsightsRequestService',
        [
            'appInsightsService',
            function (appInsightsService: any) {
                'use strict';

                const Key = 'telemetry-request';

                return {
                    getToken: function (headers: { [key: string]: any }): string {
                        if (headers === null || headers === undefined) {
                            throw new Error('headers must not be null or undefined.');
                        }

                        return headers[Key] || '00000000-0000-0000-0000-000000000000';
                    },

                    configure: function (headers: { [key: string]: any }): void {
                        headers[Key] = appInsightsService.generateToken();
                    }
                };
            }
        ]
    );
