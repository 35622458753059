import './customerSummary.scss';
import CustomerSummaryController from './customerSummary.controller';

export default class CustomerSummaryComponent implements ng.IComponentOptions {
    template: string = require('./customerSummary.html');
    controller: Function = CustomerSummaryController;

    bindings: { [binding: string]: string } = {
        customerSummary: '<',
        customerName: '<',
        isInseason: '<'
    };

    static factory() {
        const component = () => {
            return new CustomerSummaryComponent();
        };

        return component();
    }
}