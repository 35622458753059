import { RequestCriteria } from './models/request.criteria.model';
import { IRequestOptions } from './models/request.options.model.interface';
import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';
import { IRate } from './models/programRateReason/rate.model.interface';

import { IProgramsRatesReasonsResponse } from './models/programRateReason/programsRatesReasonsResponse.model.interface';

import { Discount } from './models/discount/discount.model';

import { RequestData } from './models/request.data.model';

export default class RequestService {

    constructor(
        private $http: ng.IHttpService,
        private $q: ng.IQService) { }

    getOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<IRequestOptions>> {
        return this.$http.get('{API-ApprovalTool}/requests/cpa/options');
    }

    getTerritories(): ng.IPromise<ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>> {
       return this.$http.get('{API-ApprovalTool}/requests/cpa/options/territories');
    }

    getSalesAgencies(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        const data: any = {
            salesPeriodId: criteria.salesSeason.salesPeriodId,
            territoryId: criteria.territory.orgId
        };

        return this.$http.post('{API-ApprovalTool}/requests/cpa/options/salesagencies', data);
    }

    getOperations(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        const data: any = {
            salesPeriodId: criteria.salesSeason.salesPeriodId,
            salesAgencyId: criteria.salesAgency.id
        };

        return this.$http.post('{API-ApprovalTool}/requests/cpa/options/operations', data);
    }

    getInvoicesShares(salesPeriodId: number, operationId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/cpa/options/salesperiods/${salesPeriodId}/operations/${operationId}/invoices/shares`);
    }

    getInvoicesSharesV2(salesPeriodId: number, operationId: number, salesAgencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/requests/cpa/options/salesperiods/${salesPeriodId}/operations/${operationId}/salesAgency/${salesAgencyId}/invoices/shares`);
    }

    getProgramsRatesReasons(salesPeriodId: number, operationId: number, shareId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<IProgramsRatesReasonsResponse>> {
        return this.$http.get(`{API-ApprovalTool}/requests/cpa/options/salesperiods/${salesPeriodId}/operations/${operationId}/shares/${shareId}/election/detail`);
    }

    getDiscountInformation(invoiceId: number, shareId: number, factor: IRate | number, competitivePriceAdjustmentId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Discount>> {
        factor = this.isRate(factor) ? factor.factor : factor;
        const data: any = { invoiceId, shareId, factor, competitivePriceAdjustmentId };

        return this.$http.post(`{API-ApprovalTool}/requests/cpa/discount`, data);
    }

    getDiscountInformationV2(invoiceId: number, shareId: number, factor: IRate | number, competitivePriceAdjustmentId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Discount>> {
        factor = this.isRate(factor) ? factor.factor : factor;
        const data: any = { invoiceId, shareId, factor, competitivePriceAdjustmentId };

        return this.$http.post(`{API-ApprovalTool}/requests/cpa/discount/v2`, data);
    }

    submit(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: RequestData = this.getData(criteria);

        return this.$http.post(`{API-ApprovalTool}/requests/cpa/create`, data);
    }

    private isRate(rate: IRate | number): rate is IRate {
        return (<IRate>rate).factor !== undefined;
    }

    private getRate(rate: IRate | number): number {
        return this.isRate(rate) ? rate.factor : rate;
    }

    private getData(criteria: RequestCriteria): RequestData {
        const factor: number = this.getRate(criteria.rate);

        return new RequestData(
            criteria.accountDescription.invoiceId,
            criteria.businessPartner.shareId,
            criteria.businessPartner.name,
            criteria.program.competitivePriceAdjustmentId,
            factor,
            criteria.reason.competitivePriceAdjustmentReasonId,
            criteria.comment,
            criteria.discount.score.operationPriorYearGrossInvoiceValue,
            criteria.discount.score.operationGrossInvoiceValue,
            criteria.discount.score.operationProfiledAcres,
            criteria.discount.score.operationInvoiceAcres,
            criteria.discount.score.shareGrossInvoiceValue,
            criteria.discount.score.shareElectiveDiscountValue,
            criteria.discount.discountValue,
            criteria.territory.orgId,
            criteria.operation.id,
            criteria.operation.name,
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.accountDescription.name,
            criteria.businessPartner.businessPartnerId,
            criteria.program.name,
            criteria.salesSeason.year,
            criteria.salesSeason.seasonId,
            new Date(),
            criteria.program.productLineId,
            criteria.program.calculationId,
            criteria.reason.name,
            angular.isDefined(criteria.program.impactsBudget) && criteria.program.impactsBudget
        );
    }
}