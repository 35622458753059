import {ISupportSearchReplantService} from './supportSearchReplant.service.interface';

export default class SupportSearchReplantService implements ISupportSearchReplantService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getReplantSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/replant/replacementlineitems`, data);
    }
}
