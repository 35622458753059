export default function themeConfig($mdThemingProvider: ng.material.IThemingProvider) {
    $mdThemingProvider.definePalette('customGrey',
            $mdThemingProvider.extendPalette('grey',
                {
                    'A100': '#ffffff'
                }))
        .theme('default')
        .primaryPalette('blue',
            {
                'default': '500', // by default use shade 500 from the blue palette for primary intentions
                'hue-1': '800', // use shade 800 for the <code>md-hue-1</code> class
                'hue-2': '400', // use shade 300 for the <code>md-hue-2</code> class
                'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
            })
        .accentPalette('light-blue',
            {
                'default': '900', // by default use shade A700 from the light-green palette for primary intentions
                'hue-1': '50', // use shade A400 for the <code>md-hue-1</code> class
                'hue-2': '100', // use shade A200 for the <code>md-hue-2</code> class
                'hue-3': '800' // use shade A100 for the <code>md-hue-3</code> class
            })
        .backgroundPalette('customGrey');
}
