import { OrderedDiscountLine } from './models/orderedDiscount.line.model';
import { ShareDiscountV2 } from './models/shareDiscountV2.model';

export default class DiscountsV3ComponentController {
    shareDiscounts: ShareDiscountV2;
    year: number;
    salesAgencyName: string;
    discountLines: OrderedDiscountLine[];

    previousYear: number;
    growthRetentionText: string;
    showDetails: boolean;
    showTotalDetails: boolean;
    showProgress: boolean;

    constructor(
        private $filter: ng.IFilterService) { }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.previousYear = this.year - 1;
        this.showDetails = false;
        this.showTotalDetails = false;
        this.showProgress = true;
    }

    private $onChanges(changes: any) {
        if (angular.isDefined(changes.shareDiscounts.currentValue)) {
            this.showProgress = false;
        }
    }

    public toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    public toggleTotalDetails() {
        this.showTotalDetails = !this.showTotalDetails;
    }

    public showDashes(percentage: number): boolean {
        return percentage === 0;
    }

    public formatFactor(calculationId: number, factor: number): string {
        if (calculationId === 1) {
            return (this.$filter('percentage') as Filter.IFilterPercentage)(factor);
        }

        if (calculationId === 2) {
            return this.$filter('currency')(factor, '$') + ' per unit';
        }

        return '';
    }
}