export function errorInterceptor(
    $q: ng.IQService,
    $exceptionHandler: ng.IExceptionHandlerService) {

    const interceptor: ng.IHttpInterceptor = {
        responseError: (rejection: any) => {

            let error: any;

            if (rejection instanceof Error) {
                error = rejection;
            } else {
                const config = rejection.config || { url: 'unknown', method: 'unknown', data: 'unknown' };
                error = {
                    status: rejection.status,
                    message: rejection.status ? rejection.status + ' ' + rejection.statusText : rejection.data,
                    locationHref: window.location.href,
                    httpUri: rejection.url || config.url,
                    httpMethod: rejection.method || config.method,
                    submittedData: config.data,
                    returnedData: rejection.data
                };
            }

            $exceptionHandler(error);
            return $q.reject(rejection);
        }
    };

    return interceptor;

}