import {IBudgetInfoService} from './budgetInfo.service.interface';

export default class BudgetInfoService implements IBudgetInfoService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }  

    getBudgetInfo(shareId: number, invoiceId: number): ng.IPromise<any> {
        const target: string = `{API-ApprovalTool}/budget-info/share/${shareId}/invoice/${invoiceId}`;

        return this.$http.get(target);
    }

    getProposalFlexFundApprovalAvailability(proposalId: number): ng.IPromise<any> {
        const response: string = `{API-ApprovalTool}/proposalcpa/proposal/${proposalId}/eligibility`;

        return this.$http.get(response);
    }

    getProposalCustomerFlexFundBudgetInfo(shareId: number, invoiceId: number, proposalId: number): ng.IPromise<any> {
        const response: string = `{API-ApprovalTool}/budget-info/invoice/${invoiceId}/share/${shareId}/proposal/${proposalId}`;
        return this.$http.get(response);
    }

}