import { IWindowService } from 'angular';

angular.module('approval-tool')
    .factory('appInsightsService', ['$window', function ($window: IWindowService) {
        function generateToken(): string {
            let d = new Date().getTime();
            const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });

            return uuid;
        }

        function setToken(key: string, uuid: string): void {
            $window.sessionStorage.setItem(key, uuid);
        }

        function setAuthenticatedUser(): void {
            const upn = $window.sessionStorage.getItem('upn');
            const impersonate = $window.sessionStorage.getItem('Impersonate');
            const userName = `${upn}-${impersonate}`;

            if (userName && $window.appInsights && $window.appInsights.setAuthenticatedUserContext) {
                $window.appInsights.setAuthenticatedUserContext(userName);
            }
        }

        function setAppInsightSessionId(): void {
            const key = 'telemetry-session';
            let token = $window.sessionStorage.getItem(key);

            if (!token) {
                token = generateToken();
                setToken(key, token);
            }

            const sessionIdConnex = token;

            if ($window.appInsights && $window.appInsights.context) {
                $window.appInsights.context.session.id = sessionIdConnex;
            }
        }

        function setAppInsightMetaData(): void {
            setAuthenticatedUser();
            setAppInsightSessionId();
        }

        function checkForSrcInHeader(src: string): boolean {
            const scripts = document.head.getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src === src) {
                    return false;
                }
            }

            return true;
        }

        return {
            generateToken: generateToken,

            generateAndSetToken: function (key: string): void {
                let token = $window.sessionStorage.getItem(key);

                if (!token) {
                    token = generateToken();
                    setToken(key, token);
                }
            },

            trackException: function (error: Error): void {
                if ($window.appInsights && $window.appInsights.trackException) {
                    $window.appInsights.trackException({
                        exception: error.message,
                        properties: error || {}
                    });

                } else {
                    console.warn('AppInsights is not initialized. Exception not tracked.');
                }
            },

            configureAppInsights: function (): void {
                const instrumentationKey = document.head.querySelector('meta[name=app-insights-instrumentation-key]');

                if (instrumentationKey) {
                    $window.appInsights = $window.appInsights || {};
                    $window.appInsights.config = {
                        instrumentationKey: instrumentationKey ? instrumentationKey.getAttribute('content') : '',
                        enableAutoRouteTracking: true
                    };

                    const src = 'https://js.monitor.azure.com/scripts/b/ai.2.min.js';

                    const ai = document.createElement('script');
                    // To avoid loading the CDN script multiple times, 
                    if (checkForSrcInHeader(src)) {
                        ai.src = src;
                        document.head.appendChild(ai);
                    }
                    ai.onload = function () {
                        setAppInsightMetaData();
                    }                    
                } else {
                    console.warn('Instrumentation key is missing.');
                }
            },

            setAppInsightMetaData: setAppInsightMetaData,
            setAuthenticatedUser: setAuthenticatedUser,
            setAppInsightSessionId: setAppInsightSessionId
        };
    }]);
