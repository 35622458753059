interface IFixedHeaderDirectiveScope extends ng.IScope {
    buffer: string;
    container: string;
}

interface IHTMLElement extends HTMLElement {
    closest(selectors: string): IHTMLElement;
}

export default class FixedHeaderDirective implements ng.IDirective {
    restrict: string = 'A';
    scope: Object = {
        buffer: '@',
        container: '@'
    };

    offsetTop: number = 0;

    constructor(private $document: ng.IDocumentService) { }

    link = (scope: IFixedHeaderDirectiveScope, instanceElement: ng.IAugmentedJQuery, instanceAttributes: ng.IAttributes) => {
        const element: IHTMLElement = instanceElement[0] as IHTMLElement;
        const container: IHTMLElement = element.closest(scope.container);

        const buffer: number = parseInt(scope.buffer);

        // add event handlers
        container.addEventListener('scroll', (event: Event) => this.fixElementPosition(event, instanceElement, buffer));

        // remove event handlers
        scope.$on('$destroy', () => container.removeEventListener('scroll', (event: Event) => this.fixElementPosition));
    };

    private fixElementPosition(event: Event, instanceElement: ng.IAugmentedJQuery, buffer: number): EventListenerObject {
        const element: IHTMLElement = instanceElement[0] as IHTMLElement;
        const container: IHTMLElement = event.srcElement as IHTMLElement;

        const elementWidth: number = element.clientWidth;
        const scrollTop: number = container.scrollTop;
        const elementOffsetTop: number = this.getOffsetTop(element.getBoundingClientRect().top);

        if (this.checkElementPosition(elementOffsetTop, scrollTop, buffer)) {
            instanceElement.addClass('fixed');
            instanceElement.css('width', `${elementWidth}px`);
        }
        else {
            instanceElement.removeClass('fixed');
            instanceElement.css('width', '');
        }

        return;
    }

    private checkElementPosition(elementOffsetTop: number, containerScrollTop: number, buffer: number): boolean {
        return (containerScrollTop + buffer) > elementOffsetTop;
    }

    private getOffsetTop(elementOffsetTop: number): number {
        this.setOffsetTop(elementOffsetTop);
        return elementOffsetTop > this.offsetTop ? elementOffsetTop : this.offsetTop;
    }

    private setOffsetTop(elementOffsetTop: number): void {
        elementOffsetTop > this.offsetTop ? this.offsetTop = elementOffsetTop : -1;
    }

    static factory(): ng.IDirectiveFactory {
        const directive = ($document: ng.IDocumentService) => {
            return new FixedHeaderDirective($document);
        };

        return directive;
    }
}