import { IInfinityPackageOverrideComment } from './infinityPackageOverrideComment.model.interface';
import { IInfinityPackageOverrideCommentsService } from './infinityPackageOverrideComments.service.interface';

export default class InfinityPackageOverrideCommentsService implements IInfinityPackageOverrideCommentsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveComment(comment: IInfinityPackageOverrideComment): ng.IPromise<any> {
        const uri: string = `{API-ApprovalTool}/requests/packageoverride/comments/save`;

        return this.$http.post(uri, comment);
    }
}