import { BusinessPartnerLineModel } from '../../agencySummary/businessPartnerLine.model';

export default class PlanningCustomerDetailsSearchController {

    constructor(private customers: BusinessPartnerLineModel[], private customerId: any, private searchName: string) {

    }

    cancel = function () {
        this.mdPanelRef.searchName = this.searchName;
        this.mdPanelRef.close();

    };

    close = function (customerId) {

        this.mdPanelRef.customerId = customerId;
        this.mdPanelRef.searchName = this.searchName;
        this.mdPanelRef.close();
    }

}