import './proposalCpaNavigation.scss';

import ProposalCpaNavigationComponentController from './proposalCpaNavigation.controller';

export default class ProposalCpaNavigationComponent implements ng.IComponentOptions {
    template: string = require('./proposalCpaNavigation.html');
    controller: Function = ProposalCpaNavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new ProposalCpaNavigationComponent();
        };

        return component();
    }
}