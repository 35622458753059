import AgencyBalanceSummaryComponentController from './agencyBalanceSummary.controller';

export default class AgencyBalanceSummaryComponentV2 implements ng.IComponentOptions {
    template: string = require('./agencyBalanceSummary.html');
    controller: Function = AgencyBalanceSummaryComponentController;
    bindings: { [binding: string]: string } = {
        salesYear: '<',
        salesPeriodId: '<',
        agencyId: '<',
        requestId: '<',
        requestStatusId: '<'
    };

    static factory() {
        const component: () => ng.IComponentOptions = () => {
            return new AgencyBalanceSummaryComponentV2();
        };

        return component();
    }
}