import { ReplantComment} from './replantComment.model';

export default class ReplantCommentsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveComment(comment: ReplantComment): ng.IPromise<any> {
        const uri: string = `{API-ApprovalTool}/replant/request-comments`;

        return this.$http.post(uri, comment);
    }
}