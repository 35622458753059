import UserSearchRepository from '../userSearch.repository';
import {IImpersonatedUserRepository} from './impersonatedUser/impersonatedUser.repository.interface';
import {IRedirectService} from '../../../services/redirect.service.interface';
import {IStorageService} from '../../../services/storage.service.interface';
import {IHeaderService} from '../../../services/header.service.interface';
import {StorageKeyEnum} from '../../../services/storageKey.enum';
import {HeaderKeyEnum} from '../../../services/headerKey.enum';
import {ImpersonateUser} from '../impersonateUser.model';

export default class ImpersonateDialogController {
    selectedItem: any = null;
    searchText: string = null;

    querySearch: (query: string) => ng.IPromise<any> = this.querySearchImpl;

    constructor(
        private $q: ng.IQService,
        private $mdDialog: ng.material.IDialogService,
        private userSearchRepository: UserSearchRepository,
        private impersonatedUserRepository: IImpersonatedUserRepository,
        private redirectService: IRedirectService,
        private storageService: IStorageService,
        private headerService: IHeaderService) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        if (this.storageService.exists(StorageKeyEnum.Impersonate)) {
            this.selectedItem = this.storageService.get(StorageKeyEnum.Impersonate);
        }
    }

    answer(user: ImpersonateUser): void {
        if (user) {
            this.$mdDialog.hide();
            this.impersonateUser(user);
        }
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    clear(): void {
        this.searchText = null;
        this.$mdDialog.hide();

        this.clearImpersonation();
    }

    querySearchImpl(query: string): ng.IPromise<any> {

        const deferred: ng.IDeferred<boolean> = this.$q.defer();

        this.userSearchRepository.search(query)
            .then((response: any) => deferred.resolve(response.data));

        return deferred.promise;
    }

    private impersonateUser(user: ImpersonateUser): void {
        if (user) {
            const promises: ng.IPromise<any>[] = [
                this.headerService.set(HeaderKeyEnum.Impersonate, user.id),
                this.storageService.set(StorageKeyEnum.Impersonate, user)
            ];
            this.$q.all(promises).then(() => {

                this.impersonatedUserRepository.getUser()
                    .then((response) => {
                        this.storageService.set(StorageKeyEnum.ImpAuthInfo, response.data.authInfo);

                        if (response.data.authInfo.useSupportPage) {
                            this.redirectService.redirectToSupportRequests();
                        } else {
                            this.redirectService.redirectToPendingRequestTypes();
                        }
                    });
            });
        }
    }

    private clearImpersonation(): void {
        const promises: ng.IPromise<any>[] = [
            this.storageService.remove(StorageKeyEnum.Impersonate),
            this.storageService.remove(StorageKeyEnum.ImpAuthInfo),
            this.headerService.delete(HeaderKeyEnum.Impersonate)
        ];
        this.$q.all(promises).then(() => {
            this.redirectService.redirectToSupportRequests();
        });
    }
}