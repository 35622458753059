import {IComment} from './comment.model.interface';
import {ICommentsService} from './comments.service.interface';

export default class CommentsService implements ICommentsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveComment(comment: IComment, requestType: string): ng.IPromise<any> {
        const target: string = `{API-ApprovalTool}/requests/${requestType}/comments/save`;

        return this.$http.post(target, comment);
    }
}