import { PlanningPendingRequestDetailModel } from './planningPendingRequestDetail.model';

export default class PlanningPendingRequestDetailService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getPreseasonList(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<PlanningPendingRequestDetailModel>>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/pending-territory-requests/pre-season/detail/${requestId}`);
    }

    getInseasonList(territoryId: string): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<PlanningPendingRequestDetailModel>>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/pending-territory-requests/in-season/detail/${territoryId}`);
    }

    getSalesSupportList(requestType: string, territoryId: string, salesYear: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<PlanningPendingRequestDetailModel>>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/pending-requests/detail/${requestType}/${territoryId}/${salesYear}`);
    }

    approveRequest(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/requests/customer/${requestId}/approve`, {});
    }

    denyRequest(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/bpp/requests/customer/${requestId}/deny`, {});
    }
}
