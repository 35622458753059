import OperationSalesController from './operation.sales.controller';

Application.registerController('OperationSalesController', OperationSalesController);

export default class OperationSalesComponent implements ng.IComponentOptions {
    template: string = require('./operation.sales.html');
    bindings: { [binding: string]: string } = {
        operation: '<',
        year: '<',
        name: '<'
    };
    controller: Function = OperationSalesController;

    static factory() {
        const component: Function = () => {
            return new OperationSalesComponent();
        };

        return component();
    }
}