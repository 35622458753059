import {IRedirectService} from '../services/redirect.service.interface';
import {IUserSessionService} from './userSession.service.interface';
import {AuthenticatedUserService} from './authenticatedUser.service';

export class UserSessionService implements IUserSessionService {

    private timerIntervalInSeconds: number = 5;
    private timer: number = null;
    private redirecting: boolean = false;

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private redirectService: IRedirectService) {

        this.startCheckingForTimedOutSession();
    }

    public keepAlive(): boolean {
        /// <returns type="bool">Returns true if session is active, false if session is not active</returns>
        if (!this.authenticatedUserService.isSessionActive) {
            this.redirectToLogin();
            return false;
        } else {
            this.authenticatedUserService.resetSessionActiveStartTime();
            this.startCheckingForTimedOutSession();
            return true;
        }
    }

    private invalidateUser() {
        this.authenticatedUserService.sessionTimedOut = true;
        this.authenticatedUserService.invalidateCurrentUser();
    }

    private preventUserClick(): void {
        if (!event) {
            return;
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
    }

    private redirectToLogin(): void {
        this.invalidateUser();
        this.preventUserClick();

        clearInterval(this.timer);
        this.timer = null;
        this.redirectService.redirectToLogin('timeout');
    }

    private onCheckTimedOutSession(): void {
        if (!this.authenticatedUserService.isSessionActive) {
            this.redirectToLogin();
        }
    }

    private startCheckingForTimedOutSession(): void {
        this.onCheckTimedOutSession();

        if (!this.timer) {
            this.timer = setInterval(
                this.onCheckTimedOutSession.bind(this),
                this.timerIntervalInSeconds * 1000);
        }
    }
}
