import {ICpaRequest} from '../cpaRequest.model.interface';

export default class CpaDetailRepository {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    approve(request: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/cpa/requests/${request.requestId}/approve`;
        const data: {} = this.approvalToRequestModel(request);

        return this.$http.post(url, data);
    };

    deny(request: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/cpa/requests/${request.requestId}/deny`;
        const data: {} = this.approvalToRequestModel(request);

        return this.$http.post(url, data);
    };

    withdraw(request: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/cpa/requests/${request.requestId}/withdraw`;
        const data: {} = this.approvalToRequestModel(request);

        return this.$http.post(url, data);
    };

    isPermissionStopDatePassed(request: ICpaRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/requests/competitivepriceadjustment/startstop/permissions/${request.organizationalUnitId}`;

        return this.$http.get(url);
    };

    hasInvoiceMoved(invoiceId: number): ng.IPromise<any> {
        return this.$http.get(`{API-ApprovalTool}/invoicemove/invoices/${invoiceId}/moved`)
            .then((response) => response.data);
    }

    private approvalToRequestModel(request: ICpaRequest): any {
        return {
            invoiceId: request.invoice.id,
            shareId: request.share.id,
            electionId: request.competitivePriceAdjustmentElectionId,
            productLine: request.productLineId
        };
    };
}