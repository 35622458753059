import { DoaOverrideRequest } from './doaOverride.model';
import { IDoaOverrideService } from './doaOverride.service.interface';

export default class DoaOverrideService implements IDoaOverrideService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    get(): ng.IPromise<Array<DoaOverrideRequest>> {
        return this.$http.get<Array<DoaOverrideRequest>>('{API-ApprovalTool}/doa-override')
            .then((response) => {
                return response.data;
            });
    }

    upsert(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.put(`{API-ApprovalTool}/doa-override`, request);
    }

    delete(request: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.delete(`{API-ApprovalTool}/doa-override`,
            {
                data: request,
                headers: { 'Content-Type': 'application/json' }
            });
    }
}
