import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';
import { ReplantRequest } from '../replantRequest.model';

import { ReplantDetail } from './replantDetail.model';
import ReplantDetailService from './replantDetail.service';
//import { ShareDiscountV2 } from '../../components/discounts/models/shareDiscountV2.model';
import DiscountsService from '../../components/discounts/discounts.service';
import IPromise = angular.IPromise;
import { IReplantRequestsService } from '../replantRequests.service.interface';

export default class ReplantDetailController {

    loading: boolean = true;

    request: ReplantRequest;
    detail: ReplantDetail = ReplantDetail.empty();
    //shareDiscounts: ShareDiscountV2;
    //finalApprovalDate: string = '';
    user: IUser;
    //regularInvoiceValue: number;
    //doubleCropReplantInvoiceValue: number;

    shareCurrentDiscounts: any;
    customerPreviousDiscounts: any;
    customerCurrentDiscounts: any;

    allowComment: boolean = false;
    isPermissionStopDatePassed: boolean = false;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private requestLocal: ReplantRequest,
        private replantRequestsService: IReplantRequestsService,
        private userService: IUserService,
        private replantDetailService: ReplantDetailService,
        private discountsService: DiscountsService
    ) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): IPromise<void> {
        this.request = this.requestLocal;
        this.user = this.userService.get();
        this.allowComment = this.user.canApproveCpaRequests;

        return this.getDetail()
            .finally(() => this.done());
    }

    approve(): void {
        this.request.processing = true;

        this.replantRequestsService.approve(this.request)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;

        this.replantRequestsService.deny(this.request)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => this.request.processing = false);
    }

    canApprove(): boolean {
        return this.user.canApprove && this.request.canApprove && !this.request.processing;
    }

    canDeny(): boolean {
        return this.user.canApprove && this.request.canDeny && !this.request.processing;
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    isPending(status: string): boolean {
        return status === 'Pending';
    }

    private setActionPermissions(canApprove: boolean, canDeny: boolean): void {
        this.request.canApprove = canApprove;
        this.request.canDeny = canDeny;
    }

    private getDetail() {

        /*this.getDiscounts();*/

        return this.replantDetailService.getDetail(this.request.requestId)
            .then((detail: ReplantDetail) => {
                this.detail = detail;
            });
    }

    // leaving for potential future use
    //private getDiscounts() {

    //    this.discountsService.getCustomerCurrentDiscountsV2(this.request.shareId)
    //        .then((currentCustomerDiscounts: any) => {
    //            this.customerCurrentDiscounts = currentCustomerDiscounts;
    //        });

    //    this.discountsService.getCpaShareCurrentDiscounts(0, 
    //        this.request.invoice.id,
    //        this.request.shareId)
    //        .then((shareCurrentDiscounts: any) => {
    //            this.shareCurrentDiscounts = shareCurrentDiscounts;
    //            this.regularInvoiceValue = this.shareCurrentDiscounts.regularInvoiceTotal;
    //            this.doubleCropReplantInvoiceValue = this.shareCurrentDiscounts.doubleCropReplantInvoiceTotal;
    //        });

    //    return this.discountsService.getCustomerPreviousDiscounts2020V2(this.request.shareId)
    //            .then((previousCustomerDiscounts: any) => {
    //                this.customerPreviousDiscounts = previousCustomerDiscounts;
    //            });
    //}
 
    private done(): void {
        this.loading = false;
    }
}