import BalanceExceptionTypeComponentController from './exceptionType.controller';

export default class BalanceExceptionTypeComponentV2 implements ng.IComponentOptions {
    template: string = require('./exceptionType.html');
    controller: Function = BalanceExceptionTypeComponentController;
    bindings: { [binding: string]: string } = {
        formElement: '<',
        formType: '@',
        loading: '<',
        options: '<',
        canSelect: '<',
        selected: '<',
        onOpen: '&',
        onSelect: '&'
    };

    static factory() {
        const component: () => ng.IComponentOptions = () => {
            return new BalanceExceptionTypeComponentV2();
        };

        return component();
    }
}