import { IAgencyDiscountMasterService } from "./AgencyDiscountMaster.service.interface";

export default class AgencyDiscountMasterService implements IAgencyDiscountMasterService {
    constructor(private $http: ng.IHttpService, private $timeout) {
        // noop
    }
    getPermissions(): ng.IPromise<ng.IHttpPromiseCallbackArg<object>> {
        return this.$http.get(`{API-ApprovalTool}/agency-discount/agency-view-permissions`);
    }
    getSalesAgencies(salesPeriodId: number) {
        return this.$http.get(`{API-ApprovalTool}/agency-discount/agencies`);
    }
    getList(agencyId: any, salesPeriodId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        let url: string = `{API-ApprovalTool}/agency-discount/budget-discounts-for-approval/agencies/${agencyId}/sales-periods/${salesPeriodId}`;
        return this.$http.get(url);
    }
    getListV2(agencyId: any, salesPeriodId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        let url: string = `{API-ApprovalTool}/agency-discount/budget-discounts-for-approval/agencies/${agencyId}/sales-periods/${salesPeriodId}/V2`;
        return this.$http.get(url);
    }
    getRequestHistory(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        let url: string = `{API-ApprovalTool}/agency-discount/discount-history/requests/${requestId}`;
        return this.$http.get(url);
    }
    approve(agencyId: number, salesPeriodId: number, masterList: object[], operationIds: number[]) {
        let url: string = `{API-ApprovalTool}/agency-discount/agencies/${agencyId}/sales-periods/${salesPeriodId}/approve`;
        return this.$http.post(url, { MasterList: masterList, OperationIds: operationIds});
    }
    approveV2(agencyId: number, salesPeriodId: number, masterList: object[], operationIds: number[]) {
        let url: string = `{API-ApprovalTool}/agency-discount/agencies/${agencyId}/sales-periods/${salesPeriodId}/approve/V2`;
        return this.$http.post(url, { MasterList: masterList, OperationIds: operationIds});
    }
    approveV3(agencyId: number, salesPeriodId: number, masterList: object[], operationIds: number[]) {
        let url: string = `{API-ApprovalTool}/v3/agency-discount/agencies/${agencyId}/sales-periods/${salesPeriodId}/approve`;
        return this.$http.post(url, { MasterList: masterList, OperationIds: operationIds });
    }
    deny(agencyId: number, salesPeriodId: number, operationIds: number[]) {
        let url: string = `{API-ApprovalTool}/agency-discount/agencies/${agencyId}/sales-periods/${salesPeriodId}/deny`;
        return this.$http.post(url, operationIds);
    }
    getSupportSearchResults(searchCriteria: string) {
        let criteria = `"${searchCriteria}"`;
        let url: string = `{API-ApprovalTool}/agency-discount/supportsearch`;
        return this.$http.post(url, criteria);
    }
    getOperationsBySalesAgencyAndSalesPeriod(selectedSalesAgencyId: number, selectedSalesPeriodId) {
        let url: string = `{API-ApprovalTool}/agency-discount/operations/agencies/${selectedSalesAgencyId}/sales-periods/${selectedSalesPeriodId}`;
        return this.$http.get(url);
    }
    getAgencyBudgetThreshold(salesAgency, salesPeriod) {
        let url: string = `{API-ApprovalTool}/agency-discount/budget-threshold/sales-agencies/${salesAgency}/sales-periods/${salesPeriod}`
        return this.$http.get(url);
    }
    getAgencyBudgetUsed(salesAgency, salesPeriod, requestIdsToExclude) {
        let url: string = `{API-ApprovalTool}/agency-discount/budget-threshold/sales-agencies/${salesAgency}/sales-periods/${salesPeriod}/used`
        return this.$http.post(url, { requestIdsToExclude: requestIdsToExclude });
    }
    getFlexFundBudgetEstimatedValue(budgetDetails) {
        let url: string = `{API-ApprovalTool}/agency-discount/flex-fund-budgets/estimated-value`
        return this.$http.post(url, budgetDetails);
    }
    getProductLineFlexFundBudgetEstimatedValue(budgetDetails) {
        let url: string = `{API-ApprovalTool}/agency-discount/product-line-budgets/estimated-value`
        return this.$http.post(url, budgetDetails);
    }
}