import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';


export default class SampleNavigationComponentController {
    // bindings
    page: string;

    user: IUser;

    constructor(private userService: IUserService, private $location: ng.ILocationService, private $timeout: ng.ITimeoutService) { }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    navigateToPending(): void {
        this.redirectNow('/sample/pendingRequests');
    }

    navigateToSearch(): void {
        this.redirectNow('/sample/search');
    }

    private redirectNow(url: string) {
        this.$timeout(() => {
            this.$location.url(url);
        }, 0);
    }
}