import {ISampleComment} from './sampleComment.model.interface';

export class SampleComment implements ISampleComment {

    constructor(
        public requestShareId: number,
        public comment: string,
        public requestShareCommentId: number = 0,
        public displayName: string = '',
        public commentDate: string = '1900-01-01'
    ) { }
}