import BalanceExceptionApproverSearchServiceV2 from './balanceExceptionApproverSearch.service';

import BalanceExceptionRequestDetailsControllerV2 from '../../agencyBalanceExceptionV2/balanceExceptionRequestDetails.controller';

import {ApproverSearchOptions} from './models/approverSearch.options.model';
import {AgencyBalanceExceptionApproverSearchModel} from './models/agencyBalanceExceptionApproverSearch.model';

import { RequestLine } from './../../agencyBalanceExceptionV2/core/requestLine.model';
import { RequestStatus } from './../../agencyBalanceExceptionV2/core/requestStatus.model';

import {IdAndName} from '../../../../core/models/idAndName.model';
import {OrgIdAndName} from './models/orgIdAndName.model';

import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';

import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

// temporary until newer angular-material.d.ts is integrated into project
interface ISidenavService extends ng.material.ISidenavService {
    (component: string, enableWait: boolean): angular.IPromise<ng.material.ISidenavObject>;
}

export default class BalanceExceptionApproverSearchControllerV2 {

    maximumRowsToDisplay: number = 200;

    processing: boolean = false;
    exporting: boolean = false;
    searching: boolean = false;
    requests: Array<RequestLine>;
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;
    canRequest: boolean = false;

    sideNavComponentId: string = 'search';

    options: ApproverSearchOptions;
    criteria: AgencyBalanceExceptionApproverSearchModel = {
        areas: [],
        commercialUnits: [],
        exceptionStatuses: [],
        exceptionTypes: [],
        salesAgencies: [],
        salesPeriods: [],
        territories: [],
        districts: []
    };

    selectedArea: OrgIdAndName = undefined;
    selectedCommercialUnit: OrgIdAndName = undefined;

    typesSearchTerm: string = '';
    areasSearchTerm: string = '';
    commercialUnitSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    territoriesSearchTerm: string = '';

    loading: {
        areas: boolean;
        commercialUnits: boolean;
        exceptionTypes: boolean;
        options: boolean;
        salesAgencies: boolean;
        territories: boolean;
    } = {
        areas: false,
        commercialUnits: false,
        exceptionTypes: false,
        options: false,
        salesAgencies: false,
        territories: false
    };

    changed: {
        areas: boolean;
        commercialUnits: boolean;
        territories: boolean;
    } = {
        areas: false,
        commercialUnits: false,
        territories: false
    };

    noOptions: string = 'No results';

    label: {
        areas: string;
        commercialUnits: string;
        exceptionTypes: string;
        salesAgencies: string;
        territories: string;
    } = {
        areas: 'Area',
        commercialUnits: 'Commercial unit',
        exceptionTypes: 'Exception Types',
        salesAgencies: 'Sales agencies',
        territories: 'Territories'
    };

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $mdSidenav: ISidenavService,
        private $mdMedia: ng.material.IMedia,
        private $document: ng.IDocumentService,
        private $timeout: ng.ITimeoutService,
        private balanceExceptionApproverSearchServiceV2: BalanceExceptionApproverSearchServiceV2,
        private userService: IUserService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.loading.options = true;

        const user: IUser = this.userService.get();
        this.canRequest = user.canCreateAgencyBalanceExceptions;

        if (this.$mdMedia('max-width: 1280px')) {
            this.openSearchNavAsync();
        }

         this.balanceExceptionApproverSearchServiceV2.getSearchOptions()
             .then((response: ng.IHttpPromiseCallbackArg<ApproverSearchOptions>) => {
                 this.processOptionsResponse(response.data);
                 this.activateSalesPeriods();
             })
             .finally(() => {
                 this.setHandlers();
                 this.loading.options = false;
            });
    }

    canSearch(): boolean {
        return this.criteria.salesPeriods && this.criteria.salesPeriods.length > 0;
    }

    onSearch(): void {
         this.searching = true;

         (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

         this.balanceExceptionApproverSearchServiceV2.getSearchResults(this.criteria)
             .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.processResponse(response.data);

                if (this.$mdSidenav(this.sideNavComponentId).isOpen()) {
                    this.closeSearchNav();
                }
            })
            .catch(() => {
                this.requests = [];
                this.requestGroups = [];
            })
            .finally(() => {
                this.searching = false;
            });
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    private getDisplayDate(): string {
        const date: Date = new Date();
        return `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
    }

    private openSearchNavAsync(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId, true)
            .then((sideNavInstance: ng.material.ISidenavObject) => {
                return sideNavInstance.open();
            });
    }

    openSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).open();
    }

    closeSearchNav(): ng.IPromise<any> {
        return this.$mdSidenav(this.sideNavComponentId).close();
    }

    viewDetails($event: MouseEvent, request: RequestLine): void {
        this.$mdDialog.show({
            template: require('./../../agencyBalanceExceptionV2/balanceExceptionRequestDetails.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: BalanceExceptionRequestDetailsControllerV2,
            controllerAs: 'vm',
            locals: {
                request: request
            },
            fullscreen: true
        }).finally(() => {
            this.refreshRequest(request);
        });
    }

    private refreshRequest(request: RequestLine): void {
        this.balanceExceptionApproverSearchServiceV2
            .getRequestStatus(request.requestId)
            .then(response => this.updateRowStatus(request, response.data));
    }

    private updateRowStatus(request: RequestLine, requestStatus: RequestStatus): void {
        request.statusId = requestStatus.statusId;
        request.status = requestStatus.status;
        request.canApprove = requestStatus.canApprove;
        request.canDeny = requestStatus.canDeny;
        request.canReview = requestStatus.canReview;
        request.canManuallyImplement = requestStatus.canManuallyImplement;
        request.canWithdraw = requestStatus.canWithdraw;
    }

    toggleSalesPeriods(period: IdAndName): void {
        const idx: number = this.criteria.salesPeriods.map((x) => x.id).indexOf(period.id);
        if (idx > -1) {
            this.criteria.salesPeriods.splice(idx, 1);
        }
        else {
            this.criteria.salesPeriods.push(period);
        }

        this.clearTypes();
        this.getAllTypes();
    }

    salesPeriodExists(period: IdAndName): boolean {
        const idx: number = this.criteria.salesPeriods.map((x) => x.id).indexOf(period.id);
        return idx > -1;
    }

    toggleStatuses(status: IdAndName): void {
        const idx: number = this.criteria.exceptionStatuses.map((x) => x.id).indexOf(status.id);
        if (idx > -1) {
            this.criteria.exceptionStatuses.splice(idx, 1);
        }
        else {
            this.criteria.exceptionStatuses.push(status);
        }
    }

    onCommercialUnitsSelect(): void {
        this.clearCommercialUnitsSearchTerm();

        if (this.changed.commercialUnits) {
            this.changed.commercialUnits = false;

            if (this.selectedCommercialUnit) {
                this.criteria.commercialUnits = [];
                this.criteria.commercialUnits.push(this.selectedCommercialUnit);
            }

            this.clearAreas();
            this.clearTerritories();
            this.clearSalesAgencies();
            this.clearTypes();
            this.getAreas();
            this.getTypesByOrganizationalUnits(this.criteria.commercialUnits);
        }
    }

    onAreasSelect(): void {
        this.clearAreasSearchTerm();

        if (this.changed.areas) {
            this.changed.areas = false;

            if (this.selectedArea) {
                this.criteria.areas = [];
                this.criteria.areas.push(this.selectedArea);
            }

            this.clearTerritories();
            this.clearSalesAgencies();
            this.clearTypes();
            this.getTerritoriesSalesAgenciesByAreas();
            this.getTypesByOrganizationalUnits(this.criteria.areas);
        }
    }

    onTerritoriesSelect(): void {
        this.clearTerritoriesSearchTerm();

        if (this.changed.territories) {
            this.changed.territories = false;

            this.clearSalesAgencies();
            this.clearTypes();
            this.getSalesAgencies();
            this.getTypesByOrganizationalUnits(this.criteria.territories);
        }
    }

    onAgenciesSelect(): void {
        this.clearSalesAgenciesSearchTerm();

        this.clearTypes();
        this.getTypesBySalesAgencies();
    }

    onTypesSelect(): void {
        this.clearTypesSearchTerm();
    }

    canSelectCommercialUnits(): boolean {
        return !this.loading.commercialUnits && this.options.commercialUnits && this.options.commercialUnits.length > 0;
    }

    canSelectAreas(): boolean {
        return !this.loading.areas && this.options.areas && this.options.areas.length > 0;
    }

    canSelectTerritories(): boolean {
        return !this.loading.territories && this.options.territories && this.options.territories.length > 0;
    }

    canSelectSalesAgencies(): boolean {
        return !this.loading.salesAgencies && this.options.salesAgencies && this.options.salesAgencies.length > 0;
    }

    canSelectExceptionTypes(): boolean {
        return !this.loading.exceptionTypes && this.options.exceptionTypes && this.options.exceptionTypes.length > 0;
    }

    // Select dropdown labels
    getCommercialUnitsLabel(): string {
        return this.options.commercialUnits && this.options.commercialUnits.length > 0 ? this.label.commercialUnits : this.noOptions;
    }

    getAreasLabel(): string {
        return this.options.areas && this.options.areas.length > 0 ? this.label.areas : this.noOptions;
    }

    getSalesAgenciesLabel(): string {
        if ((this.isLead() || this.isVicePresident()) && this.criteria.areas.length === 0) {
            return 'Must select area';
        }

        return this.options.salesAgencies && this.options.salesAgencies.length > 0 ? this.label.salesAgencies : this.noOptions;
    }

    getTerritoriesLabel(): string {
        if ((this.isLead() || this.isVicePresident()) && this.criteria.areas.length === 0) {
            return 'Must select area';
        }

        return this.options.territories && this.options.territories.length > 0 ? this.label.territories : this.noOptions;
    }

    getExceptionTypesLabel(): string {
        return this.options.exceptionTypes && this.options.exceptionTypes.length > 0 ?
            this.label.exceptionTypes : this.noOptions;
    }

    setCommercialUnitsChanged(): void {
        this.changed.commercialUnits = true;
    }

    setAreasChanged(): void {
        this.changed.areas = true;
    }

    setTerritoriesChanged(): void {
        this.changed.territories = true;
    }

    private setExceptionTypesLabel(): string {
        this.label.exceptionTypes = '';

        if (this.loading.exceptionTypes) {
            return 'Loading Exception types';
        }
        return this.options.exceptionTypes.length > 0 ? 'Exception types' : this.noOptions;
    }

    // permissions checks
    private isLead(): boolean {
        return this.options.permissions && this.options.permissions.role.toLowerCase() === 'cul';
    }

    private isVicePresident(): boolean {
        const vpRoles: string[] = ['rvp', 'gvp'];

        return this.options.permissions && vpRoles.indexOf(this.options.permissions.role.toLowerCase()) > -1;
    }

    // Select dropdown search term clearing
    private clearTypesSearchTerm(): void {
        this.typesSearchTerm = '';
    }

    private clearAreasSearchTerm(): void {
        this.areasSearchTerm = '';
    }

    private clearCommercialUnitsSearchTerm(): void {
        this.commercialUnitSearchTerm = '';
    }

    private clearSalesAgenciesSearchTerm(): void {
        this.salesAgenciesSearchTerm = '';
    }

    private clearTerritoriesSearchTerm(): void {
        this.territoriesSearchTerm = '';
    }

    private clearSalesAgencies(): void {
        this.options.salesAgencies = [];
        this.criteria.salesAgencies = [];
    }

    private clearTerritories(): void {
        this.options.territories = [];
        this.criteria.territories = [];
    }

    private clearAreas(): void {
        this.options.areas = [];
        this.criteria.areas = [];
        this.selectedArea = undefined;
    }

    private clearCommercialUnits(): void {
        this.options.commercialUnits = [];
        this.criteria.commercialUnits = [];
        this.selectedCommercialUnit = undefined;
    }

    private clearTypes(): void {
        this.options.exceptionTypes = [];
        this.criteria.exceptionTypes = [];
    }

    private getAreas(): void {
        this.loading.areas = true;

        this.balanceExceptionApproverSearchServiceV2.getAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<OrgIdAndName[]>) => { this.processAreasResponse(response.data); })
            .finally(() => { this.loading.areas = false; });
    }

    private getAllTypes(): void {
        this.loading.exceptionTypes = true;

        this.label.exceptionTypes = this.setExceptionTypesLabel();

        this.balanceExceptionApproverSearchServiceV2.getTypes(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<string[]>) => { this.processTypesResponse(response.data); })
            .finally(() => {
                this.loading.exceptionTypes = false;
                this.label.exceptionTypes = this.setExceptionTypesLabel();
            });
    }

    private getTypesBySalesAgencies(): void {
        this.loading.exceptionTypes = true;

        this.label.exceptionTypes = this.setExceptionTypesLabel();

        this.balanceExceptionApproverSearchServiceV2.getTypesBySalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<string[]>) => { this.processTypesResponse(response.data); })
            .finally(() => {
                this.loading.exceptionTypes = false;
                this.label.exceptionTypes = this.setExceptionTypesLabel();
            });
    }

    private getTypesByOrganizationalUnits(units: Array<OrgIdAndName>): void {
        this.loading.exceptionTypes = true;

        this.label.exceptionTypes = this.setExceptionTypesLabel();

        this.balanceExceptionApproverSearchServiceV2.getTypesByOrganizationalUnits(this.criteria.salesPeriods, units)
            .then((response: ng.IHttpPromiseCallbackArg<string[]>) => { this.processTypesResponse(response.data); })
            .finally(() => {
                this.loading.exceptionTypes = false;
                this.label.exceptionTypes = this.setExceptionTypesLabel();
            });
    }

    private getSalesAgencies(): void {
        this.loading.salesAgencies = true;

        this.balanceExceptionApproverSearchServiceV2.getSalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<IdAndName[]>) => {
                this.processSalesAgenciesResponse(response.data);
            })
            .finally(() => {
                this.loading.salesAgencies = false;
            });
    }

    private getTerritoriesSalesAgencies(): void {
        this.loading.territories = true;
        this.loading.salesAgencies = true;

        this.balanceExceptionApproverSearchServiceV2.getTerritoriesSalesAgencies()
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processTerritoriesSalesAgenciesResponse(response.data); })
            .finally(() => {
                this.loading.territories = false;
                this.loading.salesAgencies = false;
            });
    }

    private getTerritoriesSalesAgenciesByAreas(): void {
        this.loading.territories = true;
        this.loading.salesAgencies = true;

        this.balanceExceptionApproverSearchServiceV2.getTerritoriesSalesAgenciesByAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processTerritoriesSalesAgenciesResponse(response.data); })
            .finally(() => {
                this.loading.territories = false;
                this.loading.salesAgencies = false;
            });
    }

    // Select dropdown key event handlers to allow use of select search bar
    private setHandlers(): void {
        const selectors: string[] = [
            'exceptionTypes',
            'areas',
            'commercialUnits',
            'agencies',
            'territories'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    // Search options data processing
    private processOptionsResponse(options: ApproverSearchOptions): void {
        this.options = options;

        if (!this.options.permissions.canSeeTerritories) {
            this.getSalesAgencies();
        }
        else if (!this.options.permissions.canSeeAreas) {
            this.getTerritoriesSalesAgencies();
        }
    }

    private activateSalesPeriods(): void {
        this.options.salesPeriods.forEach((salesPeriod: IdAndName) => this.criteria.salesPeriods.push(salesPeriod));
    }

    private processAreasResponse(areas: OrgIdAndName[]): void {
        this.options.areas = areas;
    }

    private processTypesResponse(types: string[]): void {
        this.options.exceptionTypes = types;
    }

    private processCommercialUnitsAreasResponse(data: { commercialUnits: OrgIdAndName[]; areas: OrgIdAndName[] }): void {
        this.options.commercialUnits = data.commercialUnits;
        this.options.areas = data.areas;
    }

    private processSalesAgenciesResponse(agencies: IdAndName[]): void {
        this.options.salesAgencies = agencies;
    }

    private processTerritoriesResponse(territories: OrgIdAndName[]): void {
        this.options.territories = territories;
    }

    private processTerritoriesSalesAgenciesResponse(data: { territories: OrgIdAndName[]; agencies: IdAndName[] }): void {
        this.options.salesAgencies = data.agencies;
        this.options.territories = data.territories;
    }

    // Search result data processing
    private processResponse(data: { requestList: Array<RequestLine>; totalRequests: number; }): void {
        this.requests = data.requestList;
        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }
}
