const filters: string[] = [
    'percentage',
    'pluck',
    'shortDate',
    'units',
    'score',
    'truncate'
];

filters.forEach(filter => {
    Application.registerFilter(filter, require('./' + filter + '.filter'));
});
