import { IInfinityPackageOverrideNavigationService } from './infinityPackageOverrideNavigation.service.interface';

export default class InfinityPackageOverrideNavigationService implements IInfinityPackageOverrideNavigationService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    canOverrideRequest(): ng.IPromise<ng.IHttpPromiseCallbackArg<boolean>> {
        return this.$http.get(`{API-ApprovalTool}/infinitypackageoverride/navigation/requests/canrequest/`);
    }
}