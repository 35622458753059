import OperationProductLineSalesController from './operationProductLineSales.controller';

Application.registerController('OperationProductLineSalesController', OperationProductLineSalesController);

export default class OperationProductLineSalesComponent implements ng.IComponentOptions {
    template: string = require('./operationProductLineSales.html');
    bindings: { [binding: string]: string } = {
        operationGrowth: '<',
        year: '<',
        name: '<'
    };
    controller: Function = OperationProductLineSalesController;

    static factory() {
        const component: Function = () => {
            return new OperationProductLineSalesComponent();
        };

        return component();
    }
}