import {IUser} from '../models/user.model.interface';
import {User} from '../models/user.model';

import {IStorageService} from './storage.service.interface';
import {StorageKeyEnum} from './storageKey.enum';

export class UserService {

    constructor(
        private storageService: IStorageService,
        private $location: ng.ILocationService
    ) {
        // noop
    }

    get(): IUser {
        const authInfo = this.storageService.get(StorageKeyEnum.AuthInfo);
        const impAuthInfo = this.storageService.get(StorageKeyEnum.ImpAuthInfo);

        return new User(authInfo, impAuthInfo, this.$location.host());
    }

}
