import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

export default class CommissionAdvanceNavigationController  {
    // bindings
    page: string;
    user: IUser;

    constructor(
        private $location: ng.ILocationService,
        private userService: IUserService) { }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    navToPending(): void {
        this.$location.url('/requestType-Commission/');
    }

    navToSearchRequests(): void {
        this.$location.url('/requestType-Commission/search');
    }
}