import PricingRequestsService from './pricingRequests.service';
import {PricingRequest} from './core/pricingRequest.model';
import PricingRequestDetailController from './detail/pricingRequestDetail.controller';
import {IUser} from '../../../core/models/user.model.interface';
import { IUserService } from '../../../core/services/user.service.interface';
import {AllPricingRequestsFiltersOptions} from './request/models/allPricingRequestsFiltersOptions.model';
import {AllPricingRequestsFiltersCriteria} from './request/models/allPricingRequestsFiltersCriteria.model';
import {PricingSalesPeriod} from './request/models/pricingSalesPeriod.model';

export default class AllPricingRequestsController {
    filteredResult: PricingRequest[];

    filterCriteria: AllPricingRequestsFiltersCriteria = {
        salesPeriod: undefined,
        district: undefined,
        productLine: undefined,
        product: undefined,
        priceType: undefined,
        status: undefined,
    };

    temp: AllPricingRequestsFiltersCriteria = {
        salesPeriod: undefined,
        district: undefined,
        productLine: undefined,
        product: undefined,
        priceType: undefined,
        status: undefined,
    };

    filterOptions: AllPricingRequestsFiltersOptions = {
        districts: [],
        productLines: [],
        products: [],
        priceTypes: [],
        statuses: []
    };

    searchText: string = null;
    navigationText: string = 'Pricing requests';
    loading: boolean = true;
    requests: Array<PricingRequest>;
    allRequests: Array<PricingRequest>;
    showToolbar: boolean = false;
    salesPeriods: Array<PricingSalesPeriod>;
    canClearFilters: boolean = false;
    canMassApprove: boolean = false;

    constructor(protected $mdDialog: ng.material.IDialogService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected pricingRequestsService: PricingRequestsService) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];
        this.allRequests = [];
        this.activate();
        this.canMassApprovePricingRequests();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    viewDetails($event: MouseEvent, request: PricingRequest): void {
        this.$mdDialog.show({
                template: require('./detail/pricingRequestDetail.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                controller: PricingRequestDetailController,
                controllerAs: 'vm',
                locals: {
                    request: request
                },
                fullscreen: true
        }).finally(() => {
            this.refreshResults();
        });
    }

    onSalesYearSelect(): void {
        this.clearFilters();
        this.getAllPricingFilters();
        this.getAllRequestsBySalesPeriod();
    }

    onDistrictSelect(): void {
        this.requests = this.filterListBySelectedOptions('');
    }

    onProductLineSelect(): void {
        this.requests = this.filterListBySelectedOptions('');
    }

    onPriceTypeSelect(): void {
        this.requests = this.filterListBySelectedOptions('');
    }

    onStatusSelect(): void {
        this.requests =  this.filterListBySelectedOptions('');
    }

    onMenuOpen(property: string): void {
        const current: string = _.get(this.filterCriteria, property, undefined);
        _.set(this.temp, property, current);
    }

    onProductSelect(item: any) {
        if (item === undefined) {
            return;
        }

        this.requests = this.filterListBySelectedOptions(item);
    }

    productSearchTextChange() {
        if (this.searchText === '') {
            this.filterCriteria.product = null;
        }
        this.requests = this.filterListBySelectedOptions(this.searchText);
    }

    clearAllFilters(): void {

        this.clearFilters();
        this.getAllPricingFiltersSalesperiods();
    }

    refreshResults() {
            this.loading = true;

            if (this.filterCriteria.salesPeriod !== undefined) {
                this.pricingRequestsService.getAllRequestsBySalesPeriod(this.filterCriteria.salesPeriod.salesPeriodId)
                    .then((data) => this.processRefreshResponse(data))
                    .finally(() => this.done());
        }
    }

    private clearFilters(): void {
        this.filterCriteria.district = undefined;
        this.filterCriteria.productLine = undefined;
        this.filterCriteria.product = undefined;
        this.filterCriteria.priceType = undefined;
        this.filterCriteria.status = undefined;
        this.canClearFilters = false;
    }

    private productQuerySearch(query: string) {
        return query && this.filterOptions.products ? this.filterOptions.products.filter(product => product.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.filterOptions.products;
    }


    private activate(): void {
        this.loading = true;
        this.user = this.userService.get();
        this.showToolbar = !this.user.useSupportPage;

        this.getAllPricingFiltersSalesperiods();
    }

    private getAllPricingFiltersSalesperiods(): void {
        this.salesPeriods = [];
        this.filterCriteria.salesPeriod = undefined;
        this.pricingRequestsService.getAllPricingFiltersSalesperiods()
            .then((response) => {
                this.salesPeriods = response.data;
                this.setCurrentSalesPeriod();
                this.getAllPricingFilters();
                this.getAllRequestsBySalesPeriod();
            })
            .finally(() => this.done());
    }

    private setCurrentSalesPeriod() {
        this.filterCriteria.salesPeriod = this.salesPeriods.filter(salesPeriod => salesPeriod.isCurrent).shift();
    }

    private getAllPricingFilters(): void {
        if (this.filterCriteria.salesPeriod !== undefined) {
            this.pricingRequestsService.getAllPricingFilters(this.filterCriteria.salesPeriod.salesPeriodId)
                .then((data) => this.processFiltersResponse(data))
                .finally(() => this.done());
        }
    }

    private processFiltersResponse(response: any): void {
        if (response && response.data) {
            this.filterOptions = response.data;
        }
    }

    private processResponse(response: any): void {
        if (response && response.data) {
            this.requests = response.data;
            this.allRequests = response.data;
        }
    }

    private getAllRequestsBySalesPeriod(): void {
        if (this.filterCriteria.salesPeriod !== undefined) {
            this.pricingRequestsService.getAllRequestsBySalesPeriod(this.filterCriteria.salesPeriod.salesPeriodId)
                .then((data) => this.processRequestsResponse(data))
                .finally(() => this.done());
        }
    }

    private processRequestsResponse(response: any): void {
        if (response && response.data) {
            this.requests = response.data;
            this.allRequests = response.data;
        }
    }

    private done(): void {
        this.loading = false;
    }

    private filterListBySelectedOptions(searchText: string): PricingRequest[] {
        this.canClearFilters = true;
        this.filteredResult = this.allRequests;
        if (this.filterCriteria.district !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.district === this.filterCriteria.district);
        }

        if (this.filterCriteria.priceType !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.priceType === this.filterCriteria.priceType);
        }

        if (this.filterCriteria.status !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.status === this.filterCriteria.status);
        }

        if (this.filterCriteria.product !== undefined) {
            this.filteredResult = (searchText === '' && this.filterCriteria.product !== null) ? this.filteredResult.filter(x => x.product === this.filterCriteria.product) :
                this.filteredResult.filter(x => x.product.toLowerCase() === searchText.toLowerCase() ||
                    x.product.toLowerCase().indexOf(searchText.toLowerCase()) === 0);
        }

        if (this.filterCriteria.productLine !== undefined) {
            this.filteredResult = (this.filterCriteria.productLine.length > 0)
                ? this.filterByProductLine()
                : this.filteredResult;
        }

        return this.filteredResult;
    }

    private filterByProductLine(): PricingRequest[] {
        let requests: any = [];
        this.filterCriteria.productLine.forEach(value => {
            requests.push(this.filteredResult.filter(x => x.productLine === value));
        });
        return [].concat.apply([], requests).filter(x => !!x);
    }

    private processRefreshResponse(response: any): void {
        if (response && response.data) {
            this.allRequests = response.data;
            this.requests = this.filterListBySelectedOptions('');
        }
    }

    private canMassApprovePricingRequests() {
        $fsLDFeatureFlagService.isEnabledAsync('HQ.635556.MassPricingApproval')
            .then((enabled) => {
                this.canMassApprove = enabled && this.user.approvalToolMassProductAndTreatmentPriceApproval;
            });
    }
}


