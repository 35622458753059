import { Territory } from './territory.model';
import { ISupportSearchPlanningService } from './supportSearchPlanning.service.interface';

export default class SupportSearchPlanningService implements ISupportSearchPlanningService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getTerritories(): ng.IPromise<ng.IHttpPromiseCallbackArg<Territory[]>> {
        return this.$http.get(`{API-ApprovalTool}/sales-support/planning/territory-list`);
    }
}
