import { IUser } from '../../../core/models/user.model.interface';
import { IUserService } from '../../../core/services/user.service.interface';

import BalanceWriteOffDetailController from './detail/balanceWriteOffDetail.controller';
import { BalanceWriteOffRequest } from './balanceWriteOffRequest.model';
import BalanceWriteOffRequestsService from './balanceWriteOffRequests.service';
import SapErrorMessageDialogController from './sapErrorMessageDialog/sapErrorMessageDialog.controller';

export default class BalanceWriteOffRequestsController {

    hideToolbar: boolean = false;
    navigationText: string;
    requests: BalanceWriteOffRequest[];
    loading: boolean = true;
    canShowTable: () => boolean = (() => true);
    nothingToApprove: () => boolean = this.nothingToApproveImpl;
    allowManuallyImplement: boolean = false;

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected balanceWriteOffRequestsService: BalanceWriteOffRequestsService,
        protected $mdDialog: ng.material.IDialogService
    ) {
        this.requests = [];
        this.activate();
    }

    public viewDetails($event: MouseEvent, request: BalanceWriteOffRequest) {
        this.$mdDialog.show({
            template: require('./detail/balanceWriteOffDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: BalanceWriteOffDetailController,
            controllerAs: 'vm',
            locals: {
                requestLocal: request
            },
            fullscreen: true
        }).finally(() => {
            this.getPending();
        });

    }

    public viewSaveError($event: MouseEvent) {
        this.$mdDialog.show({
            template: require('./sapErrorMessageDialog/sapErrorMessageDialog.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: SapErrorMessageDialogController,
            controllerAs: 'vme',
            bindToController: true
        });
    }

    public confirmSuccess(response, ev) {
        if (!response.hasNoSapCallError) {
            this.viewSaveError(ev);
        }
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.user = this.userService.get();

        this.getPending();

        if (this.$location.path().indexOf('all') > -1) {
            this.navigationText = 'All pending balance write-off requests';
        } else {
            this.navigationText = 'My pending balance write-off requests';
        }
    }

    private getPending(): void {
        this.balanceWriteOffRequestsService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private processResponse(response: any): void {
        let requestData: Array<any> = response.data.pendingRequestList;
        this.requests = _.sortByAll(requestData, ['transactionDate', 'period', 'territoryId', 'salesAgency.name']);
    }

    private done(): void {
        this.loading = false;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    approve($event: MouseEvent, bwoRequest: any): void {
        $event.stopPropagation();
        bwoRequest.processing = true;

        this.balanceWriteOffRequestsService.approve(bwoRequest)
            .then((response) => {
                this.confirmSuccess(response.data, $event);
                bwoRequest.isApproved = true;
                this.setActionPermissions(bwoRequest, false, false);
            })
            .finally(() => {
                bwoRequest.processing = false;
                this.getPending();
            });
    }

    deny($event: MouseEvent, bwoRequest: any): void {
        $event.stopPropagation();
        bwoRequest.processing = true;

        this.balanceWriteOffRequestsService.deny(bwoRequest)
            .then(() => {
                bwoRequest.isDenied = true;
                this.setActionPermissions(bwoRequest, false, false);
            })
            .finally(() => {
                bwoRequest.processing = false;
                this.getPending();
            });
    }

    canApprove(request: BalanceWriteOffRequest): boolean {
        return this.user.canApproveDenyBalanceWriteOff && request.canApprove && !request.processing;
    }

    canDeny(request: BalanceWriteOffRequest): boolean {
        return this.user.canApproveDenyBalanceWriteOff && request.canDeny && !request.processing;
    }

    private setActionPermissions(request: BalanceWriteOffRequest, canApprove: boolean, canDeny: boolean): void {
        request.canApprove = canApprove;
        request.canDeny = canDeny;
    }
}