import { PlannedDiscountModel } from './plannedDiscount.model';
import { CustomerDiscountDetailsModel } from './customerDiscountDetails.model';

export default class CustomerDiscountController {

    loading: boolean = true;
    saving: boolean = false;
    discountColCount: number = 2;

    discounts: CustomerDiscountDetailsModel;
    canEdit: boolean = true;
    onSaveDiscount: (model: any) => any;
    onSaveLoadFlexFund: (loadFlexFund: any) => any;

    private $onChanges(changes: any) {
        if (changes.discounts && changes.discounts.currentValue) {
            this.discountColCount = this.discounts.previousYears.length + 3;
            this.loading = false;
        }
    }

    private safeDecimalAdd(n1: number, n2: number): number {
        return +(n1 + n2).toFixed(12);
    }

    private updateTotalDiscount(): void {
        let total: number = 0;
        for (let i: number = 0; i < this.discounts.discounts.length; i++) {
            total = this.safeDecimalAdd(total, this.discounts.discounts[i].amount);
        }

        total = this.safeDecimalAdd(total, this.discounts.flexFundDiscount.flexFund.amount);

        this.discounts.totalDiscount = total;
    }

    canEditDiscount(model: PlannedDiscountModel) {
        return !this.loading && this.discounts && this.canEdit;
    }

    savePlannedDiscount(model: PlannedDiscountModel, validator: ng.INgModelController): void {
        if (this.canEdit && validator.$valid && validator.$dirty) {
            this.updateTotalDiscount();
            this.onSaveDiscount({ model: model, totalDiscount: this.discounts.totalDiscount});
        }
    }

    saveLoadFlexFund(validator: ng.INgModelController) {
        if (this.canEdit && validator.$dirty) {
            this.onSaveLoadFlexFund({ loadFlexFund: this.discounts.flexFundDiscount.loadFlexFund });
        }
    }
}