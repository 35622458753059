import RequiredApprovalsController from './required.approvals.controller';

Application.registerController('RequiredApprovalsController', RequiredApprovalsController);

export default class RequiredApprovalsComponent implements ng.IComponentOptions {
    template: string = require('./required.approvals.html');
    bindings: { [binding: string]: string } = {
        approvals: '<'
    };
    controller: Function = RequiredApprovalsController;

    static factory() {
        const component: Function = () => {
            return new RequiredApprovalsComponent();
        };

        return component();
    }
}