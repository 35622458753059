export function isNotNull(value: any, name: string = 'value'): any {
    if (value === undefined) {
        throw new Error(`The ${name} cannot be undefined.`);
    }
    else if (value === null) {
        throw new Error(`The ${name} cannot be null.`);
    }
    return value;
}

export function isNotNullOrWhiteSpace(value: any, name: string = 'value'): void {

    if (value !== undefined && value !== null && (value === '' || (value.trim && value.trim() === ''))) {
        throw new Error(`The ${name} cannot contain only white space characters.`);
    }
    else {
        this.isNotNull(value, name);
    }
}

export function areNotNullOrWhiteSpace(values: any[]): void {
    angular.forEach(values, (val) => {
        this.isNotNullOrWhiteSpace(val);
    });
}

export function areNotNull(values: any[]): void {
    angular.forEach(values, (val) => {
        this.isNotNull(val);
    });
}

export function is(value: any, name: string = 'value'): void {
    if (value !== true) {
        throw new Error(`The ${name} must be true.`);
    }
}