import DetailBarComponentController from './detailBar.component.controller';

export default class DetailBarComponent implements ng.IComponentOptions {
    template: string = require('./detailBar.html');
    bindings: { [binding: string]: string } = {
        request: '<',
        finalApprovalDate: '<',
        showInvoiceBreakout: '<?',
        regularInvoiceValue: '<',
        doubleCropReplantInvoiceValue: '<'
    };
    controller: Function = DetailBarComponentController;

    static factory() {
        const component: any = () => {
            return new DetailBarComponent();
        };

        return component();
    }
}