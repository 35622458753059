import { DiscountImplementedCpaLine } from './discountImplementedCpaLine.model';
import { DiscountSummary as DiscountSummary } from './discountSummary.model';
import { DiscountPendingCpaLine } from './discountPendingCpaLine.model';
import { DiscountPendingTotal } from './discountPendingTotal.model';

export class ShareDiscount {
    constructor(
        public discountSummary: DiscountSummary,
        public implementedCpa: Array<DiscountImplementedCpaLine>,
        public pendingCpa: Array<DiscountPendingCpaLine>,
        public totalPending: DiscountPendingTotal,
        public allPending: DiscountPendingTotal
    ) {
        // no-op
    }
}