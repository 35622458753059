import { IInfinityPackageOverridePendingListService } from './infinityPackageOverridePendingList.service.interface';
import { PendingPackageOverrideRequest } from './pendingPackageOverrideRequest.model'; 

export default class InfinityPackageOverridePendingListService implements IInfinityPackageOverridePendingListService {

    constructor(private $http: ng.IHttpService, private $timeout) {
        // noop
    }

    getList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
       return this.$http.get(`{API-ApprovalTool}/packageoverride/requests/pending`);
    }

    approve(requestId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/packageoverride/requests/${requestId}/approve`;
        return this.$http.post(url, { requestId });
    };

    deny(requestId: any): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/packageoverride/requests/${requestId}/deny`;
        return this.$http.post(url, { requestId });
    };

    isPermissionStopDatePassed(request: PendingPackageOverrideRequest): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const url: string = `{API-ApprovalTool}/requests/competitivepriceadjustment/startstop/permissions/${request.territoryId}`;
        return this.$http.get(url);
    };
}