import ApproverSearchBarService from './approverSearchBar.service';
import {IApproverSearchBarCriteriaModel} from './models/approverSearchBarCriteria.model.interface';
import {IApproverSearchBarOptionsModel} from './models/approverSearchBarOptionsModel.model.interface';
import {IIdAndName} from '../../../../core/models/idAndName.model.interface';
import {IOrgIdAndName} from '../../../../core/models/orgIdAndName.model.interface';

export default class ApproverSearchBarController {

    options: IApproverSearchBarOptionsModel;
    criteria: IApproverSearchBarCriteriaModel = {
        areas: [],
        commercialUnits: [],
        commissionAdvanceStatuses: [],
        salesAgencies: [],
        salesPeriods: [],
        territories: []
    };

    selectedArea: IOrgIdAndName = undefined;
    selectedCommercialUnit: IOrgIdAndName = undefined;

    typesSearchTerm: string = '';
    areasSearchTerm: string = '';
    commercialUnitSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    territoriesSearchTerm: string = '';

    loading: {
        areas: boolean;
        commercialUnits: boolean;
        options: boolean;
        salesAgencies: boolean;
        territories: boolean;
    } = {
            areas: false,
            commercialUnits: false,
            options: false,
            salesAgencies: false,
            territories: false
        };

    noOptions: string = 'No results';

    label: {
        areas: string;
        commercialUnits: string;
        salesAgencies: string;
        territories: string;
    } = {
            areas: 'Area',
            commercialUnits: 'Commercial unit',
            salesAgencies: 'Sales agencies',
            territories: 'Territories'
        };

    onSearch: (criteria: any) => any;

    constructor(
        private $document: ng.IDocumentService,
        private $timeout: ng.ITimeoutService,
        private approverSearchBarService: ApproverSearchBarService
    ) { }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.loading.options = true;
    }
    
    $onChanges(changes: any) {
        if (angular.isDefined(changes.options) && angular.isDefined(changes.options.currentValue)) {
            this.processOptionsResponse(this.options);
            this.activateSalesPeriods();
            this.setHandlers();
            this.loading.options = false;
        }
    }

    canSearch(): boolean {
        return this.criteria.salesPeriods && this.criteria.salesPeriods.length > 0;
    }

    submitSearch(): void {
        this.onSearch({ criteria: this.criteria });
    }

    toggleSalesPeriods(period: IIdAndName): void {
        const idx: number = this.criteria.salesPeriods.map((period) => period.id).indexOf(period.id);
        if (idx > -1) {
            this.criteria.salesPeriods.splice(idx, 1);
        }
        else {
            this.criteria.salesPeriods.push(period);
        }

        if (this.options.permissions.canSeeCommercialUnits) {
            this.clearCommercialUnits();
            this.clearAreas();
            this.clearSalesAgencies();
            this.clearTerritories();

            this.getCommercialUnitsAreas();
        } else if (this.options.permissions.canSeeAreas) {
            this.clearAreas();
            this.clearSalesAgencies();
            this.clearTerritories();

            this.getAreas();
        } else if (this.options.permissions.canSeeTerritories) {
            this.clearSalesAgencies();
            this.clearTerritories();

            this.getTerritoriesSalesAgencies();
        } else if (this.options.permissions.canSeeSalesAgencies) {
            this.clearSalesAgencies();

            this.getSalesAgencies();
        }
    }

    salesPeriodExists(period: IIdAndName): boolean {
        const idx: number = this.criteria.salesPeriods.map((period) => period.id).indexOf(period.id);
        return idx > -1;
    }

    toggleStatuses(status: IIdAndName): void {
        const idx: number = this.criteria.commissionAdvanceStatuses.map((status) => status.id).indexOf(status.id);
        if (idx > -1) {
            this.criteria.commissionAdvanceStatuses.splice(idx, 1);
        }
        else {
            this.criteria.commissionAdvanceStatuses.push(status);
        }
    }

    onCommercialUnitsSelect(): void {
        this.clearCommercialUnitsSearchTerm();

        if (this.selectedCommercialUnit) {
            this.criteria.commercialUnits = [];
            this.criteria.commercialUnits.push(this.selectedCommercialUnit);
        }

        this.clearAreas();
        this.clearTerritories();
        this.clearSalesAgencies();
        this.getAreas();
        this.getTerritoriesSalesAgencies();
    }

    onAreasSelect(): void {
        this.clearAreasSearchTerm();

        if (this.selectedArea) {
            this.criteria.areas = [];
            this.criteria.areas.push(this.selectedArea);
        }

        this.clearTerritories();
        this.clearSalesAgencies();
        this.getTerritoriesSalesAgencies();
    }

    onTerritoriesSelect(): void {
        this.clearTerritoriesSearchTerm();

        this.clearSalesAgencies();
        this.getSalesAgencies();
    }

    onAgenciesSelect(): void {
        this.clearSalesAgenciesSearchTerm();
    }

    onTypesSelect(): void {
        this.clearTypesSearchTerm();
    }

    canSelectCommercialUnits(): boolean {
        return !this.loading.commercialUnits && this.options.commercialUnits && this.options.commercialUnits.length > 0;
    }

    canSelectAreas(): boolean {
        return !this.loading.areas && this.options.areas && this.options.areas.length > 0;
    }

    canSelectTerritories(): boolean {
        return !this.loading.territories && this.options.territories && this.options.territories.length > 0;
    }

    canSelectSalesAgencies(): boolean {
        return !this.loading.salesAgencies && this.options.salesAgencies && this.options.salesAgencies.length > 0;
    }

    // Select dropdown labels

    getCommercialUnitsLabel(): string {
        return this.options.commercialUnits && this.options.commercialUnits.length > 0 ? this.label.commercialUnits : this.noOptions;
    }

    getAreasLabel(): string {
        return this.options.areas && this.options.areas.length > 0 ? this.label.areas : this.noOptions;
    }

    getSalesAgenciesLabel(): string {
        if (this.options.permissions.canSeeCommercialUnits) {
            if (this.criteria.commercialUnits.length === 0 && this.criteria.areas.length === 0) {
                return 'Must select commercial unit or area';
            }
        } else if (this.options.permissions.canSeeAreas && this.criteria.areas.length === 0) {
            return 'Must select area';
        }

        return this.options.salesAgencies && this.options.salesAgencies.length > 0 ? this.label.salesAgencies : this.noOptions;
    }

    getTerritoriesLabel(): string {
        if (this.options.permissions.canSeeCommercialUnits) {
            if (this.criteria.commercialUnits.length === 0 && this.criteria.areas.length === 0) {
                return 'Must select commercial unit or area';
            }
        } else if (this.options.permissions.canSeeAreas && this.criteria.areas.length === 0) {
            return 'Must select area';
        }

        return this.options.territories && this.options.territories.length > 0 ? this.label.territories : this.noOptions;
    }

    // Select dropdown search term clearing

    private clearTypesSearchTerm(): void {
        this.typesSearchTerm = '';
    }

    private clearAreasSearchTerm(): void {
        this.areasSearchTerm = '';
    }

    private clearCommercialUnitsSearchTerm(): void {
        this.commercialUnitSearchTerm = '';
    }

    private clearSalesAgenciesSearchTerm(): void {
        this.salesAgenciesSearchTerm = '';
    }

    private clearTerritoriesSearchTerm(): void {
        this.territoriesSearchTerm = '';
    }

    private clearSalesAgencies(): void {
        this.options.salesAgencies = [];
        this.criteria.salesAgencies = [];
    }

    private clearTerritories(): void {
        this.options.territories = [];
        this.criteria.territories = [];
    }

    private clearAreas(): void {
        this.options.areas = [];
        this.criteria.areas = [];
        this.selectedArea = undefined;
    }

    private clearCommercialUnits(): void {
        this.options.commercialUnits = [];
        this.criteria.commercialUnits = [];
        this.selectedCommercialUnit = undefined;
    }

    private getAreas(): void {
        this.loading.areas = true;

        this.approverSearchBarService.getAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>) => { this.processAreasResponse(response.data); })
            .finally(() => { this.loading.areas = false; });
    }

    private getCommercialUnitsAreas(): void {
        this.loading.commercialUnits = true;
        this.loading.areas = true;

        this.approverSearchBarService.getCommercialUnitsAreas(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processCommercialUnitsAreasResponse(response.data); })
            .finally(() => {
                this.loading.commercialUnits = false;
                this.loading.areas = false;
            });
    }

    private getSalesAgencies(): void {
        this.loading.salesAgencies = true;

        this.approverSearchBarService.getSalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<IIdAndName[]>) => {
                this.processSalesAgenciesResponse(response.data);
            })
            .finally(() => {
                this.loading.salesAgencies = false;
            });
    }

    private getTerritoriesSalesAgencies(): void {
        this.loading.territories = true;
        this.loading.salesAgencies = true;

        this.approverSearchBarService.getTerritoriesSalesAgencies(this.criteria)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => { this.processTerritoriesSalesAgenciesResponse(response.data); })
            .finally(() => {
                this.loading.territories = false;
                this.loading.salesAgencies = false;
            });
    }

    // Select dropdown key event handlers to allow use of select search bar
    private setHandlers(): void {
        const selectors: string[] = [
            'areas',
            'commercialUnits',
            'agencies',
            'territories'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    // Search options data processing
    private processOptionsResponse(options: IApproverSearchBarOptionsModel): void {
        this.options = options;

        if (!this.options.permissions.canSeeAreas && !this.options.permissions.canSeeCommercialUnits) {
            if (this.options.permissions.canSeeTerritories) {
                this.getTerritoriesSalesAgencies();
            } else {
                this.getSalesAgencies();
            }
        }
    }

    private activateSalesPeriods(): void {
        this.options.salesPeriods.forEach((salesPeriod: IIdAndName) => this.criteria.salesPeriods.push(salesPeriod));
    }

    private processAreasResponse(areas: IOrgIdAndName[]): void {
        this.options.areas = areas;
    }

    private processCommercialUnitsAreasResponse(data: { commercialUnits: IOrgIdAndName[]; areas: IOrgIdAndName[] }): void {
        this.options.commercialUnits = data.commercialUnits;
        this.options.areas = data.areas;
    }

    private processSalesAgenciesResponse(agencies: IIdAndName[]): void {
        this.options.salesAgencies = agencies;
    }

    private processTerritoriesSalesAgenciesResponse(data: { territories: IOrgIdAndName[]; agencies: IIdAndName[] }): void {
        this.options.salesAgencies = data.agencies;
        this.options.territories = data.territories;
    }
}