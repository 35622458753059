import { ProductLineSalesGrowthModel } from './productLineSalesGrowth.model';

export class OperationProductLineSalesGrowthModel {

    constructor(
        public productLineGrowthData: Array<ProductLineSalesGrowthModel>,
        public currentYearTotalAmount: number,
        public previousYearTotalAmount: number,
        public totalAcreGrowth: number
    ) {}
}