export class PricingCreateRequestData {
    constructor(
        public requestId: number,
        public salesPeriodId: number,
        public district: string,
        public productLineId: string,
        public productId: string,
        public treatment: string,
        public priceTypeId: string,
        public priceType: string,
        public price: number,
        public units: number,
        public estimatedValue: number,
        public startPriceTypeId: string,
        public startPriceType: string,
        public startPrice: number,
        public comment: string,
        public isTreatment: boolean) {
        // no-op
    }
}