import './approverSearchBar/approverSearchBar.scss';
import './budgetInfo/budgetInfo.scss';
import './operation/operation.sales.scss';
import './salesYear/salesYear.scss'

import CommentsService from './comments/comments.service';
Application.registerService('commentsService', CommentsService);

import DiscountsService from './discounts/discounts.service';
Application.registerService('discountsService', DiscountsService);

import DiscountDetailsDisplayService from './discounts/discountDetailsDisplay.service';
Application.registerService('discountDetailsDisplayService', DiscountDetailsDisplayService);

import BudgetInfoService from './budgetInfo/budgetInfo.service';
Application.registerService('budgetInfoService', BudgetInfoService);

import OperationSalesComponent from './operation/operation.sales.component';
Application.registerComponent('operations', OperationSalesComponent.factory());

import RequiredApprovalsComponent from './approvals/required.approvals.component';
Application.registerComponent('approvals', RequiredApprovalsComponent.factory());

import DiscountsV2Component from './discounts/discountsV2.component';
Application.registerComponent('discountsV2', DiscountsV2Component.factory());

import SalesYearsComponent from './salesYear/salesYear.component';
Application.registerComponent('salesYears', SalesYearsComponent.factory());

import DiscountsV3Component from './discounts/discountsV3.component';
Application.registerComponent('discountsV3', DiscountsV3Component.factory());

import DiscountsV5Component from './discounts/discountsV5.component';
Application.registerComponent('discountsV5', DiscountsV5Component.factory());

import CommentsComponent from './comments/comments.component';
Application.registerComponent('comments', CommentsComponent.factory());

import DetailBarComponent from './detailBar/detailBar.component';
Application.registerComponent('detailBar', DetailBarComponent.factory());

import BudgetInfoComponent from './budgetInfo/budgetInfo.component';
Application.registerComponent('budgetInfo', BudgetInfoComponent.factory());

import ViewDocumentComponent from './viewDocument/viewDocument.component';
Application.registerComponent('viewDocument', ViewDocumentComponent.factory());

import OperationProductLineSalesComponent from './operation/operationProductLineSales.component';
Application.registerComponent('operationsGrowth', OperationProductLineSalesComponent.factory());

// Focus Directive
import CommentsFocusInputDirective from './comments/commentsFocusInput.directive';
Application.registerDirective('commentsFocusInput', CommentsFocusInputDirective.factory());

import ApproverSearchBarService from './approverSearchBar/approverSearchBar.service';
Application.registerService('approverSearchBarService', ApproverSearchBarService);

import ApproverSearchBarSideNavService from './approverSearchBar/approverSearchBarSideNav.service';
Application.registerService('approverSearchBarSideNavService', ApproverSearchBarSideNavService);

import ApproverSearchBarComponent from './approverSearchBar/approverSearchBar.component';
Application.registerComponent('approverSearchBar', ApproverSearchBarComponent.factory());