import { InfinityPackageOverrideDetail } from './infinityPackageOverrideDetail.model';

export default class InfinityPackageOverrideDetailService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    getDetail(requestId: number, invoiceId: number, year: number, shareId: number) {
        return this.$http.get(`{API-ApprovalTool}/packageoverride/requests/${requestId}/invoices/${invoiceId}/year/${year}/shares/${shareId}/detail`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return new InfinityPackageOverrideDetail(                  
                    this.processRequiredApprovalsResponse(response.data.requiredApprovals),
                    response.data.comments,
                    response.data.operationSales,
                    response.data.currentInfinityPackage);
            });
    }

    private processRequiredApprovalsResponse(data: any): any {
        return {
            approvalList: data.approvalList,
            finalApprovalDate: data.finalApprovalDate
        }; 
    }
}  