import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';
import { PendingPackageOverrideRequest } from '../pendingPackageOverrideRequest.model';

import { InfinityPackageOverrideDetail } from './infinityPackageOverrideDetail.model';
import InfinityPackageOverrideDetailService from './infinityPackageOverrideDetail.service';
import { ShareDiscountV2 } from '../../components/discounts/models/shareDiscountV2.model';
import DiscountsService from '../../components/discounts/discounts.service';

import { IInfinityPackageOverridePendingListService } from '../infinityPackageOverridePendingList.service.interface';

export default class InfinityPackageOverrideDetailController {

    loading: boolean = true;

    request: PendingPackageOverrideRequest;
    detail: InfinityPackageOverrideDetail = InfinityPackageOverrideDetail.empty();
    shareDiscounts: ShareDiscountV2;
    finalApprovalDate: string = '';
    user: IUser;

    shareCurrentDiscounts: any;
    customerPreviousDiscounts: any;
    customerCurrentDiscounts: any;

    allowComment: boolean = false;
    isPermissionStopDatePassed: boolean = true;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private requestLocal: PendingPackageOverrideRequest,
        private infinityPackageOverridePendingListService: IInfinityPackageOverridePendingListService,
        private userService: IUserService,
        private infinityPackageOverrideDetailService: InfinityPackageOverrideDetailService,
        private discountsService: DiscountsService
    ) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
        this.request = this.requestLocal;
        this.checkPermissionStopDatePassed();
        this.user = this.userService.get();
        this.allowComment = this.user.canApproveCpaRequests;

        return this.getDetail()
            .finally(() => this.done());
    }

    approve(): void {
        this.request.processing = true;

        this.infinityPackageOverridePendingListService.approve(this.request.requestId)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;

        this.infinityPackageOverridePendingListService.deny(this.request.requestId)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => this.request.processing = false);
    }

    canApprove(): boolean {
        return this.user.canApprove && this.request.canApprove && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    canDeny(): boolean {
        return this.user.canApprove && this.request.canDeny && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    checkPermissionStopDatePassed(): void {
        this.infinityPackageOverridePendingListService.isPermissionStopDatePassed(this.request)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                if (response.data.length > 0) {
                    this.isPermissionStopDatePassed = !_.any(response.data, (s: any) => {
                        return s.salesYear === this.request.salesYear;
                    });
                }
            });
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    isPending(status: string): boolean {
        return status === 'Pending';
    }


    private setActionPermissions(canApprove: boolean, canDeny: boolean): void {
        this.request.canApprove = canApprove;
        this.request.canDeny = canDeny;
    }

    private getDetail() {

        this.getDiscounts();

        return this.infinityPackageOverrideDetailService.getDetail(this.request.requestId, this.request.invoice.id, this.request.salesYear, this.request.shareId)
            .then((detail: InfinityPackageOverrideDetail) => {
                this.detail = detail;
            });
    }

    private async getDiscounts() {

        await this.discountsService.getCustomerCurrentDiscountsV2(this.request.shareId)
            .then((currentCustomerDiscounts: any) => {
                this.customerCurrentDiscounts = currentCustomerDiscounts;
            });

        await this.discountsService.getCpaShareCurrentDiscounts(this.request.requestId,
            this.request.invoice.id,
            this.request.shareId)
            .then((shareCurrentDiscounts: any) => {
                this.shareCurrentDiscounts = shareCurrentDiscounts;
            });

        return await this.discountsService.getCustomerPreviousDiscounts2020V2(this.request.shareId)
            .then((previousCustomerDiscounts: any) => {
                this.customerPreviousDiscounts = previousCustomerDiscounts;
            });
    }
 
    private done(): void {
        this.loading = false;
    }
}