module.exports = function ($filter) {

    const numberFilter = $filter('number');

    const numberOfUnsignificantFigures = function (string) {
        'option strict';

        var count = 0;
        const decimalSeparator = '.';
        if (string.indexOf(decimalSeparator) > -1) {
            for (var i = string.length - 1; i > 0; i--) {
                if (string[i] === '0' || string[i] === decimalSeparator) {
                    count++;
                    if (string[i] === decimalSeparator) {
                        break;
                    }
                } else {
                    break;
                }
            }
        }
        return count;
    };

    const filter = function (input, trimTrailingZeros, precision, spaceBetweenPercent) {
        'option strict';

        input *= 100;
        trimTrailingZeros = trimTrailingZeros || false;
        spaceBetweenPercent = spaceBetweenPercent || false;
        precision = precision || 1;

        var formatted = numberFilter(input, precision);

        if (formatted && trimTrailingZeros) {
            const sliceFromTheEnd = numberOfUnsignificantFigures(formatted);
            formatted = formatted.slice(0, sliceFromTheEnd !== 0 ? sliceFromTheEnd * -1 : formatted.length);
        }

        return formatted + (spaceBetweenPercent ? ' %' : '%');
    };

    return filter;

};