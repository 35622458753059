import {IReplantRequestsService} from './replantRequests.service.interface';
import {IReplantRequestsController} from './replantRequests.controller.interface';
import {IUser} from '../../../core/models/user.model.interface';
import {IUserService} from '../../../core/services/user.service.interface';

import {IReplantRequest} from './replantRequest.model.interface';
import {ReplantRequest} from './replantRequest.model';

import {ReplantRequestReplacementLineItem} from './replantRequestReplacementLineItem.model';

import ReplantDetailDisplayService from './detail/replantDetailDisplay.service';

export default class ReplantRequestsController  {

    requestsReplacement: ReplantRequestReplacementLineItem[];
    requests: IReplantRequest[];
    loading: boolean = true;
    canShowTable: () => boolean = (() => true);
    total: number = 0;
    visitedPages: number[] = [];
    requestGroups: Object;
    canPageForward: boolean = false;
    canPageBack: boolean = false;
    beginIndicator: number;
    endIndicator: number;
    nothingToApprove: () => boolean = this.nothingToApproveImpl;
    replacementRowName: string;
    spanElementName: string;
    isReplantCommentDisplayEnabled: boolean = false;    

    query: { order: string; limit: number; page: number; begin: number, end: number } = {
        order: 'requestedDate',
        limit: 10,
        page: 1,
        begin: 0,
        end: 10
    };

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService,
        private $mdDialog: ng.material.IDialogService,
        private replantRequestsService: IReplantRequestsService,
        private userService: IUserService,
        private $timeout: ng.ITimeoutService,
        private replantDetailDisplayService: ReplantDetailDisplayService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        $fsLDFeatureFlagService.isEnabledAsync('Foxtrot.298963.ApprovalToolReplantComment')
            .then((enabled) => this.isReplantCommentDisplayEnabled = enabled);

        this.requests = [];
        this.requestsReplacement = [];
        this.activate();
    }

    user: IUser;

     openLeftNav(): void {
         this.$mdSidenav('left').open();
     }

    private activate() {
        this.user = this.userService.get();
        this.getPending();
    }

    private getPending(): void {
        this.replantRequestsService.getList()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private done(): void {
        this.loading = false;
    }

    private processResponse(response: any): void {
        this.requests = response.data.pendingRequestList;
        this.total = response.data.pendingRequestList.length;
        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests.slice(this.query.begin, this.query.end);
        angular.forEach(requests, function (request) {
            request.processing = false;
            request.isApproved = false;
        });
        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, function (request) {
            return request.salesPeriod.period + ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.salesPeriod.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;

        if (this.visitedPages.indexOf(this.query.page) < 0) {
            this.visitedPages.push(this.query.page);
        }

        this.updatePagination();
    }

    private updatePagination(): void {
        this.canPageForward = this.canPaginateForward();
        this.canPageBack = this.canPaginateBackward();
        this.setIndicators();
    }

    private canPaginateForward(): boolean {
        return this.requests.length > this.query.end;
    }

    private canPaginateBackward(): boolean {
        return this.query.page > 1;
    }

    private setIndicators(): void {
        this.beginIndicator = this.query.begin + 1;

        this.endIndicator = this.requests.length > this.query.end ? this.query.end : this.requests.length;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    paginateForward(): void {
        this.query.page++;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    paginateBackward(): void {
        this.query.page--;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    approve($event: MouseEvent, replantRequest: any): void {
        $event.stopPropagation();
        replantRequest.processing = true;

        this.replantRequestsService.approve(replantRequest)
            .then(() => {
                replantRequest.isApproved = true;
                this.setActionPermissions(replantRequest, false, false);
            })
            .finally(() => replantRequest.processing = false);
    }

    deny($event: MouseEvent, replantRequest: any): void {
        $event.stopPropagation();
        replantRequest.processing = true;

        this.replantRequestsService.deny(replantRequest)
            .then(() => {
                replantRequest.isDenied = true;
                this.setActionPermissions(replantRequest, false, false);
            })
            .finally(() => replantRequest.processing = false);
    }

    canApprove(replantRequest: any): boolean {
        return this.user.canApprove && replantRequest.canApprove && !replantRequest.processing;
    }

    canDeny(replantRequest: any): boolean {
        return this.user.canDeny && replantRequest.canDeny && !replantRequest.processing;
    }

    showComment(comment): void {
        this.$mdDialog.show(
            this.$mdDialog.alert()
                .clickOutsideToClose(true)
                .title('Comment')
                .textContent(comment)
                .ok('Close')
        );
    };

    viewDetails($event: MouseEvent, request: IReplantRequest) {
        if (request.processing) {
            return;
        }
        this.replantDetailDisplayService.showReplantDetails($event, request, 'ReplantDetailController').catch(() => { });
    }

    private setActionPermissions(request: any, canApprove: boolean, canDeny: boolean): void {
        request.canApprove = canApprove;
        request.canDeny = canDeny;
    }

    private moreOrLessReplants(replant: any, $event: MouseEvent): void {
        if ($event) {
            $event.stopPropagation();
        }

        this.replacementRowName = 'row' + replant.requestId;
        this.spanElementName = document.getElementById(this.replacementRowName).innerHTML;
        if (this.spanElementName === '(more...)') {
            this.replantRequestsService
                .getReplantReplacementList(replant.requestId)
                .then((data) => {
                    this.processLineItemResponse(replant, data);
                    document.getElementById(this.replacementRowName).innerHTML = '(less...)';
                    })
                .finally(() => this.done());
        } else {
            this.hideReplacementLineItem(replant);
            document.getElementById(this.replacementRowName).innerHTML = '(more...)';
        }
    }

    private hideReplacementLineItem(rep: any): void {
        document.getElementById(rep.requestId).innerHTML = `${rep.replacementProductId} ${rep.replacementSubproductId}: ${rep.replacementUnits}`;
    }

    private processLineItemResponse(replant: any, response: any): void {
        let finalReplacementLineItem: string = '';
        let eol: string = ', <br />';
        this.requestsReplacement = response.data.pendingRequestList;
        this.requestsReplacement.forEach((item) => {
            finalReplacementLineItem = `${finalReplacementLineItem}${item.productId} ${item.subproductId}: ${item.units}${eol}`;
        });
        document.getElementById(replant.requestId).innerHTML = finalReplacementLineItem.slice(0, (-1 * eol.length));
    }
}