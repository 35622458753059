import { BwoRequestData } from './models/bwoRequest.data.model';
import { RequestCriteria } from './models/bwoRequest.criteria.model';
import { BwoRequestOptions } from './models/bwoRequest.options.model';
import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';

export class BwoRequestService {

    constructor(
        private $http: ng.IHttpService,
        private $q: ng.IQService) { }

    getSalesPeriods(): ng.IPromise<ng.IHttpPromiseCallbackArg<BwoRequestOptions>> {
        return this.$http.get('{API-ApprovalTool}/balance-writeoff-requests/sales-periods/list');
    }

    getTerritories(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IOrgIdAndName[]>> {
        return this.$http.get('{API-ApprovalTool}/balance-writeoff-requests/create-options/territories');
    }

    getSalesAgencies(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        return this.$http.get(`{API-ApprovalTool}/balance-writeoff-requests/create-options/salesagencies-by-territory/${criteria.territory.orgId}`);
    }

    getOperations(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        return this.$http.get(`{API-ApprovalTool}/balance-writeoff-requests/create-options/operations-by-salesagency/${criteria.salesAgency.id}`);
    }

    getInvoicesShares(salesPeriodId: number, operationId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/balance-writeoff-requests/create-options/invoice-shares/operation/${operationId}/salesPeriod/${salesPeriodId}`);
    }

    getBwoTypes(): ng.IPromise<ng.IHttpPromiseCallbackArg<IIdAndName[]>> {
        return this.$http.get('{API-ApprovalTool}/requests/bwo/types');
    };

    getPioneerBalance(invoiceId: number, shareId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/balance-writeoff-requests/pioneer-balance/invoices/${invoiceId}/shares/${shareId}`);
    }

    getDeferredPaymentLoanBalance(customerId: number, salesYear: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/balance-writeoff-requests/deferred-payment-loan-balance/customer/${customerId}/salesYear/${salesYear}`);
    }

    submit(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: BwoRequestData = this.getData(criteria);
        return this.$http.post(`{API-ApprovalTool}/requests/bwo/create`, data);
    }

    supportSubmit(criteria: RequestCriteria): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: BwoRequestData = this.getData(criteria);
        return this.$http.post(`{API-ApprovalTool}/support/requests/bwo/create`, data);
    }

    private getData(criteria: RequestCriteria): BwoRequestData {
        return new BwoRequestData(
            criteria.accountDescription.invoiceId,
            criteria.businessPartner.shareId,
            criteria.businessPartner.name,
            criteria.amount,
            criteria.comment,
            criteria.bwoType.id,
            criteria.territory.orgId,
            criteria.operation.id,
            criteria.operation.name,
            criteria.salesAgency.id,
            criteria.salesAgency.name,
            criteria.accountDescription.name,
            criteria.businessPartner.businessPartnerId,
            criteria.salesSeason.year,
            criteria.salesSeason.seasonId,
            criteria.salesSeason.salesPeriodId,
            criteria.isFullBalanceWriteOff,
            new Date()
        );
    }

}