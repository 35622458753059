// thirdparty tools are bundled by themselves via /thirdparty/thirdparty.js

require('../globals/globalLDFeatureFlag.service.js');
global.Application = require('./utilities/Application');
global.Ensure = require('./utilities/Ensure');
global.Authentication = require('./core/authentication/authentication');

require.context('./content/images', true, /^\.\//);
// require('./content/images/favicon.ico');
require('./approvalTool.module');
