/* eslint-disable angular/di */
angular
    .module('approval-tool')
    .factory(
        'appInsightsClientVersionService',
        [
            'appInsightsClientVersionRepository',
            function (appInsightsClientVersionRepository) {
                'use strict';

                const Key = 'telemetry-client-version';

                return {
                    configure: function (headers) {
                        var headerValue = appInsightsClientVersionRepository.getClientVersion();

                        // Do not send the client version unless present as not all consumers may choose to track it
                        if (headerValue) {
                            headers[Key] = headerValue;
                        }
                    }
                };
            }
        ]
    );