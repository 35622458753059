import { BalanceWriteOffRequestStatus } from '../../balanceWriteOff/core/balanceWriteOffRequestStatus.model';

export default class SupportSearchBalanceWriteOffService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchResults(criteria: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: string = `"${criteria}"`;
        return this.$http.post(`{API-ApprovalTool}/requests/supportsearch/balance-write-off`, data);
    }

    getRequestStatus(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<BalanceWriteOffRequestStatus>> {
        return this.$http.get(`{API-ApprovalTool}/balance-write-off/requests/${requestId}/status`);
    }
}
