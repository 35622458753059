import './loanTypeChangeNavigation.scss';

import LoanTypeChangeNavigationController from './loanTypeChangeNavigation.controller';

export default class LoanTypeChangeNavigationComponent implements ng.IComponentOptions {
    template: string = require('./loanTypeChangeNavigation.html');
    controller: Function = LoanTypeChangeNavigationController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component: () => LoanTypeChangeNavigationComponent = () => {
            return new LoanTypeChangeNavigationComponent();
        };

        return component();
    }
}