import UnitSettlementChargesComponentController from './unitSettlementCharges.controller';

export default class BalanceExceptionUnitSettlementChargesComponentV2 implements ng.IComponentOptions {
    template: string = require('./unitSettlementCharges.html');
    controller: Function = UnitSettlementChargesComponentController;
    bindings: { [binding: string]: string } = {
        form: '<',
        criteria: '<',
        loading: '<',
        options: '<',
        canSelectType: '<',
        onOpen: '&',
        onTypeSelect: '&'
    };

    static factory() {
        const component: () => ng.IComponentOptions = () => {
            return new BalanceExceptionUnitSettlementChargesComponentV2();
        };

        return component();
    }
}