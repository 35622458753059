import {IMonthlyDrawRequest} from './monthlyDrawRequest.interface';
import {IMonthlyDrawRequestDetails} from './monthlyDrawRequestDetails.interface';
import {MonthlyDrawRequestRow} from './monthlyDrawRequestRow';
import {IMonthlyDrawRequestService} from './monthlyDrawRequest.service.interface';
import {RequestStatusTypes} from '../core/requestStatusTypes';
import {ICommissionRequestActivity} from '../commission/commissionRequestActivity.interface';

export class MonthlyDrawDetailController {

    row: MonthlyDrawRequestRow;
    isLoading: boolean = true;
    activity: Array<ICommissionRequestActivity>;

    constructor(private $mdDialog: ng.material.IDialogService,
        public request: IMonthlyDrawRequest,
        public details: IMonthlyDrawRequestDetails,
        private monthlyDrawRequestService: IMonthlyDrawRequestService) {
        
    }

    $onInit(): void {
        // load details
        if (this.details == null) {
            this.getDetails();
        } else {
            this.loadDetails(this.details);
            this.doneLoading();
        }

        this.loadActivity();
    }

    cancel() {
        if (this.request.statusId === RequestStatusTypes.pending) {
            this.details.overrideDrawAmount = undefined;
        }

        this.$mdDialog.cancel();
    }

    approve(): void {
        if (this.row.details.drawAmount <= 0) {
            return;
        }

        this.request.processing = true;
        this.row.request.statusId = RequestStatusTypes.approved;
        this.monthlyDrawRequestService.approveDeny(this.row)
            .then(() => {
                this.reload();

                this.loadActivity();
            }).catch(() => {
                this.row.request.statusId = RequestStatusTypes.pending;
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;
        this.row.request.statusId = RequestStatusTypes.denied;
        this.monthlyDrawRequestService.approveDeny(this.row)
            .then(() => {
                this.reload();

                this.loadActivity();
            }).catch(() => {
                this.row.request.statusId = RequestStatusTypes.pending;
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    canApproveDeny(statusId: number): boolean {
        return statusId === RequestStatusTypes.pending;
    }

    isApproved(statusId: number): boolean {
        return statusId === RequestStatusTypes.approved;
    }

    isDenied(statusId: number): boolean {
        return statusId === RequestStatusTypes.denied;
    }

    getDrawAmountToDisplay(): number {
        return this.details.overrideDrawAmount > 0
            ? this.details.overrideDrawAmount
            : (this.details.drawAmount < 0 ? 0.0 : this.details.drawAmount);
    }

    private getDetails() {
        this.isLoading = true;
        this.monthlyDrawRequestService.getMonthlyDrawDetails(this.request.salesYear, this.request.salesAgencyId, this.request.monthlyDrawDateId)
            .then(details => this.loadDetails(details))
            .finally(() => this.doneLoading());
    }

    private loadDetails(details: IMonthlyDrawRequestDetails) {
        this.details = details;
        this.row = new MonthlyDrawRequestRow(this.request);
        this.row.addDetails(this.details);
    }

    private doneLoading() {
        this.isLoading = false;
    }

    private reload() {
        const requestId: number = this.request.requestId;

        this.monthlyDrawRequestService.getSavedMonthlyDrawRequest(requestId)
            .then(row => {
                this.row = row;

                this.request.statusId = row.request.statusId;
                this.request.status = row.request.status;

                this.details = row.details;
                this.doneLoading();
            });
    }

    private loadActivity() {
        const requestId: number = this.request.requestId;

        this.monthlyDrawRequestService.getActivity(requestId)
            .then(result => {
                this.activity = result;
            });
    }
}