export default class PricingNavigationComponentController  {
    // bindings
    page: string;
    canRequest: boolean;
    canMassApprove: boolean;

    constructor(
        private $location: ng.ILocationService) {
    }

    navToPending(): void {
        this.$location.url('/requestType-Pricing/');
    }

    navToCreateRequest(): void {
        this.$location.url('/requestType-Pricing/create');
    }

    navToCreateTreatmentRequest(): void {
        this.$location.url('/requestType-PricingTreatment/create');
    }

    navToAllPricingRequests(): void {
        this.$location.url('/requestType-Pricing/all');
    }

    navToMassProductPricingRequests(): void {
        this.$location.url('/requestType-Pricing/massProduct')
    }

    navToMassTreatmentPricingRequests(): void {
        this.$location.url('/requestType-Pricing/massTreatment')
    }

    openMenu($mdMenu: any, ev: MouseEvent): void {
        $mdMenu.open(ev);
    }
}