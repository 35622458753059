import { CustomerDetailsModel } from './customerDetails.model';

export default class PlanningCustomerDetailsService {

    constructor(private $http: ng.IHttpService,
                private $q: ng.IQService) {
        // no-op
    }

    getCustomerDetails(territoryId: string, salesAgencyId: number, customerId: number, agencyCustomerId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<CustomerDetailsModel>> {
        return this.$http.get(`{API-ApprovalTool}/bpp/territory/${territoryId}/agencies/${salesAgencyId}/customers/${customerId}/agency-customer/${agencyCustomerId}`);
    }

    saveCustomerProductLineUnits(salesAgencyId: number, agencyCustomerId: number, productLineId: string, doubleCrop: boolean, units: number) {
        return this.$http.post(`{API-ApprovalTool}/bpp/sales-agencies/${salesAgencyId}/customer-summary/agency-customers/${agencyCustomerId}/product-lines/${productLineId}/double-crop/${doubleCrop}/units`, units);
    }
}
