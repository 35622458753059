module.exports = function () {

    const filter = function (input, chars) {
        // return input if characters is non numeric  value
        if (!angular.isNumber(chars)) return input;
        // return input if inputted value is not a string or empty
        if (angular.isString(input) && input.length <= 0) return input;
        // return input if number of characters is greater than length of input
        if (angular.isString(input) && chars > input.length) return input;

        return input.substring(0, chars) + '...';
    };

    return filter;
}