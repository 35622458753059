import {IRedirectService} from '../../../../core/services/redirect.service.interface';
import {IUser} from '../../../../core/models/user.model.interface';
import {IUserService} from '../../../../core/services/user.service.interface';

export default class BalanceWriteOffNavigationController  {
    // bindings
    page: string;
    user: IUser;

    constructor(
        private $location: ng.ILocationService,
        private redirectService: IRedirectService,
        private userService: IUserService) {}

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();
    }

    navToPending(): void {
        this.redirectService.redirectToBalanceWriteOffPendingRequests();
    }

    navToAllPending(): void {
        this.redirectService.redirectToAllBalanceWriteOffPendingRequests();
    }

    navToSearch(): void {
        this.redirectService.redirectToBalanceWriteOffSearch();
    }

    navToBalanceWriteOffCreateRequest(): void {
        this.redirectService.redirectToBalanceWriteOffCreateRequest();
    }

    canRequestBalanceWriteOff(): boolean {
        return this.user.canCreate;
    }
}