import SampleCommentsController from './sampleComments.controller';

export default class SampleCommentsComponent implements ng.IComponentOptions {
    template: string = require('./sampleComments.html');
    bindings: { [binding: string]: string } = {
        comments: '<',
        requestShareId: '<',
        allowComment: '<?'
    };
    controller: Function = SampleCommentsController;

    static factory() {
        const component: Function = () => {
            return new SampleCommentsComponent();
        };

        return component();
    }
}