import AgencySummaryService from './agencySummary.service';
import { AgencySummaryModel } from './agencySummary.model';
import { BusinessPartnerLineModel } from './businessPartnerLine.model';
import PlanningCustomerDetailsController from '../customerDetails/planningCustomerDetails.controller';
import PlanningCustomerDiscountService from '../core/planningCustomerDiscount.service';
import IPromise = angular.IPromise;

export default class AgencySummaryController {
    loading: boolean = true;
    saving: boolean = false;
    updateSummary: boolean = false;
    agencyData: AgencySummaryModel;
    agencySummaryForm: ng.IFormController;
    sortOrder: string = '-adjustedGrossSalesPriorYear';
    sortedCustomerList: BusinessPartnerLineModel[];
    searchName: string;
    private currentCustomerTotalPlan: number;
    private currentCustomerFlexFundPlan: number;

    constructor(public salesAgencyId: number,
        public salesAgencyName: string,
        private territoryId: string,
        private isInseason: boolean,
        private $mdDialog: ng.material.IDialogService,
        public agencySummaryService: AgencySummaryService,
        private $filter: ng.IFilterService,
        private planningCustomerDiscountService: PlanningCustomerDiscountService) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): IPromise<void> {
        return this.loadSummary();
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    private loadSummary(): IPromise<void> {
        this.loading = true;
        
        return this.agencySummaryService.getAgencySummary(this.salesAgencyId).then(t => {
            this.agencyData = t.data;
        }).finally(() => this.done());
    }

    getMessage(val: number, isPriorYear?: boolean) {
        if (val === -1 && isPriorYear) {
            return 'N/A';
        }

        return this.formatNumberToPercentage(val);
    }

    private formatNumberToPercentage(val: number) {
        return (this.$filter('percentage') as Filter.IFilterPercentage)(val, false, 2, true);
    }

    onFocusFlexFundTarget(customer: BusinessPartnerLineModel) {
        this.currentCustomerFlexFundPlan = customer.flexFundTargetPlan;
        customer.warnFlexFund = false;
    }

    onFocusTotalDiscountTarget(customer: BusinessPartnerLineModel) {
        this.currentCustomerTotalPlan = customer.totalDiscountTargetPlan;
        customer.warnTotalDiscount = false;
    }

    saveTotalDiscountTargetPlan(customer: BusinessPartnerLineModel, validator: ng.INgModelController) {
        if (!this.shouldDisableRow(customer) && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.currentCustomerTotalPlan = undefined;
  
            this.planningCustomerDiscountService.saveTotalDiscountTargetPlan(customer.agencyCustomerId, customer.totalDiscountTargetPlan)
                .then(() => this.recalculateSummaryInfo())
                .finally(() => this.saving = false);
        } else if (!validator.$valid && validator.$dirty) {
            customer.warnTotalDiscount = true;
            validator.$rollbackViewValue();
            customer.totalDiscountTargetPlan = this.currentCustomerTotalPlan || 0;
            validator.$setPristine();
        }
    }

    saveFlexFundTargetPlan(customer: BusinessPartnerLineModel, validator: ng.INgModelController) {
        if (!this.shouldDisableRow(customer) && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.currentCustomerFlexFundPlan = undefined;

            this.planningCustomerDiscountService.saveCustomerDiscountTargetAmount(this.salesAgencyId, customer.agencyCustomerId, 1, customer.flexFundTargetPlan)
                .then(() => this.recalculateSummaryInfo())
                .finally(() => this.saving = false);
        } else if (!validator.$valid && validator.$dirty) {
            customer.warnFlexFund = true;
            validator.$rollbackViewValue();
            customer.flexFundTargetPlan = this.currentCustomerFlexFundPlan || 0;
            validator.$setPristine();
        }
    }

    saveLoadFlexFund(customer: BusinessPartnerLineModel, validator: ng.INgModelController) {
        if (!this.shouldDisableCheckbox(customer) && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.planningCustomerDiscountService.saveLoadFlexFund(this.salesAgencyId,
                    customer.agencyCustomerId,
                    customer.loadFlexFund)
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    recalculateSummaryInfo() {
        this.agencySummaryService.getAgencySummary(this.salesAgencyId).then(t => {
            this.agencyData.customerSummary = t.data.customerSummary;
        });
    }

    private done(): void {
        this.loading = false;
    }

    openCustomerDetails($event: MouseEvent, agencyCustomerId: number, customerId: number, customerName: string): void {
        const property: string = this.sortOrder.replace('-', '');

        if (this.sortOrder.indexOf('-') > -1) {
            this.sortedCustomerList = _.sortByOrder(this.agencyData.customers, [property], [false]);
        }
        else {
            this.sortedCustomerList = _.sortByOrder(this.agencyData.customers, [property], [true]);
        }

        this.$mdDialog.show({
            template: require('./../customerDetails/customerDetails.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: PlanningCustomerDetailsController,
            controllerAs: 'vm',
            locals: {
                salesAgencyId: this.salesAgencyId,
                customerId: customerId,
                agencyCustomerId: agencyCustomerId,
                customerName: customerName,
                territoryId: this.territoryId,
                customers: this.sortedCustomerList,
                sortOrder: this.sortOrder,
                searchName: this.searchName
            },
            fullscreen: true,
            skipHide: true,
            hasBackdrop: false
        }).finally(() => {
            if (this.agencyData.canEdit) {
                this.loadSummary();
            }
        });
    }

    shouldDisableRow(model: BusinessPartnerLineModel) {
        return model.hasOtherDiscounts || !this.agencyData.canEdit;
    }

    shouldDisableCheckbox (model: BusinessPartnerLineModel) {
        return this.saving || !this.agencyData.canEdit;
    }
}