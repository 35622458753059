import {IComment} from './comment.model.interface';

export class Comment implements IComment {

    constructor(
        public requestId: number,
        public comment: string,
        public requestCommentId: number = 0,
        public displayName: string = '',
        public commentDate: string = '1900-01-01'
    ) { }
}