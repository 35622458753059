import './infinityPackageOverrideRequest.scss';

import routing from './infinityPackageOverrideRequest.routes';
Application.registerConfig(routing);

import InfinityPackageOverrideRequestController from './infinityPackageOverrideRequest.controller';
Application.registerController('InfinityPackageOverrideRequestController', InfinityPackageOverrideRequestController);

import InfinityPackageOverrideRequestService from './infinityPackageOverrideRequest.service';
Application.registerService('infinityPackageOverrideRequestService', InfinityPackageOverrideRequestService);

