import ProposalCpaDetailBarComponentController from './proposalCpaDetailBar.component.controller';

export default class ProposalCpaDetailBarComponent implements ng.IComponentOptions {
    template: string = require('./proposalCpaDetailBar.html');
    bindings: { [binding: string]: string } = {
        request: '<',
        detail: '<'
    };
    controller: Function = ProposalCpaDetailBarComponentController;

    static factory() {
        const component: any = () => {
            return new ProposalCpaDetailBarComponent();
        };

        return component();
    }
}