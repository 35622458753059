import { RequestOptions } from '../../core/requestOptions.model';
import BalanceExceptionRequestMaterialCriteria from '../../core/balanceExceptionRequestMaterial.criteria.model';
import { AgencyBalanceExceptionTypeMaterial } from '../../core/agencyBalanceExceptionTypeMaterial.model';

export default class BalanceExceptionInformationComponentController {
    formType: string = 'simple';

    options: RequestOptions;
    criteria: BalanceExceptionRequestMaterialCriteria;
    filters: any = [];
    filteredMaterials: any;
    selectedSubproduct: string;
    selectedProduct: string;
    materials: any;
    filter: ng.IFilterService;
    getMaterials: any;
    clearMaterial: any;
    searchAllMaterials: boolean = false;

    constructor(private $scope: ng.IScope, private $filter: ng.IFilterService) {}

    $onInit(): void {
        this.$scope.$watch('$ctrl.criteria.agencyBalanceExceptionType', () => {
            this.setFormType();
        });

        this.filter = this.$filter;

        this.$scope.$watch('$ctrl.materials', () => {
            this.filteredMaterials = this.materials;
            this.initFilters();
            this.applyFilters();
        });
    }

    setFormType(): void {
        let type: string = 'simple';
        if (this.criteria.agencyBalanceExceptionType) {
            if (this.criteria.agencyBalanceExceptionType.requestType === 4) {
                type = 'unit-settlement';
            }
        }

        this.formType = type;
    }

    canSelectExceptionTypes(): boolean {
        return !_.isEmpty(this.options.exceptionTypes) && !_.isUndefined(this.criteria.territory);
    }

    onExceptionTypeSelect(type: AgencyBalanceExceptionTypeMaterial): void {
        this.criteria.agencyBalanceExceptionType = type;
        this.clearMaterial();
        if (this.criteria.agencyBalanceExceptionType && this.criteria.agencyBalanceExceptionType.isMaterialRequired) {
            this.getMaterials();
        }
    }

    isValueSelected(filter: any, value: any, property: string): any {
        return filter.selected === value[property];
    }

    applySubproductFilter(dataSet: any): void {
        let filter = this.filters.productId;
        this.filteredMaterials = dataSet;
        this.filteredMaterials = this.applyFilter(this.filteredMaterials, filter);

        this.setFilteredOptions(this.filters.subproductId);

        filter = this.filters.subproductId;
        this.filteredMaterials = this.applyFilter(this.filteredMaterials, filter);

        this.selectedSubproduct = this.filters.subproductId.selected;
    }

    applyFilter(dataSet: any, filter: any): any {
        if (!filter) {
            return dataSet;
        }

        let property: any = filter.property;
        let filtered: any = dataSet;
        if (filter.hasValue()) {
            filtered = this.$filter('filter')(dataSet, (value: any) => {
                return filter.compareFunction(filter, value, property);
            });
        }
        return filtered;
    }

    applyFilters(): void {
        this.filters.productId.selected = this.selectedProduct;
        this.applySubproductFilter(this.materials);
    }

    initArrayFilter(name: string, property: string, compareFunction: any): void {
        const filter: any = {
            property: property,
            options: [],
            selected: undefined,
            searched: '',
            compareFunction: compareFunction,
            hasValue: () => {
                return this.filters[name].selected !== undefined;
            }
        };

        this.filters[name] = filter;
    }

    createDistinctList(arrArg: any): any {
        return arrArg.filter((elem: any, pos: any, arr: any) => {
            if (elem === '') {
                return false;
            }
            return arr.indexOf(elem) === pos;
        });
    };

    filteredByOptions(elem: string, options: any): any {
        const index: number = options.indexOf(elem);
        let selected: any = undefined;

        if (index !== -1) {
            selected = elem;
        }

        return selected;
    };

    setFilteredOptions(filter: any): void {
        if (!filter) {
            return;
        }

        if (this.filteredMaterials) {
            const options: any = this.createDistinctList(this.filteredMaterials.map((id: any) => { return { id: id[filter.property] }; }).map((id) => { return id.id; }));

            // setting binding
            if (filter.property === 'productId') {
                filter.selected = this.criteria.selectedProduct;
            }
            if (filter.property === 'subproductId') {
                filter.selected = this.criteria.selectedSubproduct;
            }

            const selected: any = this.filteredByOptions(filter.selected, options);
            filter.selected = selected;

            // setting binding
            if (filter.property === 'productId') {
                this.criteria.selectedProduct = filter.selected;
            }
            if (filter.property === 'subproductId') {
                this.criteria.selectedSubproduct = filter.selected;
            }

            filter.options = [];
            filter.options = options.map((id: any) => { return { label: id, value: id }; });
        }
    }

    initFilters(): void {
        this.initArrayFilter('productId', 'productId', this.isValueSelected);
        this.setFilteredOptions(this.filters.productId);

        this.initArrayFilter('subproductId', 'subproductId', this.isValueSelected);
        this.setFilteredOptions(this.filters.subproductId);
    }

    onFilterClose(filterProperty: string): void {
        this.clearSearchText(filterProperty);
        this.applyFilters();
    }

    clearSearchText(filterProperty: string): void {
        if (this.filters[filterProperty]) {
            this.filters[filterProperty].searchTerm = '';
        }
    }

    onSearchAllMaterialsChange(): void {
        this.getMaterials();
    }

    showUnits(): boolean {
        return this.criteria.agencyBalanceExceptionType &&
            this.criteria.agencyBalanceExceptionType.isMaterialRequired;
    }

    onUnitsChange(): void {
        if (this.showUnits()) {
            this.criteria.amount = this.criteria.units * this.criteria.perUnit;
        }
    }
} 