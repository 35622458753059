import './sampleNavigation.scss';

import SampleNavigationComponentController from './sampleNavigation.controller';

export default class SampleNavigationComponent implements ng.IComponentOptions {
    template: string = require('./sampleNavigation.html');
    controller: Function = SampleNavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new SampleNavigationComponent();
        };

        return component();
    }
}