import { ReplantDetail } from './replantDetail.model';

export default class ReplantDetailService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    getDetail(requestId: number): ng.IPromise<ReplantDetail> {
        return this.$http.get(`{API-ApprovalTool}/replant/requests/${requestId}/details`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return new ReplantDetail(response.data.comments, response.data.replantLineItems);
            });
    }
}