import {ISupportSearchMonthlyDrawService} from './supportSearchMonthlyDraw.service.interface';
import {ISupportSearchMonthlyDrawSearchResult} from './supportSearchMonthlyDrawSearchResult.interface';

import {RequestStatusTypes} from '../../core/requestStatusTypes';
import {MonthlyDrawDetailController} from '../../monthlyDraw/monthlyDrawDetail.controller';
import {IMonthlyDrawRequestService} from '../../monthlyDraw/monthlyDrawRequest.service.interface';
import {IMonthlyDrawRequest} from '../../monthlyDraw/monthlyDrawRequest.interface';
import {MonthlyDrawRequestRow} from '../../monthlyDraw/monthlyDrawRequestRow';

import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';

let async: any = require('async');

export default class MonthlyDrawPendingRequestController {

    maximumRowsToDisplay: number;

    searching: boolean = false;
    criteria: string = '';
    requests: Array<MonthlyDrawRequestRow>;
    totalRequests: number;
    shownRequests: number;

    private concurrentDetailsCalls: number;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchMonthlyDrawService: ISupportSearchMonthlyDrawService,
        private monthlyDrawRequestService: IMonthlyDrawRequestService
    ) {
        // no-op
    }

    $onInit(): void {
        this.maximumRowsToDisplay = 200;
        this.totalRequests = 0;
        this.shownRequests = 0;
        this.concurrentDetailsCalls = 3;
    } 

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

            this.supportSearchMonthlyDrawService.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    viewDetails($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        this.$mdDialog.show({
            template: require('../../monthlyDraw/monthlyDrawDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: MonthlyDrawDetailController,
            controllerAs: '$ctrl',
            locals: {
                request: row.request,
                details: row.details
            },
            fullscreen: true
        }).finally(() => {
            this.reloadData(row);
        });
    }

    approve($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        $event.stopPropagation();
        if (row.details.drawAmount <= 0) {
            return;
        }

        row.request.processing = true;
        row.request.statusId = RequestStatusTypes.approved;
        this.monthlyDrawRequestService.approveDeny(row)
            .then(() => {
                this.refreshSavedRow(row);
            })
            .catch(() => {
                row.request.statusId = RequestStatusTypes.pending;
                row.request.processing = false;
            });
    }

    deny($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        $event.stopPropagation();
        row.request.processing = true;
        row.request.statusId = RequestStatusTypes.denied;
        this.monthlyDrawRequestService.approveDeny(row)
            .then(() => {
                this.refreshSavedRow(row);
            })
            .catch(() => {
                row.request.statusId = RequestStatusTypes.pending;
                row.request.processing = false;
            });
    }

    canApproveDeny(statusId: number): boolean {
        return statusId === RequestStatusTypes.pending;
    }

    isApproved(statusId: number): boolean {
        return statusId === RequestStatusTypes.approved;
    }

    isDenied(statusId: number): boolean {
        return statusId === RequestStatusTypes.denied;
    }

    private processResponse(data: ISupportSearchMonthlyDrawSearchResult): void {
        this.requests = data.requestList.map(req => new MonthlyDrawRequestRow(req as IMonthlyDrawRequest));
        this.loadDetailsForRequests();

        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }

    private loadDetailsForRequests() {
        let queue = async.queue((row: MonthlyDrawRequestRow, callback: Function) => {
            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private getRequestDetails(row: MonthlyDrawRequestRow): ng.IPromise<any> {
        if (row.request.statusId === RequestStatusTypes.pending) {
            return this.monthlyDrawRequestService.getMonthlyDrawDetails(row.request.salesYear, row.request.salesAgencyId, row.request.monthlyDrawDateId)
                .then(details => {
                    if (!details.invalidRequestDetail) {
                        row.addDetails(details);
                    } else {
                        _.remove(this.requests, row);
                    }
                });
        } else {
            return this.monthlyDrawRequestService.getSavedDetails(row.request.requestId)
                .then(details => row.addDetails(details));
        }
    }

    private reloadData(monthlyDrawRow: MonthlyDrawRequestRow): void {
        if (monthlyDrawRow.request.statusId !== RequestStatusTypes.pending) {
            this.refreshSavedRow(monthlyDrawRow);
        }
    }

    private refreshSavedRow(row: MonthlyDrawRequestRow): void {
        this.monthlyDrawRequestService
            .getSavedMonthlyDrawRequest(row.request.requestId)
            .then(newRow => {
                row.request = newRow.request;
                row.details = newRow.details;
            })
            .finally(() => {
                row.request.processing = false;
            });
    }
}