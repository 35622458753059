import { IInfinityPackageOverrideCommentsController } from './infinityPackageOverrideComments.controller.interface';
import { IInfinityPackageOverrideComment } from './infinityPackageOverrideComment.model.interface';
import { InfinityPackageOverrideComment } from './infinityPackageOverrideComment.model';
import { IInfinityPackageOverrideCommentsService } from './infinityPackageOverrideComments.service.interface';

import {IUser} from '../../../../../core/models/user.model.interface';
import {IUserService} from '../../../../../core/services/user.service.interface';

export default class InfinityPackageOverrideCommentsController implements IInfinityPackageOverrideCommentsController {
    // component bindings
    comments: IInfinityPackageOverrideComment[];
    requestId: number;
    requestType: string;
    allowComment: boolean;

    commentForm: ng.IFormController;
    commentText: string = '';
    showCommentForm: boolean = false;

    processing: boolean = false;

    user: IUser;

    constructor(
        private infinityPackageOverrideCommentsService: IInfinityPackageOverrideCommentsService,
        private $window: ng.IWindowService,
        private userService: IUserService) { }

    $onInit(): void {
        this.user = this.userService.get();
    }

    canComment(): boolean {
        return angular.isDefined(this.allowComment) && this.allowComment;
    }

    addComment(): void {
        this.showCommentForm = true;
        this.resetForm();
    }

    cancel(): void {
        this.showCommentForm = false;
        this.resetFocus();
        this.resetForm();
    }

    saveComment(commentText: string): void {
        const comment = new InfinityPackageOverrideComment(this.requestId, commentText);

        this.processing = true;

        this.infinityPackageOverrideCommentsService.saveComment(comment)
            .then(() => this.processCommentResponse(comment))
            .finally(() => {
                this.processing = false;
                this.showCommentForm = false;

                this.resetFocus();
                this.resetForm();
            });
    }

    disableSubmit(): boolean {
        return this.commentForm && (this.commentForm.$invalid || this.processing);
    }

    showValidationMessages(): boolean {
        return this.commentForm && (this.commentForm.$dirty && this.commentForm.$invalid);
    }

    private processCommentResponse(comment: IInfinityPackageOverrideComment): void {
        comment.displayName = this.getDisplayName();
        comment.commentDate = new Date().getTime().toString();

        this.comments.push(comment);
    }

    private getDisplayName(): string {
        return this.user.name;
    }

    private resetForm(): void {
        this.commentText = '';

        if (this.commentForm) {
            this.commentForm.$setPristine();
            this.commentForm.$setUntouched();
        }
    }

    private resetFocus(): void {
        this.$window.focus();
    }
}