import { LoanTypeChangeRequest } from '../../ltc/loanTypeChangeRequest.model';

import LoanTypeChangeRequestDetailController from '../../ltc/detail/loanTypeChangeRequestDetail.controller';
import SupportSearchLoanTypeChangeService from './supportSearchLoanTypeChange.service';

import { IActiveElementHTMLElement } from '../../../../core/interfaces/activeElementHTMLElement.interface';

export default class SupportSearchLoanTypeChangeController  {

    maximumRowsToDisplay: number;
    searching: boolean;
    criteria: string;
    requests: Array<LoanTypeChangeRequest>;
    totalRequests: number;
    shownRequests: number;

    private concurrentDetailsCalls: number;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchLoanTypeChangeService: SupportSearchLoanTypeChangeService
    ) {
        // no-op
    }

    $onInit(): void {
        this.maximumRowsToDisplay = 200;
        this.searching = false;
        this.criteria = '';
        this.totalRequests = 0;
        this.shownRequests = 0;
        this.concurrentDetailsCalls = 3;
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

            this.supportSearchLoanTypeChangeService.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    showCurrentRateNotSet(request: LoanTypeChangeRequest): boolean {
        return request.currentLoanType.loanTypeDescription === 'NA';
    }

    viewDetails($event: MouseEvent, request: LoanTypeChangeRequest): void {
        this.$mdDialog.show({
            template: require('../../ltc/detail/loanTypeChangeRequestDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: LoanTypeChangeRequestDetailController,
            controllerAs: 'vm',
            locals: {
                request: request
            },
            fullscreen: true
        }).finally(() => {
            this.supportSearchLoanTypeChangeService.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                });
        });
    }

    private processResponse(data: any): void {
        this.requests = data.requestList;

        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }
}