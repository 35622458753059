import {AgencyBalanceExceptionApproverSearchModel} from './models/agencyBalanceExceptionApproverSearch.model';
import ApproverSearchAgencyBalanceExceptionResponse from './models/approverSearchAgencyBalanceExceptionResponse.model';
import {ApproverSearchOptions} from './models/approverSearch.options.model';

import { RequestStatus } from './../../agencyBalanceExceptionV2/core/requestStatus.model';

import {OrgIdAndName} from './models/orgIdAndName.model';
import {IdAndName} from '../../../../core/models/idAndName.model';

export default class BalanceExceptionApproverSearchServiceV2 {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<ApproverSearchOptions>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exceptions/approversearch`, {timeout: 600000});
    }

    getSearchResults(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<ApproverSearchAgencyBalanceExceptionResponse>> {
        return this.$http.post(`{API-ApprovalTool}/requests/agency-balance-exceptions/approversearch`, criteria, { timeout: 600000 });
    }

    getTypes(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: any = criteria.salesPeriods.map((item) => item.id);
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/exception-types`, data, { timeout: 180000 });
    }

    getTypesBySalesAgencies(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: any = {
            salesPeriodIds: criteria.salesPeriods.map((item) => item.id),
            salesAgencies: criteria.salesAgencies
        };

        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/sales-agencies/exception-types`, data, { timeout: 180000 });
    }

    getTypesByOrganizationalUnits(salesPeriods: IdAndName[], organizationalUnits: OrgIdAndName[]): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: any = this.createData(salesPeriods, organizationalUnits);

        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/organizational-units/exception-types`, data, { timeout: 180000 });
    }

    getSalesAgencies(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        if (criteria.territories.length > 0 || criteria.areas.length === 0) {
            return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/territories/agencies`, criteria.territories, { timeout: 180000 });
        }

        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/areas/agencies`, criteria.areas, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/territories-agencies`, 1, { timeout: 180000 });
    }

    getAreas(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/areas`, criteria.commercialUnits, { timeout: 180000 });
    }

    ////getCommercialUnitsAreas(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
    ////    const data: any = this.createData(criteria.salesPeriods, criteria.commercialUnits);
    ////    return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/commercialunits/areas`, data, { timeout: 180000 });
    ////}

    getRequestStatus(requestId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<RequestStatus>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/requests/${requestId}/status`);
    }

    getTerritoriesSalesAgenciesByAreas(criteria: AgencyBalanceExceptionApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agency-balance-exceptions/areas/territories-agencies`, criteria.areas, { timeout: 180000 });
    }

    private createData(salesPeriods: IdAndName[], orgUnits: OrgIdAndName[]): any {
        const data: any = { salesPeriodIds: salesPeriods.map((item) => item.id), organizationalUnits: orgUnits };
        return data;
    }

}
