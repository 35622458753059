import {ICommissionAdvanceApproverSearchService} from './commissionAdvanceApproverSearch.service.interface';
import {ICommissionAdvanceApproverSearchModel} from './models/commissionAdvanceApproverSearch.model.interface';
import CommissionAdvanceRequestDetailsinterface = require('../commissionAdvanceRequestDetails.interface');
import ICommissionAdvanceRequestDetails = CommissionAdvanceRequestDetailsinterface.ICommissionAdvanceRequestDetails;

export default class CommissionAdvanceApproverSearchService implements ICommissionAdvanceApproverSearchService {

    constructor(private $http: ng.IHttpService, private $q) {
        // noop
    }

    getSearchOptions(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/commission-advance/requests/approversearch`, {timeout: 600000});
    }

    getCommissionAdvanceDetails(salesYear: number, salesAgencyId: number): ng.IPromise<ICommissionAdvanceRequestDetails> {
        return this.$http
            .get<ICommissionAdvanceRequestDetails>(`{API-ApprovalTool}/commission-advance-detail/salesagencies/${salesAgencyId}/salesyears/${salesYear}/v2`)
            .then((response) => {
                return response.data;
            });
    }

    getCommissionAdvanceSavedDetails(requestId: number): ng.IPromise<ICommissionAdvanceRequestDetails> {
        return this.$http.get<ICommissionAdvanceRequestDetails>(`{API-ApprovalTool}/commission-advance-detail/requests/${requestId}/v2`)
            .then((response) => {
                return response.data;
            });
    }

    getSearchResults(criteria: ICommissionAdvanceApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/commission-advance/requests/approversearch`, criteria, { timeout: 600000 });
    }

    getSalesAgencies(criteria: ICommissionAdvanceApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: ICommissionAdvanceApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: ICommissionAdvanceApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: ICommissionAdvanceApproverSearchModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }
}
