import TerritoryPlanningService from './territoryPlanning.service';
import { TerritoryPlanning } from './territoryPlanning';
export default class TerritoryPlanningController {

    loading: boolean = false;
    saving: boolean = false;
    canEdit: boolean = false;
    territoryId: string;
    flexFundVariance: number;
    totalVariance: number;
    territoryPlanning: TerritoryPlanning;

    onFlexFundChange: (variance: any) => any;

    constructor(private territoryPlanningService: TerritoryPlanningService) {
    }

    $onChanges(changes: any) {
        if (angular.isDefined(changes.territoryPlanning) && angular.isDefined(changes.territoryPlanning.currentValue)) {
            this.calculateFlexFundVariance();
            this.calculateTotalVariance();
        }
    }

    saveDiscountFundsHeld(discountFundsHeld: number, validator: ng.INgModelController) {
        if (this.canEdit && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.territoryPlanningService.saveDiscountFundsHeld(this.territoryId, discountFundsHeld)
                .then(() => this.calculateTotalVariance())
                .finally(() => this.saving = false);
        }
    }

    saveFlexFundsHeld(flexFundsHeld: number, validator: ng.INgModelController) {
        if (this.canEdit && validator.$valid && validator.$dirty) {
            this.saving = true;
            this.territoryPlanningService.saveFlexFundsHeld(this.territoryId, flexFundsHeld)
                .then(() => this.calculateFlexFundVariance())
                .finally(() => this.saving = false); 
        }
    }

    calculateFlexFundVariance() {
        const variance: number = this.getVariance(this.territoryPlanning.flexFundTarget,
            this.territoryPlanning.flexFundPlan,
            this.territoryPlanning.flexFundsHeld);
        this.flexFundVariance = _.round(variance, 5);
        this.onFlexFundChange({ variance: this.flexFundVariance });
    }

    calculateTotalVariance() {
        const variance: number = this.getVariance(this.territoryPlanning.totalDiscountTarget, this.territoryPlanning.totalDiscountPlan, this.territoryPlanning.discountFundsHeld);
        this.totalVariance = _.round(variance, 5);
    }

    private getVariance(target: number, plan: number, fundsHeld: number) {
        return (target * 10000 - plan * 10000 - fundsHeld * 10000) / 10000;
    }
}