const applicationName: string = 'approval-tool';

function getAngularModule(): any {
    return angular.module(applicationName);
}

export function createModule(dependencies: string[]): void {
    angular.module(applicationName, dependencies);
}

export function registerFactory(factoryName: string, factory: Function): void {
    getAngularModule().factory(factoryName, factory);
}

export function registerRepository(repositoryName: string, service: Function): void {
    getAngularModule().service(repositoryName, service);
}

export function registerService(serviceName: string, service: Function): void {
    getAngularModule().service(serviceName, service);
}

export function registerDirective(directiveName: string, directive: any): void {
    getAngularModule().directive(directiveName, directive);
}

export function registerComponent(componentName: string, component: any): void {
    getAngularModule().component(componentName, component);
}

export function registerController(controllerName: string, controller: any): void {
    getAngularModule().controller(controllerName, controller);
}

export function registerFilter(filterName: string, filter: any): void {
    getAngularModule().filter(filterName, filter);
}

export function registerConfig(configFn: Function): void {
    getAngularModule().config(configFn);
}

export function registerRunBlock(initializationFunction: Function): void {
    getAngularModule().run(initializationFunction);
}