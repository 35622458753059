/* eslint-disable angular/component-limit, angular/factory-name */
(() => {
    angular
        .module('approval-tool')
        .factory('appInsightsSessionInterceptorFactory', appInsightsSessionInterceptorFactory);

    appInsightsSessionInterceptorFactory.$inject = [
        'appInsightsSessionConfigurator',
        '$q'
    ];

    function appInsightsSessionInterceptorFactory(appInsightsSessionConfigurator: any, $q: ng.IQService) {
        return {
            'request': function (config: any) {
                appInsightsSessionConfigurator.configure(config.headers);
                return config || $q.when(config);
            }
        };
    }
})();