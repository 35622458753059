export default class FocusInputDirective implements ng.IDirective {
    restrict: string = 'A';

    constructor(private $timeout: ng.ITimeoutService) { }

    link = (scope: ng.IScope, instanceElement: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
        this.$timeout(() => { instanceElement[0].focus(); }, 10);
    };


    static factory(): ng.IDirectiveFactory {
        const directive = ($timeout: ng.ITimeoutService) => {
            return new FocusInputDirective($timeout);
        };

        return directive;
    }
}