import {ILoginRepository} from './login.repository.interface';

export default class LoginRepository implements ILoginRepository {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    authenticate(): ng.IHttpPromise<boolean> {
        return this.$http.get<boolean>('{API-ApprovalTool}/usersessioninfo');
    }
}
