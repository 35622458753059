export function httpPostSetContentTypeWhenBodyIsEmptyInterceptor($q: ng.IQService) {
    const interceptor: ng.IHttpInterceptor = {

        // Angular strips the HTTP method on POSTs when the body is empty 
        // (see: https://github.com/angular/angular.js/issues/749). Our WAF (firewall)
        // reports this as an error, so this interceptor sets the HTTP method
        // so as to not produce noise by the WAF.
        request(config: ng.IRequestConfig) {

            if (config.method.toLowerCase() === 'post' && angular.isUndefined(config.data)) {
                // setting the body to anything other than undefined will cause angular's
                // Content-Type removal logic to be skipped. You can see this logic in this
                // commit:https://github.com/angular/angular.js/commit/1a5bebd927ecd22f9c34617642fdf58fe3f62efb
                config.data = {};
            }

            return config || $q.when(config);
        }
    };

    return interceptor;
}