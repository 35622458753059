import { IUser } from '../../../core/models/user.model.interface';
import { IUserService } from '../../../core/services/user.service.interface';
import { ICpaRequest } from '../cpa/cpaRequest.model.interface';
import { ICpaRequestService } from '../cpa/cpaRequest.service.interface';
import CpaDetailRepository from '../cpa/detail/cpaDetail.repository';
import CpaDetailDisplayService from '../cpa/detail/cpaDetailDisplay.service';
import { IPendingRequestService } from './pendingRequests.service.interface';

export default class PendingRequestsController {

    requests: ICpaRequest[];
    loading: boolean = true;
    total: number = 0;
    visitedPages: number[] = [];

    requestGroups: Object;

    canShowTable: () => boolean = this.canShowTableImpl;
    nothingToApprove: () => boolean = this.nothingToApproveImpl;

    query: { order: string; limit: number; page: number; begin: number, end: number } = {
        order: 'requestedDate',
        limit: 10,
        page: 1,
        begin: 0,
        end: 10
    };

    beginIndicator: number;
    endIndicator: number;

    canPageForward: boolean = false;
    canPageBack: boolean = false;

    user: IUser;

    navigationText: string;
    isPermissionStopDatePassed: boolean = true;
    private navElement: HTMLElement;

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService,
        private pendingRequestService: IPendingRequestService,
        private cpaRequestService: ICpaRequestService,
        private cpaDetailRepository: CpaDetailRepository,
        private userService: IUserService,
        private $timeout: ng.ITimeoutService,
        private cpaDetailDisplayService: CpaDetailDisplayService) {
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    private activate() {
        this.user = this.userService.get();

        if (this.$location.path().indexOf('all') > -1) {
            this.navigationText = 'All pending requests';
            this.getAllPending();
        } else {
            this.navigationText = 'My pending requests';
            this.getPending();
        }
    }

    private getPending(): void {
        this.pendingRequestService.getList(this.query.page)
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    private getAllPending(): void {
        this.pendingRequestService.getAllPending()
            .then((data) => this.processResponse(data))
            .finally(() => this.done());
    }

    approve($event: MouseEvent, request: ICpaRequest): void {
        $event.stopPropagation();

        request.processing = true;

        this.cpaDetailRepository.approve(request)
            .then(() => {
                this.getAdditionalLineData(request as ICpaRequest);
                this.setActionPermissions(request, false, false, true);
            })
            .finally(() => request.processing = false);
    }

    deny($event: MouseEvent, request: ICpaRequest): void {
        $event.stopPropagation();

        request.processing = true;

        this.cpaDetailRepository.deny(request)
            .then(() => {
                this.getAdditionalLineData(request as ICpaRequest);
                this.setActionPermissions(request, false, false, false);
            })
            .finally(() => request.processing = false);
    }

    withdraw($event: MouseEvent, request: ICpaRequest): void {
        $event.stopPropagation();

        request.processing = true;

        this.cpaDetailRepository.withdraw(request)
            .then(() => {
                this.getAdditionalLineData(request as ICpaRequest);
                this.setActionPermissions(request, false, false, false);
            })
            .finally(() => request.processing = false);
    }

    canApprove(request: ICpaRequest): boolean {
        return this.user.canApproveCpaRequests && request.canApprove && !request.processing && !this.isPermissionStopDatePassed;
    }

    canDeny(request: ICpaRequest): boolean {
        return this.user.canDenyCpaRequests && request.canDeny && !request.processing && !this.isPermissionStopDatePassed;
    }

    canWithdraw(request: ICpaRequest): boolean {
        return this.user.canWithdrawCpaRequests && request.canWithdraw && !request.processing && !this.isPermissionStopDatePassed;
    }

    checkPermissionStopDatePassed(request: ICpaRequest): void {
        this.cpaDetailRepository.isPermissionStopDatePassed(request)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.isPermissionStopDatePassed = true;
                if (response.data.length > 0) {
                    this.isPermissionStopDatePassed = !_.any(response.data,
                        (s: any) => {
                            return s.salesYear === request.salesPeriod.year;
                        });
                }
            }).finally(() => this.setPermissions(request, this.isPermissionStopDatePassed));
    }

    private setPermissions(request: ICpaRequest, isPermissionStopDatePassed: boolean): void {
        request.canApprove = request.canApprove && !isPermissionStopDatePassed;
        request.canWithdraw = request.canWithdraw && !isPermissionStopDatePassed;
        request.canDeny = request.canDeny && !isPermissionStopDatePassed;
        this.lineDone(request);
    }

    private setActionPermissions(request: ICpaRequest, canApprove: boolean, canDeny: boolean, canWithdraw: boolean): void {
        request.canApprove = canApprove;
        request.canDeny = canDeny;
        this.$timeout(() => {
            request.canWithdraw = canWithdraw;
        }, 2000);
    }

    private lineDone(request: ICpaRequest): void {
        request.loadingAdditionalData = false;
    }

    private done(): void {
        this.loading = false;
    }

    private processResponse(response: any): void {
        this.requests = response.data.pendingRequestList;
        this.total = response.data.pendingRequestList.length;

        this.groupRequests();
    }

  private groupRequests() {
        let requests: any[] = this.requests.slice(this.query.begin, this.query.end);

        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, function (request) {
            return request.salesPeriod.period + ' - ' +
                request.organizationalUnitId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.salesPeriod.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;

        // get single level array of IRequest from grouped by object
        const sorted: any[] = _.chain(this.requestGroups).values().flatten().value();

        if (this.visitedPages.indexOf(this.query.page) < 0) {
            this.visitedPages.push(this.query.page);
            this.getAdditionalLinesData(sorted);
        }

        this.updatePagination();
    }

    private getAdditionalLinesData(requests: ICpaRequest[]): void {
        angular.forEach(requests, (request: ICpaRequest) => this.getAdditionalLineData(request));
    }

    private getAdditionalLineData(request: ICpaRequest): void {
        request.loadingAdditionalData = true;

        this.cpaRequestService.getLineScore(request)
            .then(data => this.cpaRequestService.processLineResponse(request, data))
            .finally(() => this.checkPermissionStopDatePassed(request));
    }

    private canShowTableImpl(): boolean {
        return !this.loading && this.requests.length > 0;
    }

    private nothingToApproveImpl(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    paginateForward(): void {
        this.query.page++;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    paginateBackward(): void {
        this.query.page--;

        this.query.begin = (this.query.page - 1) * this.query.limit;
        this.query.end = this.query.begin + this.query.limit;

        this.groupRequests();
    }

    private updatePagination(): void {
        this.canPageForward = this.canPaginateForward();
        this.canPageBack = this.canPaginateBackward();
        this.setIndicators();
    }

    private canPaginateForward(): boolean {
        return this.requests.length > this.query.end;
    }

    private canPaginateBackward(): boolean {
        return this.query.page > 1;
    }

    private setIndicators(): void {
        this.beginIndicator = this.query.begin + 1;

        this.endIndicator = this.requests.length > this.query.end ? this.query.end : this.requests.length;
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    showDialog($event: MouseEvent, request: ICpaRequest): void {
        this.cpaDetailDisplayService.showCpaDetails($event, request, 'CpaApprovalDetailV2Controller');
    }
}