import { ProposalCpaDetail } from './proposalcpaDetail.model';

export default class ProposalCpaDetailService {

    constructor(private $http: ng.IHttpService) {
        // no-op
    }

    getDetailV2(requestId: number) {
        return this.$http.get(`{API-ApprovalTool}/proposalcpa/requests/${requestId}/detail/v2`)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                return new ProposalCpaDetail(
                    null,
                    this.processRequiredApprovalsResponse(response.data.requiredApprovals),
                    response.data.comments,
                    null,
                    response.data.operationSales,
                    response.data.shareDoubleCropInvoiceValue,
                    response.data.shareGrossAndDiscountValue);
            });
    }

    private processRequiredApprovalsResponse(data: any): any {
        return {
            approvalList: data.approvalList,
            finalApprovalDate: data.finalApprovalDate
        }; 
    }
}  