import {IMonthlyDrawRequest} from './monthlyDrawRequest.interface';
import {IMonthlyDrawRequestDetails} from './monthlyDrawRequestDetails.interface';
import {IMonthlyDrawRequestRow} from './monthlyDrawRequestRow.interface';

export class MonthlyDrawRequestRow implements IMonthlyDrawRequestRow {
    request: IMonthlyDrawRequest;
    details: IMonthlyDrawRequestDetails;
    isLoadingDetails: boolean;

    constructor(request: IMonthlyDrawRequest, details?: IMonthlyDrawRequestDetails) {
        this.request = request;
        this.isLoadingDetails = true;

        if (details) {
            this.addDetails(details);
        }
    }

    addDetails(details: IMonthlyDrawRequestDetails): void {
        if (details.overrideDrawAmount === 0) {
            details.overrideDrawAmount = undefined;
        }
        this.details = details;
        this.isLoadingDetails = false;
    }
}