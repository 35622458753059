'use strict';

angular.module('approval-tool', [
    'ngCookies',
    'ngRoute',
    'ngMaterial',
    'ngResource',
    'ngMessages',
    'ngLocale',
    'Authentication',
    'pioneer.Exceptions',
    'ui.router',
    'md.data.table',
    'fixed.table.header'
]);

import config from './core/configuration/approvalTool.config';
import themeConfig from './core/configuration/approvalTool.theme.config';
import debugConfig from './core/configuration/approvalTool.debug.config';

angular.module('approval-tool').config(config);
angular.module('approval-tool').config(themeConfig);
angular.module('approval-tool').config(debugConfig);

import './core/_bootstrap';
import './views/_bootstrap'; 
import './core/services/appInsights/appInsights.service';
import './core/services/appInsights/appInsightsClientVersion.interceptor';
import './core/services/appInsights/appInsightsClientVersion.service';
import './core/services/appInsights/appInsightsClientVersion.repository';
import './core/services/appInsights/appInsightsRequest.interceptor';
import './core/services/appInsights/appInsightsRequest.service';
import './core/services/appInsights/appInsightsServerPerformance.interceptor';
import './core/services/appInsights/appInsightsSession.configurator';
import './core/services/appInsights/appInsightsSession.interceptor';
import './core/services/appInsights/appInsightsServerPerformance.queue';


import locationChange from './core/configuration/approvalTool.locationChange';
import launchDarklyInit from './core/configuration/approvalTool.launchDarklyInit';

const appInsights = (
    $log: ng.ILogService,
    appInsightsService: any
) => {
    try {
        appInsightsService.configureAppInsights()
    } catch (ex) {
        $log.warn(ex);
    }
};

appInsights.$inject = [
    '$log',
    'appInsightsService'
];

const configureApp = () => {
    angular.module('approval-tool')
        .config(['$mdThemingProvider', '$locationProvider', '$qProvider', '$httpProvider',
            function ($mdThemingProvider, $locationProvider, $qProvider, $httpProvider) {

            // Configure theme
            var oldBlue = $mdThemingProvider.extendPalette('blue', {
                'contrastDefaultColor': 'dark',
                'contrastStrongLightColors': '400 500 600 700 800 900'
            });
            $mdThemingProvider.definePalette('oldBlue', oldBlue);
            $mdThemingProvider.theme('default')
                .primaryPalette('oldBlue');

            // Configure location provider
            $locationProvider.hashPrefix('');

            // Configure error handler
            $qProvider.errorOnUnhandledRejections(false);

            // Register interceptors
            $httpProvider.interceptors.push('appInsightsClientVersionInterceptorFactory');
            $httpProvider.interceptors.push('appInsightsRequestInterceptorFactory');
            $httpProvider.interceptors.push('appInsightsSessionInterceptorFactory');
            $httpProvider.interceptors.push('appInsightsServerPerformanceInterceptorFactory');
        }]);
};

const runApp = () => {
    angular.module('approval-tool').run(locationChange).run(launchDarklyInit).run(appInsights);
};

const registerBootstrap = () => angular.bootstrap(document.body, ['approval-tool'], { strictDi: false });

configureApp();
runApp();
registerBootstrap();