export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-EncircaCommission/', {
            template: require('./requestTypesEncircaCommission.html'),
            controller: 'RequestTypesEncircaCommissionController',
            controllerAs: 'vm'
        })
        .when('/requestType-EncircaCommission/search',
            {
                template: require('../encircaCommissionAdvance/search/servicesCommissionAdvanceApproverSearch.html'),
                controller: 'ServicesCommissionAdvanceApproverSearchController',
                controllerAs: 'vm'
            });
}

let requestTypesEncircaCommissionState: ng.ui.IState = {
    name: 'requestType-EncircaCommission',
    url: '/requestType-EncircaCommission/',
    views: {
        '': {
            template: require('./requestTypesEncircaCommission.html'),
            controller: 'RequestTypesEncircaCommissionController',
            controllerAs: 'vm'
        },
        'advance-tab@requestType-EncircaCommission': {
            template: require('../encircaCommissionAdvance/encircaCommissionAdvanceRequests.html'),
            controller: 'EncircaCommissionAdvanceRequestController',
            controllerAs: 'vm'
        }
    }
};

angular.module('approval-tool').config(($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state(requestTypesEncircaCommissionState);
});