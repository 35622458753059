import CreateBalanceExceptionRequestControllerV2 from './createBalanceExceptionRequest.controller';
import BalanceExceptionRequestsServiceV2 from './balanceExceptionRequests.service';
import {IUserService} from '../../../core/services/user.service.interface';

export default class SupportCreateBalanceExceptionRequestControllerV2 extends CreateBalanceExceptionRequestControllerV2 {
    hideToolbar: boolean = true;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $location: ng.ILocationService,
        protected $document: ng.IDocumentService,
        protected $mdDialog: ng.material.IDialogService,
        protected balanceExceptionRequestsServiceV2: BalanceExceptionRequestsServiceV2,
        protected userService: IUserService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected $mdToast: ng.material.IToastService) {
        super($timeout, $location, $document, $mdDialog, balanceExceptionRequestsServiceV2, userService, $mdSidenav, $mdToast);
    }
}