import { ISampleComment } from './comments/sampleComment.model.interface';
import { IDiscount } from '../../components/discounts/models/discount.model.interface';
import { IDiscountTotal } from '../../components/discounts/models/totals/discount.total.model.interface';
import { IOperationSaleGrowth } from '../../components/operation/operationSalesGrowth.model.interface';
import { IOperationSaleGrowthTotal } from '../../components/operation/operationSalesGrowthTotal.model.interface';

import { OperationProductLineSalesGrowthModel } from '../../components/operation/operationProductLineSalesGrowth.model';
import { RequiredApproval } from '../../components/approvals/required.approval.model';
import { ShareDiscount } from '../../components/discounts/models/shareDiscount.model';

export class SampleDetail {

    constructor(
        public operationSales: {
            operationSaleGrowths: IOperationSaleGrowth[],
            operationSaleGrowthsTotal: IOperationSaleGrowthTotal;
        },
        public requiredApprovals: {
            approvalList: RequiredApproval[],
            finalApprovalDate: string;
        },
        public comments: ISampleComment[],
        public discounts: {
            discountList: IDiscount[],
            discountTotals: IDiscountTotal[];
        },
        public operationSalesGrowth: OperationProductLineSalesGrowthModel = null,
        public shareDiscounts: ShareDiscount = null) {
        // no-op
    }

    static empty(): SampleDetail {
        return new SampleDetail(
            {
                operationSaleGrowths: [],
                operationSaleGrowthsTotal: {
                    currentYearTotalAmount: undefined,
                    currentYearTotalUnits: undefined,
                    growth: undefined,
                    growthPercent: undefined,
                    previousYearTotalAmount: undefined,
                    previousYearTotalUnits: undefined
                }
            },
            {
                approvalList: [],
                finalApprovalDate: undefined
            },
            [],
            {
                discountList: [],
                discountTotals: []
            },
            new OperationProductLineSalesGrowthModel([], undefined, undefined, undefined),
            new ShareDiscount(undefined, undefined, undefined, undefined, undefined));
    }
}