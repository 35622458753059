import {IUserSessionService} from '../session/userSession.service.interface';

export default class KeepSessionAliveDirective implements ng.IDirective {

    scope: boolean = false;
    restrict: string = 'A';
    link: (scope: ng.IScope, element: ng.IAugmentedJQuery) => void;

    constructor(userSessionService: IUserSessionService) {
        KeepSessionAliveDirective.prototype.link = (scope: ng.IScope, element: ng.IAugmentedJQuery) => {
            element.bind('click', (event) => {
                const ableToKeepSessionAlive: boolean = userSessionService.keepAlive();

                if (!ableToKeepSessionAlive) {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                }
            });
        };
    }

    static factory(): ng.IDirectiveFactory {
        return (userSessionService: IUserSessionService) => new KeepSessionAliveDirective(userSessionService);
    }
}