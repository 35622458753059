import {ISupportSearchReplantService} from './supportSearchReplant.service.interface';
import {ISupportSearchReplantController} from './supportSearchReplant.controller.interface';
import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';
import {ISupportSearchReplantRequest} from './supportSearchReplantRequest.model.interface';
import {SupportSearchReplantRequest} from './supportSearchReplantRequest.model';
import {ReplantRequestReplacementLineItem} from './../../replant/replantRequestReplacementLineItem.model';
import {IReplantRequestsService} from './../../replant/replantRequests.service.interface';
import ReplantDetailDisplayService from './../../replant/detail/replantDetailDisplay.service';
import {IReplantRequest} from './../../../requests/replant/replantRequest.model.interface';

export default class SupportSearchReplantController {

    maximumRowsToDisplay: number = 200;

    searching: boolean = false;
    exporting: boolean = false;
    criteria: string = '';
    requests: ISupportSearchReplantRequest[];
    requestGroups: Object;
    totalRequests: number = 0;
    shownRequests: number = 0;
    requestsReplacement: ReplantRequestReplacementLineItem[];
    replacementRowName: string;
    spanElementName: string;    

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchReplantService: ISupportSearchReplantService,
        private replantRequestsService: IReplantRequestsService,
        private replantDetailDisplayService: ReplantDetailDisplayService
    ) { }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement>this.$document[0]).activeElement.blur();

            this.supportSearchReplantService.getReplantSearchResults(this.getFriendlyCriteria())
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                    this.requestGroups = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    viewDetails($event: MouseEvent, request: IReplantRequest) {
        if (request.processing) {
            return;
        }
        this.replantDetailDisplayService.showReplantDetails($event, request, 'ReplantDetailController').catch(() => { });
    }

    private getFriendlyCriteria(): string {
        return this.criteria.replace('\\', '').replace('"', '');
    }

    private processResponse(data: any): void {

        this.requests = data.requestList;
        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;

        this.groupRequests();
    }

    private groupRequests() {
        let requests: any[] = this.requests;

        // create object grouped by composite key
        let groups: _.Dictionary<any[]> = _.groupBy(requests, (request) => {
            return request.salesPeriod.period + ' - ' +
                request.territoryId + ' - ' +
                request.salesAgency.name + ' (' +
                request.salesAgency.id + ')';
        });

        // sort IRequest array items for each key by sales period request date
        _.forIn(groups, (value, key) => {
            let sorted: any[] = _.sortByOrder(value, (request) => { return request.salesPeriod.requestedDate; }, 'desc');
            groups[key] = sorted;
        });

        this.requestGroups = groups;
    }

    private moreOrLessReplants(replant: any, $event: MouseEvent): void {
        if ($event) {
            $event.stopPropagation();
        }

        this.replacementRowName = 'row' + replant.requestId;
        this.spanElementName = document.getElementById(this.replacementRowName).innerHTML;
        if (this.spanElementName === '(more...)') {
            this.replantRequestsService
                .getReplantReplacementList(replant.requestId)
                .then((data) => {
                    this.processLineItemResponse(replant, data);
                    document.getElementById(this.replacementRowName).innerHTML = '(less...)';
                })
                .finally(() => {
                    this.searching = false;
                });
        } else {
            this.hideReplacementLineItem(replant);
            document.getElementById(this.replacementRowName).innerHTML = '(more...)';
        }
    }

    private hideReplacementLineItem(rep: any): void {
        document.getElementById(rep.requestId).innerHTML = `${rep.replacementProductId} ${rep.replacementSubproductId}: ${rep.replacementUnits}`;
    }

    private processLineItemResponse(replant: any, response: any): void {
        let finalReplacementLineItem: string = '';
        let eol: string = ', <br />';
        this.requestsReplacement = response.data.pendingRequestList;
        this.requestsReplacement.forEach((item) => {
            finalReplacementLineItem = `${finalReplacementLineItem}${item.productId} ${item.subproductId}: ${item.units}${eol}`;
        });
        document.getElementById(replant.requestId).innerHTML = finalReplacementLineItem.slice(0, (-1 * eol.length));
    }

}