import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';
import { SampleRequest } from '../sampleRequest.model';

import { SampleDetail } from './sampleDetail.model';
import SampleDetailService from './sampleDetail.service';
import { ShareDiscountV2 } from '../../components/discounts/models/shareDiscountV2.model';
import DiscountsService from '../../components/discounts/discounts.service';
import IPromise = angular.IPromise;
import { ISampleRequestsService } from '../sampleRequests.service.interface';

export default class SampleDetailV2Controller {

    loading: boolean = true;

    request: SampleRequest;
    detail: SampleDetail = SampleDetail.empty();
    shareDiscounts: ShareDiscountV2;
    finalApprovalDate: string = '';
    user: IUser;
    regularInvoiceValue: number;
    doubleCropReplantInvoiceValue: number;

    shareCurrentDiscounts: any;
    customerPreviousDiscounts: any;
    customerCurrentDiscounts: any;

    allowComment: boolean = false;
    isPermissionStopDatePassed: boolean = true;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private requestLocal: SampleRequest,
        private sampleRequestsService: ISampleRequestsService,
        private userService: IUserService,
        private sampleDetailService: SampleDetailService,
        private discountsService: DiscountsService
    ) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): IPromise<void> {
        this.request = this.requestLocal;
        this.checkPermissionStopDatePassed();
        this.user = this.userService.get();
        this.allowComment = this.user.canApproveCpaRequests;

        return this.getDetail()
            .finally(() => this.done());
    }

    approve(): void {
        this.request.processing = true;

        this.sampleRequestsService.approve(this.request)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;

        this.sampleRequestsService.deny(this.request)
            .then(() => {
                this.getDetail();
                this.setActionPermissions(false, false);
            })
            .finally(() => this.request.processing = false);
    }

    canApprove(): boolean {
        return this.user.canApprove && this.request.canApprove && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    canDeny(): boolean {
        return this.user.canApprove && this.request.canDeny && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    checkPermissionStopDatePassed(): void {
        this.sampleRequestsService.isPermissionStopDatePassed(this.request)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                if (response.data.length > 0) {
                    this.isPermissionStopDatePassed = !_.any(response.data, (s: any) => {
                        return s.salesYear === this.request.salesYear;
                    });
                }
            });
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    isPending(status: string): boolean {
        return status === 'Pending';
    }


    private setActionPermissions(canApprove: boolean, canDeny: boolean): void {
        this.request.canApprove = canApprove;
        this.request.canDeny = canDeny;
    }

    private getDetail() {

        this.getDiscounts();

        return this.sampleDetailService.getDetailV2(this.request.requestShareId,
            this.request.invoice.id,
            this.request.salesYear,
            this.request.shareId)
            .then((detail: SampleDetail) => {
                this.detail = detail;
            });
    }

    private getDiscounts() {

        this.discountsService.getCustomerCurrentDiscountsV2(this.request.shareId)
            .then((currentCustomerDiscounts: any) => {
                this.customerCurrentDiscounts = currentCustomerDiscounts;
            });

        this.discountsService.getCpaShareCurrentDiscounts(0, 
            this.request.invoice.id,
            this.request.shareId)
            .then((shareCurrentDiscounts: any) => {
                this.shareCurrentDiscounts = shareCurrentDiscounts;
                this.regularInvoiceValue = this.shareCurrentDiscounts.regularInvoiceTotal;
                this.doubleCropReplantInvoiceValue = this.shareCurrentDiscounts.doubleCropReplantInvoiceTotal;
            });

        return this.discountsService.getCustomerPreviousDiscounts2020V2(this.request.shareId)
                .then((previousCustomerDiscounts: any) => {
                    this.customerPreviousDiscounts = previousCustomerDiscounts;
                });
    }
 
    private done(): void {
        this.loading = false;
    }
}