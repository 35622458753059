export default function routes($routeProvider: ng.route.IRouteProvider) {

    $routeProvider
        .when('/pricingRequests/', {
            template: require('./pricingRequests.html'),
            controller: 'PricingPendingRequestsController',
            controllerAs: 'vm'
        })
        .when('/pricingRequestV2/', {
            template: require('./request/pricingRequestV2.html'),
            controller: 'PricingRequestV2Controller',
            controllerAs: 'vm'
        });
}