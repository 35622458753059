import EncircaCommissionAdvanceRequestService from './encircaCommissionAdvanceRequest.service';
Application.registerRepository('encircaCommissionAdvanceRequestService', EncircaCommissionAdvanceRequestService);

import EncircaCommissionAdvanceRequestController from './encircaCommissionAdvanceRequest.controller';
Application.registerController('EncircaCommissionAdvanceRequestController', EncircaCommissionAdvanceRequestController);

import ServicesCommissionAdvanceNavigationComponent from './navigation/servicesCommissionAdvanceNavigation.component';
Application.registerComponent('servicesCommissionAdvanceNavigation', ServicesCommissionAdvanceNavigationComponent.factory());

import ServicesCommissionAdvanceApproverSearchController from './search/servicesCommissionAdvanceApproverSearch.controller';
Application.registerController('ServicesCommissionAdvanceApproverSearchController', ServicesCommissionAdvanceApproverSearchController);

import ServicesCommissionAdvanceApproverSearchService from './search/servicesCommissionAdvanceApproverSearch.service';
Application.registerRepository('servicesCommissionAdvanceApproverSearchService', ServicesCommissionAdvanceApproverSearchService);
