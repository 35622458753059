import { DiscountDisplayModel } from './models/discountDisplay.model';
import { CustomerDiscounts } from './models/customerDiscounts.model';
import { OrderedDiscountLineV2 } from './models/orderedDiscountLineV2.model';
import { DiscountImplementedCpaLineV2 } from './models/discountImplementedCpaLineV2.model';
import { ShareCurrentDiscountsV3 } from './models/shareCurrentDiscountsV3.model';
import { DiscountPendingCpaLine } from './models/discountPendingCpaLine.model';
import { RateAndAmount } from './models/rateAndAmount.model';

export default class DiscountDetailsDisplayService {

    constructor(private $filter: ng.IFilterService) {
        // no-op
    }

    setPreviousYearData(displayDetails: DiscountDisplayModel, previousYearData: CustomerDiscounts): DiscountDisplayModel {
        if (!angular.isDefined(displayDetails) || displayDetails === null) {
            displayDetails = new DiscountDisplayModel();
        }

        displayDetails.cpaTotal.previousPercent = this.formatPercentage(previousYearData.cpa.percent);
        displayDetails.cpaTotal.previousAmount = this.formatCurrency(previousYearData.cpa.amount);

        displayDetails.total.previousPercent = this.formatPercentage(previousYearData.total.percent);
        displayDetails.total.previousAmount = this.formatCurrency(previousYearData.total.amount);

        previousYearData.discountLines.forEach((discountLine: OrderedDiscountLineV2) => {
            const line = this.findByNameOrderNumber(displayDetails.discountLines, discountLine.name, discountLine.orderNumber);
            if (!line) {
                const newLine: any = {
                    name: discountLine.name,
                    orderNumber: discountLine.orderNumber,
                    previousPercent: this.formatPercentage(discountLine.percent),
                    previousAmount: this.formatCurrency(discountLine.amount)
                };
                displayDetails.discountLines.push(newLine);
            } else {
                line.previousPercent = this.formatPercentage(discountLine.percent);
                line.previousAmount = this.formatCurrency(discountLine.amount);
            }
        });

        previousYearData.implementedCpa.forEach((cpaLine: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.implementedCpa, cpaLine.name);
            line.previousPercent = this.formatPercentage(cpaLine.factor);
            line.previousAmount = this.formatCurrency(cpaLine.amount);
        });

        displayDetails.doubleCropTotal.previousPercent = this.formatPercentage(0);
        displayDetails.doubleCropTotal.previousAmount = this.formatCurrency(0);

        displayDetails.doubleCropPendingApproved.previousPercent = this.formatPercentage(0);
        displayDetails.doubleCropPendingApproved.previousAmount = this.formatCurrency(0);

        displayDetails.loadedPreviousCustomer = true;

        this.updateLines(displayDetails);

        return displayDetails;
    }

    setPreviousYearCustomerDataV3(displayDetails: DiscountDisplayModel, previousYearData: ShareCurrentDiscountsV3): DiscountDisplayModel {
        if (!angular.isDefined(displayDetails) || displayDetails === null) {
            displayDetails = new DiscountDisplayModel();
        }

        displayDetails.cpaTotal.previousPercent = this.formatPercentage(previousYearData.cpaTotal.percent);
        displayDetails.cpaTotal.previousAmount = this.formatCurrency(previousYearData.cpaTotal.amount);
        displayDetails.total.previousPercent = this.formatPercentage(previousYearData.total.percent);
        displayDetails.total.previousAmount = this.formatCurrency(previousYearData.total.amount);

        previousYearData.discountLines.forEach((discountLine: OrderedDiscountLineV2) => {
            const line = this.findByNameOrderNumber(displayDetails.discountLines, discountLine.name, discountLine.orderNumber);
            if (!line) {
                const newLine: any = {
                    name: discountLine.name,
                    orderNumber: discountLine.orderNumber,
                    previousPercent: this.formatPercentage(discountLine.percent),
                    previousAmount: this.formatCurrency(discountLine.amount)
                };
                displayDetails.discountLines.push(newLine);
            } else {
                line.previousPercent = this.formatPercentage(discountLine.percent);
                line.previousAmount = this.formatCurrency(discountLine.amount);
            }
        });

        previousYearData.cpaImplemented.forEach((cpaLine: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.implementedCpa, cpaLine.name);
            line.previousPercent = this.formatPercentage(cpaLine.factor);
            line.previousAmount = this.formatCurrency(cpaLine.amount);
        });

        displayDetails.totalPending.previousPercent = this.formatPercentage(previousYearData.cpaTotalPending.percent);
        displayDetails.totalPending.previousAmount = this.formatCurrency(previousYearData.cpaTotalPending.amount);

        previousYearData.cpaPending.forEach((pendingCpaLine: DiscountPendingCpaLine) => {
            const line: any = this.findByNameTicks(displayDetails.pendingCpa, pendingCpaLine.name, pendingCpaLine.ticks);
            line.previousPercent = this.formatShareCpaFactor(pendingCpaLine.calculationId, pendingCpaLine.maxFactor);
            line.previousAmount = this.formatCurrency(pendingCpaLine.currentAmount);
        });

        //replant
        displayDetails.totalReplant.previousPercent = this.formatPercentage(previousYearData.replantTotal.percent);
        displayDetails.totalReplant.previousAmount = this.formatCurrency(previousYearData.replantTotal.amount);

        displayDetails.replantPendingTotal.previousPercent = this.formatPercentage(previousYearData.replantPendingTotal.percent);
        displayDetails.replantPendingTotal.previousAmount = this.formatCurrency(previousYearData.replantPendingTotal.amount);

        displayDetails.replantPendingApproved.previousPercent = this.formatPercentage(previousYearData.replantTotalPendingApproved.percent);
        displayDetails.replantPendingApproved.previousAmount = this.formatCurrency(previousYearData.replantTotalPendingApproved.amount);

        previousYearData.replant.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.replant, implemented.name);
            line.previousPercent = this.formatPercentage(implemented.factor);
            line.previousAmount = this.formatCurrency(implemented.amount);
        });

        previousYearData.replantPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.replantPending, pending.name);
            line.previousPercent = this.formatPercentage(pending.maxFactor);
            line.previousAmount = this.formatCurrency(pending.currentAmount);
        });

        //// double crop
        displayDetails.doubleCropTotal.previousPercent = this.formatPercentage(previousYearData.doubleCropTotal.percent);
        displayDetails.doubleCropTotal.previousAmount = this.formatCurrency(previousYearData.doubleCropTotal.amount);

        displayDetails.doubleCropPendingTotal.previousPercent = this.formatPercentage(previousYearData.doubleCropTotalPending.percent);
        displayDetails.doubleCropPendingTotal.previousAmount = this.formatCurrency(previousYearData.doubleCropTotalPending.amount);

        displayDetails.doubleCropPendingApproved.previousPercent = this.formatPercentage(previousYearData.doubleCropTotalPendingApproved.percent);
        displayDetails.doubleCropPendingApproved.previousAmount = this.formatCurrency(previousYearData.doubleCropTotalPendingApproved.amount);

        previousYearData.doubleCropImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.doubleCrop, implemented.name);
            line.previousPercent = this.formatPercentage(implemented.factor);
            line.previousAmount = this.formatCurrency(implemented.amount);
        });

        previousYearData.doubleCropPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.doubleCropPending, pending.name);
            line.previousPercent = this.formatPercentage(pending.maxFactor);
            line.previousAmount = this.formatCurrency(pending.currentAmount);
        });

        // cactus
        displayDetails.cactusTotal.previousPercent = this.formatPercentage(previousYearData.cactusTotal.percent);
        displayDetails.cactusTotal.previousAmount = this.formatCurrency(previousYearData.cactusTotal.amount);

        displayDetails.cactusPendingTotal.previousPercent = this.formatPercentage(previousYearData.cactusTotalPending.percent);
        displayDetails.cactusPendingTotal.previousAmount = this.formatCurrency(previousYearData.cactusTotalPending.amount);

        displayDetails.cactusPendingApproved.previousPercent = this.formatPercentage(previousYearData.cactusTotalPendingApproved.percent);
        displayDetails.cactusPendingApproved.previousAmount = this.formatCurrency(previousYearData.cactusTotalPendingApproved.amount);

        previousYearData.cactusImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.cactus, implemented.name);
            line.previousPercent = this.formatPercentage(implemented.factor);
            line.previousAmount = this.formatCurrency(implemented.amount);
        });

        previousYearData.cactusPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.cactusPending, pending.name);
            line.previousPercent = this.formatPercentage(pending.maxFactor);
            line.previousAmount = this.formatCurrency(pending.currentAmount);
        });

        displayDetails.loadedPreviousCustomer = true;

        this.updateLines(displayDetails);

        return displayDetails;
    }

    setCurrentCustomerDataV3(displayDetails: DiscountDisplayModel, currentYearData: ShareCurrentDiscountsV3): DiscountDisplayModel {
        if (!angular.isDefined(displayDetails) || displayDetails === null) {
            displayDetails = new DiscountDisplayModel();
        }

        displayDetails.cpaTotal.currentCustomerPercent = this.formatPercentage(currentYearData.cpaTotal.percent);
        displayDetails.cpaTotal.currentCustomerAmount = this.formatCurrency(currentYearData.cpaTotal.amount);
        displayDetails.total.currentCustomerPercent = this.formatPercentage(currentYearData.total.percent);
        displayDetails.total.currentCustomerAmount = this.formatCurrency(currentYearData.total.amount);

        currentYearData.discountLines.forEach((discountLine: OrderedDiscountLineV2) => {
            const line = this.findByNameOrderNumber(displayDetails.discountLines, discountLine.name, discountLine.orderNumber);
            if (!line) {
                const newLine: any = {
                    name: discountLine.name,
                    orderNumber: discountLine.orderNumber,
                    currentCustomerPercent: this.formatPercentage(discountLine.percent),
                    currentCustomerAmount: this.formatCurrency(discountLine.amount)
                };
                displayDetails.discountLines.push(newLine);
            } else {
                line.currentCustomerPercent = this.formatPercentage(discountLine.percent);
                line.currentCustomerAmount = this.formatCurrency(discountLine.amount);
            }
        });

        currentYearData.cpaImplemented.forEach((cpaLine: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.implementedCpa, cpaLine.name);
            line.currentCustomerPercent = this.formatPercentage(cpaLine.factor);
            line.currentCustomerAmount = this.formatCurrency(cpaLine.amount);
        });

        displayDetails.totalPending.currentCustomerPercent = this.formatPercentage(currentYearData.cpaTotalPending.percent);
        displayDetails.totalPending.currentCustomerAmount = this.formatCurrency(currentYearData.cpaTotalPending.amount);

        displayDetails.customerAllPendingTotals.percent = this.formatPercentage(currentYearData.cpaTotalPendingApproved.percent);
        displayDetails.customerAllPendingTotals.amount = this.formatCurrency(currentYearData.cpaTotalPendingApproved.amount);

        currentYearData.cpaPending.forEach((pendingCpaLine: DiscountPendingCpaLine) => {
            const line: any = this.findByNameTicks(displayDetails.pendingCpa, pendingCpaLine.name, pendingCpaLine.ticks);
            line.currentCustomerPercent = this.formatShareCpaFactor(pendingCpaLine.calculationId, pendingCpaLine.maxFactor);
            line.currentCustomerAmount = this.formatCurrency(pendingCpaLine.currentAmount);
        });

        //replant
        displayDetails.totalReplant.currentCustomerPercent = this.formatPercentage(currentYearData.replantTotal.percent);
        displayDetails.totalReplant.currentCustomerAmount = this.formatCurrency(currentYearData.replantTotal.amount);

        displayDetails.replantPendingTotal.currentCustomerPercent = this.formatPercentage(currentYearData.replantPendingTotal.percent);
        displayDetails.replantPendingTotal.currentCustomerAmount = this.formatCurrency(currentYearData.replantPendingTotal.amount);

        displayDetails.replantPendingApproved.currentCustomerPercent = this.formatPercentage(currentYearData.replantTotalPendingApproved.percent);
        displayDetails.replantPendingApproved.currentCustomerAmount = this.formatCurrency(currentYearData.replantTotalPendingApproved.amount);

        currentYearData.replant.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.replant, implemented.name);
            line.currentCustomerPercent = this.formatPercentage(implemented.factor);
            line.currentCustomerAmount = this.formatCurrency(implemented.amount);
        });

        currentYearData.replantPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.replantPending, pending.name);
            line.currentCustomerPercent = this.formatPercentage(pending.maxFactor);
            line.currentCustomerAmount = this.formatCurrency(pending.currentAmount);
        });

        //// double crop
        displayDetails.doubleCropTotal.currentCustomerPercent = this.formatPercentage(currentYearData.doubleCropTotal.percent);
        displayDetails.doubleCropTotal.currentCustomerAmount = this.formatCurrency(currentYearData.doubleCropTotal.amount);

        displayDetails.doubleCropPendingTotal.currentCustomerPercent = this.formatPercentage(currentYearData.doubleCropTotalPending.percent);
        displayDetails.doubleCropPendingTotal.currentCustomerAmount = this.formatCurrency(currentYearData.doubleCropTotalPending.amount);

        displayDetails.doubleCropPendingApproved.currentCustomerPercent = this.formatPercentage(currentYearData.doubleCropTotalPendingApproved.percent);
        displayDetails.doubleCropPendingApproved.currentCustomerAmount = this.formatCurrency(currentYearData.doubleCropTotalPendingApproved.amount);

        currentYearData.doubleCropImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.doubleCrop, implemented.name);
            line.currentCustomerPercent = this.formatPercentage(implemented.factor);
            line.currentCustomerAmount = this.formatCurrency(implemented.amount);
        });

        currentYearData.doubleCropPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.doubleCropPending, pending.name);
            line.currentCustomerPercent = this.formatPercentage(pending.maxFactor);
            line.currentCustomerAmount = this.formatCurrency(pending.currentAmount);
        });

        // cactus
        displayDetails.cactusTotal.currentCustomerPercent = this.formatPercentage(currentYearData.cactusTotal.percent);
        displayDetails.cactusTotal.currentCustomerAmount = this.formatCurrency(currentYearData.cactusTotal.amount);

        displayDetails.cactusPendingTotal.currentCustomerPercent = this.formatPercentage(currentYearData.cactusTotalPending.percent);
        displayDetails.cactusPendingTotal.currentCustomerAmount = this.formatCurrency(currentYearData.cactusTotalPending.amount);

        displayDetails.cactusPendingApproved.currentCustomerPercent = this.formatPercentage(currentYearData.cactusTotalPendingApproved.percent);
        displayDetails.cactusPendingApproved.currentCustomerAmount = this.formatCurrency(currentYearData.cactusTotalPendingApproved.amount);

        currentYearData.cactusImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.cactus, implemented.name);
            line.currentCustomerPercent = this.formatPercentage(implemented.factor);
            line.currentCustomerAmount = this.formatCurrency(implemented.amount);
        });

        currentYearData.cactusPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.cactusPending, pending.name);
            line.currentCustomerPercent = this.formatPercentage(pending.maxFactor);
            line.currentCustomerAmount = this.formatCurrency(pending.currentAmount);
        });

        displayDetails.loadedCurrentCustomer = true;

        this.updateLines(displayDetails);

        return displayDetails;
    }

    setCurrentShareDataV3(displayDetails: DiscountDisplayModel, shareCurrentDiscounts: ShareCurrentDiscountsV3): DiscountDisplayModel {
        if (!angular.isDefined(displayDetails) || displayDetails === null) {
            displayDetails = new DiscountDisplayModel();
        }

        displayDetails.cpaTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.cpaTotal.percent);
        displayDetails.cpaTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.cpaTotal.amount);

        displayDetails.total.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.total.percent);
        displayDetails.total.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.total.amount);

        shareCurrentDiscounts.discountLines.forEach((discountLine: OrderedDiscountLineV2) => {
            const line = this.findByNameOrderNumber(displayDetails.discountLines, discountLine.name, discountLine.orderNumber);
            if (!line) {
                const newLine: any = {
                    name: discountLine.name,
                    orderNumber: discountLine.orderNumber,
                    currentSharePercent: this.formatPercentage(discountLine.percent),
                    currentShareAmount: this.formatCurrency(discountLine.amount)
                };
                displayDetails.discountLines.push(newLine);
            } else {
                line.currentSharePercent = this.formatPercentage(discountLine.percent);
                line.currentShareAmount = this.formatCurrency(discountLine.amount);
            }
        });

        shareCurrentDiscounts.cpaImplemented.forEach((cpaLine: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.implementedCpa, cpaLine.name);
            line.currentSharePercent = this.formatShareCpaFactor(cpaLine.calculationId, cpaLine.factor);
            line.currentShareAmount = this.formatShareCpaAmount(cpaLine.factor, cpaLine.amount);
        });

        displayDetails.totalPending.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.cpaTotalPending.percent);
        displayDetails.totalPending.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.cpaTotalPending.amount);

        displayDetails.allPendingTotals.percent = this.formatPercentage(shareCurrentDiscounts.cpaTotalPendingApproved.percent);
        displayDetails.allPendingTotals.amount = this.formatCurrency(shareCurrentDiscounts.cpaTotalPendingApproved.amount);

        shareCurrentDiscounts.cpaPending.forEach((pendingCpaLine: DiscountPendingCpaLine) => {
            const line: any = this.findByNameTicks(displayDetails.pendingCpa, pendingCpaLine.name, pendingCpaLine.ticks);
            line.currentSharePercent = this.formatShareCpaFactor(pendingCpaLine.calculationId, pendingCpaLine.maxFactor);
            line.currentShareAmount = this.formatCurrency(pendingCpaLine.currentAmount);
        });

        //replant
        displayDetails.totalReplant.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.replantTotal.percent);
        displayDetails.totalReplant.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.replantTotal.amount);

        displayDetails.replantPendingTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.replantPendingTotal.percent);
        displayDetails.replantPendingTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.replantPendingTotal.amount);

        displayDetails.replantPendingApproved.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.replantTotalPendingApproved.percent);
        displayDetails.replantPendingApproved.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.replantTotalPendingApproved.amount);
        
        shareCurrentDiscounts.replant.forEach((replantLine: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.replant, replantLine.name);
            line.currentSharePercent = this.formatPercentage(replantLine.factor);
            line.currentShareAmount = this.formatCurrency(replantLine.amount);
        });

        shareCurrentDiscounts.replantPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.replantPending, pending.name);
            line.currentSharePercent = this.formatPercentage(pending.maxFactor);
            line.currentShareAmount = this.formatCurrency(pending.currentAmount);
        });

        // double crop
        displayDetails.doubleCropTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.doubleCropTotal.percent);
        displayDetails.doubleCropTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.doubleCropTotal.amount);

        displayDetails.doubleCropPendingTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.doubleCropTotalPending.percent);
        displayDetails.doubleCropPendingTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.doubleCropTotalPending.amount);

        displayDetails.doubleCropPendingApproved.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.doubleCropTotalPendingApproved.percent);
        displayDetails.doubleCropPendingApproved.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.doubleCropTotalPendingApproved.amount);

        shareCurrentDiscounts.doubleCropImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.doubleCrop, implemented.name);
            line.currentSharePercent = this.formatPercentage(implemented.factor);
            line.currentShareAmount = this.formatCurrency(implemented.amount);
        });

        shareCurrentDiscounts.doubleCropPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.doubleCropPending, pending.name);
            line.currentSharePercent = this.formatPercentage(pending.maxFactor);
            line.currentShareAmount = this.formatCurrency(pending.currentAmount);
        });

        // cactus
        displayDetails.cactusTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.cactusTotal.percent);
        displayDetails.cactusTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.cactusTotal.amount);

        displayDetails.cactusPendingTotal.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.cactusTotalPending.percent);
        displayDetails.cactusPendingTotal.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.cactusTotalPending.amount);

        displayDetails.cactusPendingApproved.currentSharePercent = this.formatPercentage(shareCurrentDiscounts.cactusTotalPendingApproved.percent);
        displayDetails.cactusPendingApproved.currentShareAmount = this.formatCurrency(shareCurrentDiscounts.cactusTotalPendingApproved.amount);

        shareCurrentDiscounts.cactusImplemented.forEach((implemented: DiscountImplementedCpaLineV2) => {
            const line: any = this.findByName(displayDetails.cactus, implemented.name);
            line.currentSharePercent = this.formatPercentage(implemented.factor);
            line.currentShareAmount = this.formatCurrency(implemented.amount);
        });

        shareCurrentDiscounts.cactusPending.forEach((pending: DiscountPendingCpaLine) => {
            const line: any = this.findByName(displayDetails.cactusPending, pending.name);
            line.currentSharePercent = this.formatPercentage(pending.maxFactor);
            line.currentShareAmount = this.formatCurrency(pending.currentAmount);
        });

        displayDetails.loadedCurrentShare = true;

        this.updateLines(displayDetails);

        return displayDetails;
    }

    private updateLines(displayDetails: DiscountDisplayModel) {
        if (displayDetails.loaded()) {
            displayDetails.implementedCpa = displayDetails.implementedCpa.filter((value) => {
                return this.nonZeroAmount(value.currentShareAmount) ||
                    this.nonZeroAmount(value.currentCustomerAmount) ||
                    this.nonZeroAmount(value.previousAmount);
            });

            displayDetails.discountLines = displayDetails.discountLines.filter((value) => {
                return this.nonZeroAmount(value.currentShareAmount) ||
                    this.nonZeroAmount(value.currentCustomerAmount) ||
                    this.nonZeroAmount(value.previousAmount);
            });
        }

        displayDetails.implementedCpa.forEach(value => {
            if (displayDetails.loadedPreviousCustomer && !value.previousAmount) {
                value.previousAmount = this.formatCurrency(0);
                value.previousPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });

        displayDetails.discountLines.forEach(value => {
            if (displayDetails.loadedPreviousCustomer && !value.previousAmount) {
                value.previousAmount = this.formatCurrency(0);
                value.previousPercent = this.formatPercentage(0);
            };

            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = this.formatPercentage(0);
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = this.formatPercentage(0);
            };
        });

        displayDetails.doubleCrop.forEach(value => {
            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });

        displayDetails.doubleCropPending.forEach(value => {
            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });


        displayDetails.cactus.forEach(value => {
            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });

        displayDetails.cactusPending.forEach(value => {
            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });

        displayDetails.pendingCpa.forEach(value => {

            if (displayDetails.loadedCurrentCustomer && !value.currentCustomerAmount) {
                value.currentCustomerAmount = this.formatCurrency(0);
                value.currentCustomerPercent = '\u2014';
            };

            if (displayDetails.loadedCurrentShare && !value.currentShareAmount) {
                value.currentShareAmount = this.formatCurrency(0);
                value.currentSharePercent = '\u2014';
            };
        });
    }

    private nonZeroAmount(value: string) {
        return angular.isDefined(value) && value !== null && Number(value.replace(/[^0-9.-]+/g, '')) !== 0;
    }

    private findByName(items: Array<any>, name: string): any {
        let result: any = null, index: number = 0;
        while (result === null && index < items.length) {
            if (items[index].name === name) {
                result = items[index];
            }

            ++index;
        }

        if (result === null) {
            result = { name: name };
            items.push(result);
        }

        return result;
    }

    private findByNameOrderNumber(items: Array<any>, name: string, orderNumber: number): any {
        let result: any = null, index: number = 0;
        while (result === null && index < items.length) {
            if (items[index].name === name && items[index].orderNumber === orderNumber) {
                result = items[index];
            }

            ++index;
        }

        return result;
    }

    private findByNameTicks(items: Array<any>, name: string, ticks: number): any {
        let result: any = null, index: number = 0;
        while (result === null && index < items.length) {
            if (items[index].name === name && items[index].ticks === ticks) {
                result = items[index];
            }

            ++index;
        }

        if (result === null) {
            result = { name: name, ticks: ticks };
            items.push(result);
        }

        return result;
    }

    private formatCurrency(value: number) {
        return this.$filter('currency')(value, '$', 2);
    }

    private formatPercentage(value: number) {
        return (this.$filter('percentage') as Filter.IFilterPercentage)(value);
    }

    private formatShareCpaAmount(factor: number, amount: number): string {
        if (factor === 0) {
            return '\u2014';
        }

        return this.formatCurrency(amount);
    }

    private formatShareCpaFactor(calculationId: number, factor: number): string {
        if (factor === 0) {
            return '\u2014';
        }

        if (calculationId === 1) {
            return (this.$filter('percentage') as Filter.IFilterPercentage)(factor);
        }

        if (calculationId === 2) {
            return this.$filter('currency')(factor, '$') + ' per unit';
        }

        return '';
    }
}