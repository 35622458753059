import { IMassPricingDetailRequest } from "./detail/massPricingDetail.model.interface";

export default class MassPricingRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getMassProductPricingApprovalList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/mass-approval/product`);
    }

    getMassTreatmentPricingApprovalList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/mass-approval/treatment`);
    }

    approveMassPricingRequest(detailData: IMassPricingDetailRequest): ng.IPromise<any> {
        return this.$http.post(`{API-ApprovalTool}/pricing/mass-approval/detail`, detailData);
    }
}