import BalanceExceptionInformationComponentController from './exceptionInformation.controller';

export default class BalanceExceptionInformationComponentV2 implements ng.IComponentOptions {
    template: string = require('./exceptionInformation.html');
    controller: Function = BalanceExceptionInformationComponentController;
    bindings: { [binding: string]: string } = {
        form: '<',
        criteria: '<',
        loading: '<',
        options: '<',
        onOpen: '&',
        getMaterials: '&',
        clearMaterial: '&',
        materials: '<'
    };

    static factory() {
        const component: () => ng.IComponentOptions = () => {
            return new BalanceExceptionInformationComponentV2();
        };

        return component();
    }
}