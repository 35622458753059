module.exports = function ($filter) {

    const numberFilter = $filter('number');

    const endsWith = function(string, suffix) {
        'option strict';

        return string.indexOf(suffix, string.length - suffix.length) !== -1;
    };

    const filter = function(input) {
        'option strict';

        var formatted = numberFilter(input, 1);
        const pointZeroSuffix = '.0';

        if (formatted && endsWith(formatted, pointZeroSuffix)) {
            formatted = formatted.slice(0, formatted.length - pointZeroSuffix.length);
        }

        return formatted;
    };

    return filter;

};