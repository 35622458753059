import './sampleDetail.scss';

import SampleDetailService from './sampleDetail.service';
Application.registerRepository('sampleDetailService', SampleDetailService);

import SampleDetailRepository from './sampleDetail.repository';
Application.registerController('SampleDetailRepository', SampleDetailRepository);

import SampleDetailV2Controller from './sampleDetailV2.controller';
Application.registerController('SampleDetailV2Controller', SampleDetailV2Controller);

import SampleCommentsComponent from './comments/sampleComments.component';
Application.registerComponent('sampleComments', SampleCommentsComponent.factory());

import SampleDetailBarComponent from './detailBar/sampleDetailBar.component';
Application.registerComponent('sampleDetailBar', SampleDetailBarComponent.factory());

import SampleCommentsService from './comments/sampleComments.service';
Application.registerRepository('sampleCommentsService', SampleCommentsService);

import SampleDetailDisplayService from './sampleDetailDisplay.service';
Application.registerService('sampleDetailDisplayService', SampleDetailDisplayService);