import {IApproverSearchBarCriteriaModel} from './models/approverSearchBarCriteria.model.interface';

export default class ApproverSearchBarService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getSalesAgencies(criteria: IApproverSearchBarCriteriaModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/agencies`, criteria, { timeout: 180000 });
    }

    getTerritoriesSalesAgencies(criteria: IApproverSearchBarCriteriaModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/territories/agencies`, criteria, { timeout: 180000 });
    }

    getAreas(criteria: IApproverSearchBarCriteriaModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/areas`, criteria, { timeout: 180000 });
    }

    getCommercialUnitsAreas(criteria: IApproverSearchBarCriteriaModel): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/requests/approversearch/commercialunits/areas`, criteria, { timeout: 180000 });
    }
}
