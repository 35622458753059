import { BwoRequestOptions } from './models/bwoRequest.options.model';
import { BwoRequestLoading } from './models/bwoRequest.loading.model';
import { RequestCriteria } from './models/bwoRequest.criteria.model';

import { IIdAndName } from '../../../../core/models/idAndName.model.interface';
import { IOrgIdAndName } from '../../../../core/models/orgIdAndName.model.interface';
import { Invoice } from '../../core/models/invoice.model';
import { Share } from '../../core/models/share.model';
import { BwoRequestService } from './bwoRequest.service';

import { IBwoRequestFormController } from './models/bwoRequestForm.interface';
import { IRedirectService } from '../../../../core/services/redirect.service.interface';
import { PioneerAccountBalance } from './models/bwoRequest.pioneerAccountBalance.model';
import { DeferredPaymentLoanBalance } from './models/bwoRequest.deferredPaymentLoanBalance.model';

export default class BwoRequestController {
    pioneerAccountBalance: PioneerAccountBalance = new PioneerAccountBalance(0, 0, 0, 0);
    deferredPaymentLoanBalance: DeferredPaymentLoanBalance = new DeferredPaymentLoanBalance(0, 0, 0);
    isChecked: boolean = false;
    maxBalance: number;

    options: BwoRequestOptions = {
        salesSeasons: [],
        territories: [],
        agencies: [],
        operations: [],
        accountDescriptions: [],
        bwoTypes: [],
        businessPartners: []
    };

    loading: BwoRequestLoading = {
        options: undefined,
        salesSeasons: undefined,
        agencies: undefined,
        territories: undefined,
        operations: undefined,
        accountDescriptions: undefined,
        businessPartners: undefined,
        bwoTypes: undefined,
        amount: undefined,
        submit: undefined
    };

    criteria: RequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        businessPartnerName: undefined,
        bwoType: undefined,
        comment: undefined,
        amount: undefined,
        isFullBalanceWriteOff: undefined
    };

    temp: RequestCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        operation: undefined,
        accountDescription: undefined,
        businessPartner: undefined,
        businessPartnerName: undefined,
        bwoType: undefined,
        comment: undefined,
        amount: undefined,
        isFullBalanceWriteOff: undefined
    };

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    // Search terms
    territoriesSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    operationsSearchTerm: string = '';

    requestForm: IBwoRequestFormController;

    hideToolbar: boolean = false;
    isStatusDismissed: boolean = true;
    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $document: ng.IDocumentService,
        protected $q: ng.IQService,
        protected bwoRequestService: BwoRequestService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected redirectService: IRedirectService,
        protected $mdToast: ng.material.IToastService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
         this.setHandlers();

         this.getSalesPeriods();
    }

    private setHandlers(): void {
        const selectors: string[] = [
            'territories',
            'agencies',
            'operations'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    /**
     * Sets respective temp criteria object property for comparison on menu close
     * or to undefined if criteria property not found/defined
     * @param property
     */
    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    // Select options and criteria reset
    private clearTerritories(): void {
        this.resetElement(this.requestForm.territory);

        this.options.territories = [];
        this.criteria.territory = undefined;
        this.temp.territory = undefined;
    }

    private clearSalesAgencies(): void {
        this.resetElement(this.requestForm.salesAgency);

        this.options.agencies = [];
        this.criteria.salesAgency = undefined;
        this.temp.salesAgency = undefined;
    }

    private clearOperations(): void {
        this.resetElement(this.requestForm.operation);

        this.options.operations = [];
        this.criteria.operation = undefined;
        this.temp.operation = undefined;
    }

    private clearAccountDescriptions(): void {
        this.resetElement(this.requestForm.accountDescription);

        this.options.accountDescriptions = [];
        this.criteria.accountDescription = undefined;
        this.temp.accountDescription = undefined;
    }

    private clearBusinessPartners(): void {
        this.resetElement(this.requestForm.businessPartner);

        this.options.businessPartners = [];
        this.criteria.businessPartner = undefined;
        this.temp.businessPartner = undefined;
        this.isStatusDismissed = true;
        this.pioneerAccountBalance = new PioneerAccountBalance(0, 0, 0, 0);
    }

    private clearBwoTypes(): void {
        this.resetElement(this.requestForm.bwoType);

        this.options.bwoTypes = [];
        this.criteria.bwoType = undefined;
        this.temp.bwoType = undefined;
    }

    private clearComment(): void {
        this.resetElement(this.requestForm.comment);

        this.criteria.comment = undefined;
    }

    private clearAmount(): void {
        this.resetElement(this.requestForm.amount);

        this.criteria.amount = undefined;
    }

    private getBalances(invoiceId: number, shareId: number, customerId: number, salesYear: number): void {
        this.loading.amount = true;
        const promises: ng.IPromise<any>[] = [
            this.bwoRequestService.getDeferredPaymentLoanBalance(customerId, salesYear)
            .then((data) => this.processDeferredPaymentLoanResponse(data)),
            this.bwoRequestService.getPioneerBalance(invoiceId, shareId)
                .then((data) => this.processResponse(data))
        ];
        this.$q.all(promises).then(() => {
            this.getBwoTypes();
        }).finally(() => this.loading.amount = false);
    }

    private processDeferredPaymentLoanResponse(response: any): void {
        this.deferredPaymentLoanBalance = response.data;
        this.loading.amount = true;
    }

    private processResponse(response: any): void {
        this.pioneerAccountBalance = response.data;
        this.loading.amount = true;
    }

    // Labels
    getTerritoriesLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.territories) {
            return loadingLabel;
        }
        else if (!this.criteria.salesSeason) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.territories) && this.loading.territories === false) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    getSalesAgenciesLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.agencies) {
            return loadingLabel;
        }
        else if (!this.criteria.territory) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.agencies) && this.loading.agencies === false) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    getOperationsLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.operations) {
            return loadingLabel;
        }
        else if (!this.criteria.salesAgency) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.operations) && this.loading.operations === false) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    getAccountDescriptionsLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.accountDescriptions) {
            return loadingLabel;
        }
        else if (!this.criteria.operation) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.accountDescriptions) && this.loading.accountDescriptions === false) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    getBusinessPartnersLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.businessPartners) {
            return loadingLabel;
        }
        else if (!this.criteria.accountDescription) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.businessPartners) && this.loading.businessPartners === false) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    getBwoTypesLabel(loadingLabel: string, inputLabel: string, noDataLabel: string): string {
        if (this.loading.bwoTypes) {
            return loadingLabel;
        }
        else if (!this.criteria.businessPartner) {
            return inputLabel;
        }
        else if (_.isEmpty(this.options.bwoTypes)) {
            return noDataLabel;
        }
        else {
            return inputLabel;
        }
    }

    // Select callbacks
    onSalesSeasonsSelect(): void {
        if (this.hasChanged(this.temp.salesSeason, this.criteria.salesSeason)) {
            this.clearTerritories();
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.clearBwoTypes();
            this.clearComment();
            this.clearAmount();
            this.getTerritories();
        }
    }

    onTerritoriesSelect(): void {
        this.territoriesSearchTerm = '';

        if (this.hasChanged(this.temp.territory, this.criteria.territory)) {
            this.clearSalesAgencies();
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.getSalesAgencies();
            this.clearComment();
            this.clearAmount();
            this.clearBwoTypes();
        }
    }

    onSalesAgenciesSelect(): void {
        this.salesAgenciesSearchTerm = '';

        if (this.hasChanged(this.temp.salesAgency, this.criteria.salesAgency)) {
            this.clearOperations();
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.getOperations();
            this.clearComment();
            this.clearAmount();
            this.clearBwoTypes();
        }
    }

    onOperationsSelect(): void {
        this.operationsSearchTerm = '';

        if (this.hasChanged(this.temp.operation, this.criteria.operation)) {
            this.clearAccountDescriptions();
            this.clearBusinessPartners();
            this.getInvoicesShares();
            this.clearAmount();
            this.clearComment();
            this.clearBwoTypes();
        }
    }

    onAccountDescriptionsSelect(): void {
        if (this.hasChanged(this.temp.accountDescription, this.criteria.accountDescription)) {
            this.clearBwoTypes();
            this.clearAmount();
            this.clearComment();
            this.criteria.businessPartner = undefined;
            this.setBusinessPartners(this.getFilteredBusinessPartners());
        }
    }

    onBusinessPartnersSelect(): void {
        if (this.hasChanged(this.temp.businessPartner, this.criteria.businessPartner)) {
            this.isStatusDismissed = true;
            this.clearBwoTypes();
            this.clearAmount();
            this.clearComment();
            this.getBalances(this.criteria.businessPartner.invoiceId,
                this.criteria.businessPartner.shareId,
                this.criteria.businessPartner.businessPartnerId,
                this.criteria.salesSeason.year);
        }
    }

    onBwoTypeSelect(): void {
        if (this.hasChanged(this.temp.bwoType, this.criteria.bwoType)) {
            this.clearAmount();
            this.maxBalance = (this.criteria.bwoType.id === 1) ? this.pioneerAccountBalance.totalAmount : this.deferredPaymentLoanBalance.totalAmount;
        }
    }


    // Getters
    private getSalesPeriods(): ng.IPromise<any> {
        this.loading.salesSeasons = true;

        return this.bwoRequestService.getSalesPeriods()
            .then((response) => {
                this.processSalesPeriodsResponse(response.data);
            })
            .finally(() => this.loading.salesSeasons = false);
    }

    private getTerritories(): ng.IPromise<any> {
        this.loading.territories = true;

        return this.bwoRequestService.getTerritories(this.criteria)
            .then((response) => {
                this.processTerritoriesResponse(response.data);
                this.setTerritory();
            })
            .finally(() => this.loading.territories = false);
    }

    private getSalesAgencies(): ng.IPromise<any> {
        this.loading.agencies = true;

        return this.bwoRequestService.getSalesAgencies(this.criteria)
            .then((response) => {
                this.processSalesAgenciesResponse(response.data);
                this.setSalesAgency();
            })
            .finally(() => this.loading.agencies = false);
    }

    private getOperations(): void {
        this.loading.operations = true;

        this.bwoRequestService.getOperations(this.criteria)
            .then((response) => {
                this.processOperationsResponse(response.data);
                this.setOperation();
            })
            .finally(() => this.loading.operations = false);
    }

    private getInvoicesShares(): ng.IPromise<any> {
        this.loading.accountDescriptions = true;
        this.loading.businessPartners = true;

        return this.bwoRequestService.getInvoicesShares(this.criteria.salesSeason.salesPeriodId, this.criteria.operation.id)
            .then((response) => {
                this.processInvoicesSharesResponse(response.data);
                this.setAccountDescriptions();
                this.setBusinessPartners(this.getFilteredBusinessPartners());
            })
            .finally(() => {
                this.loading.accountDescriptions = false;
                this.loading.businessPartners = false;
            });
    }

    private getBwoTypes(): ng.IPromise<any> {
        this.loading.bwoTypes = true;

        return this.bwoRequestService.getBwoTypes()
            .then((response) => {
                this.processBwoTypesResponse(response.data);
            })
            .finally(() => {
                this.loading.bwoTypes = false;
            });
    }

    // Field enable disable
    canSelectTerritories(): boolean {
        return !_.isEmpty(this.options.territories) && !_.isUndefined(this.criteria.salesSeason);
    }

    canSelectSalesAgencies(): boolean {
        return !_.isEmpty(this.options.agencies) && !_.isUndefined(this.criteria.territory);
    }

    canSelectOperations(): boolean {
        return !_.isEmpty(this.options.operations) && !_.isUndefined(this.criteria.salesAgency);
    }

    canSelectAccountDescriptions(): boolean {
        return !_.isEmpty(this.options.accountDescriptions) && !_.isUndefined(this.criteria.operation);
    }

    canSelectBusinessPartners(): boolean {
        return !_.isEmpty(this.options.businessPartners) && !_.isUndefined(this.criteria.accountDescription);
    }

    canSelectBwoTypes(): boolean {
        if (this.pioneerAccountBalance.totalAmount <= 0) {
            return !_.isEmpty(this.options.bwoTypes) && !_.isUndefined(this.criteria.businessPartner);
        }
        return true;
    }

    canEnterAmount(): boolean {
        return !_.isUndefined(this.criteria.bwoType);
    }

    checkIfFullBalanceWriteOff(): void {
        this.criteria.isFullBalanceWriteOff = (this.pioneerAccountBalance.totalAmount === this.criteria.amount ||
            this.deferredPaymentLoanBalance.totalAmount === this.criteria.amount);
    }

    populateAmount(): void {
        if (this.criteria.isFullBalanceWriteOff) {
            this.criteria.amount = this.criteria.bwoType.id === 1 ? this.pioneerAccountBalance.totalAmount :
                this.deferredPaymentLoanBalance.totalAmount;
            this.requestForm.amount.$setTouched();
        }  else if (this.criteria.isFullBalanceWriteOff === false) {
            this.criteria.amount = undefined;
            document.getElementById('bwoAmountId').focus();
        }
    }

    enableFullBalanceWriteOff(): boolean {
        if (this.criteria.bwoType && this.criteria.bwoType.id > 0) {
            return true;
        }

        return false;
    }


    canComment(): boolean {
        return !_.isUndefined(this.criteria.businessPartner);
    }

    // Form
    showHint(formElement: ng.INgModelController): boolean {
        return formElement.$pristine || formElement.$valid;
    }

    dismissStatus(): boolean {
        this.isStatusDismissed = true;
        return this.isStatusDismissed;
    }

    resetForm(): void {
        this.criteria.territory = undefined;
        this.clearSalesAgencies();
        this.clearOperations();
        this.clearAccountDescriptions();
        this.clearBusinessPartners();
        this.clearComment();
        this.clearBwoTypes();
        this.clearAmount();
        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (this.requestForm && formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit;
    }

    submit(): void {
        this.loading.submit = true;

        this.bwoRequestService.submit(this.criteria)
            .then(() => {
                this.loading.submit = false;
                this.$mdToast.show(
                    this.$mdToast.simple().textContent('Balance write-off request saved')
                    .position('bottom center'));
                this.resetForm();
            });
    }

    isProcessing(): boolean {
        return this.loading.options || this.loading.submit;
    }

    private setTerritory(): void {
        if (this.isSingular(this.options.territories)) {
            this.criteria.territory = this.options.territories[0];
            this.getSalesAgencies();
        }
    }

    private setSalesAgency(): void {
        if (this.isSingular(this.options.agencies)) {
            this.criteria.salesAgency = this.options.agencies[0];
            this.getOperations();
        }
    }

    private setOperation(): void {
        if (this.isSingular(this.options.operations)) {
            this.criteria.operation = this.options.operations[0];
            this.getInvoicesShares();
        }
    }

    private setAccountDescriptions(): void {
        if (this.isSingular(this.options.accountDescriptions)) {
            this.criteria.accountDescription = this.options.accountDescriptions[0];
        }
    }

    private setBusinessPartners(filteredBusinessPartners: Share[]): void {
        if (this.isSingular(filteredBusinessPartners)) {
            this.criteria.businessPartner = filteredBusinessPartners[0];
            if (this.criteria.businessPartner != null) {
                this.getBalances(this.criteria.businessPartner.invoiceId,
                    this.criteria.businessPartner.shareId,
                    this.criteria.businessPartner.businessPartnerId,
                    this.criteria.salesSeason.year);
            }
        }
    }

    // API data processing
    private processSalesPeriodsResponse(options: BwoRequestOptions): void {
        this.options.salesSeasons = options.salesSeasons;
    }

    private processTerritoriesResponse(territories: IOrgIdAndName[]): void {
        this.options.territories = territories;
    }

    private processSalesAgenciesResponse(agencies: IIdAndName[]): void {
        this.options.agencies = agencies;
    }

    private processOperationsResponse(operations: IIdAndName[]): void {
        this.options.operations = operations;
    }

    private processInvoicesSharesResponse(invoicesShares: { invoices: Invoice[]; shares: Share[] }) {
        this.options.accountDescriptions = invoicesShares.invoices;
        this.options.businessPartners = invoicesShares.shares;
    }

    private processBwoTypesResponse(data: IIdAndName[]): void {
        this.options.bwoTypes = [];
        if (this.pioneerAccountBalance.totalAmount > 0) {
            this.isStatusDismissed = true;
            this.options.bwoTypes.push(data[0]);
        }

        if (this.deferredPaymentLoanBalance.totalAmount > 0) {
            this.isStatusDismissed = true;
            this.options.bwoTypes.push(data[1]);
            this.options.bwoTypes.push(data[2]);
        }

        if (this.pioneerAccountBalance.totalAmount <= 0 && this.deferredPaymentLoanBalance.totalAmount <= 0) {
            this.isStatusDismissed = false;
            this.options.bwoTypes = [];
        }
    }

    // Helper methods

    /**
     * Checks if collection has only one (1) item
     * @param values - collection of options objects
     * @returns {Boolean}
     */
    private isSingular(values: any[]): boolean {
        return _.isArray(values) && _.size(values) === 1;
    }

    /**
   * Compares criteria objects to determine if new value has been selected
   * @param current
   * @param selected
   * @returns {Boolean} - true if respective criteria object property has changed
   */
    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    // get filtered results
    private getFilteredBusinessPartners(): Share[] {
        if (!_.isUndefined(this.criteria.accountDescription)) {
            return this.options.businessPartners.filter((businessPartner) => {
                return businessPartner.invoiceId === this.criteria.accountDescription.invoiceId;
            });
        }

        return this.options.businessPartners;
    }

    getbwoTypes(bwoSearchTerm: string): ng.IPromise<IIdAndName[]> {
        if (_.isEmpty(bwoSearchTerm)) {
            return this.$q.when(this.options.bwoTypes);
        }

        const bwoTypes: IIdAndName[] = this.options.bwoTypes.filter((program) => {
            return program.name.toLocaleLowerCase().indexOf(bwoSearchTerm.toLocaleLowerCase()) > -1;
        });

        return this.$q.when(bwoTypes);
    }
}