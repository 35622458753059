import InfinityPackageOverrideDetailBarComponentController from './infinityPackageOverrideBar.component.controller';

export default class InfinityPackageOverrideDetailBarComponent implements ng.IComponentOptions {
    template: string = require('./infinityPackageOverrideDetailBar.html');
    bindings: { [binding: string]: string } = {
        request: '<',
        detail: '<'
    };
    controller: Function = InfinityPackageOverrideDetailBarComponentController;

    static factory() {
        const component: any = () => {
            return new InfinityPackageOverrideDetailBarComponent();
        };

        return component();
    }
}