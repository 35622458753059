import { ICpaRequest } from '../cpaRequest.model.interface';
import { ICpaRequestService } from '../cpaRequest.service.interface';
import { IUser } from '../../../../core/models/user.model.interface';
import { IUserService } from '../../../../core/services/user.service.interface';

import { CpaDetail } from './cpaDetail.model';
import CpaDetailRepository from './cpaDetail.repository';
import CpaDetailService from './cpaDetail.service';
import { ShareDiscountV2 } from '../../components/discounts/models/shareDiscountV2.model';
import DiscountsService from '../../components/discounts/discounts.service';
import IPromise = angular.IPromise;

export default class CpaApprovalDetailV2Controller {

    loading: boolean = true;

    request: ICpaRequest;
    detail: CpaDetail = CpaDetail.empty();
    shareDiscounts: ShareDiscountV2;
    finalApprovalDate: string = '';
    user: IUser;
    regularInvoiceValue: number;
    doubleCropReplantInvoiceValue: number;

    shareCurrentDiscounts: any;
    customerPreviousDiscounts: any;
    customerCurrentDiscounts: any;

    allowComment: boolean = false;
    isPermissionStopDatePassed: boolean = true;
    hasInvoiceMoved: boolean = false;

    constructor(
        protected $q: ng.IQService,
        protected $mdDialog: ng.material.IDialogService,
        protected requestLocal: ICpaRequest,
        protected cpaRequestService: ICpaRequestService,
        protected cpaDetailRepository: CpaDetailRepository,
        protected $timeout: ng.ITimeoutService,
        protected userService: IUserService,
        protected cpaDetailService: CpaDetailService,
        protected discountsService: DiscountsService
    ) {        
        this.request = this.requestLocal;
    }

    $onInit(): void {
        this.checkPermissionStopDatePassed();
        this.user = this.userService.get();
        this.allowComment = this.user.canApproveCpaRequests;

        this.activate();

        this.cpaDetailRepository.hasInvoiceMoved(this.request.invoice.id)
            .then(data => {
                this.hasInvoiceMoved = data;
            });
    }
     
    private activate(): IPromise<void> {
        return this.getDetail()
            .then(() => this.getLineScore())
            .finally(() => this.done());
    }

    showBudgetInfo(): boolean {
        if (this.request) {
            return this.request.salesPeriod.year > 2019;
        }

        return false;
    }

    approve(): void {
        this.request.processing = true;

        this.cpaDetailRepository.approve(this.request)
            .then(() => {
                this.getLineScore();
                this.getDetail();
                this.setActionPermissions(false, false, true);
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny(): void {
        this.request.processing = true;

        this.cpaDetailRepository.deny(this.request)
            .then(() => {
                this.getLineScore();
                this.getDetail();
                this.setActionPermissions(false, false, false);
            })
            .finally(() => this.request.processing = false);
    }

    withdraw(): void {
        this.request.processing = true;

        this.cpaDetailRepository.withdraw(this.request)
            .then(() => {
                this.getLineScore();
                this.getDetail();
                this.setActionPermissions(false, false, false);
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    canApprove(): boolean {
        return this.user.canApproveCpaRequests && this.request.canApprove && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    canDeny(): boolean {
        return this.user.canDenyCpaRequests && this.request.canDeny && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    canWithdraw(): boolean {
        return this.user.canWithdrawCpaRequests && this.request.canWithdraw && !this.request.processing && !this.isPermissionStopDatePassed;
    }

    checkPermissionStopDatePassed(): void {
        this.cpaDetailRepository.isPermissionStopDatePassed(this.request)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                if (response.data.length > 0) {
                    this.isPermissionStopDatePassed = !_.any(response.data, (s: any) => {
                        return s.salesYear === this.request.salesPeriod.year;
                    });
                }
            });
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }

    isPending(status: string): boolean {
        return status === 'Pending';
    }

    is2019Forward(): boolean {
        return this.request.salesPeriod.year >= 2019;
    }

    showOldDiscounts(): boolean {
        return this.request.salesPeriod.year < 2020;
    }

    private setActionPermissions(canApprove: boolean, canDeny: boolean, canWithdraw: boolean): void {
        this.request.canApprove = canApprove;
        this.request.canDeny = canDeny;
        this.$timeout(() => {
            this.request.canWithdraw = canWithdraw;
        }, 2000);
    }

    private getLineScore() {
        if (this.request && this.request.statusId === 3) {
            return this.cpaRequestService.getLineScore(this.request)
                .then((response: ng.IHttpPromiseCallbackArg<any>) => this.cpaRequestService.processLineResponse(this.request, response));
        } else {
            const deferred: ng.IDeferred<any> = this.$q.defer();
            this.request.discountAmount = (!this.request['discount']) ? 0 : this.request['discount'];
            deferred.resolve(this.request);
            return deferred.promise;
        }
    }

    private getDetail() {

        this.getDiscounts();

        if (!this.is2019Forward()) {
            return this.cpaDetailService.getOldDetail(this.request.requestId,
                this.request.invoice.id,
                this.request.salesPeriod.year,
                this.request.share.id)
                .then((detail: CpaDetail) => {
                    this.detail = detail;
                });
        }

        return this.cpaDetailService.getDetailV6(this.request.requestId,
            this.request.invoice.id,
            this.request.salesPeriod.year,
            this.request.share.id)
            .then((detail: CpaDetail) => {
                this.detail = detail;
            });
    }

    private getDiscounts() {
        if (this.showOldDiscounts()) {
            return this.discountsService.getShareCachedDiscounts(this.request.requestId,
                this.request.invoice.id,
                this.request.salesPeriod.year,
                this.request.share.id)
                .then((shareDiscounts: ShareDiscountV2) => {
                    this.shareDiscounts = shareDiscounts;
                });
        }

        this.discountsService.getCustomerCurrentDiscountsV2(this.request.share.id)
            .then((currentCustomerDiscounts: any) => {
                this.customerCurrentDiscounts = currentCustomerDiscounts;
            });

        this.discountsService.getCpaShareCurrentDiscounts(this.request.requestId,
            this.request.invoice.id,
            this.request.share.id)
            .then((shareCurrentDiscounts: any) => {
                this.shareCurrentDiscounts = shareCurrentDiscounts;
                this.regularInvoiceValue = this.shareCurrentDiscounts.regularInvoiceTotal;
                this.doubleCropReplantInvoiceValue = this.shareCurrentDiscounts.doubleCropReplantInvoiceTotal;
            });

            if (this.request.salesPeriod.year <= 2020) {
                return this.discountsService.getCustomerPreviousDiscounts(this.request.requestId,
                        this.request.invoice.id,
                        this.request.salesPeriod.year,
                        this.request.share.id)
                    .then((previousCustomerDiscounts: any) => {
                        this.customerPreviousDiscounts = previousCustomerDiscounts;
                    });
            }

            return this.discountsService.getCustomerPreviousDiscounts2020V2(this.request.share.id)
                .then((previousCustomerDiscounts: any) => {
                    this.customerPreviousDiscounts = previousCustomerDiscounts;
                });
        }
 
    private done(): void {
        this.loading = false;
    }
}