import './territoryPlanning.scss';
import TerritoryPlanningController from './territoryPlanning.controller';

export default class TerritoryPlanningComponent implements ng.IComponentOptions {
    template: string = require('./territoryPlanning.html');
    controller: Function = TerritoryPlanningController;

    bindings: { [binding: string]: string } = {
        territoryPlanning: '<',
        territoryId: '<',
        canEdit: '<',
        onFlexFundChange: '&',
        isInseason: '<'
    };

    static factory() {
        const component: () => TerritoryPlanningComponent = () => {
            return new TerritoryPlanningComponent();
        };

        return component();
    }
}