import {AgencyBalanceSummary} from './agencyBalanceSummary.model';
import AgencyBalanceSummaryServiceV2 from './agencyBalanceSummary.service';

export default class AgencyBalanceSummaryComponentController {
    salesYear: number;
    salesPeriodId: number;
    agencyId: number;
    requestId: number;
    requestStatusId: number;
    approved: boolean = false;
    denied: boolean = false;

    agencyBalanceSummaryModel: AgencyBalanceSummary;
    loading: boolean = false;

    constructor(private agencyBalanceSummaryServiceV2: AgencyBalanceSummaryServiceV2) { }

    $onInit(): void {
        this.loadData();
    }

    private $onChanges() {
       this.loadData();
    }

    private loadData() {
        if (this.salesPeriodId && this.salesYear && this.agencyId) {
            this.loading = true;

            this.agencyBalanceSummaryServiceV2.getBalanceSummary(this.salesPeriodId, this.salesYear, this.agencyId)
                .then(response =>
                    this.agencyBalanceSummaryModel = response.data
                )
                .finally(() => this.loading = false);
        } else if (this.requestId) {
            this.loading = true;

            this.approved = this.requestStatusId === 5 || this.requestStatusId === 7 || this.requestStatusId === 8;
            this.denied = this.requestStatusId === 6;

            this.agencyBalanceSummaryServiceV2.getBalanceSummaryByRequest(this.requestId)
                .then(response =>
                    this.agencyBalanceSummaryModel = response.data
                )
                .finally(() => this.loading = false);
        } else {
            this.agencyBalanceSummaryModel = null;
        }
    }

    isSignedDelivered(): boolean {
        return this.salesYear >= 2020;
    }
}