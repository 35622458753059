export default class Percent implements ng.IDirective {
    restrict: string = 'A';
    require: string = '?ngModel';

    scope: Object = {
        decimalplace: '@',
        allowzero: '@'
    };
    
    link: (scope: IPercentScope,
        element: angular.IAugmentedJQuery,
        attrs: IMyAttributes,
        ctrl: angular.INgModelController) => void = (scope: IPercentScope, element: ng.IAugmentedJQuery, attrs: IMyAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            let decimalPlaces: number = 1;
            if (attrs.decimalplace) {
                decimalPlaces = parseInt(scope.decimalplace);
            }

            let allowZero: boolean = false;
            if (attrs.allowzero) {
                allowZero = (scope.allowzero === 'true');
            }

            const displayStringFn = () => {
                let result: string;
                if (ctrl.$modelValue > 0 || (ctrl.$modelValue === 0 && allowZero)) {
                    result = (ctrl.$modelValue * 100).toFixed(decimalPlaces) + ' %';
                }
                return result;
            }

            const displayValueFn = () => {
                return angular.isNumber(ctrl.$modelValue) ? (ctrl.$modelValue * 100).toFixed(decimalPlaces) : '';
            }

            ctrl.$formatters.push(() => {
                return displayStringFn();
            });

            ctrl.$parsers.push((viewValue) => {
                const regex: RegExp = new RegExp(`100|([\\d]{0,2}(\\.\\d{0,${decimalPlaces}})?)`);
                const cleanedValue: string = viewValue.match(regex)[0];
                if (cleanedValue !== viewValue) {
                    ctrl.$setViewValue(cleanedValue);
                    ctrl.$render();
                };

                return parseFloat((Number(cleanedValue) / 100).toFixed(decimalPlaces + 2));
            });

            element.bind('blur', () => element.val(displayStringFn()));
            element.bind('focus', () => element.val(displayValueFn()));
        };

    static factory(): ng.IDirectiveFactory {
        return () => new Percent();
    }

}

interface IPercentScope extends ng.IScope {
    decimalplace: string
    allowzero: string
}

export interface IMyAttributes extends ng.IAttributes {
    decimalplace: string;
    allowzero: string;
}