import './servicesCommissionAdvanceNavigation.scss';

import ServicesCommissionAdvanceNavigationController from './servicesCommissionAdvanceNavigation.controller';

export default class ServicesCommissionAdvanceNavigationComponent implements ng.IComponentOptions {
    template: string = require('./servicesCommissionAdvanceNavigation.html');
    controller: Function = ServicesCommissionAdvanceNavigationController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component: () => ServicesCommissionAdvanceNavigationComponent = () => {
            return new ServicesCommissionAdvanceNavigationComponent();
        };

        return component();
    }
}