import {IAuthenticatedUserService} from './authenticatedUser.service.interface';
import {IHeaderService} from '../services/header.service.interface';
import {HeaderKeyEnum} from '../services/headerKey.enum';
import {IStorageService} from '../services/storage.service.interface';
import {StorageKeyEnum} from '../services/storageKey.enum';

export class AuthenticatedUserService implements IAuthenticatedUserService {

    username: string;
    password: string;
    private timeoutInMinutes: number = 30;

    constructor(
        private $http: ng.IHttpService,
        private headerService: IHeaderService,
        private storageService: IStorageService
    ) {
        // noop
    }

    get isSessionActive(): boolean {
        const sessionTimedOut = this.sessionTimedOut;
        let activeUntil: string = this.storageService.get(StorageKeyEnum.SessionActiveUntil);

        // BUGBUG If a redirection is made directly from the Login page, the SessionActiveUntil value won't be set yet
        if (sessionTimedOut === false && !activeUntil) {
            this.resetSessionActiveStartTime();
        }

        activeUntil = this.storageService.get(StorageKeyEnum.SessionActiveUntil);
        return (activeUntil && (new Date(activeUntil).getTime() > new Date().getTime()));
    }

    setCurrentUser(username: string, password: string): void {
        const encoded: string = btoa(username + ':' + password);
        const authorizationHeader: string = 'Basic ' + encoded;

        this.headerService.set(HeaderKeyEnum.Authorization, authorizationHeader);
        this.storageService.set(StorageKeyEnum.Authorization, authorizationHeader);

        this.resetSessionActiveStartTime();
    }

    invalidateCurrentUser(): void {
        this.headerService.delete(HeaderKeyEnum.Authorization);
        this.headerService.delete(HeaderKeyEnum.Impersonate);
        this.storageService.clear();

        localStorage.removeItem('logged-In-User');
    }

    get sessionTimedOut() {
        return localStorage.getItem('SessionTimedOut');
    }

    set sessionTimedOut(value: any) {
        localStorage.setItem('SessionTimedOut', value);
    }

    resetSessionActiveStartTime() {
        const newDate: Date = new Date(new Date().getTime() + this.timeoutInMinutes * 60000);
        this.storageService.set(StorageKeyEnum.SessionActiveUntil, newDate.toString());
        this.sessionTimedOut = false;
    }
};
