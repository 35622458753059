export default function routes($routeProvider: ng.route.IRouteProvider) {
    $routeProvider
        .when('/requestType-MonthlyDraw/', {
            template: require('./requestTypesMonthlyDraw.html'),
            controller: 'RequestTypesMonthlyDrawController',
            controllerAs: 'vm'
        });
}

let requestTypesMonthlyDrawState: ng.ui.IState = {
    name: 'requestType-MonthlyDraw',
    url: '/requestType-MonthlyDraw/',
    views: {
        '': {
            template: require('./requestTypesMonthlyDraw.html'),
            controller: 'RequestTypesMonthlyDrawController',
            controllerAs: 'vm'
        },
        'monthly-draw-tab@requestType-MonthlyDraw': {
            template: require('../monthlyDraw/monthlyDrawRequests.html'),
            controller: 'MonthlyDrawPendingRequestController',
            controllerAs: 'vm'
        }
    }
};

angular.module('approval-tool').config(($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state(requestTypesMonthlyDrawState);
});
