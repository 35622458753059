import { IUserService } from '../../../../core/services/user.service.interface';
import { IUser } from '../../../../core/models/user.model.interface';

import { BalanceWriteOffRequest } from '../balanceWriteOffRequest.model';
import { BalanceWriteOffRequestStatus } from '../core/balanceWriteOffRequestStatus.model';
import { BwoDetail } from './bwoDetail.model';

import BalanceWriteOffDetailService from './balanceWriteOffDetail.service';
import BalanceWriteOffRequestsService from '../balanceWriteOffRequests.service';
import SupportBalanceWriteOffManualImplementRequestsService from '../supportBalanceWriteOffManualImplementRequests.service';
import SapErrorMessageDialogController from '../sapErrorMessageDialog/sapErrorMessageDialog.controller';

export default class BalanceWriteOffDetailController {
    request: BalanceWriteOffRequest;
    user: IUser;
    isLoading: boolean;
    detail: BwoDetail;
    isDeferredPaymentFullBalanceWriteOff: boolean;
    allowComment: boolean = false;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private requestLocal: BalanceWriteOffRequest,
        private userService: IUserService,
        private balanceWriteOffDetailService: BalanceWriteOffDetailService,
        private balanceWriteOffRequestsService: BalanceWriteOffRequestsService,
        private supportBalanceWriteOffManualImplementRequestsService: SupportBalanceWriteOffManualImplementRequestsService) {
        
    }

    $onInit(): void {
        this.request = this.requestLocal;
        this.user = this.userService.get();
        this.allowComment = this.user.canCommentBalanceWriteOff;
        this.loadDetails();
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    viewSaveError($event: MouseEvent) {
        this.$mdDialog.show({
            template: require('../sapErrorMessageDialog/sapErrorMessageDialog.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: SapErrorMessageDialogController,
            controllerAs: 'vme',
            bindToController: true
        });
    }

    confirmSuccess(response, ev) {
        if (!response.hasNoSapCallError) {
            this.viewSaveError(ev);
        }
    }

    approve($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceWriteOffRequestsService.approve(this.request)
            .then((response) => {
                this.confirmSuccess(response.data, $event);
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    deny($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceWriteOffRequestsService.deny(this.request)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    withdraw($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.balanceWriteOffRequestsService.withdraw(this.request)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    manuallyImplement($event: MouseEvent): void {
        $event.stopPropagation();
        this.request.processing = true;

        this.supportBalanceWriteOffManualImplementRequestsService.manuallyImplement(this.request)
            .then(() => {
                this.cancel();
            })
            .finally(() => {
                this.request.processing = false;
            });
    }

    canApprove(): boolean {
        return this.user.canApproveDenyBalanceWriteOff && this.request.canApprove && !this.request.processing;
    }

    canDeny(): boolean {
        return this.user.canApproveDenyBalanceWriteOff && this.request.canDeny && !this.request.processing;
    }

    canWithdraw(): boolean {
        return this.request.canWithdraw && !this.request.processing;
    }

    canManuallyImplement(): boolean {
        return this.user.canManuallyImplementBalanceWriteOff && this.request.canManuallyImplement && !this.request.processing;
    }

    deferredPaymentFullBalanceWriteOff() {
        this.isDeferredPaymentFullBalanceWriteOff = ((this.request.bwoTypeId === 2 || this.request.bwoTypeId === 3) && this.request.isFullBalanceWriteOff);
    }

    private loadDetails(): void {
        this.isLoading = true;
        this.getDetail().finally(() => {
            this.isLoading = false;
            this.deferredPaymentFullBalanceWriteOff();
        });
    }

    private getDetail() {

        return this.balanceWriteOffDetailService.getDetail(this.request.requestId, this.request.invoice.id, this.request.shareId, this.request.year)
            .then((response: ng.IHttpPromiseCallbackArg<any>) => {
                this.detail = response.data;

                this.updateRowStatus(this.detail.status);
            });
    }

    private updateRowStatus(requestStatus: BalanceWriteOffRequestStatus): void {
        this.request.statusId = requestStatus.statusId;
        this.request.status = requestStatus.status;
        this.request.canApprove = requestStatus.canApprove;
        this.request.canDeny = requestStatus.canDeny;
        this.request.canManuallyImplement = requestStatus.canManuallyImplement;
        this.request.canWithdraw = requestStatus.canWithdraw;
    }
}