export class RequestData  {

    constructor(
        public invoiceId: number,
        public shareId: number,
        public shareName: string,
        public competitivePriceAdjustmentId: number,
        public factor: number,
        public reasonId: number,
        public comment: string,
        public operationPriorYearGrossInvoiceValue: number,
        public operationGrossInvoiceValue: number,
        public operationProfiledAcres: number,
        public operationInvoiceAcres: number,
        public shareGrossInvoiceValue: number,
        public shareElectiveDiscountValue: number,
        public value: number,
        public territoryId: string,
        public operationId: number,
        public operationName: string,
        public salesAgencyId: number,
        public salesAgencyName: string,
        public accountDescriptionName: string,
        public businessPartnerId: number,
        public cpaName: string,
        public year: number,
        public seasonId: string,
        public requestedDate: Date,
        public productLineId: string,
        public calculationId: number,
        public reason: string,
        public impactsBudget?: boolean) { }
}