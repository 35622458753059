import PricingRequestDetailUpdate from './detail/pricingRequestDetailUpdate';

export default class PricingRequestsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getPendingList(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/pending-requests`);
    }

    approve(requestId: number, isTreatment: boolean): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/pricing/requests/${requestId}/treated/${isTreatment}/approve`, 1);
    }

    approveRequestUpdated(requestId: number, data: PricingRequestDetailUpdate): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.post(`{API-ApprovalTool}/pricing/requests/${requestId}/approve-request-updated`, data);
    }

    deny(requestId: number, comment: string): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        const data: string = `"${comment}"`;
        return this.$http.post(`{API-ApprovalTool}/pricing/requests/${requestId}/deny`, data);
    }

    getAllRequestsBySalesPeriod(salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/salesperiods/${salesPeriodId}/all-requests`);
    }

    getAllPricingFiltersSalesperiods(): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/all-requests/filters/salesperiods`);
    }

    getAllPricingFilters(salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<any>> {
        return this.$http.get(`{API-ApprovalTool}/pricing/salesperiods/${salesPeriodId}/all-requests/filters`);
    }
}
