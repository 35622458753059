import ApproverSearchBarController from './approverSearchBar.controller';

export default class ApproverSearchBarComponent implements ng.IComponentOptions {
    template: string = require('./approverSearchBar.html');
    controller: Function = ApproverSearchBarController;
    controllerAs: 'cvm';
    bindings: { [binding: string]: string } = {
        options: '<',
        onSearch: '&'
    };

    static factory() {
        const component: () => ApproverSearchBarComponent = () => {
            return new ApproverSearchBarComponent();
        };

        return component();
    }
}