import './navigation.scss';

import NavigationComponentController from './navigation.controller';

export default class NavigationComponent implements ng.IComponentOptions {
    template: string = require('./navigation.html');
    controller: Function = NavigationComponentController;
    bindings: { [binding: string]: string } = {
        page: '@'
    };

    static factory() {
        const component = () => {
            return new NavigationComponent();
        };

        return component();
    }
}