import {ProductCharges} from './productCharges.model';
import {Reason} from './reason.model';

export default class UnitSettlementChargesServiceV2 {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    // TODO - call Invoices API directly (update API interceptor first)
    getUnitSettlementCharges(salesAgencyId: number, salesPeriodId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<ProductCharges>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/unit-settlement-charges/agencies/${salesAgencyId}/salesperiods/${salesPeriodId}`);
    }

    getUnitSettlementReasonsBySalesAgency(salesPeriodId: number, salesAgencyId: number): ng.IPromise<ng.IHttpPromiseCallbackArg<Array<Reason>>> {
        return this.$http.get(`{API-ApprovalTool}/requests/agency-balance-exception/waive-unit-settlement-charges/salesperiods/${salesPeriodId}/sales-agencies/${salesAgencyId}/reasons`);
    }
}