export function httpResponseCodeOverrideInterceptor($q: ng.IQService) {

    const interceptor: ng.IHttpInterceptor = {

        // This interceptor exists because HTTP Response Codes of 403 and 500 are getting
        // mapped to a 404 by a security device in front of our servers. This class preserves
        // the original status code in the form of an HTTP header so that it can be
        // reconstituted by the caller.
        responseError(rejection: any) {

            if (rejection.headers) {
                const override: number = rejection.headers('x-http-response-statuscode-override');

                if (override && !isNaN(override)) {
                    rejection.status = Number(override);
                }
            }
            return $q.reject(rejection);
        }
    };

    return interceptor;

}