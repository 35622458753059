import { BalanceWriteOffRequest } from './../../balanceWriteOff/balanceWriteOffRequest.model';
import { BalanceWriteOffRequestStatus } from '../../balanceWriteOff/core/balanceWriteOffRequestStatus.model';

import BalanceWriteOffDetailController from '../../balanceWriteOff/detail/balanceWriteOffDetail.controller';
import SupportSearchBalanceWriteOffService from './supportSearchBalanceWriteOff.service';

import {IActiveElementHTMLElement} from '../../../../core/interfaces/activeElementHTMLElement.interface';

export default class SupportSearchBalanceWriteOffController  {

    maximumRowsToDisplay: number = 200;

    searching: boolean = false;
    criteria: string = '';
    requests: Array<BalanceWriteOffRequest>;
    totalRequests: number = 0;
    shownRequests: number = 0;

    private concurrentDetailsCalls: number = 3;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private $document: ng.IDocumentService,
        private supportSearchBalanceWriteOffService: SupportSearchBalanceWriteOffService
    ) {
        // no-op
    }

    onSearch(): void {
        if (!this.searching && this.criteria.replace(/ /g, '') !== '') {
            this.searching = true;

            (<IActiveElementHTMLElement> this.$document[0]).activeElement.blur();

            this.supportSearchBalanceWriteOffService.getSearchResults(this.criteria.replace('\\', ''))
                .then((response) => {
                    this.processResponse(response.data);
                })
                .catch(() => {
                    this.requests = [];
                })
                .finally(() => {
                    this.searching = false;
                });
        }
    }

    hasResults(): boolean {
        return !this.searching && this.requests && this.requests.length > 0;
    }

    noResults(): boolean {
        return !this.searching && this.requests && this.requests.length === 0;
    }

    viewDetails($event: MouseEvent, request: BalanceWriteOffRequest): void {
        this.$mdDialog.show({
            template: require('../../balanceWriteOff/detail/balanceWriteOffDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: BalanceWriteOffDetailController,
            controllerAs: 'vm',
            locals: {
                requestLocal: request
            },
            fullscreen: true
        }).finally(() => {
            this.refreshRequest(request);
        });
    }

    private refreshRequest(request: BalanceWriteOffRequest): void {
        this.supportSearchBalanceWriteOffService
            .getRequestStatus(request.requestId)
            .then(response => this.updateRowStatus(request, response.data));
    }

    private updateRowStatus(request: BalanceWriteOffRequest, requestStatus: BalanceWriteOffRequestStatus): void {
        request.statusId = requestStatus.statusId;
        request.status = requestStatus.status;
        request.canApprove = requestStatus.canApprove;
        request.canDeny = requestStatus.canDeny;
        request.canManuallyImplement = requestStatus.canManuallyImplement;
        request.canWithdraw = requestStatus.canWithdraw;
    }

    private processResponse(data: any): void {
        this.requests = data.requestList;

        this.totalRequests = data.totalRequests;
        this.shownRequests = this.totalRequests > this.maximumRowsToDisplay ? this.maximumRowsToDisplay : this.totalRequests;
    }
}