import {IHeaderService} from './header.service.interface';
import {HeaderKeyEnum} from './headerKey.enum';

export class HeaderService implements IHeaderService {

    constructor(private $http: ng.IHttpService, private $q: ng.IQService) {
        // noop
    }

    get(key: HeaderKeyEnum): any {
        return this.$http.defaults.headers.common[key.toString()];
    }
    set(key: HeaderKeyEnum, value: any): ng.IPromise<any> {
        return this.$q.when(this.$http.defaults.headers.common[key.toString()] = value);
    }
    delete(key: HeaderKeyEnum): ng.IPromise<any> {
        return this.$q.when(delete this.$http.defaults.headers.common[key.toString()]);
    }

    exists(key: HeaderKeyEnum): boolean {
        return this.$http.defaults.headers.common[key.toString()];
    }
}
