export default class NumberInputDirective implements ng.IDirective {

    restrict: string = 'A';
    require: string = '?ngModel';
    filter: ng.IFilterService;

    static $inject: string[] = ['$filter'];
    constructor(private $filter: ng.IFilterService) {
        this.filter = $filter;
    }

    link: (scope: angular.IScope,
           element: angular.IAugmentedJQuery,
           attrs: angular.IAttributes,
           ctrl: angular.INgModelController) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.INgModelController) => {
            if (!ctrl) {
                return;
            }

            const numberFilter: angular.IFilterCurrency = this.$filter('number');

            function unmaskValue(val: number) {
                return val;
            }

            function maskValue(val: number) {
                return numberFilter(val);
            }

            ctrl.$parsers.push(unmaskValue);
            ctrl.$formatters.push(maskValue);

            element.bind('blur', () => {
                element.val(maskValue(ctrl.$modelValue));
            });
            element.bind('focus', () => {
                element.val(ctrl.$modelValue);
            });
        };

    static factory(): ng.IDirectiveFactory {
        const directive = ($filter: ng.IFilterService) => new NumberInputDirective($filter);
        directive.$inject = ['$filter'];
        return directive;
    }

}