export class RequestCount {
    constructor(
        public agencyBalanceExceptionRequestCount: number,
        public balanceWriteOffRequestCount: number,
        public commissionAdvanceSeedRequestCount: number,
        public commissionAdvanceServiceRequestCount: number,
        public cpaRequestCount: number,
        public monthlyDrawRequestCount: number,
        public replantRequestCount: number,
        public pricingRequestCount: number,
        public bppRequestCount: number,
        public sampleRequestCount: number,
        public proposalCpaRequestCount: number,
        public packageOverrideRequestCount: number
    ) { }
}