import { IUserService } from '../../../core/services/user.service.interface';
import LoanTypeChangeRequestsService from './loanTypeChangeRequests.service';
import { LoanTypeChangeRequest } from './loanTypeChangeRequest.model';
import { IUser } from '../../../core/models/user.model.interface';
import LoanTypeChangeRequestDetailController from './detail/loanTypeChangeRequestDetail.controller';
import LoanTypeChangeBalanceDetailmodel = require('./request/models/loanTypeChangeBalanceDetail.model');
import LoanTypeChangeBalanceDetail = LoanTypeChangeBalanceDetailmodel.LoanTypeChangeBalanceDetail;
import { AllLoanTypeChangeRequestsFiltersCriteria } from
    './request/models/allLoanTypeChangeRequestsFiltersCriteria.model';
import { AllLoanTypeChangeRequestsFiltersOptions } from './request/models/allLoanTypeChangeRequestsFiltersOptions.model';
import { LoanTypeChangeSalesPeriodFilter } from './request/models/loanTypeChangeSalesPeriodFilter.model';

export default class AllLoanTypeChangeRequestsController {
    filterCriteria: AllLoanTypeChangeRequestsFiltersCriteria = {
        salesPeriod: undefined,
        territory: undefined,
        status: undefined,
        salesAgency: undefined,
        operation: undefined,
        businessPartner: undefined,
        newLoanType: undefined
    };

    filterOptions: AllLoanTypeChangeRequestsFiltersOptions = {
        salesPeriods: [],
        territories: [],
        statuses: [],
        salesAgencies: [],
        operations: [],
        businessPartners: [],
        newLoanTypes: []
    };

    navigationText: string;
    requests: LoanTypeChangeRequest[];
    allRequests: LoanTypeChangeRequest[];
    filteredResult: LoanTypeChangeRequest[];
    loading: boolean = true;
    salesPeriods: LoanTypeChangeSalesPeriodFilter[];
    canClearFilters: boolean = false;

    territoriesSearchTerm: string = '';
    operationsSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';
    businessPartnersSearchTerm: string = '';

    paginationOptions: any = {
        limit: 10,
        limitOptions: [10, 25, 50],
        page: 1
    };

    constructor(
        protected $location: ng.ILocationService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected loanTypeChangeRequestsService: LoanTypeChangeRequestsService,
        protected $mdDialog: ng.material.IDialogService,
        protected $timeout: ng.ITimeoutService,
        protected $document: ng.IDocumentService,
        protected $q: ng.IQService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.requests = [];
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    viewDetails($event: MouseEvent, request: LoanTypeChangeRequest): void {
        this.$mdDialog.show({
            template: require('./detail/loanTypeChangeRequestDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: LoanTypeChangeRequestDetailController,
            controllerAs: 'vm',
            locals: {
                request: request
            },
            fullscreen: true
        })
            .finally(() => this.getAllRequests(true));
    }

    showCurrentRateNotSet(request: LoanTypeChangeRequest): boolean {
        return request.currentLoanType.loanTypeDescription === 'NA';
    }

    private activate() {
        this.setHandlers();
        this.user = this.userService.get();
        this.getAllRequests();
        this.navigationText = 'Loan Type Change Requests';
    }

    private getAllRequests(isRefresh: boolean = false): void {
        this.loading = true;

        if (isRefresh) {
            this.refreshResults();
        }
        else {
            this.getAllLoanTypeChangeFiltersSalesperiods();
        }
    }

    private getAllLoanTypeChangeFiltersSalesperiods(): void {
        this.salesPeriods = [];
        this.filterCriteria.salesPeriod = undefined;
        this.loanTypeChangeRequestsService.getAllLoanTypeChangeFiltersSalesperiods()
            .then((response) => {
                this.salesPeriods = response.data;
                this.setCurrentSalesPeriod();
                this.getAllRequestListBySalesPeriod();
            });
    }

    private setCurrentSalesPeriod(): void {
        this.filterCriteria.salesPeriod = this.salesPeriods.filter(salesPeriod => salesPeriod.isCurrent).shift();
    }

    private getAllRequestListBySalesPeriod(isRefresh: boolean = false): void {
        if (this.filterCriteria.salesPeriod !== undefined) {
            this.loading = true;
            this.loanTypeChangeRequestsService
                .getAllRequestListBySalesPeriod(this.filterCriteria.salesPeriod.salesPeriodId)
                .then((data) => {
                    if (isRefresh) {
                        this.processRefreshResponse(data);
                    }
                    else {
                        this.processRequestsResponse(data);
                    }
                })
                .finally(() => {
                    this.done();
                });
        }
    }

    private processRequestsResponse(response: any): void {
        if (response && response.data) {
            this.requests = response.data;
            this.allRequests = response.data;

            this.filterOptions.territories = _.uniq(_.map(this.allRequests, x => x.territoryId));
            this.filterOptions.statuses = _.uniq(_.map(this.allRequests, x => x.status.name));
            this.filterOptions.salesAgencies = _.uniq(_.map(this.allRequests, x => x.salesAgency), 'id');
            this.filterOptions.operations = _.uniq(_.map(this.allRequests, x => x.operation), 'id');
            this.filterOptions.businessPartners = _.uniq(_.map(this.allRequests, x => x.businessPartner), 'id');
            this.filterOptions.newLoanTypes = _.uniq(_.map(this.allRequests, x => x.newLoanType), 'loanTypeName');
        }
    }

    private getBalance(request: LoanTypeChangeRequest): LoanTypeChangeBalanceDetail {
        return new LoanTypeChangeBalanceDetail(request.balances.netInvoiceAmount,
            request.balances.receipts.cropProtection,
            request.balances.receipts.encirca,
            request.balances.receipts.sellerService);
    }

    private processRefreshResponse(response: any): void {
        if (response && response.data) {
            this.allRequests = response.data;
            this.requests = this.filterListBySelectedOptions('');
        }
    }

    refreshResults() {
        this.getAllRequestListBySalesPeriod(true);
    }

    private done(): void {
        this.loading = false;
    }

    private setHandlers(): void {
        const selectors: string[] = [
            'territories',
            'agencies',
            'operations',
            'businessPartners'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    noFilteredResults(length: number, canSelect: boolean): boolean {
        return canSelect && length === 0;
    }

    canSelectTerritories(): boolean {
        return !_.isEmpty(this.filterOptions.territories) && !_.isUndefined(this.filterCriteria.salesPeriod);
    }

    canSelectSalesAgencies(): boolean {
        return !_.isEmpty(this.filterOptions.salesAgencies) && !_.isUndefined(this.filterCriteria.salesPeriod);
    }

    canSelectOperations(): boolean {
        return !_.isEmpty(this.filterOptions.operations) && !_.isUndefined(this.filterCriteria.salesPeriod);
    }

    canSelectBusinessPartners(): boolean {
        return !_.isEmpty(this.filterOptions.businessPartners) && !_.isUndefined(this.filterCriteria.salesPeriod);
    }

    onSalesYearSelect(): void {
        this.clearFilters();
        this.getAllRequestListBySalesPeriod();
    }

    onTerritoriesSelect(): void {
        this.territoriesSearchTerm = '';
        this.requests = this.filterListBySelectedOptions('');
    }

    onStatusSelect(): void {
        this.requests = this.filterListBySelectedOptions('');
    }

    onSalesAgencySelect(): void {
        this.salesAgenciesSearchTerm = '';
        this.requests = this.filterListBySelectedOptions('');
    }

    onOperationSelect(): void {
        this.operationsSearchTerm = '';
        this.requests = this.filterListBySelectedOptions('');
    }

    onBusinessPartnerSelect(): void {
        this.businessPartnersSearchTerm = '';
        this.requests = this.filterListBySelectedOptions('');
    }

    onNewLoanTypeSelect(): void {
        this.requests = this.filterListBySelectedOptions('');
    }

    private filterListBySelectedOptions(searchText: string): LoanTypeChangeRequest[] {
        this.canClearFilters = true;
        this.filteredResult = this.allRequests;
        if (this.filterCriteria.territory !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.territoryId === this.filterCriteria.territory);
        }

        if (this.filterCriteria.status !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.status.name === this.filterCriteria.status);
        }

        if (this.filterCriteria.salesAgency !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.salesAgency.id === this.filterCriteria.salesAgency.id);
        }

        if (this.filterCriteria.operation !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.operation.id === this.filterCriteria.operation.id);
        }

        if (this.filterCriteria.businessPartner !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.businessPartner.id === this.filterCriteria.businessPartner.id);
        }

        if (this.filterCriteria.newLoanType !== undefined) {
            this.filteredResult = this.filteredResult.filter(x => x.newLoanType.loanTypeName === this.filterCriteria.newLoanType.loanTypeName);
        }

        return this.filteredResult;
    }

    clearAllFilters(): void {
        this.clearFilters();
        this.getAllLoanTypeChangeFiltersSalesperiods();
    }

    private clearFilters(): void {
        this.filterCriteria.territory = undefined;
        this.filterCriteria.status = undefined;
        this.filterCriteria.salesAgency = undefined;
        this.filterCriteria.operation = undefined;
        this.filterCriteria.businessPartner = undefined;
        this.filterCriteria.newLoanType = undefined;
        this.canClearFilters = false;
    }
}