export default class PricingCommentDialogController {

    commentText: string = '';
    commentForm: ng.IFormController;

    constructor(
        private title: string,
        private cancelText: string,
        private confirmText: string,
        private maxLength: number,
        private requireComment: boolean,
        private $mdDialog: ng.material.IDialogService) {

    }

    confirm(): void {
        this.$mdDialog.hide(this.commentText);
    }

    abort(): void {
        this.$mdDialog.cancel();
    }

    disableSubmit(): boolean {
        return this.commentForm && this.commentForm.$invalid;
    }

    showValidationMessages(): boolean {
        return this.commentForm && (this.commentForm.$dirty && this.commentForm.$invalid);
    }
}