import {ISampleComment} from './sampleComment.model.interface';
import {ISampleCommentsService} from './sampleComments.service.interface';

export default class SampleCommentsService implements ISampleCommentsService {

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    saveComment(comment: ISampleComment): ng.IPromise<any> {
        const uri: string = `{API-ApprovalTool}/requests/sample/comments/save`;

        return this.$http.post(uri, comment);
    }
}