import BalanceExceptionRequestsServiceV2 from './balanceExceptionRequests.service';
import {IUser} from '../../../core/models/user.model.interface';
import {IUserService} from '../../../core/services/user.service.interface';
import { RequestOptionsMaterial } from './core/RequestOptionsMaterial.model';
import BalanceExceptionRequestMaterialCriteria from './core/balanceExceptionRequestMaterial.criteria.model';
import {CreateBalanceExceptionRequestFormController} from './core/createBalanceExceptionRequestForm.interface';

import {IOrgIdAndName} from '../../../core/models/orgIdAndName.model.interface';
import { AgencyBalanceExceptionTypeMaterial} from './core/agencyBalanceExceptionTypeMaterial.model';
import {SalesAgency} from './core/salesAgency.model';

export default class CreateBalanceExceptionRequestControllerV2  {

    navigationText: string = 'Request agency balance exception';

    loading: any = {
        options: false,
        salesSeasons: false,
        agencies: false,
        territories: false,
        exceptionTypes: false
    };

    options: RequestOptionsMaterial = { salesSeasons: null, agencies: null, exceptionTypes: null, territories: null };

    criteria: BalanceExceptionRequestMaterialCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        agencyBalanceExceptionType: undefined,
        amount: undefined,
        comments: undefined,
        unitSettlementChargesProduct: undefined,
        unitSettlementChargesReason: undefined,
        canSearchAllMaterials: false,
        selectedProduct: '',
        selectedSubproduct: '',
        units: 1,
        perUnit: 1
    };

    temp: BalanceExceptionRequestMaterialCriteria = {
        salesSeason: undefined,
        territory: undefined,
        salesAgency: undefined,
        agencyBalanceExceptionType: undefined,
        amount: undefined,
        comments: undefined,
        unitSettlementChargesProduct: undefined,
        unitSettlementChargesReason: undefined,
        canSearchAllMaterials: false,
        selectedProduct: '',
        selectedSubproduct: '',
        units: 1,
        perUnit: 1
    };

    // Search terms
    territoriesSearchTerm: string = '';
    salesAgenciesSearchTerm: string = '';

    hideToolbar: boolean = false;
    canShowTable: () => boolean = (() => true);
    selectedView: string = 'create';
    canRequest: boolean = true;

    requestForm: CreateBalanceExceptionRequestFormController;

    materials: any;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $location: ng.ILocationService,
        protected $document: ng.IDocumentService,
        protected $mdDialog: ng.material.IDialogService,
        protected balanceExceptionRequestsServiceV2: BalanceExceptionRequestsServiceV2,
        protected userService: IUserService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected $mdToast: ng.material.IToastService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    private activate() {
        this.setHandlers();

        this.user = this.userService.get();
        this.loading.options = true;
        this.balanceExceptionRequestsServiceV2.getOptions()
            .then(createData => {
                this.options = createData.data;
                this.criteria.salesSeason = this.options.salesSeasons.filter(salesSeason => salesSeason.isCurrent).shift();

                this.$timeout(() => {
                    this.setTerritory();
                    this.setSalesAgency();
                });
            })
            .finally(() => this.loading.options = false);
    }

    private setHandlers(): void {
        const selectors: string[] = [
            'territories',
            'agencies',
            'exceptionTypes'
        ];

        selectors.forEach((selector) => this.setKeyHandler(`#${selector} input`));
    }

    private setKeyHandler(selector: string): void {
        this.$timeout(() => {
            const input: Element = this.$document[0].querySelector(selector);

            if (input) {
                input.addEventListener('keydown', (ev: Event) => ev.stopPropagation());
            }
        }, 10);
    }

    onMenuOpen(property: string): void {
        const current: string = _.get(this.criteria, property, undefined);
        _.set(this.temp, property, current);
    }

    // Select callbacks
    onSalesSeasonsSelect(): void {
        if (this.hasChanged(this.temp.salesSeason, this.criteria.salesSeason)) {
            this.clearSalesAgencies();
            this.clearExceptionTypes();

                if (angular.isDefined(this.criteria.territory)) {
                    this.getSalesAgencies();
                }
        }
    }

    onTerritoriesSelect(): void {
        this.territoriesSearchTerm = '';

        if (this.hasChanged(this.temp.territory, this.criteria.territory)) {
            this.clearSalesAgencies();
            this.clearExceptionTypes();
            // TODO clear activity list, summary

            this.getSalesAgencies();
        }
    }

    onSalesAgenciesSelect(): void {
        this.salesAgenciesSearchTerm = '';

        if (this.hasChanged(this.temp.salesAgency, this.criteria.salesAgency)) {
            // to do clear activity list, summary
            // TODO get activity, summary
            this.getExceptionTypes();
        }
    }

    getMaterials(): any {
        if (this.criteria.canSearchAllMaterials) {
            this.balanceExceptionRequestsServiceV2.getAllMaterial(this.criteria.agencyBalanceExceptionType.productLineId)
                .then(createData => {
                    this.materials = createData.data;
                });
        }
        else {
            this.balanceExceptionRequestsServiceV2.getMaterial(this.criteria.salesSeason.salesPeriodId, this.criteria.territory.orgId, this.criteria.agencyBalanceExceptionType.productLineId)
                .then(createData => {
                    this.materials = createData.data;
                });
        }
    }

    // get methods
    private getTerritories(): ng.IPromise<any> {
        this.loading.territories = true;

        return this.balanceExceptionRequestsServiceV2.getCurrentTerritories()
            .then((response) => {
                this.processTerritoriesResponse(response.data);
                this.setTerritory();
            })
            .finally(() => this.loading.territories = false);
    }

    private getSalesAgencies(): ng.IPromise<any> {
        this.loading.agencies = true;

        return this.balanceExceptionRequestsServiceV2.getSalesAgenciesWithInactive(this.criteria.salesSeason.salesPeriodId,
                this.criteria.territory.orgId)
            .then((response) => {
                this.processSalesAgenciesWithInactiveResponse(response.data);
                this.setSalesAgency();
            })
            .finally(() => this.loading.agencies = false);
    }

    private getExceptionTypes(): ng.IPromise<any> {
        this.loading.exceptionTypes = true;

        return this.balanceExceptionRequestsServiceV2.getAgencyExceptionTypes(this.criteria.salesSeason.salesPeriodId, this.criteria.salesAgency.id)
            .then((response) => {
                this.processExceptionTypesResponse(response.data);
                this.setExceptionType();
            })
            .finally(() => this.loading.exceptionTypes = false);
    }

    // reset criteria
    private clearTerritories(): void {
        this.resetElement(this.requestForm.territory);

        this.options.territories = [];
        this.criteria.territory = undefined;
        this.temp.territory = undefined;
    }

    private clearSalesAgencies(): void {
        this.resetElement(this.requestForm.salesAgency);

        this.options.agencies = [];
        this.criteria.salesAgency = undefined;
        this.temp.salesAgency = undefined;
        // to do clear activity list, summary
    }

    private clearExceptionTypes(): void {
        this.resetElement(this.requestForm.agencyBalanceExceptionType);
        this.options.exceptionTypes = [];
        this.criteria.agencyBalanceExceptionType = undefined;
        this.temp.agencyBalanceExceptionType = undefined;
    }

    private clearAmount(): void {
        this.resetElement(this.requestForm.agencyBalanceExceptionType);

        this.temp.perUnit     = 1;
        this.temp.units       = 1;
        this.temp.amount      = 1;
        this.criteria.perUnit = 1;
        this.criteria.units   = 1;
        this.criteria.amount  = 1;
    }

    private clearComment(): void {
        this.resetElement(this.requestForm.comment);

        this.criteria.comments = undefined;
    }

    clearMaterial(): void {
        this.criteria.canSearchAllMaterials = false;
        this.temp.canSearchAllMaterials = false;
        this.temp.selectedSubproduct = '';
        this.criteria.selectedSubproduct = '';
        this.temp.selectedProduct = '';
        this.criteria.selectedProduct = '';
        this.clearAmount();
    }

    // Field enable disable
    canSelectTerritories(): boolean {
        return !_.isEmpty(this.options.territories) && !_.isUndefined(this.criteria.salesSeason);
    }

    canSelectSalesAgencies(): boolean {
        return !_.isEmpty(this.options.agencies) && !_.isUndefined(this.criteria.territory);
    }


    private resetForm(): void {
        this.criteria.territory = undefined;

        this.clearSalesAgencies();

        this.clearExceptionTypes();

        this.clearAmount();

        this.clearComment();

        this.clearMaterial();

        this.setTerritory();
        this.setSalesAgency();

        this.requestForm.$setPristine();
        this.requestForm.$setUntouched();
    }

    private resetElement(formElement: ng.INgModelController): void {
        if (formElement) {
            formElement.$setViewValue(undefined);
            formElement.$rollbackViewValue();

            formElement.$setPristine();
            formElement.$setUntouched();
        }
    }

    private isValid(formElement: ng.INgModelController): boolean {
        return formElement && formElement.$valid;
    }

    canSubmit(): boolean {
        return this.requestForm && this.requestForm.$valid && !this.loading.submit && !(this.requestForm.unitSettlementChargeReason && this.requestForm.unitSettlementChargeReason.$modelValue === undefined)
    }

    submit(): void {
        this.loading.submit = true;
        this.balanceExceptionRequestsServiceV2.createV2(this.criteria)
            .then(() => {
                this.loading.submit = false;
                this.$mdToast.show(
                    this.$mdToast.simple().textContent('Agency balance exception saved')
                    .position('bottom center'));
                this.resetForm();
            });
    }

    isProcessing(): boolean {
        return this.loading.options || this.loading.submit;
    }

    // process methods
    private processTerritoriesResponse(territories: Array<IOrgIdAndName>): void {
        this.options.territories = territories;
    }

    private processSalesAgenciesWithInactiveResponse(agencies: SalesAgency[]): void {
        this.options.agencies = agencies;
    }

    private processExceptionTypesResponse(exceptionTypes: Array<AgencyBalanceExceptionTypeMaterial>): void {
        this.options.exceptionTypes = exceptionTypes;
    }

    // set
    private setTerritory(): void {
        if (this.isSingular(this.options.territories)) {
            this.criteria.territory = this.options.territories[0];
            this.getSalesAgencies();
        }
    }

    private setSalesAgency(): void {
        if (this.isSingular(this.options.agencies)) {
            this.criteria.salesAgency = this.options.agencies[0];
            this.getExceptionTypes();
            // TODO: get clear activity list, summary
        }
    }

    private setExceptionType(): void {
        if (this.isSingular(this.options.exceptionTypes)) {
            this.criteria.agencyBalanceExceptionType = this.options.exceptionTypes[0];
        }
    }

    // helpers
    private hasChanged(current: any, selected: any): boolean {
        return !_.isEqual(current, selected);
    }

    noFilteredResults(length: number, canSelect: boolean): boolean {
        return canSelect && length === 0;
    }

    private isSingular(values: any[]): boolean {
        return _.isArray(values) && _.size(values) === 1;
    }
}