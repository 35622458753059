import './donutChart.css'
import { Chart } from 'chart.js'

export default class DonutChartDirective implements ng.IDirective {
    template: string = require('./donutChart.directive.html');
    scope: Object= {
        data: '=',
        labels: '=',
        colors: '=',
        customLegend: '='
    };

    constructor() { }

    link: (scope: angular.IScope, element: angular.IAugmentedJQuery) => void = (scope: ng.IScope, element: ng.IAugmentedJQuery) => {
        var chart;
        scope.$watch(function (scope) {
            return {data: scope.data, customLegend: scope.customLegend}}, function (newData) {
            if (newData) {
                var chartOptions = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: scope.data,
                            backgroundColor: scope.colors
                        }],
                        labels: scope.labels,
                    },
                    options: {
                        responsive: true,
                        rotation: -30,
                        legend: {
                            onClick: (e) => e.stopPropagation(),
                            position: 'left',
                            labels: {
                                filter: (legendItem, data) => data.datasets[0].data[legendItem.index] != 0,
                            },
                        },
                        title: {
                            display: scope.customLegend ? true : false,
                            text: scope.customLegend,
                        }
                    },
                    plugins: [
                        {
                            afterLayout: function (chart) {
                                chart.legend.legendItems.forEach(
                                    (label) => {
                                        let value = chart.data.datasets[0].data[label.index];
                                        label.text += ' (' + value + '%)';

                                        const originalFit = chart.legend.fit;
                                        chart.legend.fit = function fit() {
                                            originalFit.bind(chart.legend)();
                                            this.width = 200 - label.text.length;
                                        }

                                        return label;
                                    }
                                )
                            },
                        },
                    ]
                };

                if (chart) {
                    chart.destroy();
                }

                var canvasElement = element.find('canvas')[0];
                if (canvasElement) {
                    chart = new Chart(canvasElement, chartOptions);
                }
            }
        }, true);
    }

    static factory(): ng.IDirectiveFactory {
        const component = () => {
            return new DonutChartDirective();
        };

        return component;
    }
}