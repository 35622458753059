export default class RequestTypesEncircaCommissionController {

    navigationText: string;

    showCommissionAdvanceTab: boolean = false;
    showMonthlyDrawTab: boolean = false;

    constructor(
        private $location: ng.ILocationService,
        private $mdSidenav: ng.material.ISidenavService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    private activate() {
       if (this.$location.path().indexOf('all') > -1) {
            this.navigationText = 'All pending requests';
       } else {
            this.navigationText = 'My pending requests';
       }
    }

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }
}