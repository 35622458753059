import { EncircaCommissionAdvanceRequest } from './encircaCommissionAdvanceRequest';
import { IEncircaCommissionAdvanceRequestDetails } from './encircaCommissionAdvanceRequestDetails.interface';

export class EncircaCommissionAdvanceRequestRow {
    request: EncircaCommissionAdvanceRequest;
    details: IEncircaCommissionAdvanceRequestDetails;
    isLoadingDetails: boolean;
    isPending: number = 3;
    isApproved: number = 5;
    isDenied: number = 6;

    constructor(request: EncircaCommissionAdvanceRequest) {
        this.request = request;
        this.request.isApproved = request.statusId === this.isApproved;
        this.request.isDenied = request.statusId === this.isDenied;
        this.request.isPending = request.statusId === this.isPending;
        this.isLoadingDetails = true;
    }

    addDetails(details: IEncircaCommissionAdvanceRequestDetails) {
        this.details = details;
        this.isLoadingDetails = false;
    }
}