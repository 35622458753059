import { IUser } from '../../../core/models/user.model.interface';
import { IUserService } from '../../../core/services/user.service.interface';
import MassTreatmentPricingDetailController from './detail/massTreatmentPricingDetail.controller';
import MassPricingRequestsService from './massPricingRequests.service';
import { MassPricingRequest } from './request/models/massPricingRequest.model';
import { MassPricingDetailRequest } from './detail/massPricingDetail.model';

export default class MassTreatmentPricingRequestsController {

    navigationText: string = 'Mass Treatment Pricing Requests';
    canRequest: boolean = false;
    hideToolbar: boolean = false;
    canMassApprove: boolean = true;
    loading: boolean = true;
    requests: Array<MassPricingRequest>

    constructor(
        protected $mdDialog: ng.material.IDialogService,
        protected $mdSidenav: ng.material.ISidenavService,
        protected userService: IUserService,
        protected massPricingRequestsService: MassPricingRequestsService
    ) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    user: IUser;

    openLeftNav(): void {
        this.$mdSidenav('left').open();
    }

    viewDetails($event: MouseEvent, request: MassPricingRequest, detailType: string): void {
        $event.stopPropagation();

        if (detailType === 'approve') {
            var massPricingDetailRequest = new MassPricingDetailRequest(request.fileName, this.removeSpecialCharacters(request.fileVersion), true, 2, 'Approved');
            this.loading = true;
            this.massPricingRequestsService.approveMassPricingRequest(massPricingDetailRequest)
                .then((response) => {
                    this.refreshResults();
                });
        } else {
            this.$mdDialog.show({
                template: require('./detail/massTreatmentPricingDetail.html'),
                parent: angular.element(document.body),
                targetEvent: $event,
                controller: MassTreatmentPricingDetailController,
                controllerAs: 'vm',
                locals: {
                    request: request,
                    detailType: detailType
                },
                fullscreen: true
            }).finally(() => {
                this.refreshResults();
            });
        }
    }

    refreshResults() {
        this.loading = true;
        this.getRequests();
    }

    canShowTable(): boolean {
        return !this.loading;
    }

    nothingToApprove(): boolean {
        return !this.loading && this.requests.length === 0;
    }

    private activate() {
        this.user = this.userService.get();
        this.canRequest = this.user.canCreatePricingRequests;

        this.getRequests();
    }

    private done(): void {
        this.loading = false;
    }

    private getRequests(): void {
        this.loading = true;
        this.requests = [];
        this.massPricingRequestsService.getMassTreatmentPricingApprovalList()
            .then((response) => {
                this.requests = response.data;
                this.done();
            });
    }

    private removeSpecialCharacters(input: string): string {
        return input.replace(/[:.-]/g, '');
    }
}