import {apiInterceptor} from './interceptors/api.interceptor';
import {authorizationInterceptor} from './interceptors/authorization.interceptor';
import {encodeUriInterceptor} from './interceptors/encodeURI.interceptor';
import {errorInterceptor } from './interceptors/error.interceptor';
import {httpHeaderInterceptor } from './interceptors/httpHeader.interceptor';
import {httpMethodInterceptor } from './interceptors/httpMethod.interceptor';
import {httpPostSetContentTypeWhenBodyIsEmptyInterceptor } from './interceptors/httpPostSetContentTypeWhenBodyIsEmpty.interceptor';
import {httpResponseCodeOverrideInterceptor } from './interceptors/httpResponseCodeOverride.interceptor';

export default function config($routeProvider: ng.route.IRouteProvider, $httpProvider: ng.IHttpProvider) {

    $routeProvider.otherwise({
        redirectTo: '/login/'
    });

    $httpProvider.interceptors.push(apiInterceptor);
    $httpProvider.interceptors.push(authorizationInterceptor);
    $httpProvider.interceptors.push(encodeUriInterceptor);
    $httpProvider.interceptors.push(errorInterceptor);
    $httpProvider.interceptors.push(httpMethodInterceptor);
    $httpProvider.interceptors.push(httpHeaderInterceptor);
    $httpProvider.interceptors.push(httpPostSetContentTypeWhenBodyIsEmptyInterceptor);
    $httpProvider.interceptors.push(httpResponseCodeOverrideInterceptor);
}
