// ./
import {IAuthenticatedUserService} from './authenticatedUser.service.interface';

// ../core/services/
import {IHeaderService} from '../services/header.service.interface';
import {HeaderKeyEnum} from '../services/headerKey.enum';
import {IStorageService} from '../services/storage.service.interface';
import {StorageKeyEnum} from '../services/storageKey.enum';

export class AuthenticatedUserService implements IAuthenticatedUserService {

    username: string;
    password: string;

    private timeoutInMinutes: number = 30;

    constructor(
        private headerService: IHeaderService,
        private storageService: IStorageService) {
    }

    get isSessionActive(): boolean {
        const activeUntil: any = this.storageService.get(StorageKeyEnum.SessionActiveUntil);
        return (activeUntil && (new Date(activeUntil).getTime() > new Date().getTime()));
    }

    setCurrentUser(username: string, password: string): void {
        const encoded: string = btoa(username + ':' + password);
        const authorizationHeader: string = `Basic ${encoded}`;

        this.headerService.set(HeaderKeyEnum.Authorization, authorizationHeader);
        this.storageService.set(StorageKeyEnum.Authorization, authorizationHeader);
        this.resetSessionActiveStartTime();
    }

    invalidateCurrentUser(): void {
        this.headerService.delete(HeaderKeyEnum.Authorization);
        this.headerService.delete(HeaderKeyEnum.Impersonate);

        this.storageService.clear();
        localStorage.removeItem('logged-In-User');
    }

    get sessionTimedOut(): boolean {
        return localStorage.getItem('SessionTimedOut') === 'true';
    }

    set sessionTimedOut(value: boolean) {
        localStorage.setItem('SessionTimedOut', value.toString());
    }

    resetSessionActiveStartTime() {
        this.storageService.set(StorageKeyEnum.SessionActiveUntil, new Date(new Date().getTime() + this.timeoutInMinutes * 60000).toString());
        this.sessionTimedOut = false;
    }
};
