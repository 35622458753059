import { IProposalCpaDetailBarComponentController } from './proposalcpaDetailBar.component.controller.interface';

import { ProposalCpaRequest } from '../../proposalcpaRequest.model';
import { ProposalCpaDetail } from '../proposalcpaDetail.model';

export default class ProposalCpaDetailBarComponentController implements IProposalCpaDetailBarComponentController{
    request: ProposalCpaRequest;
    detail: ProposalCpaDetail;

    constructor() { }

    $onInit(): void {
        // no-op
    }

}