import BudgetInfoComponentController from './budgetInfo.component.controller';

export default class BudgetInfoComponent implements ng.IComponentOptions {
    template: string = require('./budgetInfo.html');
    bindings: { [binding: string]: string } = {
        shareId: '<',
        invoiceId: '<',
        proposalId: '<?'
    };
    controller: Function = BudgetInfoComponentController;

    static factory() {
        const component: Function = () => {
            return new BudgetInfoComponent();
        };

        return component();
    }
}