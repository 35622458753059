import {IMonthlyDrawRequestService} from './monthlyDrawRequest.service.interface';
import {MonthlyDrawRequestRow} from './monthlyDrawRequestRow';
import {MonthlyDrawDetailController} from './monthlyDrawDetail.controller';
import {RequestStatusTypes} from '../core/requestStatusTypes';

let async: any = require('async');

export default class MonthlyDrawPendingRequestController {

    loading: boolean = true;
    requests: Array<MonthlyDrawRequestRow>;

    private concurrentDetailsCalls: number = 3;

    constructor(private monthlyDrawRequestService: IMonthlyDrawRequestService,
        private $mdDialog: ng.material.IDialogService) {
        this.$onInit();
    }

    $onInit(): void {
        this.activate();
    }

    viewDetails($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        this.$mdDialog.show({
            template: require('./monthlyDrawDetail.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            controller: MonthlyDrawDetailController,
            controllerAs: '$ctrl',
            locals: {
                request: row.request,
                details: row.details
            },
            fullscreen: true
        }).finally(() => {
            this.reloadData(row);
        });
    }

    approve($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        $event.stopPropagation();
        if (row.details.drawAmount <= 0) {
            return;
        }

        row.request.processing = true;
        row.request.statusId = RequestStatusTypes.approved;
        this.monthlyDrawRequestService.approveDeny(row)
            .then(() => {
                this.refreshSavedRow(row);
            })
            .catch(() => {
                row.request.statusId = RequestStatusTypes.pending;
                row.request.processing = false;
            });
    }

    deny($event: MouseEvent, row: MonthlyDrawRequestRow): void {
        $event.stopPropagation();
        row.request.processing = true;
        row.request.statusId = RequestStatusTypes.denied;
        this.monthlyDrawRequestService.approveDeny(row)
            .then(() => {
                this.refreshSavedRow(row);
            })
            .catch(() => {
                row.request.statusId = RequestStatusTypes.pending;
                row.request.processing = false;
            });
    }

    canApproveDeny(statusId: number): boolean {
        return statusId === RequestStatusTypes.pending;
    }

    isApproved(statusId: number): boolean {
        return statusId === RequestStatusTypes.approved;
    }

    isDenied(statusId: number): boolean {
        return statusId === RequestStatusTypes.denied;
    }

    private activate() {
        this.loadData();
    }

    private loadData() {
        this.monthlyDrawRequestService.listPendingRequests()
            .then((requests) => {
                this.requests = requests.map(req => new MonthlyDrawRequestRow(req));
                this.loadDetailsForRequests();
            })
            .finally(() => this.done());
    }

    private loadDetailsForRequests() {
        const queue = async.queue((row: MonthlyDrawRequestRow, callback: Function) => {
            this.getRequestDetails(row).then(() => callback());
        }, this.concurrentDetailsCalls);
        this.requests.forEach((row) => queue.push(row));
    }

    private done(): void {
        this.loading = false;
    }

    private getRequestDetails(row: MonthlyDrawRequestRow): ng.IPromise<any> {
        if (row.request.statusId === RequestStatusTypes.pending) {
            return this.monthlyDrawRequestService.getMonthlyDrawDetails(row.request.salesYear, row.request.salesAgencyId, row.request.monthlyDrawDateId)
                .then(details => {
                    if (!details.invalidRequestDetail) {
                        row.addDetails(details);
                    } else {
                        _.remove(this.requests, row);
                    }
                });
        } else {
            return this.monthlyDrawRequestService.getSavedDetails(row.request.requestId)
                .then(details => row.addDetails(details));
        }
    }

    private reloadData(monthlyDrawRow: MonthlyDrawRequestRow): void {
        if (monthlyDrawRow.request.statusId !== RequestStatusTypes.pending) {
            this.refreshSavedRow(monthlyDrawRow);
        }
    }

    private refreshSavedRow(row: MonthlyDrawRequestRow): void {
        this.monthlyDrawRequestService
            .getSavedMonthlyDrawRequest(row.request.requestId)
            .then(newRow => {
                row.request = newRow.request;
                row.details = newRow.details;
            })
            .finally(() => {
                row.request.processing = false;
            });
    }
}