import {IUserService} from '../../services/user.service.interface';
import {IUser} from '../../models/user.model.interface';
import {IStorageService} from '../../services/storage.service.interface';
import {StorageKeyEnum} from '../../services/storageKey.enum';

import ImpersonateDialogController from './dialog/impersonate.dialog.controller';

export default class ImperonsateComponentController {

    user: IUser;
    impersonated: boolean;
    authUserName: string;
    authUserEmail: string;

    constructor(
        private $mdDialog: ng.material.IDialogService,
        private userService: IUserService,
        private storageService: IStorageService) {
        
    }

    $onInit(): void {
        this.activate();
    }

    private activate(): void {
        this.user = this.userService.get();

        this.impersonated = this.storageService.exists(StorageKeyEnum.Impersonate) === true ? true : false;
        this.authUserName = this.user.name;
        this.authUserEmail = this.user.email;
    }

    canImpersonate(): boolean {
        return this.user.canImpersonate;
    }

    showDialog($event: MouseEvent): void {
        this.$mdDialog.show({
            template: require('./dialog/impersonate.dialog.html'),
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: ImpersonateDialogController,
            controllerAs: '$ctrl'
        });
    }
}