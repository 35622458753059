import {ICommissionPermissionsService} from './commissionPermissions.service.interface';
import {ICommissionPermissions} from './commissionPermissions.interface';

export class CommissionPermissionsService implements ICommissionPermissionsService {

    static $inject: string[] = ['$http'];

    constructor(private $http: ng.IHttpService) {
        // noop
    }

    getPermissions(): ng.IPromise<ICommissionPermissions> {
        return this.$http.get<ICommissionPermissions>('{API-ApprovalTool}/commissions/permissions')
            .then((response) => response.data);
    }
}